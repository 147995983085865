import {
  CircularProgress,
  sButton,
  Fab,
  TextField,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Alert,
  Snackbar,
  Dialog,
  CardActions,
  Button,
  List,
  ListItem,
} from "@mui/material";
import {io} from "socket.io-client";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import AddItem from "src/components/FloorLayout/AddItem";
import {makeStyles} from "tss-react/mui";
import React, {useEffect, useState, useRef} from "react";
import {Helmet} from "react-helmet";
import {useDispatch, useSelector} from "react-redux";
import DateTimePicker from "src/components/dashboard/DateTimePicker";
import GridContainers from "src/components/dashboard/GridContainers";
import Spinner from "src/utils/spinner/spinner";
import {Steps, Hints} from "intro.js-react";
import "intro.js/introjs.css";
import "intro.js/themes/introjs-modern.css";
import {Toursteps} from "src/components/TourJS/Tousteps";
import {setTourStatus} from "src/components/TourJS/helpers";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  Truck as TruckIcon,
  User as UserIcon,
  Users as UsersIcon,
  DollarSign as DollarIcon,
  Monitor as KdsIcon,
} from "react-feather";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import {RiLayoutGridFill} from "react-icons/ri";
import {useNavigate} from "react-router";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      width: "100%",
      maxWidth: 250,
    },
  
    item: {
      padding: 0
    },
    switch: {
      position: "absolute",
      top: "100px",
      right: "30px",
    },
    tile: {
      cursor: "pointer",
      border: "0px",
    },
    tileWrapper: {
      padding: "0px",
      border: "0px",
      width: "150px",
      backgroundColor: "white"
    },
    newOrderTileWrapper: {
      padding: "0px",
      border: "0px",
      width: "250px",
      backgroundColor: "#ffd6d6"
    },
    icon: {
      width: "30px",
      height: "30px",
      backgroundColor: "#ffd6d6",
      padding: "5px",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "10px"
    },
    // tilesGrid: {
    //   [theme.breakpoints.down("sm")]: {
    //     display: "flex",
    //     justifyContent: "center",
    //   },
    // },
  };
});

function Dashboard() {
  const {classes} = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const particularRestaurant = useSelector((state) => state.restaurant);
  const restaurant = useSelector((state) => state.restaurant);

  const [orderName, setOrderName] = useState();
  const [takeout, settakeout] = useState(false);
  const socket = useRef();
  const [tempSocket, setTempSocket] = useState(null);
  const [newOrder, setNewOrder] = useState(false);
  const [startingDate, setStartingDate] = useState("");
  const [endingDate, setEndingDate] = useState("");
  const [currentRestaurant, setCurrentRestaurant] = useState(null);
  const [initialStep, setInitialStep] = useState(0);
  const {firstTimeLogin} = useSelector((state) => state.auth);
  const [stepsEnabled, setStepsEnabled] = useState(false);

  const {name, role} = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [addItemBtnLoading, setAddItemBtnLoading] = useState();
  const [menuScreen, setMenuScreen] = useState(false);
  const [tableNo, setTableNo] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const flexContainer = {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    overflowX: "auto",
    overflowY: "auto",
    scrollBehavior: "auto",
    backgroundColor: "white"
  };

  
  useEffect(() => {
    if (particularRestaurant._id) setCurrentRestaurant(particularRestaurant);
  }, [particularRestaurant._id]);

  useEffect(() => {
    if (firstTimeLogin) {
      setStepsEnabled(firstTimeLogin);
    }
  }, [firstTimeLogin]);

  useEffect(() => {
    const connect = async () => {
      const makeConnection = async () => {
        // socket.current = io("wss://api.dinify.io", {transports: ["websocket"]});
        socket.current = io(process.env.REACT_APP_WEB_SOCKET_URL, {
          transports: ["websocket"],
        });
        //       socket.current = io("ws://localhost:3003");
        setTempSocket(socket);
      };
      makeConnection();
    };
    connect();
  });

  const exit = () => {
    let tourStatus = "off";
    setTourStatus(tourStatus).then(window.location.reload());
  };

  const items = !(role == "ROLE_EMPLOYEE")
    ? [
        {
          href: "/app/orders",
          icon: <TruckIcon size="15px" />,
          title: "Orders",
          className: "orders",
          desc: "Manage orders",
        },
        {
          href: "/app/floorlayout",
          icon: <RiLayoutGridFill size="15px" />,
          title: "Floor Layout",
          className: "floorlayout",
          desc: "Manage dine-in",
        },
        {
          href: "/app/products",
          icon: <ShoppingBagIcon size="15px" />,
          title: "Menu",
          className: "products",
          desc: "Manage menu",
        },
        {
          href: "/app/customers",
          icon: <UsersIcon size="15px" />,
          title: "Employees",
          className: "customers",
          desc: "Employee details",
        },
        {
          href: "/app/accounting",
          icon: <DollarIcon size="15px" />,
          title: "Accounting",
          className: "accounting",
          desc: "Accounting data",
        },
        {
          href: "/app/account",
          icon: <UserIcon size="15px" />,
          title: "Profile",
          className: "account",
          desc: "Profile details",
        },
        {
          href: "/app/manageaccount",
          icon: <SettingsIcon size="15px" />,
          title: "Settings",
          className: "manageaccount",
          desc: "Settings",
        },
        {
          href: "/app/kds",
          icon: <KdsIcon size="15px" />,
          title: "KDS",
          className: "kds",
          desc: "Manage kitchen",
        },
        {
          href: "/app/knowledgebase",
          icon: <MenuBookIcon fontSize="15px" />,
          title: "Knowledge",
          className: "knowledgebase",
          desc: "Learn from here",
        },
        // restaurant.multilocation && {
        //   href: "/app/managelocation",
        //   icon: <LocationIcon />,
        //   title: "Manage Locations",
        //   className: "managelocation",
        // },
      ]
    : [
        {
          href: "/app/orders",
          icon: <TruckIcon size="15px" />,
          title: "Orders",
          desc: "Manage your orders",
        },
        {
          href: "/app/floorlayout",
          icon: <RiLayoutGridFill size="15px" />,
          title: "Floor Layout",
          className: "floorlayout",
          desc: "Manage tables",
        },
        {
          href: "/app/kds",
          icon: <KdsIcon size="15px" />,
          title: "KDS",
          className: "kds",
          desc: "Manage your kitchen",
        },
      ];

  return (
    <>
      <Helmet>
        <title>Dashboard | Dinify</title>
      </Helmet>

      <Box sx={{mx: 3, mt: 4}}>
      <Card className={classes.newOrderTileWrapper} sx={{backgroundColor: "#ffd6d6", width: "100%", height: "100%"}}>
              <CardContent sx={{textAlign: "center"}}>
                <div className={classes.wrapper}>
                  <Box
                    alignItems="center"
                    onClick={() => {
                      setMenuScreen(true);
                    }}
                    onTouchStart={() => {
                      setMenuScreen(true);
                    }}
                  >
                    {matches ? (
                      <Typography sx={{mt: 2, color: "white"}} style ={{ color: "black" }} variant="h3">
                        Takeout Order
                      </Typography>
                    ) : (
                      <AddIcon sx={{mt: 1}} />
                    )}
                  </Box>
                </div>
                <Dialog aria-labelledby="simple-dialog-title" open={menuScreen}>
                  {true && (
                    <>
                      <CloseIcon
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "5px",
                          // margin: "10px",
                          cursor: "pointer",
                          fontSize: "30px",
                          color: "#fff",
                        }}
                        onClick={() => setMenuScreen(false)}
                      />
                      <AddItem
                        tableNo={"takeout"}
                        tempSocket={tempSocket}
                        setMenuScreen={setMenuScreen}
                        setShowSuccess={setShowSuccess}
                        newOrder={"true"}
                        setNewOrder={setNewOrder}
                      />
                    </>
                  )}
                </Dialog>
              </CardContent>
            </Card>
      </Box>
      <Box sx={{mx: 3, mt: 4}}>
        <List style={flexContainer}>
        {/* <Grid container spacing={2} className={classes.tilesGrid}> */}
          {/* <Grid item> */}
          {/* <ListItem style={root}>
            
          </ListItem> */}
          
          {items.map((tile) => {
            return (
              <ListItem item key={tile.title} classes={{ root: classes.item }}>
                <Card className={classes.tileWrapper} style={{ border: "none", boxShadow: "none" }}>
                  <CardContent>
                    <Box
                      display={"flex"}
                      flexDirection="column"
                      alignItems="center"
                      onClick={() => {
                        navigate(tile.href, {replace: true});
                      }}
                      className={classes.tile}
                    >
                      {/* <Box display={"flex"} alignItems="center"> */}
                        <Box className={classes.icon}>
                          {tile.icon}
                        </Box>
                        <Typography variant="h4" sx={{ml: 0}}>
                          {tile.title}
                        </Typography>
                        {matches ? (
                        <Typography variant="body2" sx={{mt: 0.5}}>
                          {tile.desc}
                        </Typography>
                        ) : ""}
                      {/* </Box> */}
                      
                    </Box>
                  </CardContent>
                </Card>
              </ListItem>
            );
          })}
        </List>
      </Box>
      <Dialog aria-labelledby="simple-dialog-title" open={takeout}>
        {tempSocket && (
          <>
            <CloseIcon
              style={{
                position: "absolute",
                right: "10px",
                top: "5px",
                // margin: "10px",
                cursor: "pointer",
                fontSize: "30px",
                color: "#fff",
              }}
              onClick={() => settakeout(false)}
            />
            <AddItem
              tableNo={"takeout"}
              tempSocket={tempSocket}
              setMenuScreen={settakeout}
              setShowSuccess={setShowSuccess}
              newOrder={true}
              setNewOrder={setNewOrder}
            />
          </>
        )}
      </Dialog>
      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => setShowSuccess(false)}
      >
        <Alert
          onClose={() => setShowSuccess(false)}
          variant="filled"
          severity="success"
          style={{margin: "auto"}}
        >
          New Order has been added successfully!
        </Alert>
      </Snackbar>
      <Box style={{margin: "40px 20px 20px 0", width: "fit-content"}}>
        <DateTimePicker setStartingDate={setStartingDate} setEndingDate={setEndingDate} />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" minHeight="100vh">
        {currentRestaurant ? (
          <GridContainers startingDate={startingDate} endingDate={endingDate} />
        ) : (
          <Spinner />
        )}
      </Box>
      {stepsEnabled ? (
        <Steps
          enabled={stepsEnabled}
          steps={Toursteps}
          initialStep={initialStep}
          onExit={exit}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default Dashboard;
