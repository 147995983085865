import React, {useState} from "react";
import {
  Box,
  Collapse,
  TableCell,
  TableRow,
  IconButton,
  Chip,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import {Link} from "react-router-dom";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {KeyboardArrowUp, Phone} from "@mui/icons-material";
import Email from "@mui/icons-material/Email";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {makeStyles} from "tss-react/mui";
const useStyles = makeStyles()(() => {
  return {
    requiredBox: {
      border: "1px solid #df2800",
      color: "#df2800",
      borderRadius: "5px",
      padding: "3px 2px",
      lineHeight: 0.5,
      marginRight: "5px",
      outline: "none !important",
      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
      margin: "0 3px",
      minWidth: "fit-content",
    },
  };
});

export default function TableContent({row, index}) {
  const {classes} = useStyles();
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow key={index} sx={{"& > *": {borderBottom: "unset"}}}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Breadcrumbs>
            <Link to={`/app/CRM/${row._id}?user=${row.name}`}>{row.name}</Link>
          </Breadcrumbs>
        </TableCell>
        <TableCell>{row.orderedAt}</TableCell>
        <TableCell>{row.type}</TableCell>
        <TableCell>${row.TotalPrice}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{margin: 1}}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  overflowX: "auto",
                  marginTop: "5px",
                }}
              >
                <Typography size="large" style={{marginRight: 10}}>
                  Items ordered:
                </Typography>
                {row.items.map((item) => (
                  <Box className={classes.requiredBox} key={item}>
                    <Typography
                      variant="caption"
                      fontSize="medium"
                      style={{lineHeight: 1.1}}
                    >
                      {item}
                    </Typography>
                  </Box>
                ))}
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  overflowX: "auto",
                  marginTop: "5px",
                }}
              >
                {row.email !== "not available" && (
                  <Chip
                    variant="outlined"
                    size="medium"
                    color="default"
                    icon={<Email />}
                    label={row.email}
                  />
                )}
                {row.phone !== "not available" && (
                  <Chip
                    variant="outlined"
                    size="medium"
                    color="default"
                    icon={<Phone />}
                    label={row.phone}
                  />
                )}
                {row.newCustomer !== "Not known" && (
                  <Chip
                    variant="outlined"
                    size="medium"
                    color="primary"
                    icon={row.newCustomer ? <NewReleasesIcon /> : <CheckCircleIcon />}
                    label={row.newCustomer ? "New Customer" : "Repeating Customer"}
                  />
                )}
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
