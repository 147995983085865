// import { UploadLogo } from 'src/components/account/helpers'
import {Router} from "react-router";
import {UploadLogo} from "src/components/account/helpers";
import {deleteFromLocal, makeDecrypt, makeEncrypt} from "src/utils/securels";
import axios from "../../axios";
import {
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  SET_USER,
  SET_ONBOARDED,
  SELECTED_CATEGORY,
  LOADING_USER,
  SET_RESTAURANT,
  LOADING_RESTAURANT,
} from "../types";
import {
  createExpressAccount,
  createNewCustomer,
  createOnboardingLink,
  getRestaurantBranches,
} from "./restaurantActions";
// let history = useHistory();

export const CheckResturant = (userData) => async (dispatch) => {
  return axios
    .post("/auth/check-restaurant", userData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.message;
    });
};

export const loginAction = (userData, navigate) => async (dispatch) => {
  return axios
    .post("/auth/signin-employee", userData)
    .then((response) => {
      const jwt = `Bearer ${response.data.token}`;
      makeEncrypt("jwt", jwt);
      makeEncrypt("employeeId", response.data.employee._id);
      dispatch({type: SET_AUTHENTICATED});
      dispatch({
        type: SET_USER,
        payload: response.data.employee,
      });
      axios.defaults.headers.common.Authorization = jwt;
      dispatch(getUserData(navigate, userData, false));
    })
    .catch((error) => {
      return error.message;
    });
};

export const signUpAction = (userData, navigate) => (dispatch) => {
  return axios.post("/auth/signup-admin", userData);
};

export const logoutAction = () => (dispatch) => {
  deleteFromLocal("jwt");
  deleteFromLocal("verified");
  deleteFromLocal("onboarded");
  deleteFromLocal("restaurantName");
  deleteFromLocal("printerId");
  deleteFromLocal("employeeId");
  deleteFromLocal("restaurantId");
  delete axios.defaults.headers.common.Authorization;
  dispatch({type: SET_UNAUTHENTICATED});
  axios.get("/auth/signout-employee");
  window.location.href = "/login";
};

const setRestaurantDataToLs = (navigate, dispatch, res) => {
  if (res.data.employee && res.data.restaurant) {
    dispatch({
      type: SET_USER,
      payload: res.data.employee,
    });
    dispatch({
      type: SET_RESTAURANT,
      payload: res.data.restaurant,
    });
    if (res.data.restaurant.multilocation) {
      dispatch(getRestaurantBranches());
    }
    makeEncrypt("restaurantId", res.data.restaurant._id);
    makeEncrypt("restaurantName", res.data.restaurant.name);
    makeEncrypt("printerId", res.data.restaurant.printerId);
  } else {
    dispatch({
      type: SET_USER,
      payload: res.data.employee,
    });
  }
  if (res.data.employee.restaurant) {
    makeEncrypt("onboarded", true);
    dispatch({
      type: SET_ONBOARDED,
    });
    if (res.data.restaurant) {
      if (res.data.restaurant.isVerified) {
        // navigate('/app/dashboard')
        makeEncrypt("verified", true);
      } else {
        navigate("/verification/pendingVerification");
        makeEncrypt("verified", false);
      }
    } else {
      navigate("/verification/pendingVerification");
      makeEncrypt("verified", false);
    }
  } else {
    navigate("/restaurant/onboarding");
    makeEncrypt("onboarded", false);
    dispatch({
      type: SET_ONBOARDED,
    });
  }
};
export const getUserData = (navigate, userData, shouldReload) => async (dispatch) => {
  if (!shouldReload) {
    dispatch({type: LOADING_USER});
    dispatch({type: LOADING_RESTAURANT});
  }
  axios
    .get(`/auth/loggedInEmployee?restaurantId=${userData.restaurantId}`, {
      headers: {Authorization: makeDecrypt("jwt")},
    })
    .then((res) => {
      if (makeDecrypt("restaurantId")) {
        if (res.data.restaurant._id == makeDecrypt("restaurantId")) {
          setRestaurantDataToLs(navigate, dispatch, res);
        } else {
          dispatch(changeBranch(makeDecrypt("restaurantId")));
        }
      } else {
        setRestaurantDataToLs(navigate, dispatch, res);
      }
    })
    .catch((error) => {
      return error.message;
    });
};

export const changeBranch = (selectedBranch) => async (dispatch) => {
  axios
    .get(`/restaurant/restaurant-details/${selectedBranch}`, {
      headers: {Authorization: makeDecrypt("jwt")},
    })
    .then((branch) => {
      let branchData = branch ? branch.data : null;

      if (branchData) {
        axios
          .get("/auth/loggedInEmployee?onlyEmployee=true", {
            headers: {Authorization: makeDecrypt("jwt")},
          })
          .then((thisRes) => {
            dispatch({
              type: SET_USER,
              payload: thisRes.data,
            });

            dispatch({
              type: SET_RESTAURANT,
              payload: branchData,
            });

            if (branchData.multilocation) {
              dispatch(getRestaurantBranches());
            }
            makeEncrypt("restaurantId", branchData._id);
            makeEncrypt("restaurantName", branchData.name);
            makeEncrypt("printerId", branchData.printerId);
          });
      }
    })
    .catch((error) => {});
};

export const onBoardRestaurant =
  (
    restaurantData,
    logoImage,
    navigate,
    newBranch,
    executeSubscription,
    subscriptionLink
  ) =>
  (dispatch) => {
    dispatch({type: SET_ONBOARDED});
    axios
      .post(`/restaurant/onboard-restaurant?newBranch=${newBranch}`, restaurantData, {
        headers: {Authorization: makeDecrypt("jwt")},
      })
      .then(async (res) => {
        if (!newBranch) makeEncrypt("restaurantId", res.data._id);
        else makeEncrypt("branchRestaurantId", res.data._id);
        makeEncrypt("legalRepresentative", restaurantData.legalRepresentative);

        const formData = new FormData();
        formData.append("file", logoImage);
        try {
          const newData = await UploadLogo(formData, res.data._id);
          let accountId;
          let onboardingLink;
          if (makeDecrypt("country") == "CANADA") {
            const resData = await createExpressAccount();
            accountId = resData.id;
            const onboardingLinkData = await createOnboardingLink(accountId);

            onboardingLink = onboardingLinkData.url;
          } else if (makeDecrypt("country") == "INDIA") {
            onboardingLink = subscriptionLink;
          }

          // updating restaurant with logo image
          //Note:If restaurant logo is not there this call will not get execute(Fix it)
          const newRestaurant = await axios.put(
            `/restaurant/restaurant-details/${res.data._id}`,
            {
              logoUrl: newData.location,
              stripeAccountId: accountId,
              stripeOnboardingLink: onboardingLink,
              customerId: makeDecrypt("customerId"),
            },
            {headers: {Authorization: makeDecrypt("jwt")}}
          );
          localStorage.removeItem("customerId");

          executeSubscription();
        } catch (error) {
          return error.message;
        }
      })
      .catch((error) => {
        return error.message;
      });
    // navigate('/verification/pendingVerification')
  };

export const forgotPasswordAction = (userData) => {
  return axios.post("/auth/forgot-password", userData).then((res) => res.data);
};

export const resetPasswordAction = (userData) => {
  return axios
    .post("/auth/reset-password", userData)
    .then((res) => res.data)
    .catch((error) => {
      return error;
    });
};
