import {makeDecrypt} from "src/utils/securels";
import axios from "../../../axios";

export const getMenuItem = async (id) => {
  return await axios
    .get(`/menu/${id}/menu-item-detail`, {
      headers: {Authorization: makeDecrypt("jwt")},
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.message;
    });
};

export const getLoggedInEmployee = async () => {
  return await axios
    .get(`/auth/loggedInEmployee`, {
      headers: {Authorization: makeDecrypt("jwt")},
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.message;
    });
};

export const cancelOrder = async (orderId) => {
  const employeeId = makeDecrypt("employeeId");
  const restId = makeDecrypt("restaurantId");
  return await axios
    .put(
      `/order/${employeeId}/${restId}/${orderId}/cancel-order`,
      {},
      {headers: {Authorization: makeDecrypt("jwt")}}
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.message;
    });
};

export const acceptOrder = async (orderId, isCapturePayment) => {
  const employeeId = makeDecrypt("employeeId");
  const restId = makeDecrypt("restaurantId");
  return await axios
    .put(
      `/order/${employeeId}/${restId}/${orderId}/accept-order`,
      {paymentDone: isCapturePayment},
      {headers: {Authorization: makeDecrypt("jwt")}}
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.message;
    });
};

export const completeOrder = async (orderId, paymentIntentId) => {
  const employeeId = makeDecrypt("employeeId");
  const restId = makeDecrypt("restaurantId");
  return axios
    .put(
      `/order/${employeeId}/${restId}/${orderId}/complete-order-restaurant`,
      {paymentIntentId: paymentIntentId},
      {headers: {Authorization: makeDecrypt("jwt")}}
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.message;
    });
};

export const getOrdersByPage = async (days, page, limit, tab, orderType) => {
  const employeeId = makeDecrypt("employeeId");
  const restId = makeDecrypt("restaurantId");
  return await axios
    .get(
      `/order/${employeeId}/${restId}/${days}/orders?page=${page}&limit=${limit}&tab=${tab}&orderType=${orderType}`,
      {headers: {Authorization: makeDecrypt("jwt")}}
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.message;
    });
};

export const getLiveOrdersForEachTime = () => {
  const employeeId = makeDecrypt("employeeId");
  const restId = makeDecrypt("restaurantId");
  return axios
    .get(`/order/${employeeId}/${restId}/live-orders`, {
      headers: {Authorization: makeDecrypt("jwt")},
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.message;
    });
};

export const getUser = async (userId) => {
  return await axios
    .post(`/user/get-user-details`, {
      userId: userId,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.message;
    });
};

export const capturePayment = async (paymentIntentId, stripeAccountId) => {
  return await axios
    .post("/stripe/capturePayment", {
      PaymentIntentId: paymentIntentId,
      stripeAccountId: stripeAccountId,
    })
    .then((res) => {
      if (res.data.status === "succeeded") {
        return res.data;
      }
    })
    .catch((error) => {
      return error.message;
    });
};

export const refundPayment = async (paymentIntentId, stripeAccountId, amount) => {
  return await axios
    .post("/stripe/refundPayment", {
      PaymentIntentId: paymentIntentId,
      stripeAccountId: stripeAccountId,
      amount: amount,
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.message;
    });
};
export const printData = async (data, dinify_print_station) => {
  return await axios
    .post(
      `/order/${data.restaurantId}/${data.orderId}/print-order?dinify_print_station=${dinify_print_station}`,
      data
    )
    .then((res) => res.data)
    .catch((error) => {
      return error.message;
    });
};
export const updateUser = async (userId, data) => {
  return await axios
    .patch(`/user/update-user/${userId}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.message;
    });
};

export const updateOrder = async (orderId, data) => {
  return await axios
    .put(`/order/${makeDecrypt("restaurantId")}/${orderId}/update-order`, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.message;
    });
};

export const printTheReceipt = async (orderId, data) => {
  return await axios
    .post(`/order/${makeDecrypt("restaurantId")}/${orderId}/print-order-sample`, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.message;
    });
};

export const paymentRequestToClover = async ({
  order,
  deviceId,
  apiToken,
  restaurantName,
  splitting,
  bill,
}) => {
  return await axios
    .post(`/clover/request-payment-to-clover?split=${splitting}`, {
      order,
      deviceId,
      apiToken,
      restaurantName,
      bill,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.message;
    });
};
export const SendEmail = (email, order, items, restaurant) => {
  axios
    .post(
      `/order/send-receipt-mail`,
      {
        email: email,
        order: order,
        items: items,
        restaurant: restaurant,
      },
      {
        headers: {Authorization: makeDecrypt("jwt")},
      }
    )
    .then((response) => {
      return response.data.message;
    });
};
