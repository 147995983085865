import {Box, TextField} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {makeStyles} from "tss-react/mui";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {makeDecrypt} from "src/utils/securels";
import {deleteTag} from "./helpers";

const useStyles = makeStyles()((theme) => {
  return {
    options: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "auto",
      // width:'fit-content',
      padding: "15px 30px 15px 30px",
      // zIndex:11,
      // backgroundColor: 'red',
      // backgroundColor:"white",
      // position:'absolute',
      // top:0,
      // right:0
    },
  };
});

function EachTag({tag}) {
  const dispatch = useDispatch();

  const [individualTag, setIndividualTag] = useState(null);

  useEffect(() => {
    setIndividualTag(tag);
  }, [tag]);

  const handleNameChange = (e) => {
    setIndividualTag({...individualTag, name: e.target.value});
  };

  const handleDeleteIconClick = () => {
    deleteTag(individualTag.name, makeDecrypt("restaurantId"), dispatch);
  };
  return (
    <>
      {individualTag ? (
        <Box display="flex" alignItems="center">
          {/* <input type="checkbox" /> */}
          <TextField
            fullWidth
            value={individualTag.name}
            disabled={individualTag.isDisable}
            onChange={handleNameChange}
            variant="outlined"
          />

          <DeleteIcon onClick={() => handleDeleteIconClick()} />
        </Box>
      ) : (
        ""
      )}
    </>
  );
}

export default EachTag;
