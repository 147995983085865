import { useEffect, useState, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import AddItem from "src/components/FloorLayout/AddItem";
import {
  Typography,
  AppBar,
  Tabs,
  Alert,
  Snackbar,
  Tab,
  Button,
  Box,
  Divider,
  Dialog,
  Breadcrumbs,
  Switch,
  FormControlLabel,
  Link,
} from "@mui/material/";
import Notifcation from "../Notification/notification1.mp3";
import longNotification from "../Notification/long-notification.mp3";
import { Helmet } from "react-helmet";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { getLiveOrders, getWaitingOrders, resetInitialLoad, setReload } from "src/redux/actions/orderActions";
import Spinner from "src/utils/spinner/spinner";
import TabPanel from "../components/order/TabPanel";
import PastOrders from "src/components/order/PastOrders";
import { io } from "socket.io-client";
import { getUserData } from "src/redux/actions/authActions";
import { useNavigate } from "react-router-dom";
import useSound from "use-sound";
import AlertMsg from "src/components/AlertMsg";
import { makeDecrypt, makeEncrypt } from "src/utils/securels";
import WaitingOrders from "src/components/order/WaitingOrders";
import { setThrottling, updateRestaurant } from "src/redux/actions/restaurantActions";
import OrderTools from "src/components/order/OrderTools";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Truck } from "react-feather";
import axios from "../axios";
import HomeIcon from "@mui/icons-material/Home";

const audio = new Audio(Notifcation);
const longAudio = new Audio(longNotification);

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      // backgroundColor: theme.palette.background.paper,
      width: "100%",
      // height: "100vh",
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1),
      },
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(3),
      },
    },
    ordersBlock: {
      // margin: `${theme.spacing(2)} 0`,
      backgroundColor: "#f8f9fa",
      marginBottom: "20px",
      padding: "10px",
    },
    button: {
      width: "20vw",
      [theme.breakpoints.down("sm")]: {
        fontSize: 8,
      },
    },
    heading: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
    },
    swipeableViews: {
      // padding: "0px",
      marginTop: "20px",
      minHeight: "300px",
    },
    prinCont: {
      display: "flex",
      alignItems: "center",
      marginRight: "10px",
    },
    throttlingBox: {
      display: "flex",
      boxShadow:
        "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;",
      padding: "7px",
      borderRadius: "50%",
    },
    circle: {
      width: "20px",
      height: "20px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      backgroundColor: "white",
      marginLeft: "5px",
      border: "1px solid ",
    },
    appBar: {
      backgroundColor: "white",
      boxShadow:
        "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
      border: "none",
      borderRadius: "5px",
    },
    breadCrumbs: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "2px 8px ",
      borderRadius: "20px",
      backgroundColor: "#ffffff",
      border: "1px solid #FF5757",
      fontSize: "15px",
      fontWeight: "500",
      "& .MuiBreadcrumbs-separator svg": {
        fontSize: "20px !important",
      },
    },
  };
});

const Order = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { order } = useSelector((state) => state);
  const { restaurant } = useSelector((state) => state);
  const { _id } = useSelector((state) => state.restaurant);
  const {loadingOrders, initialLoad} = useSelector((state)=> state.order);
  const { liveOrders, waitingOrders, reload } = order;
  const { classes } = useStyles();
  const theme = useTheme();
  const [showSuccess, setShowSuccess] = useState(false);
  const [value, setValue] = useState(0);
  const socket = useRef();
  const [takeout, settakeout] = useState(false);
  const [newOrder, setNewOrder] = useState(false);
  const [tempSocket, setTempSocket] = useState(null);
  const [printerStatus, setPrinterStatus] = useState(false);
  const [play, { stop }] = useSound(Notifcation);
  const [alertMessage, setAlertMessage] = useState("");
  const [flag, setFlag] = useState(false);
  // const [timer, setTimer] = useState();
  let timer;
  longAudio.preload;
  audio.preload;

  useEffect(() => {
    const makeConnection = async () => {
      // socket.current = io("wss://api.dinify.io", {transports: ["websocket"]});
      socket.current = io(process.env.REACT_APP_WEB_SOCKET_URL, {
        transports: ["websocket"],
      });
      //       socket.current = io("ws://localhost:3003");
      setTempSocket(socket);
      socket.current.on("refreshOrders", (data) => {
        if (data.restaurantId == makeDecrypt("restaurantId")) {
          audio.play();
          dispatch(setReload());
        }
      });

      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic MzVmMDRyVHM1Q284YUdaNGNyMlhuTDg0UkZtd2FobXFoLWpGQ3ZVMkRBVTo="
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      let test = await fetch("https://api.printnode.com/printers", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result[0].state === "online") {
            setPrinterStatus(true);
            makeEncrypt("printerStatus", true);
          } else {
            setPrinterStatus(false);
            makeEncrypt("printerStatus", false);
          }
        })
        .catch((error) => setAlertMessage(error.message));
    };
    makeConnection();
  }, []);

  useEffect(() => {
    dispatch(getLiveOrders());
    dispatch(getWaitingOrders());
    // }, [dispatch,reload,order]);
  }, [dispatch, reload, _id]);
  // useEffect(() => {
  //   if (
  //     liveOrders.some((eachOrder) => eachOrder.tableNumber == "takeout") &&
  //     liveOrders.some((eachOrder) =>
  //       eachOrder.items.some((item) => item.isAccepted !== true)
  //     ) &&
  //     longAudio.paused &&
  //     !flag
  //   ) {
  //     setFlag(true);
  //     longAudio.play();
  //     timer = setTimeout(() => {
  //       longAudio.pause();
  //       makeCall();
  //     }, 30000);
  //   } else if (timer) {
  //     clearTimeout(timer);
  //   }
  // }, [liveOrders]);

  useEffect(() => {
    if (
      liveOrders.some((eachOrder) =>
        eachOrder.items.some((item) => item.isAccepted == true)
      )
    )
      longAudio.pause();
  }, [liveOrders, longAudio]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const testSocket = () => {
    tempSocket.current.emit("test-socket", {
      data: "yes",
    });
  };

  const CustomTab = ({ name, count }) => {
    return (
      <Box display={"flex"}>
        <Typography variant="h6">{name}</Typography>
        {count ? (
          <Box className={classes.circle}>
            <Typography>{count}</Typography>
          </Box>
        ) : (
          ""
        )}
      </Box>
    );
  };

  const makeCall = () => {
    setFlag(false);

    if (
      liveOrders.some((eachOrder) =>
        eachOrder.items.some((item) => item.isAccepted !== true)
      ) &&
      liveOrders.makecalls
    )
      axios
        .post("/order/make-accept-order-call", {
          userPhoneNo: restaurant.restaurantAddress.phoneNo,
        })
        .then((response) => {
          // eslint-disable-next-line no-console
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
  };
  useEffect(() => {
    // Reset initialLoad when the component unmounts (leaving the order page)
    return () => {
      dispatch(resetInitialLoad());
    };
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Orders | Dinify</title>
      </Helmet>
      {loadingOrders && initialLoad ? (
        <Spinner />
      ) : (
        <div className={classes.root}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            style={{ marginBottom: "10px" }}
          >
            <Link
              color="primary"
              onClick={() => navigate("/app/dashboard")}
              style={{ cursor: "pointer" }}
            >
              <Box className={classes.breadCrumbs}>
                <HomeIcon
                  color="primary"
                  style={{ fontSize: "15px", marginRight: "2px" }}
                />
                Home
              </Box>
            </Link>
            <Link
              color="primary"
              // href="/app/orders"
              style={{ cursor: "pointer" }}
            >
              <Box className={classes.breadCrumbs}>
                <Truck size={15} style={{ fontSize: "12px", marginRight: "2px" }} />
                Orders
              </Box>
            </Link>
          </Breadcrumbs>
          <Dialog aria-labelledby="simple-dialog-title" open={takeout}>
            {tempSocket && (
              <>
                <CloseIcon
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "5px",
                    // margin: "10px",
                    cursor: "pointer",
                    fontSize: "30px",
                    color: "#fff",
                  }}
                  onClick={() => settakeout(false)}
                />
                <AddItem
                  tableNo={"takeout"}
                  tempSocket={tempSocket}
                  setMenuScreen={settakeout}
                  setShowSuccess={setShowSuccess}
                  newOrder={newOrder}
                  setNewOrder={setNewOrder}
                />
              </>
            )}
          </Dialog>
          <Snackbar
            open={showSuccess}
            autoHideDuration={6000}
            onClose={() => setShowSuccess(false)}
          >
            <Alert
              onClose={() => setShowSuccess(false)}
              variant="filled"
              severity="success"
              style={{ margin: "auto" }}
            >
              {newOrder
                ? "New Order has been added successfully!"
                : "Item has been added successfully!"}
            </Alert>
          </Snackbar>
          <Box
            display={"flex"}
            flexDirection="column"
            justifyContent={"space-between"}
            backgroundColor= "#F4F6F8"
            sx={{
              mb: 1,
              position: "sticky",
              top: "0",
              zIndex: "1000",
              // backgroundColor: "white",
            }}
          >
            <Box
              sx={{ position: "relative", display: "flex", mb: 1 }}
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Box>
                <Button
                  variant="contained"
                  onClick={() => {
                    setNewOrder(true);
                    settakeout(true);
                    // setTableNo('default');
                  }}
                  className={classes.button}
                >
                  Create Takeout Order
                </Button>
              </Box>
              <Box className={classes.throttlingBox}>
                <OrderTools />
              </Box>
            </Box>
            <AppBar position="static" className={classes.appBar}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
                scrollButtons="auto"
              >
                <Tab
                  label={<CustomTab name={"Live"} count={liveOrders?.length} />}
                  {...a11yProps(0)}
                />
                <Tab
                  label={<CustomTab name={"Waiting"} count={waitingOrders?.length} />}
                  {...a11yProps(1)}
                />
                <Tab label="Past" {...a11yProps(2)} />
                <Tab label="Tips" {...a11yProps(3)} />
                {/* <Tab label="Settings" {...a11yProps(3)} /> */}
              </Tabs>
            </AppBar>
          </Box>
          <>
            {tempSocket && liveOrders.length ? (
              <TabPanel
                value={value}
                index={0}
                dir={theme.direction}
                order={order}
                setTakeout={settakeout}
                tempSocket={tempSocket}
                setAlertMessage={setAlertMessage}
              ></TabPanel>
            ) : (
              <TabPanel value={value} index={0}>
                <Box className={classes.ordersBlock} boxShadow={3}>
                  <Typography variant="h4">Live Orders</Typography>
                  <Divider style={{ margin: "10px 0" }} />
                  <Typography
                    color="textSecondary"
                    variant="h5"
                    margin={"auto"}
                    textAlign="center"
                  >
                    There are no live orders
                  </Typography>
                </Box>
              </TabPanel>
            )}
            <WaitingOrders
              value={value}
              index={1}
              dir={theme.direction}
              order={order}
              tempSocket={tempSocket}
            />
            <TabPanel value={value} index={2} dir={theme.direction}>
              <PastOrders tempSocket={tempSocket} tab="pastOrders" />
            </TabPanel>
            <TabPanel value={value} index={3} dir={theme.direction}>
              <PastOrders tempSocket={tempSocket} tab="tips" />
            </TabPanel>
          </>
        </div>
      )}
      <AlertMsg alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
    </>
  );
};

export default Order;
