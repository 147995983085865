import {ThemeProvider, StyledEngineProvider} from "@mui/material/styles";
import jwt_decode from "jwt-decode";
import "react-perfect-scrollbar/dist/css/styles.css";
import {useSelector} from "react-redux";
import {useRoutes} from "react-router-dom";
import routes from "src/routes";
import theme from "src/theme";
import {logoutAction} from "./redux/actions/authActions";
import store from "./redux/store";
import {SET_AUTHENTICATED} from "./redux/types";
import axios from "axios";
import "./styles/app.css";
import {makeDecrypt} from "./utils/securels";
import { useDispatch } from "react-redux";
import { updateRestaurant } from "src/redux/actions/restaurantActions";

const token = makeDecrypt("jwt");

if (token) {
  const decodedToken = jwt_decode(token);
  if (decodedToken.exp * 1000 < Date.now()) {
    store.dispatch(logoutAction());
    window.location.href = "/login";
  } else {
    store.dispatch({type: SET_AUTHENTICATED});
    axios.defaults.headers.common["Authorization"] = token;
  }
}

const App = () => {
  const {authenticated} = useSelector((state) => state.auth);

  const routing = useRoutes(routes(authenticated));
  const dispatch = useDispatch();
  

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{routing}</ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
