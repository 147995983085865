import {Box, Button, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function DateFilter({setStartingDate, setEndingDate}) {
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    if (startDate && endDate) {
      setStartingDate(startDate.toISOString());
      setEndingDate(endDate.toISOString());
    }
  }, []);
  const handleClick = () => {
    setStartingDate(startDate.toISOString());
    setEndingDate(endDate.toISOString());
  };

  const customInput = (date, label) => {
    return <TextField label={label} value={date} size="small" />;
  };

  return (
    <Box display="flex" justifyContent="left" alignItems="center" margin="20px 0 0 20px">
      <Box>
        <DatePicker
          selected={startDate}
          customInput={customInput(startDate, "Start Date")}
          onChange={(date) => setStartDate(date)}
        />
      </Box>
      <Box style={{margin: "0 20px"}}>
        <DatePicker
          selected={endDate}
          customInput={customInput(endDate, "End Date")}
          onChange={(date) => setEndDate(date)}
        />
      </Box>
      <Button onClick={handleClick} variant="contained">
        Filter
      </Button>
    </Box>
  );
}

export default DateFilter;
