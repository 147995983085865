import {Avatar, Box} from "@mui/material";
import {useRef, useState} from "react";
import {useSelector} from "react-redux";
import {makeStyles} from "tss-react/mui";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

const useStyles = makeStyles()(() => {
  return {
    profilePhotoCont: {
      width: "300px",
      height: "300px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      margin: "auto",
      padding: "20px 0",
    },
    avatarCont: {
      position: "relative",
      display: "flex",
      overflow: "hidden",
      width: "180px",
      height: "180px",
      alignItems: "center",
      justifyContent: "center",
      margin: "auto",
      cursor: "pointer",
      border: "1px solid grey",
      borderRadius: "50%",
    },
    avatar: {
      width: "100%",
      height: "100%",
      cursor: "pointer",
      "&:hover": {
        width: "102%",
        height: "102%",
      },
      transition: "all 0.2s ease",
    },
    overlay: {
      position: "absolute",
      top: "0",
      bottom: "0",
      left: "0",
      right: "0",
      height: "100%",
      width: "100%",
      transition: ".3s ease",
      backgroundColor: "rgb(0,0,0,0.3)",
      borderRadius: "50%",
    },
    imgOverlay: {
      color: "white",
      fontSize: "60px",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      msTransform: "translate(-50%, -50%)",
      textAlign: "center",
      "&:hover": {
        color: "#f0f0f0",
      },
    },
  };
});

const user = {
  avatar: "/static/IMAGE.png",
  city: "",
  country: "CANADA",
  jobTitle: "Senior Developer",
  name: "",
  timezone: "",
};

const AccountProfile = ({logoImage, setLogoImage, newBranch, ...props}) => {
  const {classes} = useStyles();

  const [showOverlay, setShowOverlay] = useState();
  let {logoUrl, name} = useSelector((state) => state.restaurant);

  const [singleFile, setSingleFile] = useState("");

  let upload = useRef(null);
  const [tempUrl, setTempUrl] = useState("");

  const SingleFileChange = (e) => {
    setSingleFile(e.target.files[0]);
    setLogoImage(e.target.files[0]);
    setTempUrl(URL.createObjectURL(e.target.files[0]));
  };

  const handleClick = (e) => {
    e.preventDefault();
    upload.click();
  };

  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
        className={classes.avatarCont}
        // onMouseEnter={() => setShowOverlay(true)}
        // onMouseLeave={() => setShowOverlay(false)}
        onClick={handleClick}
      >
        <Avatar
          src={tempUrl ? tempUrl : logoUrl && !newBranch ? logoUrl : user.avatar}
          className={classes.avatar}
        />
        {showOverlay && (
          <Box className={classes.overlay}>
            <AddAPhotoIcon className={classes.imgOverlay} onClick={handleClick} />
          </Box>
        )}
      </Box>
      <input
        type="file"
        className="form-control"
        onChange={(e) => SingleFileChange(e)}
        ref={(e) => (upload = e)}
        style={{display: "none"}}
      />
    </>
  );
};

export default AccountProfile;
