import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {Formik} from "formik";
import {useState} from "react";
import {Helmet} from "react-helmet";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import FacebookIcon from "src/icons/Facebook";
import GoogleIcon from "src/icons/Google";
import * as Yup from "yup";
import ForgotPasswordHelper from "src/components/account/helpers/ForgotPassword";
import {forgotPasswordAction} from "src/redux/actions/authActions";
import {useDispatch} from "react-redux";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);

  const handleClickOpen = (message) => {
    setMessage(message);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const [email, setEmail] = useState('')
  // const [password, setPassword] = useState('')
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Reset Password"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
            })}
            onSubmit={(values, {setSubmitting, setFieldError, setErrors, setStatus}) => {
              const userData = {email: values.email};
              forgotPasswordAction(userData, navigate)
                .then((response) => {
                  // navigate('/app/dashboard', { replace: true });
                  handleClickOpen(response.message);
                  setSubmitting(false);
                })
                .catch((response) => {
                  handleClickOpen("Please enter correct email address.");
                  setSubmitting(false);
                });
            }}
          >
            {({
              errors,
              error,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                {error && <div>{error}</div>}
                <Box sx={{mb: 3}}>
                  <Typography color="textPrimary" variant="h2">
                    Reset Password
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <Box sx={{py: 2}}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Reset Password
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default ForgotPassword;
