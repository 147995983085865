// import EasyTimer from "easytimer";
const initialState = {
  liveOrders: [],
  waitingOrders: [],
  loadingOrders: true,
  reload: false,
  initialLoad: true,
};

export default function orderReducer(state = initialState, action) {
  switch (action.type) {
    case "ADD_LIVE_ORDERS":
      return {
        ...state,
        liveOrders: action.payload,
        loadingOrders: false,
        initialLoad: false,
      };
    case "SET_RELOAD":
      return {
        ...state,
        reload: !state.reload,
        loadingOrders: true,
      };
    case "ADD_WAITING_ORDERS":
      return {
        ...state,
        waitingOrders: action.payload,
        loadingOrders: false,
      };
    case "RESET_INITIAL_LOAD":
      return {
        ...state,
        initialLoad: true,
        loadingOrders: true
      };
    default:
      return state;
  }
}
