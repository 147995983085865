import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fade,
  Grid,
  Link,
  Menu,
  MenuItem,
  Modal,
  Paper,
  TextField,
  Typography,
  FormControl,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Select,
  InputLabel,
  IconButton,
  Switch,
} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import EditIcon from "@mui/icons-material/Edit";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
  editCategoryName,
  toggleCategoryStatus,
} from "../../redux/actions/restaurantActions";
import {
  CATEGORY_LOADING,
  ERASE_MENU,
  LOADING_MENU,
  SELECTED_CATEGORY,
} from "../../redux/types";
import Spinner from "../../utils/spinner/spinner";
import {deleteCategory, deleteCategoryN} from "./helpers";
import AlertMsg from "../AlertMsg";
import OpenWithRoundedIcon from "@mui/icons-material/OpenWithRounded";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { editCategoryNameN, toggleCategoryStatusN } from "src/redux/actions/categoryActions";

const useStyles = makeStyles()((theme) => {
  return {
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      width: "fit-content",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2),
    },
    menuHeading: {
      padding: " 10px",
      textDecoration: "none",
      fontWeight: "500",
      fontSize: "18px",
      color: "black",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      cursor: "pointer",
    },
    selectTimeMsg: {
      margin: "20px auto",
      color: "black",
    },
    timePicker: {
      margin: "10px 30px auto 0px",
    },
  };
});

const ProductCard = ({
  name,
  status,
  printer,
  tempSocket,
  sequence,
  categoryId,
  setThisReload,
  setCurrentCategories,
  setMenuItemsLoader,
  thisReload,
  page,
  limit,
  selectedDate,
  ...rest
}) => {
  const {classes} = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [editedCategoryName, setEditedCategoryName] = useState(false);
  const {mainMenu, selectedMainMenu} = useSelector((state) => state.mainMenu);
  const {loading, role} = useSelector((state) => state.auth);
  const {loadingRestaurant, _id, printers} = useSelector((state) => state.restaurant);
  const {loadingMenu, selectedCategory} = useSelector((state) => state.menu);
  const [newSequence, setNewSequence] = useState(0);
  const toggleStatus = status == "Online" ? "Offline" : "Online";
  const [warning, setWarning] = useState(false);
  const [newPrinter, setNewPrinter] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [newDate, setNewDate] = useState({
    start: "00:01",
    end: "23:59",
  });

  const handleClickOpen = (message) => {
    setOpen(true);
    setAnchorEl(null);
  };

  const handleSelectedCategory = () => {
    if (selectedCategory != name) {
      setMenuItemsLoader(true);
      dispatch({type: LOADING_MENU});
      dispatch({type: ERASE_MENU});
      // setCurrentCategories([]);
      dispatch({
        type: SELECTED_CATEGORY,
        payload: name,
      });
    }
  };

  // useEffect(() => {
  //   setMenuItemsLoader(false);
  // }, [selectedCategory]);

  useEffect(() => {
    setNewSequence(sequence);
    setEditedCategoryName(name);
    if (printer) {
      setNewPrinter(printer);
    }
    setNewDate(selectedDate);
  }, [name, sequence, printer, selectedDate]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditCategory = (event) => {
    dispatch({type: CATEGORY_LOADING});
    const data = {
      previousName: name,
      newName: editedCategoryName,
      restId: _id,
      sequence: newSequence,
      printer: newPrinter,
      availability: newDate,
    };
    dispatch(editCategoryNameN(data, navigate, selectedMainMenu));
    setOpen(false);
    setNewDate({
      start: "00:01",
      end: "23:59",
    });
  };

  const handleChange = (event) => {
    setEditedCategoryName(event.target.value);
  };

  const handleCloseWarning = () => {
    setWarning(false);
  };

  const handleDeleteCategory = (e) => {
    dispatch({type: CATEGORY_LOADING});
    e.preventDefault();

    deleteCategoryN(name, categoryId, dispatch, navigate, selectedMainMenu)
      .then((data) => {
        handleCloseWarning();
        handleDialogClose();
      })
      .catch((error) => setAlertMessage(error.message));
  };

  const changeCategoryStatus = (event) => {
    const data = {
      category: name,
      status: toggleStatus,
      restId: _id,
    };

    tempSocket.current.emit("changeSameCategoryItemStatus", {
      categoryName: name,
      status: toggleStatus,
      restaurantId: _id,
    });
    dispatch({type: CATEGORY_LOADING});
    dispatch(
      toggleCategoryStatusN(
        data,
        navigate,
        selectedMainMenu,
        selectedCategory,
        page,
        limit
      )
    );
  };

  const handleNewDateChange = (e) => {
    setNewDate({...newDate, [e.target.name]: e.target.value});
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Edit category"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <DialogContentText>{/* {message} */}</DialogContentText>
            <>
              <TextField
                autoFocus
                margin="dense"
                name="name"
                id="name"
                label="Name"
                fullWidth
                value={editedCategoryName}
                onChange={handleChange}
              />
              <TextField
                autoFocus
                type="number"
                margin="dense"
                name="sequence"
                id="sequence"
                label="Category Sequence"
                fullWidth
                value={newSequence}
                onChange={(e) => setNewSequence(e.target.value)}
              />
              <FormControl style={{margin: "10px 0"}} fullWidth>
                <InputLabel htmlFor="printer-native-simple">Select Printer</InputLabel>
                <Select
                  native
                  label="Select Printer"
                  value={newPrinter}
                  onChange={(e) => setNewPrinter(e.target.value)}
                  inputProps={{
                    name: "Select Printer",
                    id: "printer-native-simple",
                  }}
                >
                  {printers?.map((printer, index) => (
                    <option key={printer._id} value={printer._id}>
                      {printer.customName}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <Typography className={classes.selectTimeMsg}>
                Select Timeframe in which the category will be available
              </Typography>
              <TextField
                id="start"
                name="start"
                label="START TIME"
                type="time"
                defaultValue={newDate?.start}
                size="small"
                onChange={handleNewDateChange}
                className={classes.timePicker}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />

              <TextField
                id="end"
                name="end"
                label="END TIME"
                type="time"
                defaultValue={newDate?.end}
                size="small"
                onChange={handleNewDateChange}
                className={classes.timePicker}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
            </>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
          <Button onClick={handleEditCategory} color="primary" name={name} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {loading == true && loadingMenu == true && loadingRestaurant == true ? (
        <Spinner />
      ) : (
        <>
          <Box
            // boxShadow={selectedCategory == name ? 15 : 1}
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "fit-content",
              width: "220px",
              padding: 0,
              backgroundColor: selectedCategory == name ? "#FFEBEB" : "white",
              border: selectedCategory == name ? "1px solid #FF5757" : "",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;",
            }}
            {...rest}
          >
            <Box className={classes.menuHeading}>
              <Box display={"flex"} alignItems="center" sx={{cursor: "grab"}}>
                <OpenWithRoundedIcon fontSize="small" />
              </Box>
              <Box
                onClick={handleSelectedCategory}
                sx={{ml: 1}}
                height="100%"
                width="100%"
              >
                <Typography align="center" variant="h5">
                  {name}
                </Typography>
              </Box>
              {role === "ROLE_ADMIN" && (
                <Box display={"flex"}>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={handleClick}
                  >
                    <MoreVertIcon color="action" />
                  </IconButton>

                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={status == "Online" ? true : false}
                            onChange={changeCategoryStatus}
                            name={name}
                            color="primary"
                            size="small"
                          />
                        }
                        label={status}
                      />
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleClickOpen("Add Menu Item")}
                      name={name}
                    >
                      EDIT
                    </MenuItem>
                    <MenuItem onClick={() => setWarning(true)} name={name}>
                      DELETE
                    </MenuItem>
                  </Menu>
                </Box>
              )}
            </Box>
          </Box>
          <Dialog
            open={warning}
            onClose={handleCloseWarning}
            closeAfterTransition
            // BackdropComponent={Backdrop}
            // BackdropProps={{
            //   timeout: 500,
            // }}
          >
            <form noValidate autoComplete="off" onSubmit={handleDeleteCategory}>
              <DialogContent>
                <Typography>
                  Do you really want to delete {name} category.All items under this
                  category will also get deleted
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button variant="outlined" onClick={handleCloseWarning} color="primary">
                  Cancel
                </Button>
                <Button variant="contained" color="primary" type="submit">
                  Ok
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </>
      )}
      <AlertMsg alertMessage={alertMessage} />
    </>
  );
};

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductCard;
