import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  List,
  ListItem,
  Select,
  TextField,
  Typography,
  Divider,
  LinearProgress,
  Grid,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import ProductCard from "src/components/product/ProductCard";
import {addMenu} from "src/redux/actions/mainMenuActions";
import {addCategory} from "../../../redux/actions/restaurantActions";
import EachMenuCard from "../EachMenuCard";
import {makeStyles} from "tss-react/mui";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {copyAllMenuItems} from "../helpers";
import {createDiscount, getAllDiscounts} from "src/redux/actions/discountActions";
import DiscountCard from "./DiscountCard";
import DiscountForm from "./DiscountForm";

const useStyles = makeStyles()((theme) => {
  return {
    selectTimeMsg: {
      margin: "20px auto 10px",
      color: "black",
    },

    timePicker: {
      margin: "10px 30px auto 0px",
    },
    discountsBox: {
      display: "flex",
      flexDirection: "row",
      padding: 0,
      overflowX: "auto",
      scrollBehavior: "auto",
    },
    discount: {
      margin: "10px",
    },
  };
});

const Discount = () => {
  const {classes} = useStyles();

  const [open, setOpen] = useState(false);
  const [newMenu, setNewMenu] = useState("");

  const {_id} = useSelector((state) => state.restaurant);
  const {discount} = useSelector((state) => state.discount);
  const {loading} = useSelector((state) => state.discount);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {role, restaurant} = useSelector((state) => state.auth);
  const flexContainer = {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    overflowX: "auto",
    scrollBehavior: "auto",
  };

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    dispatch(getAllDiscounts(dispatch));
  }, []);

  const handleClickOpen = (message) => {
    setOpen(true);
  };

  const handleCopyMenuItems = (day) => {};

  function CircularProgressWithLabel(props) {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" {...props} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="caption"
            component="div"
            color="textSecondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box>
        <Box sx={{mt: 2}}>
          <Card>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 2,
              }}
            >
              <Typography variant="h3" color="textPrimary">
                Discounts
              </Typography>
              {role === "ROLE_ADMIN" && (
                <>
                  <Button
                    color="primary"
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={handleClickOpen}
                    className="addNewButton"
                  >
                    Add Discount
                  </Button>

                  {/* {restaurant !== _id && !mainMenu?.length && !progress ? (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleCopyMenuItems}
                      className="copyMenuItems"
                      style={{marginLeft: "10px"}}
                    >
                      COPY FROM MAIN BRANCH
                    </Button>
                  ) : (
                    ""
                  )} */}
                  {progress ? <CircularProgressWithLabel value={progress} /> : ""}
                </>
              )}
            </Box>
            {loading ? <LinearProgress /> : <Divider />}
            <Box sx={{mt: 1}}>
              {/* {!menuLoading && ( */}
              <Box className={classes.discountsBox}>
                {discount?.length ? (
                  discount.map((discount) => {
                    return (
                      <Box className={classes.discount} key={discount?._id}>
                        <DiscountCard
                          discount={discount}
                          handleClickOpen={handleClickOpen}
                        />
                      </Box>
                    );
                  })
                ) : (
                  <Box
                    style={{
                      display: "flex",
                      width: "100%",
                      minHeight: "100px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography textAlign="center"> No discounts yet</Typography>
                  </Box>
                )}
              </Box>
              {/* )} */}
            </Box>
          </Card>
        </Box>
      </Box>
      {open && <DiscountForm setOpen={setOpen} />}
    </>
  );
};

export default Discount;
