import {Avatar, Box, Card, CardContent, Grid, Typography} from "@mui/material";
import {green} from "@mui/material/colors";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

const TotalCustomers = ({totalNumberOfOrders, ...rest}) => {
  return (
    <Card {...rest}>
      <CardContent>
        <Grid container spacing={3} sx={{justifyContent: "space-between"}}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              TOTAL ORDERS
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {totalNumberOfOrders}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: green[600],
                height: 56,
                width: 56,
              }}
            >
              <LocalShippingIcon />
            </Avatar>
          </Grid>
        </Grid>
        {/* <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          pt: 2
        }}
      >
        <ArrowUpwardIcon sx={{ color: green[900] }} />
        <Typography
          variant="body2"
          sx={{
            color: green[900],
            mr: 1
          }}
        >
          16%
        </Typography>
        <Typography
          color="textSecondary"
          variant="caption"
        >
          Since last month
        </Typography>
      </Box> */}
      </CardContent>
    </Card>
  );
};

export default TotalCustomers;
