import {createTheme} from "@mui/material/styles";
import shadows from "./shadows";
import typography from "./typography";

const theme = createTheme({
  palette: {
    background: {
      default: "#F4F6F8",
    },
    primary: {
      main: "#FF5757",
      contrastText: "#ffffff",
    },
    text: {
      primary: "#172b4d",
      secondary: "#6b778c",
    },
    secondary: {
      main: "#F4F6F8",
    },
    black: {
      main: "#000",
    },
    white: {
      default: "#fff",
    },
  },
  shadows,
  typography: {
    h1: {
      "@media (min-width:600px)": {
        fontSize: "1.6rem",
        fontWeight: "700",
      },
      "@media (max-width:600px)": {
        fontSize: "1.4rem",
      },
    },
    h2: {
      "@media (min-width:600px)": {
        fontSize: "1.4rem",
        fontWeight: "600",
      },
      "@media (max-width:600px)": {
        fontSize: "1.2rem",
        fontWeight: "600",
      },
    },
    h3: {
      "@media (min-width:600px)": {
        fontSize: "1.4rem",
        fontWeight: "500",
      },
      "@media (max-width:600px)": {
        fontSize: "1rem",
        fontWeight: "500",
      },
    },
    h4: {
      "@media (min-width:600px)": {
        fontSize: "1.2rem",
        fontWeight: "500",
      },
      "@media (max-width:600px)": {
        fontSize: "1rem",
        fontWeight: "500",
      },
    },
    h5: {
      "@media (min-width:600px)": {
        fontSize: "1rem",
        fontWeight: "500",
      },
      "@media (max-width:600px)": {
        fontSize: "0.9rem",
        fontWeight: "500",
      },
    },
    h6: {
      "@media (min-width:600px)": {
        fontSize: "0.9rem",
        fontWeight: "500",
      },
      "@media (max-width:600px)": {
        fontSize: "0.9rem",
        fontWeight: "500",
      },
    },
  },
});
export default theme;
