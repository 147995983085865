import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "tss-react/mui";
import { useDispatch, useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Container,
  Grid,
  Typography,
  Paper,
  Button,
  Divider,
  Checkbox,
  Switch,
  TextField,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Zoom,
  FormControlLabel,
  Breadcrumbs,
  Link,
} from "@mui/material";
import axiosInstance from "src/axios";
import PlanCard from "src/components/onboarding-steps/PlanCard";
import { getAllProducts } from "../components/onboarding-steps/helpers";
import axios from "../axios";
import AlertMsg from "src/components/AlertMsg";
import {
  setHalfToCustomer,
  setPrintDirect,
  setPushToCustomer,
} from "src/components/account/helpers";
import {
  addPrinter,
  updateRestaurant,
  setThrottling,
} from "src/redux/actions/restaurantActions";
import { useNavigate, useSearchParams } from "react-router-dom";
import WorkingHours from "src/components/settings/WorkingHours";
import { Settings } from "react-feather";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import HomeIcon from "@mui/icons-material/Home";

const useStyles = makeStyles()({
  root: {
    textAlign: "center",
  },
  detailsWrapper: {
    padding: "30px",
  },
  eachRow: {
    display: "flex",
    padding: "20px",
    textAlign: "left",
  },
  selectedPlanWrap: {
    padding: "40px",
    // textAlign:'left',
    minHeight: "300px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  prinCont: {
    display: "flex",
    // flexDirection: "column",
    alignItems: "center",
    // justifyContent: "center",
    margin: "10px",
  },
  // prinCont: {
  //   display: "flex",
  //   flexDirection: "row",
  //   alignItems: "center",
  //   justifyContent: "space-around",
  //   marginTop: "10px",
  // },
  buttonProgress: {
    // color: primary,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  breadCrumbs: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2px 8px ",
    borderRadius: "20px",
    backgroundColor: "#ffffff",
    border: "1px solid #FF5757",
    fontSize: "15px",
    fontWeight: "500",
    "& .MuiBreadcrumbs-separator svg": {
      fontSize: "20px !important",
    },
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

function ManageAccount() {
  const { classes } = useStyles();
  const {
    dinify_payment_plan,
    customerId,
    printDirect,
    printers,
    dinifySidePayments,
    restaurantAddress,
    userAuthentication,
    currencySign,
    contactlessOrdering,
    lazyLoading,
    makecalls,
    allowReservations,
    force_OnlinePayments,
    resWorkingHours,
    clover,
  } = useSelector((state) => state.restaurant);
  const { country } = useSelector((state) => state.restaurant.restaurantAddress);
  const { restaurant } = useSelector((state) => state);
  let rows = [];
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [defaultName, setDefaultName] = useState();
  const [customName, setCustomName] = useState();
  const [btnLoading, setBtnLoading] = useState();
  const [razorpayAccountId, setRazorpayAccountId] = useState("");
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [secret, setSecret] = useState();
  const [open, setOpen] = useState(false);
  const [openWorkingHours, setOpenWorkingHours] = useState(false);
  const [deviceId, setDeviceId] = useState();
  const [changeDevice, setChangeDevice] = useState();
  const navigate = useNavigate();

  const pushToCustomer = useSelector(
    (state) => state.restaurant?.dinify_payment_plan?.push_to_customer
  );

  const halfToCustomer = useSelector(
    (state) => state.restaurant?.dinify_payment_plan?.half_to_customer
  );

  if (dinify_payment_plan)
    rows = [
      createData("Dinify plan name", dinify_payment_plan?.plan_name),
      createData(
        "Application service fee(Per Order)",
        `${currencySign}` + dinify_payment_plan?.application_service_fee
      ),
      createData(
        "Transferred to customer",
        dinify_payment_plan?.push_to_customer ? "Yes" : "No"
      ),
      createData(
        "Monthly fee",
        restaurantAddress.country.toLowerCase() === "canada"
          ? dinify_payment_plan?.subscription_fee
          : dinify_payment_plan?.subscription_fee / 100
      ),
    ];

  const handleClick = () => {
    axiosInstance
      .post("/stripe/create-customer-portal-session", { customerId })
      .then((data) => {
        window.open(data.data.url, "_blank");
      });
  };

  useEffect(() => {
    if (dinify_payment_plan)
      if (country.toUpperCase() == "CANADA")
        axios
          .post(`/stripe/getPrice`, { priceId: dinify_payment_plan.plan_id })
          .then((data) => {
            setSelectedPrice(data.data);
          })
          .catch((error) => setAlertMessage(error.message));
      else if (country == "INDIA")
        axios
          .get(`/rzrpay/get-plan-details/${dinify_payment_plan.plan_id}`)
          .then((data) => {
            setSelectedPrice(data.data);
          })
          .catch((error) => setAlertMessage(error.message));
  }, [dinify_payment_plan]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getAllProducts()
      .then((data) => {
        setProducts(data?.data);
      })
      .catch((error) => setAlertMessage(error.message));
  }, []);

  useEffect(() => {
    if (restaurantAddress.country === "INDIA") {
      setRazorpayAccountId(restaurant.razorpayAccountId);
    }
  }, [restaurantAddress, restaurant]);
  useEffect(() => {
    if (alertMessage)
      setTimeout(() => {
        setAlertMessage("");
      }, 2000);
  }, [alertMessage]);
  const handlePushToCustomer = () => {
    setPushToCustomer(!pushToCustomer);
  };

  const handleHalfToCustomer = () => {
    setHalfToCustomer(!halfToCustomer);
  };

  const handleCheck = () => {
    setPrintDirect(!printDirect);
  };

  const handleAddPrinter = () => {
    setBtnLoading(true);
    dispatch(addPrinter(restaurant, defaultName, customName));
  };

  const handleAddRazorpayId = () => {
    dispatch(updateRestaurant({ razorpayAccountId: razorpayAccountId }));
  };

  useEffect(() => {
    setBtnLoading(false);
    setCustomName("");
    setDefaultName("");
  }, [printers]);

  useEffect(() => {
    let url = window.location.href;
    if (url.includes("?") && !restaurant.clover?.apiToken) {
      getApiToken();
    }
  }, []);

  const getApiToken = () => {
    axios
      .get(
        `${process.env.REACT_APP_CLOVER_LINK}/oauth/token?client_id=${process.env.REACT_APP_CLOVER_APP_ID
        }&client_secret=${process.env.REACT_APP_CLOVER_CLIENT_SECRET
        }&code=${searchParams.get("code")}
    `
      )
      .then((response) => {
        dispatch(
          updateRestaurant({
            clover: {
              apiToken: response.data.access_token,
              merchantId: searchParams.get("merchant_id"),
            },
          })
        );
      })
      .catch((error) => console.log(error));
  };

  const updateDeviceId = () => {
    dispatch(
      updateRestaurant({
        clover: {
          apiToken: restaurant.clover.apiToken,
          merchantId: restaurant.clover.merchantId,
          deviceId: deviceId,
        },
      })
    ).then((response) => {
      setChangeDevice(false);
    });
  };

  //TODO:Add features of the plan in the plan card if it is in the manage account page

  return (
    <>
      <Helmet>
        <title>Manage Account | Dinify</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          style={{ marginBottom: "10px", marginLeft: "20px" }}
        >
          <Link
            color="primary"
            onClick={() => navigate("/app/dashboard")}
            style={{ cursor: "pointer" }}
          >
            <Box className={classes.breadCrumbs}>
              <HomeIcon color="primary" style={{ fontSize: "15px", marginRight: "2px" }} />
              Home
            </Box>
          </Link>
          <Link
            color="primary"
            // href="/app/orders"
            style={{ cursor: "pointer" }}
          >
            <Box className={classes.breadCrumbs}>
              <Settings size={15} style={{ fontSize: "15px", marginRight: "2px" }} />
              Setting
            </Box>
          </Link>
        </Breadcrumbs>
        <Container maxWidth="lg">
          {/* <Grid container spacing={3} className={classes.root}> */}
            {/* <Grid
              item
              lg={4}
              md={6}
              xs={12}
            // pl={15}
            >
              <Paper className={classes.selectedPlanWrap}>
                {selectedPrice && products?.length ? (
                  <PlanCard
                    selectedPrice={selectedPrice}
                    item={selectedPrice}
                    products={products}
                    handleClick={handleClick}
                    manage={true}
                  />
                ) : (
                  ""
                )}
              </Paper>
            </Grid> */}
            <Grid item >
              <Paper sx={{ p: 3 }}>
                <Typography variant="h3" sx={{ mb: 2 }}>
                  Account Details
                </Typography>
                <Divider />
                <Box className={classes.detailsWrapper}>
                  {rows.map((row) => (
                    <React.Fragment key={row.name}>
                      {!(
                        row.name === "Monthly fee" &&
                        dinify_payment_plan.usage_type === "metered"
                      ) ? (
                        <Box className={classes.eachRow}>
                          <Typography variant="h6" fontSize="20px" width="350px">
                            {row.name}
                          </Typography>
                          <Typography fontSize="20px">{row.calories}</Typography>
                        </Box>
                      ) : null}
                    </React.Fragment>
                  ))}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClick}
                    style={{ marginTop: "20px" }}
                  >
                    MANAGE SUBSCRIPTION
                  </Button>
                </Box>
              </Paper>
            </Grid>
          {/* </Grid> */}
          <Paper style={{ marginTop: "20px" }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h4">Dinify Application Fee</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <Box className={classes.prinCont}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={pushToCustomer ? pushToCustomer : false}
                          onChange={() => handlePushToCustomer()}
                          color="primary"
                          name="checkedB"
                        />
                      }
                      label={"Push 100% to customer"}
                    />
                    <Tooltip
                      title={<h3>Customer will pay whole application fee</h3>}
                      placement="top"
                      TransitionComponent={Zoom}
                    >
                      <Button>
                        {" "}
                        <InfoIcon />
                      </Button>
                    </Tooltip>
                  </Box>
                  <Box className={classes.prinCont}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={halfToCustomer ? halfToCustomer : false}
                          onChange={() => handleHalfToCustomer()}
                          color="primary"
                          name="checkedB"
                        />
                      }
                      label={"Push 50% to customer"}
                    />
                    <Tooltip
                      title={
                        <h3>
                          Customer and Restaurant both will pay 50% of the application fee
                        </h3>
                      }
                      placement="top"
                      TransitionComponent={Zoom}
                    >
                      <Button>
                        {" "}
                        <InfoIcon />
                      </Button>
                    </Tooltip>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography variant="h4">Print Setting</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box className={classes.prinCont}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={printDirect ? printDirect : false}
                        onChange={() => handleCheck()}
                        color="primary"
                        name="checkedB"
                      />
                    }
                    label={"Print direct"}
                  />
                  <Tooltip
                    title={
                      <h3>
                        Order will get accept and print immediately after order placed
                      </h3>
                    }
                    placement="top"
                    TransitionComponent={Zoom}
                  >
                    <Button>
                      {" "}
                      <InfoIcon />
                    </Button>
                  </Tooltip>
                </Box>
                <Divider />
                <Typography variant="h5" m={2}>
                  Add printers
                </Typography>
                <Box className={classes.prinCont}>
                  <TextField
                    value={defaultName}
                    label="Default Name"
                    onChange={(e) => setDefaultName(e.target.value)}
                    size="small"
                  />
                  <TextField
                    value={customName}
                    label="Custom Name"
                    onChange={(e) => setCustomName(e.target.value)}
                    size="small"
                    style={{ margin: "auto 10px" }}
                  />
                  <div
                    className={classes.wrapper}
                    style={{ position: "relative", marginLeft: "15px" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      // className={buttonClassname}
                      disabled={btnLoading}
                      onClick={() => handleAddPrinter()}
                    >
                      ADD PRINTER
                    </Button>
                    {btnLoading && (
                      <CircularProgress size={24} className={classes.buttonProgress} />
                    )}
                  </div>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h4">Dinify Side Payments</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box className={classes.prinCont}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={
                          dinifySidePayments === undefined ? true : dinifySidePayments
                        }
                        onChange={() => {
                          dispatch(
                            updateRestaurant({ dinifySidePayments: !dinifySidePayments })
                          );
                        }}
                        color="primary"
                        name="checkedC"
                      />
                    }
                    label={"Dinify side payments"}
                  />
                  <Tooltip
                    title={<h3>Customers can pay through the dinify app</h3>}
                    placement="top"
                    TransitionComponent={Zoom}
                  >
                    <Button>
                      {" "}
                      <InfoIcon />
                    </Button>
                  </Tooltip>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h4">User Authentication</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box className={classes.prinCont}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={userAuthentication}
                        onChange={() => {
                          dispatch(
                            updateRestaurant({ userAuthentication: !userAuthentication })
                          );
                        }}
                        color="primary"
                        name="checkedC"
                      />
                    }
                    label={"User Authentication"}
                  />
                  <Tooltip
                    title={<h3>Requires otp verification for placing orders</h3>}
                    placement="top"
                    TransitionComponent={Zoom}
                  >
                    <Button>
                      {" "}
                      <InfoIcon />
                    </Button>
                  </Tooltip>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h4">Contactless Ordering</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box className={classes.prinCont}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={
                          contactlessOrdering === undefined ? true : contactlessOrdering
                        }
                        onChange={() => {
                          dispatch(
                            updateRestaurant({ contactlessOrdering: !contactlessOrdering })
                          );
                        }}
                        color="primary"
                        name="checkedC"
                      />
                    }
                    label={"Contactless Ordering"}
                  />
                  <Tooltip
                    title={
                      <h3>
                        Allow to place orders thorugh the app. If off only menu will be
                        visible
                      </h3>
                    }
                    placement="top"
                    TransitionComponent={Zoom}
                  >
                    <Button>
                      {" "}
                      <InfoIcon />
                    </Button>
                  </Tooltip>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h4">Lazy Loading </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box className={classes.prinCont}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={lazyLoading === undefined ? true : lazyLoading}
                        onChange={() => {
                          dispatch(updateRestaurant({ lazyLoading: !lazyLoading }));
                        }}
                        color="primary"
                        name="checkedC"
                      />
                    }
                    label={"Lazy Loading"}
                  />
                  <Tooltip
                    title={<h3>Recommended: ON for big menus</h3>}
                    placement="top"
                    TransitionComponent={Zoom}
                  >
                    <Button>
                      {" "}
                      <InfoIcon />
                    </Button>
                  </Tooltip>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h4">Manage Automatic Calls</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box className={classes.prinCont}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={makecalls === undefined ? true : makecalls}
                        onChange={() => {
                          dispatch(updateRestaurant({ makecalls: !makecalls }));
                        }}
                        color="primary"
                        name="checkedC"
                      />
                    }
                    label={"Automative Calls"}
                  />
                  <Tooltip
                    title={
                      <h3>Make calls for every 10 minutes till the order is accepted</h3>
                    }
                    placement="top"
                    TransitionComponent={Zoom}
                  >
                    <Button>
                      {" "}
                      <InfoIcon />
                    </Button>
                  </Tooltip>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h4">Table Reservations</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box className={classes.prinCont}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={
                          allowReservations === undefined ? true : allowReservations
                        }
                        onChange={() => {
                          dispatch(
                            updateRestaurant({ allowReservations: !allowReservations })
                          );
                        }}
                        color="primary"
                        name="checkedC"
                      />
                    }
                    label={"Table Reservations"}
                  />
                  <Tooltip
                    title={<h3>Allow users to reserve tables </h3>}
                    placement="top"
                    TransitionComponent={Zoom}
                  >
                    <Button>
                      {" "}
                      <InfoIcon />
                    </Button>
                  </Tooltip>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h4">Takeout Orders</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box className={classes.prinCont}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={
                          force_OnlinePayments === undefined ? true : force_OnlinePayments
                        }
                        onChange={() => {
                          dispatch(
                            updateRestaurant({
                              force_OnlinePayments: !force_OnlinePayments,
                            })
                          );
                        }}
                        color="primary"
                        name="checkedC"
                      />
                    }
                    label={"Force onilne payments"}
                  />
                  <Tooltip
                    title={<h3>Only accept online payments for Takeout Orders</h3>}
                    placement="top"
                    TransitionComponent={Zoom}
                  >
                    <Button>
                      {" "}
                      <InfoIcon />
                    </Button>
                  </Tooltip>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h4">Connect Clover </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box className={classes.prinCont}>
                  {!restaurant.clover?.apiToken ? (
                    <Button
                      variant="outlined"
                      href={`${process.env.REACT_APP_CLOVER_LINK}/oauth/authorize?client_id=${process.env.REACT_APP_CLOVER_APP_ID}`}
                    >
                      Connect{" "}
                    </Button>
                  ) : (
                    <Box>
                      <Box>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={clover?.acceptPayment}
                              onChange={() => {
                                dispatch(
                                  updateRestaurant({
                                    clover: {
                                      acceptPayment: !clover?.acceptPayment,
                                      deviceId: clover?.deviceId,
                                      merchantId: clover?.merchantId,
                                      apiToken: clover?.apiToken,
                                    },
                                  })
                                );
                              }}
                              name="checkedB"
                              color="primary"
                            />
                          }
                          label="Accept payment"
                        />
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        {!restaurant.clover?.deviceId || changeDevice ? (
                          <Box>
                            <Typography> Enter device id</Typography>
                            <Box display="flex" sx={{ mt: 2 }}>
                              <TextField
                                value={deviceId}
                                onChange={(e) => setDeviceId(e.target.value)}
                                label="Device Id"
                                size="small"
                              />
                              <Button
                                sx={{ ml: 2 }}
                                variant="contained"
                                onClick={() => updateDeviceId()}
                              >
                                Submit
                              </Button>
                            </Box>
                          </Box>
                        ) : (
                          <Box>
                            <Typography>
                              Clover is connected with device Id
                              <span style={{ fontWeight: 500 }}>
                                {`"${restaurant.clover.deviceId}"`}
                              </span>
                            </Typography>
                            <Button
                              sx={{ mt: 2 }}
                              variant="outlined"
                              onClick={() => setChangeDevice(true)}
                            >
                              {" "}
                              Change Device
                            </Button>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )}
                  <Tooltip
                    title={<h3>Connect your clover device</h3>}
                    placement="top"
                    TransitionComponent={Zoom}
                  >
                    <Button>
                      {" "}
                      <InfoIcon />
                    </Button>
                  </Tooltip>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h4">Update working hours</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Button
                  style={{ marginTop: "20px" }}
                  variant="contained"
                  onClick={() => setOpenWorkingHours(true)}
                >
                  Update Working Hours
                </Button>
                <Tooltip
                  title={
                    <h3>
                      Set or update working hours of the restaurant. It will show
                      restaurant is Open or Closed on the app
                    </h3>
                  }
                  placement="top"
                  TransitionComponent={Zoom}
                >
                  <Button>
                    {" "}
                    <InfoIcon />
                  </Button>
                </Tooltip>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Container>
      </Box>
      <Dialog open={open}>
        <DialogContent>
          <Typography variant="h6" style={{ marginBottom: "10px" }}>
            Enter Client Secret
          </Typography>
          <TextField
            label="Client Secret"
            value={secret}
            onChange={(e) => setSecret(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" sx={{ mr: 1 }} onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button variant="contained" onClick={getApiToken}>
            Connect
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openWorkingHours} maxWidth="900px">
        <DialogContent>
          <WorkingHours
            resWorkingHours={resWorkingHours}
            setAlertMessage={setAlertMessage}
            setOpenWorkingHours={setOpenWorkingHours}
          />
        </DialogContent>
      </Dialog>
      <AlertMsg alertMessage={alertMessage} />
    </>
  );
}

export default ManageAccount;
