import {makeDecrypt} from "src/utils/securels";
import axios from "../../../axios";

export const UploadLogo = async (data, restaurantId) => {
  try {
    return await axios
      .post(`/restaurant/restaurant-logo?restaurantId=${restaurantId}`, data, {
        headers: {Authorization: makeDecrypt("jwt")},
      })
      .then((res) => res.data)
      .catch((error) => {
        return error.message;
      });
  } catch (error) {
    return error.msg;
  }
};
export const UploadCover = async (data, restaurantId) => {
  try {
    return await axios
      .post(`/restaurant/restaurant-logo?restaurantId=${restaurantId}&cover=cover`, data, {
        headers: {Authorization: makeDecrypt("jwt")},
      })
      .then((res) => res.data)
      .catch((error) => {
        return error.message;
      });
  } catch (error) {
    return error.msg;
  }
};

export const getSubscription = async (id, country) => {
  if (country == "CANADA")
    return await axios
      .get(`/stripe/getSubscription/${id}`)
      .then((res) => res.data)
      .catch((error) => {
        return error;
      });
  else if (country == "INDIA")
    return await axios
      .get(`/razorpay/getSubscription/${id}`)
      .then((res) => {
        res.data;
      })
      .catch((error) => {
        return error;
      });
};

export const setPrintDirect = async (status) => {
  let restId = makeDecrypt("restaurantId");
  await axios
    .post(`/restaurant/set-print-direct/${restId}`, {status})
    .then(() => {
      window.location.reload();
    })
    .catch((error) => {});
};

// export const updateDinifySidePayments = async (status) => {
//   let restId = makeDecrypt("restaurantId");
//   await axios
//     .post(`/restaurant/update-dinify-side-payments/${restId}`, {status})
//     .then(() => {
//       window.location.reload();
//     })
//     .catch((error) => {
//       alert("error");
//     });
// };

export const setPushToCustomer = async (status) => {
  let restId = makeDecrypt("restaurantId");
  await axios
    .post(`/restaurant/set-push-to-customer/${restId}`, {status})
    .then(() => {
      window.location.reload();
    })
    .catch((err) => {});
};

export const setHalfToCustomer = async (status) => {
  let restId = makeDecrypt("restaurantId");
  await axios
    .post(`/restaurant/set-half-to-customer/${restId}`, {status})
    .then(() => {
      window.location.reload();
    })
    .catch((err) => {});
};
