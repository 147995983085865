import axios from "src/axios";
import {makeDecrypt} from "src/utils/securels";

export const updateEmployee = async (employeeData, employeeId, restId) => {
  return await axios
    .put(`/employee/${restId}/update-employee/${employeeId}`, employeeData, {
      headers: {Authorization: makeDecrypt("jwt")},
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.message;
    });
};
