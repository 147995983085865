import React, {useEffect, useState} from "react";

export default function Timer({timeOfCreation, setIntervalId, status, table}) {
  const [hrs, setHrs] = useState(0);
  const [mns, setMns] = useState(0);
  const [sns, setSns] = useState(0);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (table.status === "notServed") {
      // setHrs(0);
      // setMns(0);
      // setSns(0);
      // let tempLoaded = false;
      let id = setInterval(() => {
        let time = new Date().getTime() - new Date(table.createdAt).getTime();
        var msec = time;
        var hh = Math.floor(msec / 1000 / 60 / 60);
        msec -= hh * 1000 * 60 * 60;
        var mm = Math.floor(msec / 1000 / 60);
        msec -= mm * 1000 * 60;
        var ss = Math.floor(msec / 1000);
        msec -= ss * 1000;
        setHrs(hh);
        setMns(mm);
        setSns(ss);
      }, 1000);
      // tempLoaded = true;
      // setLoaded(tempLoaded);
      setIntervalId(id);
    }
  }, [table]);

  return (
    <>
      <div>
        <div style={{textAlign: "right"}}>
          <div style={{fontSize: "30px"}}>
            <span>{hrs}</span>:<span>{mns}</span>:<span>{sns}</span>
          </div>
        </div>
      </div>
    </>
  );
}
