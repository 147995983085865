import {
  Box,
  Container,
  Typography,
  Checkbox,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Button,
  Modal,
  TextField,
  Radio,
  Grid,
  Snackbar,
  Alert,
} from "@mui/material";

import {makeStyles} from "tss-react/mui";

import React, {useState, useEffect} from "react";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveIcon from "@mui/icons-material/Remove";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import axios from "../../axios";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import {IoArrowBackCircleOutline} from "react-icons/io5";

import Spinner from "src/utils/spinner/spinner";
import {makeDecrypt, makeEncrypt} from "src/utils/securels";
import moment from "moment";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      position: "relative",
      padding: "0",
    },
    dishBox: {
      height: "150px",
      width: "90%",
      borderRadius: "10px",
      // padding:'10px',
    },
    dishImg: {
      height: "100%",
      width: "100%",
      borderRadius: "10px",
      objectFit: "cover",
    },
    btn: {
      width: "135px",
    },
    paper: {
      display: "flex",
      width: "90%",
      justifyContent: "space-between",
      marginBottom: "10px",
      padding: "5px",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "7px",
    },
    addition: {
      width: "100%",
      display: "flex",
      // justifyContent:'space-between ',
      alignItems: "center",
    },
    //   actionBtns:{
    //     flex:0.5,
    //     margin:'5px'
    //   },
    input: {
      width: "100%",
      margin: "10px 0",
    },

    accordion: {
      marginBottom: "10px",
    },
    actionWrap: {
      display: "flex",
      textAlign: "center",
      alignItems: "center",
      marginTop: "auto",
      justifyContent: "space-between",
    },
    counterWrap: {
      boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
      borderRadius: "5px",
      cursor: "pointer",
    },
    backBtn: {
      // width:'40px',
      position: "absolute",
      top: "10px",
      cursor: "pointer",
      fontSize: "30px",
      // borderRadius:'100px',
    },
    counterBtn: {
      display: "flex",
      padding: "5px ",
      alignItems: "center",
      justifyContent: "center",
    },
    counterVal: {
      display: "flex",
      padding: "5px 20px",
      alignItems: "center",
      justifyContent: "center",
    },
  };
});

function DishScreen({
  match,
  update,
  itemsNo,
  currentItem,
  menuId,
  userId,
  startBillButton,
  user,
  setPrevPage,
  setShowSpinner,
  setSelection,
  setUser,
  setUserId,
  newOrder,
  setCurrentCartItems,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const {user} = useSelector(state => state.stripe)
  const {classes} = useStyles();

  const [values, setValues] = useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedD: false,
    // firebaseOtp: false,
    menuItm: null,
    noOfItems: 1,
    specialInstructions: "",
  });

  const {menuItm, noOfItems, specialInstructions} = values;
  const [currentModifiers, setCurrentModifiers] = useState([]);
  const [previousModifiers, setPriviousModifiers] = useState([]);

  const [allSelectedModifiers, setAllSelectedModifiers] = useState([]);
  const tawkToPropertyId = "614502bbd326717cb6820e3b";

  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [mandatoryModifiers, setMandatoryModifiers] = useState();
  const [discountedPrice, setDiscountedPrice] = useState();
  const [discountLoaded, setDiscountLoaded] = useState();

  const tawkToKey = "1ffqp5av0";
  const {currencySign} = useSelector((state) => state.restaurant);
  useEffect(() => {
    const getMenuItem = async () => {
      const menuItem = await axios.get(`/menu/${menuId}/menu-item-detail`);
      setCurrentModifiers(menuItem.data.modifiers ? menuItem.data.modifiers : []);
      if (menuItem?.data.modifiers?.length) {
        let temp = [];
        for (const thisModifier of menuItem.data.modifiers) {
          if (thisModifier.isMandatory) {
            temp.push(thisModifier.type);
          }
        }
        setMandatoryModifiers(temp);
      }
      if (update && itemsNo)
        setValues({
          ...values,
          menuItm: menuItem.data,
          noOfItems: itemsNo,
          specialInstructions: currentItem.specialInstructions,
        });
      else setValues({...values, menuItm: menuItem.data});
    };
    getMenuItem();

    const getUser = () => {
      axios
        .post(`/user/user-details`, {
          userId: userId,
        })
        .then((response) => {
          if (update) {
            const data = response.data.cart.items.find((item) => item.itemId === menuId);

            if (data.modifiers) {
              data.modifiers.map((item) => {
                item.selectedOptions.map((option) =>
                  setAllSelectedModifiers((prevValues) => [
                    ...prevValues,
                    option.modifierId,
                  ])
                );
              });
            }
          }
        });
    };

    getUser();
    // tawkTo(tawkToPropertyId, tawkToKey)
  }, [menuId]);

  const [modifiersPrice, setModifiersPrice] = useState(0);

  useEffect(() => {}, [allSelectedModifiers]);

  const handleChange = (event) => {
    setValues({[event.target.name]: event.target.checked});
  };

  const handleBillClick = () => {
    setOrdersInLocalStorage();
  };

  const handleClose = () => {
    setValues({...values, firebaseOtp: false});
  };

  useEffect(() => {
    if (currentItem?.modifiers.length) {
      let temp = [];
      for (const thisModifier of currentItem.modifiers) {
        if (thisModifier.isMandatory) {
          temp.push(thisModifier.type);
        }
      }
      // let tempMandatoryModifiers = menuItm.modifiers.map((thisModifier) =>
      //   thisModifier.isMandatory ? thisModifier.type : ""
      // )
      setMandatoryModifiers(temp);
    }
  }, []);

  const createUser = async () => {
    return await axios
      .post("/user/user-details")
      .then((res) => {
        res.data;
        setUser(res.data);
      })
      .catch((error) => {
        setErrorMsg(error);
        setOpen(true);
      });
  };

  const [dataToSend, setDataToSend] = useState([]);

  const getModifiers = () => {
    let data = [];

    currentModifiers.map((item) => {
      if (item.options.some((option) => option.selected === true)) {
        let tempSelectedOptions = [];
        item.options.map((thisOption) => {
          if (thisOption.selected === true)
            tempSelectedOptions.push({
              modifierId: thisOption._id,
              modifierName: thisOption.modifierName,
              modifierPrice: thisOption.modifierPrice,
            });
        });
        data.push({
          selectedTypeId: item.type,
          selectedOptions: tempSelectedOptions,
        });
      }
    });

    setDataToSend(data);
    //  return data;
  };

  useEffect(() => {
    // // setModifiersPrice()
    setModifiersPrice(0);

    let tempPrice = 0;

    dataToSend.map((item) => {
      item.selectedOptions.map((option) => {
        tempPrice = tempPrice + option.modifierPrice;
      });
    });

    setModifiersPrice(tempPrice);
  }, [dataToSend]);

  const setOrdersInLocalStorage = async () => {
    let orders = [];
    let totalPrice = 0;

    try {
      if (dataToSend?.length && mandatoryModifiers?.length) {
        let flag = false;
        let count = 0;
        for (const item of dataToSend) {
          if (mandatoryModifiers.some((thisItem) => thisItem === item.selectedTypeId))
            count++;
          //   flag = true
          // else flag = false
        }
        if (count === mandatoryModifiers?.length) {
          addToCart(dataToSend);
        } else {
          setErrorMsg("select mandatory modifier");
          setOpen(true);
        }
      } else if (dataToSend.length === 0 && mandatoryModifiers?.length) {
        setErrorMsg("select mandatory modifier");
        setOpen(true);
      } else {
        addToCart(dataToSend);
      }
    } catch (error) {
      setOpen(true);
    }
  };

  const addToCart = () => {
    setShowSpinner(true);
    axios
      .post(`user/${userId}/${makeDecrypt("restaurantId")}/${menuId}/add-to-cart`, {
        phone: user.phone,
        quantity: noOfItems,
        price: (discountedPrice ? discountedPrice : menuItm.price) + modifiersPrice,
        specialInstructions: specialInstructions,
        modifiers: dataToSend.length ? dataToSend : undefined,
        name: menuItm.name,
        pstPercentage: menuItm.pstApplicable && menuItm.pstPercentage,
      })
      .then((res) => {
        if (!newOrder) startBillButton("normal", res.data.user);
        else {
          handlePrev();
          setShowSpinner(false);
          setUser(res.data.user);
          setCurrentCartItems(res.data.user.cart.items);
        }
        // navigate(`/checkout/${makeDecrypt('restaurantId')}`)
      })
      .catch((error) => {
        setErrorMsg(error);
        setOpen(true);
      });
  };

  const handleAction = (action, addToBill) => {
    let orders = [];
    let totalPrice = 0;

    if (action === "remove") {
      removeCartItem(makeDecrypt("user"));
    }
    if (action === "update") {
      updateCart(makeDecrypt("user"));
    }
  };

  const updateCart = async (phone) => {
    await axios
      .put(
        `/user/${makeDecrypt("userId")}/${makeDecrypt(
          "restaurantId"
        )}/${menuId}/update-cart`,
        {
          phone: makeDecrypt("user"),
          menuItemId: menuId,
          quantity: noOfItems,
          price: (discountedPrice ? discountedPrice : menuItm.price) + modifiersPrice,
          specialInstructions: specialInstructions,
          modifiers: dataToSend.length ? dataToSend : undefined,
          objectId: currentItem._id,
        }
      )
      .then((res) => {
        navigate(`/checkout/${makeDecrypt("restaurantId")}`);
        // dispatch(refreshCheckout())
      })
      .catch((error) => {
        setErrorMsg(error);
        setOpen(true);
      });
  };

  const removeCartItem = (phone) => {
    axios
      .put(
        `/user/${makeDecrypt("userId")}/${makeDecrypt(
          "restaurantId"
        )}/${menuId}/delete-cart-item`,
        {
          menuItemId: menuId,
          phone: makeDecrypt("user"),
          objectId: currentItem._id,
        }
      )
      .then((res) => {
        navigate(`/checkout/${makeDecrypt("restaurantId")}`);
        // dispatch(refreshCheckout())
      })
      .catch((error) => {
        setErrorMsg(error);
        setOpen(true);
      });
  };

  const handleRadioChange = (e, typeId, optionId, isMultiSelect, index, thisIndex) => {
    //logic for getting selected modifers
    let tempModifiers = currentModifiers;

    tempModifiers.map((item, index) => {
      if (item._id === typeId) {
        item.options.map((option, newIndex) => {
          if (isMultiSelect === false) {
            if (option._id === optionId) {
              tempModifiers[index].options[newIndex] = {
                ...option,
                selected: true,
              };
            } else {
              tempModifiers[index].options[newIndex] = {
                ...option,
                selected: false,
              };
            }
          } else {
            if (option._id === optionId) {
              tempModifiers[index].options[newIndex] = {
                ...option,
                selected: e.target.checked,
              };
            }
          }
        });
      }
    });

    getModifiers();
  };

  const handlePrev = () => {
    setPrevPage(false);
    setSelection(false);
  };

  useEffect(() => {
    if (menuItm?.discount)
      axios
        .get(`discount/get-discount/${menuItm?.discount}`)
        .then((data) => {
          let discount = data.data;
          let today = moment(new Date()).format("dddd");
          var currentTime = moment().format("HH:mm");
          var startTime = new Date("01/01/2000 " + discount.startTime);
          var endTime = new Date("01/01/2000 " + discount.endTime);
          currentTime = new Date("01/01/2000 " + currentTime);
          if (
            new Date(discount.startDate) < new Date() < new Date(discount.endDate) &&
            discount.days.find((day) => day == today) &&
            startTime < currentTime < endTime
          ) {
            if (discount.percentOff) {
              let amountOff = (discount.percentOff / 100) * menuItm?.price;
              setDiscountedPrice(menuItm?.price - amountOff);
            } else {
              setDiscountedPrice(menuItm?.price - discount.amountOff);
            }
          }
          setDiscountLoaded(true);
        })
        .catch((error) => {
          setDiscountLoaded(true);
        });
    else if (menuItm && !menuItm.discount) setDiscountLoaded(true);
  }, [menuItm]);

  return (
    <Box>
      <Helmet>
        <title>Menu | Dinify</title>
      </Helmet>
      {menuItm ? (
        <Container className={classes.root}>
          <Grid container boxShadow="5" sx={{p: 2, pt: 6}}>
            <IoArrowBackCircleOutline
              className={classes.backBtn}
              onClick={() => handlePrev()}
            />

            <Grid item xs={5} className={classes.dishCont}>
              <Box className={classes.paper} boxShadow="2">
                <Typography variant="h5" color={"secondary"}>
                  {menuItm.name}
                </Typography>
                <Typography variant="body1" color={"secondary"}>
                  {currencySign}
                  {(discountedPrice ? discountedPrice : menuItm.price) + modifiersPrice}
                </Typography>
              </Box>
              <Box className={classes.dishBox} boxShadow="5">
                <img
                  className={classes.dishImg}
                  src={
                    menuItm.imageUrl
                      ? menuItm.imageUrl
                      : "https://images.unsplash.com/photo-1592861956120-e524fc739696?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8cmVzdGF1cmFudHN8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=60"
                  }
                  alt=""
                />
              </Box>
            </Grid>

            <Grid
              item
              xs={7}
              sx={{position: "relative"}}
              display="flex"
              flexDirection={"column"}
            >
              {menuItm.modifiers.map((item, index) => {
                return (
                  <Accordion elevation={2} key={index} className={classes.accordion}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      align="center"
                      className={classes.accordionSummary}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography component="p" className={classes.typo}>
                        {item.type}
                        {item.isMandatory ? <span style={{color: "red"}}>*</span> : ""}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box width="100%">
                        {item.options.map((option, thisIndex) => {
                          return (
                            <Box className={classes.addition} key={option._id}>
                              <Box display="flex" alignItems="center" sx={{mr: 1}}>
                                {!update &&
                                  (item.multiSelect ? (
                                    <input
                                      type="checkbox"
                                      onChange={(e) =>
                                        handleRadioChange(
                                          e,
                                          item._id,
                                          option._id,
                                          item.multiSelect,
                                          index,
                                          thisIndex
                                        )
                                      }
                                    />
                                  ) : (
                                    <input
                                      onChange={(e) =>
                                        handleRadioChange(
                                          e,
                                          item._id,
                                          option._id,
                                          item.multiSelect,
                                          index,
                                          thisIndex
                                        )
                                      }
                                      type="radio"
                                      name={item.type}
                                    />
                                  ))}

                                {update &&
                                  (item.multiSelect ? (
                                    <input
                                      type="checkbox"
                                      checked={allSelectedModifiers.some(
                                        (id) => id === option._id
                                      )}
                                      onChange={(e) =>
                                        handleRadioChange(
                                          e,
                                          item._id,
                                          option._id,
                                          item.multiSelect,
                                          index,
                                          thisIndex
                                        )
                                      }
                                    />
                                  ) : (
                                    <input
                                      checked={allSelectedModifiers.some(
                                        (id) => id === option._id
                                      )}
                                      onChange={(e) =>
                                        handleRadioChange(
                                          e,
                                          item._id,
                                          option._id,
                                          item.multiSelect,
                                          index,
                                          thisIndex
                                        )
                                      }
                                      type="radio"
                                      name={item.type}
                                    />
                                  ))}
                              </Box>
                              <Typography>
                                {option.modifierName}
                                {option.modifierPrice != 0
                                  ? `(Price: ${currencySign}${option.modifierPrice})`
                                  : ""}
                              </Typography>
                            </Box>
                          );
                        })}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                );
              })}

              <Box display="flex" sx={{mb: 2}}>
                <TextField
                  label="Special Instruction"
                  variant="outlined"
                  size="small"
                  className={classes.input}
                  value={specialInstructions}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      specialInstructions: e.target.value,
                    })
                  }
                />
              </Box>

              <Box className={classes.actionWrap}>
                <Box display="flex" className={classes.counterWrap} sx={{mr: 4}}>
                  <Box
                    className={classes.counterBtn}
                    onClick={() =>
                      noOfItems > 1 && setValues({...values, noOfItems: noOfItems - 1})
                    }
                  >
                    <RemoveIcon style={{fontSize: "26px"}} />
                  </Box>
                  <Box className={classes.counterVal}>
                    <Typography variant="h4">{noOfItems}</Typography>
                  </Box>
                  <Box
                    className={classes.counterBtn}
                    onClick={() => setValues({...values, noOfItems: noOfItems + 1})}
                  >
                    <AddIcon style={{fontSize: "26px"}} />
                  </Box>
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.btn}
                  onClick={handleBillClick}
                >
                  {newOrder ? "Add To Cart" : "Add To Bill"}
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
            <Alert
              onClose={() => setOpen(false)}
              severity="error"
              variant="filled"
              sx={{width: "100%"}}
            >
              {errorMsg}
            </Alert>
          </Snackbar>
        </Container>
      ) : (
        <Spinner />
      )}
    </Box>
  );
}

export default DishScreen;
