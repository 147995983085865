import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {makeDecrypt} from "src/utils/securels";
import EachTag from "./EachTag";
import {addThisNewTag} from "./helpers";

function Tags({message, open, setOpen}) {
  const {tags} = useSelector((state) => state.restaurant);
  const [thisTags, setThisTags] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (tags.length) {
      let tempTags = [];
      for (const thisTag of tags) {
        tempTags.push({name: thisTag, isDisable: true});
      }

      setThisTags(tempTags);
    }
  }, [tags]);
  const handleDialogClose = () => {
    setOpen(false);
    setIsNewItem(false);
  };

  const [isNewItem, setIsNewItem] = useState(false);
  const [newTag, setNewTag] = useState("");
  const handleNewTagAdd = () => {
    addThisNewTag(newTag, makeDecrypt("restaurantId"), dispatch);
    setIsNewItem(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" variant="h4">
          {message}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
          {thisTags.map((tag, index) => {
            return <EachTag tag={tag} key={tag} />;
          })}
          {isNewItem && (
            <TextField
              fullWidth
              value={newTag}
              disabled={false}
              onChange={(e) => setNewTag(e.target.value)}
              variant="outlined"
            />
          )}
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary" variant="outlined">
              Close
            </Button>

            {isNewItem ? (
              <Button
                color="primary"
                variant="outlined"
                onClick={() => handleNewTagAdd()}
              >
                Save
              </Button>
            ) : (
              <Button
                color="primary"
                variant="outlined"
                onClick={() => setIsNewItem(true)}
              >
                Add new tag
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Tags;
