import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import {withStyles, makeStyles} from "tss-react/mui";
import React from "react";
import {useSelector} from "react-redux";
import moment from "moment";

const useStyles = makeStyles()((theme) => {
  return {
    table: {
      maxHeight: "60%",
    },
    spinner: {
      display: "flex",
      // width: "1000PX",
      minHeight: "300px",
      alignItems: "center",
      justifyContent: "center",
    },
  };
});

const StyledTableCell = withStyles(TableCell, (theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}));

const StyledTableRow = withStyles(TableRow, (theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

function OrderTable({orders, page, limit, progress}) {
  const {classes} = useStyles();
  const serviceFee = useSelector(
    (state) => state.restaurant?.dinify_payment_plan?.application_service_fee
  );
  const {currencySign} = useSelector((state) => state.restaurant);

  return (
    <>
      {orders.length > 0 ? (
        <TableContainer component={Paper} className={classes.table}>
          <Table stickyHeader aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell style={{backgroundColor: "#FF5757"}}>
                  Order No.
                </StyledTableCell>
                <StyledTableCell style={{backgroundColor: "#FF5757"}} align="right">
                  Total Including Taxes
                </StyledTableCell>
                <StyledTableCell style={{backgroundColor: "#FF5757"}} align="right">
                  PST
                </StyledTableCell>
                <StyledTableCell style={{backgroundColor: "#FF5757"}} align="right">
                  GST
                </StyledTableCell>
                <StyledTableCell style={{backgroundColor: "#FF5757"}} align="right">
                  App Fee
                </StyledTableCell>
                <StyledTableCell style={{backgroundColor: "#FF5757"}} align="right">
                  Date Time
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {orders.map((order) => {
                let subTotal = 0;

                for (const i in order.items) {
                  subTotal =
                    parseFloat(subTotal) +
                    parseFloat(order.items[i].quantity * order.items[i].price);
                }

                return (
                  <StyledTableRow key={order._id}>
                    <StyledTableCell component="th" scope="row">
                      {order.seqValue}
                    </StyledTableCell>

                    <StyledTableCell align="right">
                      {currencySign} {order.grandTotal.toFixed(2)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {currencySign} {order.pstValue}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {currencySign} {(order.tax - order.pstValue).toFixed(2)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {currencySign}{" "}
                      {!order.pushToCustomer
                        ? order.halfToCustomer
                          ? serviceFee / 2
                          : serviceFee
                        : 0}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {moment(order.createdAt).fromNow()}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : progress == 100 && !orders.length ? (
        <Box className={classes.spinner}>
          <Typography>Sorry No Data Available</Typography>
        </Box>
      ) : (
        <Box className={classes.spinner}>
          <CircularProgress style={{margin: "auto"}} />
        </Box>
      )}
    </>
  );
}

export default OrderTable;
