import {
    ADD_N_CATEGORY,
    UPDATE_N_CATEGORY,
    DELETE_N_CATEGORY,
} from "../types";

const initialState = {
    menuCategories: [],
    categoryLoading: false,
};

export default function menuCategoryReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_N_CATEGORY:
            return {
                ...state,
                menuCategories: [...state.menuCategories, ...action.payload],
                // loadingRestaurant: false,
                // restaurant: { ...action.payload },
                // loading: false
            };
        case UPDATE_N_CATEGORY:
            return {
                ...state,
                ...action.payload,
                // loadingRestaurant: false,
            };
        case DELETE_N_CATEGORY:
            return {
                ...state,
                menuCategories: [
                    state.menuCategories.filter((category) => category !== action.payload),
                ],
                // loadingRestaurant: false,
            };

        default:
            return state;
    }
}
