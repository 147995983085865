export const columns = [
  {
    accessorKey: "id",
    header: "OrderId",
    size: 230,
  },
  {
    accessorKey: "totalPrice",
    header: "TotalPrice",
    size: 130,
  },
  {
    accessorKey: "gst",
    header: "GST",
    size: 100,
  },
  {
    accessorKey: "pst",
    header: "PST",
    size: 100,
  },
  {
    accessorKey: "appliedFee",
    header: "AppFee",
    size: 100,
  }
];

export const AccountingCsvOptions = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  headers: columns.map((c) => c.header),
};
