import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import React, {useEffect, useState} from "react";
import OrderItemsList from "./OrderItemsList";
const useStyles = makeStyles()((theme) => {
  return {
    formControl: {
      margin: theme.spacing(1),
      [theme.breakpoints.between("xs", "sm")]: {
        minWidth: 10,
        "& #standard-basic": {
          fontSize: "0.8rem",
          width: 50,
        },
      },
      [theme.breakpoints.between("sm", "md")]: {
        minWidth: 40,
        "& #standard-basic": {
          fontSize: "1.2rem",
          flex: 2,
          width: 100,
        },
      },
      [theme.breakpoints.between("md", "lg")]: {
        minWidth: 90,
        margin: theme.spacing(2),
      },

      [theme.breakpoints.up("lg")]: {
        minWidth: 120,
        margin: theme.spacing(3),
      },
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  };
});

function OrderSplitting({
  rows,
  orderItemsWithPst,
  appliedFee,
  gstValue,
  setOrderSplitValues,
  order,
  cloverDeviceConnected,
  setAlertMessage,
  saveOn,
}) {
  const {classes} = useStyles();
  const [dividesIn, setDividesIn] = useState(0);
  let initialCheckBoxState = {
    checked2: false,
    checked3: false,
    checked4: false,
  };
  const [state, setState] = React.useState(initialCheckBoxState);
  const {checked2, checked3, checked4} = state;
  const [selectedBill, setSelectedBill] = useState("");
  const [splitValues, setSplitValues] = useState({
    splitedIn: 2,
    bills: [],
  });
  const {splitedIn, bills} = splitValues;
  const [splittingType, setSplittingType] = React.useState("manually");

  useEffect(() => {
    setState((prevValues) => ({...prevValues, checked2: true}));
    setDividesIn(2);
  }, []);

  useEffect(() => {
    if (order && order.orderSplitted) {
      setSplitValues({
        splitedIn: order.orderSplitValues.splitedIn,
        bills: order.orderSplitValues.bills,
      });
      setDividesIn(order.orderSplitValues.splitedIn);
      setSelectedBill("1");
      let tempState = initialCheckBoxState;
      tempState[`checked${order.orderSplitValues.splitedIn}`] =
        order.orderSplitValues.splitedIn;
      setState((prevValues) => tempState);
    }
  }, [order]);

  useEffect(() => {
    if (
      !order.orderSplitted ||
      (order.orderSplitted && order.orderSplitValues.splitedIn !== dividesIn && dividesIn)
    ) {
      setSelectedBill("");
      let tempBills = [];
      for (let i = 1; i <= dividesIn; i++) {
        tempBills.push({
          billNo: i,
          selectedItems: [],
          appliedPst: 0,
          appliedGst: 0,
          appliedApplicationFee: 0,
          totalBill: 0,
          subTotal: 0,
          billGenerated: false,
        });
      }
      setSplitValues((prevValues) => ({
        ...prevValues,
        splitedIn: parseInt(dividesIn),
        bills: tempBills,
      }));
    }
  }, [dividesIn]);

  useEffect(() => {
    if (splitValues.bills.length) setOrderSplitValues(splitValues);
  }, [splitValues]);

  const handleChange = (e) => {
    setState(initialCheckBoxState);
    setDividesIn(e.target.value);
    if (e.target.name !== "customNumber")
      setState((prevValues) => ({...prevValues, [e.target.name]: e.target.checked}));
  };

  const handleSplittingTypeChange = (e) => {
    setSplittingType(e.target.value);

    if (e.target.value === "equally") {
      setSelectedBill("1");
      let tempBills = [];
      let tempRows = [...rows];
      let tempTotalPst = 0;
      let tempTotalBill = 0;
      let tempSubTotalBill = 0;
      rows.forEach((row, index) => {
        let tempAppliedPst = 0;
        if (orderItemsWithPst.some((item) => item._id === row._id)) {
          tempAppliedPst = orderItemsWithPst.find(
            (item) => item._id === row._id
          ).itemTotalPst;
        }
        tempTotalPst = tempTotalPst + tempAppliedPst;
        tempTotalBill = tempTotalBill + row.price;
        tempRows[index] = {
          ...row,
          selected: true,
          subTotalForBill: parseFloat((row.price / dividesIn).toFixed(2)),
          applicablePst: parseFloat((tempAppliedPst / dividesIn).toFixed(2)),
        };
      });
      tempSubTotalBill = tempTotalBill;
      tempTotalBill =
        tempTotalBill +
        parseFloat(gstValue / dividesIn) +
        parseFloat(appliedFee / dividesIn) +
        parseFloat(tempTotalPst / dividesIn);
      for (let i = 1; i <= dividesIn; i++) {
        tempBills.push({
          billNo: i,
          selectedItems: tempRows,
          appliedPst: parseFloat((tempTotalPst / dividesIn).toFixed(2)),
          appliedGst: parseFloat((gstValue / dividesIn).toFixed(2)),
          appliedApplicationFee: parseFloat((appliedFee / dividesIn).toFixed(2)),
          totalBill: parseFloat((tempTotalBill / dividesIn).toFixed(2)),
          subTotal: parseFloat((tempSubTotalBill / dividesIn).toFixed(2)),
          billGenerated: false,
        });
      }
      setSplitValues((prevValues) => ({
        ...prevValues,
        splitedIn: parseInt(dividesIn),
        bills: tempBills,
      }));
    }
  };

  return (
    <Box>
      <Box display="flex" gap="20px" alignItems="center">
        <Typography variant="h4">Divide bill by</Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked2}
              value={2}
              onChange={handleChange}
              name="checked2"
            />
          }
          label="2"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={checked3}
              value={3}
              onChange={handleChange}
              name="checked3"
            />
          }
          label="3"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={checked4}
              value={4}
              onChange={handleChange}
              name="checked4"
            />
          }
          label="4"
        />

        <TextField
          className={classes.formControl}
          id="standard-basic"
          label="Enter Custom Value"
          type="number"
          name="customNumber"
          InputProps={{inputProps: {min: 2, max: 10}}}
          onChange={handleChange}
          value={dividesIn}
        />
      </Box>
      <Box display="flex" gap="20px" alignItems="center">
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="splittingtype"
            name="splittingType"
            value={splittingType}
            onChange={handleSplittingTypeChange}
          >
            <FormControlLabel
              value="manually"
              control={<Radio />}
              label="Split Manually"
            />
            <FormControlLabel value="equally" control={<Radio />} label="Split Equally" />
          </RadioGroup>
        </FormControl>
      </Box>

      <Divider />
      <Box>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">Select Bill</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={selectedBill}
            sx={{
              "& #demo-simple-select-outlined": {
                width: 100,
              },
            }}
            onChange={(e) => {
              setSelectedBill(e.target.value);
            }}
            label="Select Bill"
          >
            {Array.apply(null, {length: dividesIn}).map((e, i) => {
              return (
                <MenuItem value={i + 1} key={i}>
                  Bill {i + 1}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <OrderItemsList
        rows={rows}
        orderItemsWithPst={orderItemsWithPst}
        dividesIn={dividesIn}
        appliedFee={appliedFee}
        gstValue={gstValue}
        selectedBill={selectedBill}
        splitValues={splitValues}
        setSplitValues={setSplitValues}
        bills={bills}
        cloverDeviceConnected={cloverDeviceConnected}
        order={order}
        splittingType={splittingType}
        setSplittingType={setSplittingType}
        setAlertMessage={setAlertMessage}
        saveOn={saveOn}
      />
    </Box>
  );
}

export default OrderSplitting;
