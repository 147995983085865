import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {
  Box,
  Button,
  Dialog,
  Divider,
  Card,
  CardContent,
  Menu,
  MenuItem,
  Modal,
  TextField,
  Typography,
  Chip,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  deleteTable,
  updateRestaurant,
  updateTable,
} from "src/redux/actions/restaurantActions";
import {makeStyles} from "tss-react/mui";
import qrBackground from "../../Images/qrBackground.png";
import QRCode from "qrcode";
import {makeDecrypt} from "src/utils/securels";
import {getUserData} from "src/redux/actions/authActions";
import {useEffect} from "react";
import {useNavigate} from "react-router";
const useStyles = makeStyles()((theme) => {
  return {
    printModal: {
      // backgroundImage: `URL(${qrBackground})`,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50% , -50%)",
    },
    reservationModel: {
      alignItems: "center",
      position: "absolute",
      top: "50%",
      left: "50%",
      width: "50vw",
      transform: "translate(-50% , -50%)",
    },
    qrCode: {
      position: "fixed",
      top: "200px",
    },
  };
});

function TableActions({
  table,
  tables,
  setTables,
  isDisabled,
  childSnackBar,
  ReservationData,
}) {
  const navigate = useNavigate();
  const [anchorE2, setAnchorE2] = useState();
  const dispatch = useDispatch();
  const {classes} = useStyles();
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [qrcode, setQrCode] = useState("");
  const [currentTable, setcurrentTable] = useState(0);
  const [reservationModel, setreservationModel] = useState(false);
  const [newTableId, setnewTableId] = useState(table.tableNo);
  const [renameTable, setrenameTable] = useState(false);
  useEffect(() => {
    // dispatch(getUserData(navigate));
    if (!disabled) window.print();
  }, [disabled]);

  const changeTableStat = (tableId, tableNo, status) => {
    let tempTables = tables;

    let tableIndex = tempTables.findIndex((table) => table._id == tableId);

    tempTables[tableIndex].isDisabled = status;

    dispatch(updateRestaurant({tables: tempTables}));

    // setStatusBtnLoading(tableNo);
    // disableTable(activeTable._id, status).then((data) => {
    //   window.location.reload();
    //   // navigate('/app/floorlayout')
    // });
  };

  const handleDelete = () => {
    setAnchorE2();
    dispatch(deleteTable(table._id, tables, setTables));
    childSnackBar("sucess", "The table has been deleted");
  };
  const handleUpdate = () => {
    setAnchorE2();
    let duplicate = false;
    tables.map((table) => {
      if (table.tableNo == newTableId) duplicate = true;
    });
    if (!duplicate) {
      dispatch(updateTable(table._id, tables, setTables, newTableId));
      setrenameTable(false);
      // setmsg("");
      childSnackBar("sucess", "The table name has been changed");
    } else {
      childSnackBar("error", "The table is already present");
      setrenameTable(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setDisabled(true);
  };

  const setQRCodeData = (tableNumber) => {
    let restID = makeDecrypt("restaurantId");
    return "https://dine.dinify.io/restaurant/" + restID + "/table/" + tableNumber;
  };

  const handleSubmit = () => {
    // e.preventDefault();

    const generateQR = async (text) => {
      try {
        let qr = await QRCode.toDataURL(text);
        setQrCode(qr);
        setOpen(true);
        // handlePrint();
      } catch (error) {
        // setAlertMessage(error.message);
      }
    };
    generateQR(setQRCodeData(table.tableNo));
  };

  return (
    <Box sx={{display: "flex", alignItems: "center"}}>
      <MoreVertIcon
        onClick={(e) => {
          setAnchorE2(e.currentTarget);
        }}
        onTouchStart={(e) => {
          setAnchorE2(e.currentTarget);
        }}
        id={`moreMenuIcon${table.tableNo}`}
        style={{
          color: "white",
          fontSize: "18px",
          cursor: "pointer",
        }}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorE2}
        keepMounted
        open={Boolean(anchorE2)}
        onClose={() => setAnchorE2()}
      >
        <MenuItem
          onClick={() => changeTableStat(table._id, table.tableNo, !table.isDisabled)}
          onTouchStart={() =>
            changeTableStat(table._id, table.tableNo, !table.isDisabled)
          }
        >
          {table.isDisabled ? "ENABLE" : "DISABLE"}
        </MenuItem>
        <MenuItem onClick={handleSubmit}>Generate QR Code</MenuItem>

        {!isDisabled && (
          <MenuItem
            onClick={() => {
              handleDelete();
            }}
            onTouchStart={() => {
              handleDelete();
            }}
          >
            Delete
          </MenuItem>
        )}
        {!isDisabled && (
          <MenuItem
            onClick={() => {
              setAnchorE2();
              setrenameTable(true);
            }}
            onTouchStart={() => {
              setAnchorE2();
              setrenameTable(true);
            }}
          >
            Rename
          </MenuItem>
        )}
        {table.isReserved && (
          <MenuItem
            onClick={() => {
              setcurrentTable(table.tableNo);
              setreservationModel(true);
            }}
            onTouchStart={() => {
              setcurrentTable(table.tableNo);
              setreservationModel(true);
            }}
          >
            Reservation Info
          </MenuItem>
        )}
      </Menu>

      <Dialog
        open={renameTable}
        // TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Box sx={{p: 2}}>
          <Typography variant="h4"> Enter New Table Number</Typography>
          <Divider style={{margin: "5px 0"}} />
          <Box sx={{mt: 2, p: 1}}>
            <TextField
              label="Table Number"
              value={newTableId}
              onChange={(e) => setnewTableId(e.target.value)}
            />
          </Box>
          <Box display={"flex"} justifyContent={"right"} sx={{mt: 1, p: 1}}>
            <Button
              variant="outlined"
              onClick={() => {
                setrenameTable(false);
                setAnchorE2();
              }}
              color="primary"
              style={{marginRight: "10px"}}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={() => handleUpdate()} color="primary">
              Change
            </Button>
          </Box>
        </Box>
      </Dialog>
      <Modal open={reservationModel} onClose={(e) => setreservationModel(false)}>
        <Card className={classes.reservationModel}>
          {ReservationData.length && (
            <CardContent>
              <Typography
                sx={{fontSize: 14}}
                variant="h6"
                color="text.secondary"
                gutterBottom
              >
                Reserved From:{" "}
                {new Date(ReservationData[0].reservation.startTime).toUTCString()}
              </Typography>
              <Typography
                sx={{fontSize: 14}}
                variant="h6"
                color="text.secondary"
                gutterBottom
              >
                Reserved TO:{" "}
                {new Date(ReservationData[0].reservation.endTime).toUTCString()}
              </Typography>
              {ReservationData[0]?.user && (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  {ReservationData[0]?.user?.name && (
                    <Chip
                      label={ReservationData[0]?.user?.name}
                      variant="outlined"
                      color="primary"
                    />
                  )}
                  {ReservationData[0]?.user?.phone && (
                    <Chip
                      label={ReservationData[0]?.user?.phone}
                      variant="outlined"
                      color="primary"
                    />
                  )}
                  {ReservationData[0]?.user?.email && (
                    <Chip
                      label={ReservationData[0]?.user?.email}
                      variant="outlined"
                      color="primary"
                    />
                  )}
                </Box>
              )}
            </CardContent>
          )}
        </Card>
      </Modal>
      <Modal open={open} onClose={handleClose}>
        <Box className={classes.printModal}>
          <img
            src={qrBackground}
            height="604.72440945"
            width="415.7480315"
            style={{marginBottom: "40px"}}
          />
          <img src={qrcode} height="160" width="160" className={classes.qrCode} />
          {disabled && (
            <Button
              variant="contained"
              onClick={() => {
                setDisabled(false);
              }}
              color="primary"
            >
              Print
            </Button>
          )}
        </Box>
      </Modal>
    </Box>
  );
}

export default TableActions;
