import {Helmet} from "react-helmet";
import {Box, Container, Typography, Button} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {useNavigate} from "react-router-dom";

const AfterSignUp = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Verify your email</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="md">
          <Typography align="center" color="textPrimary" variant="h4">
            Sign up successfull! Please verify your email before logging in.
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle2"></Typography>
          <Box sx={{textAlign: "center", mt: 2}}>
            <Button
              variant="contained"
              onClick={() => navigate("/login", {replace: true})}
              endIcon={<NavigateNextIcon />}
            >
              Login
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default AfterSignUp;
