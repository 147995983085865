// import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {Formik} from "formik";
import moment from "moment";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {canadaStates, countires, indiaStates, nepalStates} from "../../utils/data";
import {makeDecrypt, makeEncrypt} from "src/utils/securels";
import * as Yup from "yup";
var momentTimezone = require("moment-timezone");

const useStyles = makeStyles()(() => {
  return {
    root: {
      height: "100%",
      overflowY: "scroll",
      padding: "10px",
    },
    subtitle: {marginBottom: "10px"},
    // infoWrapper: {
    //   // overflowY: "scroll",
    //   // maxHeight: "550px",
    // },
  };
});

// Testing conflicts
const states = [
  {
    value: "british-columbia",
    label: "British Columbia",
  },
];

const cuisineList = ["Indian", "South-Indian", "Italian", "Fast-Food", "American"];

const alcoholServedOptions = [
  {
    value: "true",
    label: "Yes",
  },
  {
    value: "false",
    label: "No",
  },
];

const OnBoardingProfile = (props) => {
  const {classes} = useStyles();
  const {logoImage} = props;
  const {activeStep} = props;
  const {setActiveStep} = props;
  const {setRepresentativeName} = props;
  const {setRestaurantData} = props;
  const {newBranch} = props;
  const {initialData} = props;

  let restData = null;
  if (makeDecrypt("restData")) restData = JSON.parse(makeDecrypt("restData"));

  return (
    <Container className={classes.root}>
      <Formik
        initialValues={
          restData.restaurantLicenseNumber
            ? {
                provinceOrState: restData.restaurantAddress?.provinceOrState,
                country: restData.restaurantAddress?.country,
                restaurantLicenseNumber: restData.restaurantLicenseNumber,
                establishmentDate: restData.establishmentDate,
                paymentModes: restData.cuisine,
                gst: restData.taxTypes?.GST,
                pst: restData.taxTypes?.PST,
                pst2: restData.taxTypes?.PST2,
                cuisine: restData.cuisine,
                logo: "",
                menuCategories: [],
                alcoholServed: restData.alcoholServed,
                city: restData.restaurantAddress?.city,
                zipCode: restData.restaurantAddress?.zipCode,
                streetNo: restData.restaurantAddress?.streetNo,
                streetName: restData.restaurantAddress?.streetName,
                unitNo: restData.restaurantAddress?.unitNo,
                multilocation: restData.multilocation,
                dinifySidePayments: restData.dinifySidePayments,
                timeZone: restData.restaurantAddress?.timeZone,
              }
            : {
                provinceOrState: "",
                country: "",
                restaurantLicenseNumber: "",
                establishmentDate: "",
                paymentModes: [],
                gst: "",
                pst: "",
                pst2: "",
                cuisine: [],
                logo: "",
                menuCategories: [],
                alcoholServed: "true",
                city: "",
                zipCode: "",
                streetNo: "",
                streetName: "",
                unitNo: "",
                multilocation: newBranch ? true : false,
                dinifySidePayments: true,
                timeZone: momentTimezone.tz.guess(),
              }
        }
        validationSchema={Yup.object().shape({
          establishmentDate: Yup.string().required("Required field"),
          country: Yup.string().required("Required field"),
          provinceOrState: Yup.string().required("Required field"),
          restaurantLicenseNumber: Yup.string().required("Required field"),
          // paymentModes: Yup.string().required('Required field'),
          gst: Yup.string().required("Required field"),
          pst: Yup.string().required("Required field"),
          pst2: Yup.string().required("Required field"),
          city: Yup.string().required("Required field"),
          zipCode: Yup.string().required("Required field"),
          streetNo: Yup.string().required("Required field"),
          streetName: Yup.string().required("Required field"),
          unitNo: Yup.string().required("Required field"),
          // cuisine: Yup.string().required('Required field'),
          logo: Yup.string(),
          // menuCategories: Yup.string().required('Required field'),
          // alcoholServed: Yup.string().required('Required field')
          timeZone: Yup.string().required("Required field"),
        })}
        onSubmit={(values) => {
          // const userData = { email: values.email, password: values.password }

          const restaurantData = {
            email: initialData.email,
            restaurantName: initialData.restaurantName,
            paymentModes: values.paymentModes,
            restaurantLicenseNumber: values.restaurantLicenseNumber,
            establishmentDate: values.establishmentDate,
            cuisine: values.cuisine,
            alcoholServed: values.alcoholServed,
            logoUrl: values.logoUrl,
            // menuCategories: values.email,
            restaurantAddress: {
              phoneNo: initialData.restaurantAddress.phoneNo,
              provinceOrState:
                values.country === "CANADA" ? "British Columbia" : "Maharashtra",
              country: values.country,
              city: values.city,
              zipCode: values.zipCode,
              streetNo: values.streetNo,
              streetName: values.streetName,
              unitNo: values.unitNo,
              timeZone: values.timeZone,
            },
            taxTypes: {GST: values.gst, PST: values.pst, PST2: values.pst2},
            legalRepresentative: initialData.legalRepresentative,
            multilocation: values.multilocation,
            dinifySidePayments: values.dinifySidePayments,
          };
          setRepresentativeName(restaurantData.legalRepresentative);
          setRestaurantData(restaurantData);
          makeEncrypt("country", restaurantData.restaurantAddress.country);
          makeEncrypt("restData", JSON.stringify(restaurantData));
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values,
        }) => {
          const unCheckAll = () => {
            setFieldValue("multilocation", false);
            // setFieldValue("dinifySidePayments", false);
          };

          return (
            <form autoComplete="off" noValidate {...props}>
              <Box style={{height: "10%"}}>
                <Box>
                  <Typography variant="h1">Restaurant Details</Typography>
                </Box>
                <Box sx={{mt: 4, ml: 1}}>
                  {/*className={classes.infoWrapper} */}
                  <Box>
                    <Typography color="textSecondary" variant="h4" sx={{mb: 2}}>
                      Address
                    </Typography>
                    <Box sx={{ml: 1}}>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.country && errors.country)}
                            helperText={touched.country && errors.country}
                            fullWidth
                            label="Country"
                            name="country"
                            onChange={(e) => {
                              handleChange(e);
                              setFieldValue("provinceOrState", "");
                            }}
                            required
                            select
                            value={values.country}
                            variant="outlined"
                          >
                            {countires.map((country) => {
                              return (
                                <MenuItem value={country.value} key={country.value}>
                                  {country.label}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          {values.country === "CANADA" ? (
                            <TextField
                              error={Boolean(
                                touched.provinceOrState && errors.provinceOrState
                              )}
                              helperText={
                                touched.provinceOrState && errors.provinceOrState
                              }
                              fullWidth
                              label="Select State"
                              name="provinceOrState"
                              onChange={handleChange}
                              required
                              select
                              value={values.provinceOrState}
                              variant="outlined"
                            >
                              {canadaStates.map((eachState) => {
                                return (
                                  <MenuItem value={eachState} key={eachState}>
                                    {eachState}
                                  </MenuItem>
                                );
                              })}
                            </TextField>
                          ) : values.country === "INDIA" ? (
                            <Grid item md={6} xs={12}>
                              <TextField
                                error={Boolean(
                                  touched.provinceOrState && errors.provinceOrState
                                )}
                                helperText={
                                  touched.provinceOrState && errors.provinceOrState
                                }
                                fullWidth
                                label="Select State"
                                name="provinceOrState"
                                onChange={handleChange}
                                required
                                select
                                value={values.provinceOrState}
                                variant="outlined"
                              >
                                {indiaStates.map((eachState) => {
                                  return (
                                    <MenuItem value={eachState} key={eachState}>
                                      {eachState}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                            </Grid>
                          ) : values.country === "NEPAL" ? (
                            <Grid item md={6} xs={12}>
                              <TextField
                                error={Boolean(
                                  touched.provinceOrState && errors.provinceOrState
                                )}
                                helperText={
                                  touched.provinceOrState && errors.provinceOrState
                                }
                                fullWidth
                                label="Select State"
                                name="provinceOrState"
                                onChange={handleChange}
                                required
                                select
                                value={values.provinceOrState}
                                variant="outlined"
                              >
                                {nepalStates.map((eachState) => {
                                  return (
                                    <MenuItem value={eachState} key={eachState}>
                                      {eachState}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                            </Grid>
                          ) : (
                            <TextField
                              error={Boolean(touched.state && errors.state)}
                              helperText={touched.state && errors.state}
                              fullWidth
                              label="Select State"
                              name="state"
                              onChange={handleChange}
                              defaultValue="Select state"
                              value={values.provinceOrState}
                              // value="British Coulmbia"
                              variant="outlined"
                              disabled={true}
                            />
                          )}
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.city && errors.city)}
                            helperText={touched.city && errors.city}
                            fullWidth
                            label="City"
                            name="city"
                            onChange={handleChange}
                            value={values.city}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.unitNo && errors.unitNo)}
                            helperText={touched.unitNo && errors.unitNo}
                            fullWidth
                            label="Unit Number"
                            name="unitNo"
                            onChange={handleChange}
                            value={values.unitNo}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.streetNo && errors.streetNo)}
                            helperText={touched.streetNo && errors.streetNo}
                            fullWidth
                            label="Street Number"
                            name="streetNo"
                            onChange={handleChange}
                            value={values.streetNo}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.streetName && errors.streetName)}
                            helperText={touched.streetName && errors.streetName}
                            fullWidth
                            label="Street Name"
                            name="streetName"
                            onChange={handleChange}
                            value={values.streetName}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.zipCode && errors.zipCode)}
                            helperText={touched.zipCode && errors.zipCode}
                            fullWidth
                            label="Zip Code"
                            name="zipCode"
                            onChange={handleChange}
                            value={values.zipCode}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <Divider sx={{my: 3}} />
                  <Box>
                    <Typography color="textSecondary" variant="h4" sx={{mb: 2}}>
                      Taxes
                    </Typography>
                    <Box sx={{ml: 1}}>
                      <Grid container spacing={4}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.gst && errors.gst)}
                            helperText={touched.gst && errors.gst}
                            fullWidth
                            label="GST %"
                            name="gst"
                            onChange={handleChange}
                            value={values.gst}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.pst && errors.pst)}
                            helperText={touched.pst && errors.pst}
                            fullWidth
                            label="PST1 %"
                            name="pst"
                            onChange={handleChange}
                            value={values.pst}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.pst2 && errors.pst2)}
                            helperText={touched.pst2 && errors.pst2}
                            fullWidth
                            label="PST2 %"
                            name="pst2"
                            onChange={handleChange}
                            value={values.pst2}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <Divider sx={{my: 3}} />
                  <Box sx={{mb: 4}}>
                    <Typography color="textSecondary" variant="h4" sx={{mb: 2}}>
                      Other details
                    </Typography>
                    <Box sx={{ml: 1}}>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(
                              touched.restaurantLicenseNumber &&
                                errors.restaurantLicenseNumber
                            )}
                            helperText={
                              touched.restaurantLicenseNumber &&
                              errors.restaurantLicenseNumber
                            }
                            fullWidth
                            label="License Number"
                            name="restaurantLicenseNumber"
                            onChange={handleChange}
                            value={values.restaurantLicenseNumber}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(
                              touched.establishmentDate && errors.establishmentDate
                            )}
                            helperText={
                              touched.establishmentDate && errors.establishmentDate
                            }
                            id="establishmentDate"
                            label="Date of Establishment"
                            name="establishmentDate"
                            type="date"
                            fullWidth
                            value={moment(new Date(values.establishmentDate)).format(
                              "YYYY-MM-DD"
                            )}
                            onChange={handleChange}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            // error={Boolean(touched.alcoholServed && errors.alcoholServed)}
                            // helperText={touched.alcoholServed && errors.alcoholServed}
                            fullWidth
                            label="Alcohol Served"
                            name="alcoholServed"
                            // onChange={(option) => setFieldValue('alcoholServed', option.value)}
                            onChange={handleChange}
                            select
                            SelectProps={{native: true}}
                            // value={values.alcoholServed}
                            variant="outlined"
                          >
                            {alcoholServedOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        {!newBranch && (
                          <Grid item md={6} xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values.multilocation === true}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setFieldValue("multilocation", true);
                                    } else {
                                      unCheckAll();
                                    }
                                  }}
                                  name="multilocation"
                                  // value={values.multilocation}
                                />
                              }
                              label="Multiple Branches"
                            />
                          </Grid>
                        )}
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.timeZone && errors.timeZone)}
                            helperText={touched.timeZone && errors.timeZone}
                            fullWidth
                            label="Select timezone"
                            name="timeZone"
                            onChange={handleChange}
                            select
                            value={values.timeZone}
                            variant="outlined"
                          >
                            {momentTimezone.tz.names().map((timezone) => {
                              return (
                                <MenuItem value={timezone} key={timezone}>
                                  {timezone}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    p: 2,
                  }}
                  style={{display: "none"}}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                    type="submit"
                    id="hiddenButton"
                  >
                    Save details
                  </Button>
                </Box>
              </Box>
            </form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default OnBoardingProfile;
