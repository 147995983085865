import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "tss-react/mui";
// import MUIDataTable from "mui-datatables";
import React, { useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setReload } from "src/redux/actions/orderActions";
import {
  createMenuItem,
  updateMenuItemStatus
} from "../../redux/actions/menuActions";
import { getMenuItem } from "../order/helpers";
import {
  UpdateMenuItem,
  UploadMenuImage,
  createModifierForRestaurant,
  deleteMenuItem,
} from "./helpers";
// import MaterialTable from "material-table";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import ShowMoreText from "react-show-more-text";
import axiosInstance from "src/axios";
import { deleteFromLocal, makeDecrypt, makeEncrypt } from "src/utils/securels";
import MenuActions from "./MenuActions";
import Tags from "./Tags";
// import FileBase64 from "react-file-base64";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeleteIcon from "@mui/icons-material/Delete";
import Compressor from "compressorjs";
import {
  LOADING_COMPLETED,
  LOADING_MENU,
  LOADING_NOT_COMPLETED,
  STOP_LOADING_MENU,
} from "src/redux/types";

const useStyles = makeStyles()((theme) => {
  return {
    formControl: {
      margin: theme.spacing(1),
      // width: "100%",
    },
    editItem: {
      position: "relative",
    },
    actions: {
      position: "absolute",
      top: "0",
      right: "40px",
    },
    options: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "auto",
      // width:'fit-content',
      padding: "15px 30px 15px 30px",
      // zIndex:11,
      // backgroundColor: 'red',
      // backgroundColor:"white",
      // position:'absolute',
      // top:0,
      // right:0
    },
    tagsCont: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: "10px auto",
    },
    optionsButton: {
      justifyContent: "left",
      margin: "10px",
      marginLeft: "0px",
      paddingLeft: "0px",
    },
    selectTagCont: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "10px",
    },
    selectedTagcont: {
      display: "flex",
      flexDirection: "row",
      overflow: "scroll",
      minHeight: "200px",
    },
    loaderBox: {
      display: "flex",
      minHeight: "200px",
      alignItems: "center",
      justifyContent: "center",
    },
    selectTimeMsg: {
      margin: "20px auto 10px",
      color: "black",
    },
    timePicker: {
      margin: "10px 30px auto 0px",
    },
  };
});

const MenuListResults = ({
  tempSocket,
  rows,
  values,
  setValues,
  thisReload,
  setThisReload,
  menuItemsLoader,
  setMenuItemsLoader,
  setShowBackdrop,
  selectedMainMenu,
  ...rest
}) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedMenuId, setSelectedMenuId] = useState("");
  const [selectedMenuIdStatus, setselectedMenuIdStatus] = useState("");
  const { menu, selectedCategory, allModifiers, loadingMenu, loadingCompleted } =
    useSelector((state) => state.menu);

  const { _id, logoUrl, taxTypes } = useSelector((state) => state.restaurant);
  const restaurantTags = useSelector((state) => state.restaurant.tags);
  const { role } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);

  const [page, setPage] = useState(1);
  const [flag, setFlag] = useState(false);
  const [pstApplicable, setPstApplicable] = useState(false);
  const [pstPercentage, setPstPercentage] = useState();
  const [isPstType1, setIsPstType1] = useState();
  const [optionsDialogOpen, setoptionsDialogOpen] = useState(false);

  const [alertMessage, setAlertMessage] = useState("");
  const [availbleTags, setAvailableTags] = useState([]);
  const [newTag, setNewTag] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [snackOpen, setSnackOpen] = useState();
  const [snackType, setSnackType] = useState("success");
  const [imageLoading, setImageLoading] = useState();
  const [pstTypes, setPstTypes] = useState({
    pstType1: 0,
    pstType2: 0,
  });

  const { pstType1, pstType2 } = pstTypes;
  // const pstType1 = 5;
  // const pstType2 = 7;

  useEffect(() => {
    if (taxTypes?.PST)
      setPstTypes({ pstType1: taxTypes.PST, pstType2: taxTypes.PST2 ? taxTypes.PST2 : 0 });
  }, [taxTypes]);

  // const [data, setMenuData] = useState([]);
  const category = selectedCategory;
  const [menuItemDetails, setMenuItemDetails] = useState({
    name: "",
    price: "",
    description: "",
    category: category,
    diet: "",
    imageUrl: "",
    status: "Offline",
    allergens: ["None"],
    restId: _id,
    limitedQuantity: false,
    quantity: 0,
    tags: [],
    availability: {
      start: "00:01",
      end: "23:59",
    },
    discount: null,
    modifiers: "",
    instagramVideoUrl: "",
    tiktokVideoUrl: "",
    facebookVideoUrl: "",
  });

  const [tempTags, setTempTags] = useState({
    gf: false,
    df: false,
    vegan: false,
  });

  const [addModifierFlag, setAddModifierFlag] = useState(false);
  const [copyModifierFlag, setCopyModifierFlag] = useState(false);
  const { gf, df, vegan } = tempTags;

  const {
    name,
    description,
    price,
    imageUrl,
    restId,
    limitedQuantity,
    quantity,
    tags,
    availability,
    discount,
    modifiers,
    instagramVideoUrl,
    tiktokVideoUrl,
    facebookVideoUrl,
  } = menuItemDetails;
  const { reload } = useSelector((state) => state.order);
  const [justOpen, setJustOpen] = useState({
    limitOpen: false,
  });
  const { currencySign } = useSelector((state) => state.restaurant);
  const discounts = useSelector((state) => state.discount.discount);
  const [alwaysAvailable, setAlwaysAvailable] = useState(false);
  const [customerFacingDiscounts, setCustomerFacingDiscounts] = useState([])

  // tags.length && setSelectedTags(tags);

  useEffect(() => {
    tags && setSelectedTags(tags);
  }, [tags]);
  // const [] = useState(false)
  useEffect(() => {
    if (alertMessage)
      setTimeout(() => {
        setAlertMessage("");
      }, 3000);
  }, [alertMessage]);
  const [menuImage, setMenuImage] = useState();
  let upload = useRef(null);

  useEffect(() => {
    setMenuImage(imageUrl);
  }, [imageUrl]);

  const { limitOpen } = justOpen;
  const randomImage = logoUrl;

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  function createData(
    image,
    name,
    description,
    price,
    status,
    modifiers,
    id,
    restaurant,
    tags,
    pstApplicable,
    quantity,
    limitedQuantity,
    pstPercentage,
    availability,
    discount,
    instagramVideoUrl,
    tiktokVideoUrl,
    facebookVideoUrl,
  ) {
    return {
      image,
      name,
      description,
      price,
      status,
      modifiers,
      id,
      restaurant,
      tags,
      pstApplicable,
      quantity,
      limitedQuantity,
      pstPercentage,
      availability,
      discount,
      instagramVideoUrl,
      tiktokVideoUrl,
      facebookVideoUrl,
    };
  }

  // const [rowsss, setRowsss] = useState([]);

  // // useEffect(() => {
  // //   rowsss.length < menu.length && setRowsss([]);
  // // }, [menu]);

  // // useEffect(() => {
  // //   setRowsss([]);
  // menu.map((menuItem) => {
  //   setRowsss((preVal) => [
  //     ...preVal,
  //     createData(
  //       menuItem.imageUrl ? menuItem.imageUrl : randomImage,
  //       menuItem.name,
  //       menuItem.description,
  //       menuItem.price,
  //       menuItem.status,
  //       menuItem.modifiers ? {id: menuItem._id, modifiers: menuItem.modifiers} : [],
  //       menuItem._id,
  //       menuItem.restaurant,
  //       menuItem.tags,
  //       menuItem.pstApplicable,
  //       menuItem.quantity,
  //       menuItem.limitedQuantity,
  //       menuItem.pstPercentage
  //     ),
  //   ]);
  // });
  // }, [menu]);

  let rowsss = [];

  menu.map((menuItem) => {
    rowsss.push(
      createData(
        menuItem.imageUrl ? menuItem.imageUrl : randomImage,
        menuItem.name,
        menuItem.description,
        menuItem.price,
        menuItem.status,
        menuItem.modifiers ? { id: menuItem._id, modifiers: menuItem.modifiers } : [],
        menuItem._id,
        menuItem.restaurant,
        menuItem.tags,
        menuItem.pstApplicable,
        menuItem.quantity,
        menuItem.limitedQuantity,
        menuItem.pstPercentage,
        menuItem.availability,
        menuItem.discount,
        menuItem.instagramVideoUrl ? menuItem.instagramVideoUrl : "",
        menuItem.tiktokVideoUrl ? menuItem.tiktokVideoUrl : "",
        menuItem.facebookVideoUrl ? menuItem.facebookVideoUrl : ""
      )
    );
  });

  // useEffect(() => {
  //   rowsss.forEach((currentRow) => {
  //     // handleRowClick(currentRow);
  //   });
  // }, [rowsss]);

  //useEffect(() => {
  // if (menuItemsLoader) rowsss = [];
  //}, [setMenuItemsLoader]);

  // useEffect(() => {
  //   dispatch(getMenuItems(false)).then((res) => {
  //     setMenuItems(res=>);
  //   });
  // }, []);

  // useEffect(() => {
  //   alert(selectedCategory);
  // }, [selectedCategory]);

  const handleCreateMenu = async () => {
    dispatch({ type: LOADING_MENU });
    dispatch({ type: LOADING_NOT_COMPLETED });

    let createModifierForRest = null;
    if (description && price && name) {
      let thisData = menuItemDetails;
      thisData.tags = selectedTags;

      thisData.pstApplicable = pstApplicable;

      thisData.pstApplicable = pstApplicable;
      thisData.pstPercentage = pstPercentage;
      if (addModifierFlag) {
        let currentOptions = Object.keys(modifierOptions).map((item, index) => {
          return modifierOptions[item];
        });

        let justModifier = {
          type: type,
          options: currentOptions,
          multiSelect: multiSelect,
          isMandatory: isMandatory,
        };
        thisData.modifiers = [justModifier];
        createModifierForRest = justModifier;
      } else if (copyModifierFlag) {
        let modifiersToTransfer = allModifiers.filter((item) =>
          selectedModifiers.some((thisName) => thisName == item.type)
        );

        // for (const thisModifier of modifiersToTransfer) {
        modifiersToTransfer.forEach((thisModifier) => {
          thisModifier._id = undefined;
          if (thisModifier.options.length) {
            thisModifier.options.forEach((thisOption) => {
              thisOption._id = undefined;
            });
          }
        });
        thisData.modifiers = modifiersToTransfer;
      }
      // }
      // let ds = await dispatch(getMenuItems(false));
      // let previousModifiers = [];
      //  try {
      //    const menuItem = await getMenuItem(selectedMenuId);
      //    if (menuItem.modifiers) {
      //      previousModifiers = menuItem.modifiers;
      //    }
      //  } catch (error) {
      //    setAlertMessage(error.message);
      //  }

      // previousModifiers.push({
      //   type: type,
      //   options: currentOptions,
      //   multiSelect: multiSelect,
      // });
      if (addModifierFlag && createModifierForRest && !modifierExists) {
        dispatch(createModifierForRestaurant(createModifierForRest, _id));
      }

      if (!modifierExists) {
        dispatch(
          createMenuItem(
            thisData,
            menuImage,
            navigate,
            thisReload,
            setThisReload,
            logoUrl,
            setAlertMessage,
            setSnackOpen
          )
        );
        // setOpen(false);
        // setMenuImage("");
        handleDialogClose();
      } else {
        setAlertMessage("Modifier type already exists");
      }
    } else {
      setAlertMessage("All fields required");
    }
  };

  const handlePageChange = (currentPage) => {
    setPage(currentPage);
  };

  const handleSelectMenuItem = (menuId, toggleStatus, row) => {
    setselectedMenuIdStatus(toggleStatus);
    setSelectedMenuId(menuId);
    let thisTags = tempTags;

    if (row.tags?.length) {
      row.tags.forEach((tag) => {
        if (tag === "Gluten Free") thisTags.gf = true;
        if (tag === "Dairy Free") thisTags.df = true;
        if (tag === "Vegan") thisTags.vegan = true;
      });
    }

    if (Object.keys(thisTags).length === row.tags?.length) setTempTags(thisTags);

    setPstApplicable(row.pstApplicable);
    row.pstPercentage == pstType1 ? setIsPstType1(true) : setIsPstType1(false);
    setMenuItemDetails((prevValues) => ({
      ...prevValues,
      name: row.name,
      description: row.description,
      price: row.price,
      imageUrl: row.image,
      restId: row.restaurant,
      quantity: row.quantity,
      pstPercentage: row.pstPercentage,
      limitedQuantity: row.limitedQuantity,
      tags: row.tags,
      availability: row.availability,
      discount: row.discount,
      modifiers: row.modifiers,
      instagramVideoUrl: row.instagramVideoUrl,
      tiktokVideoUrl: row.tiktokVideoUrl,
      facebookVideoUrl: row.facebookVideoUrl
    }));
  };

  const handleClickOpen = (message) => {
    setMessage(message);
    setOpen(true);
  };

  const [selectedModifiers, setSelectedModifiers] = useState([]);
  const [modifiersNameList, setModifiersNameList] = useState([]);
  useEffect(() => {
    if (message === "Add Menu Item") {
      setMenuItemDetails({
        name: "",
        price: "",
        description: "",
        category: category,
        diet: "",
        imageUrl: "",
        status: "Offline",
        allergens: ["None"],
        restId: _id,
        tags: [],
        availability: {
          start: "00:01",
          end: "23:59",
        },
        discount: "",
        instagramVideoUrl: "",
        tiktokVideoUrl: "",
        facebookVideoUrl: ""
      });

      // }
    }
  }, [message]);

  useEffect(() => {
    if (allModifiers.length) {
      let temp = allModifiers.map((item) => item.type);
      setModifiersNameList(temp);
    }
  }, [allModifiers]);
  const [actionsOpen, setActionsOpen] = useState(false);

  const handleDialogClose = () => {
    setCopyModifierFlag(false);
    setAddModifierFlag(false);
    setActionsOpen(!actionsOpen);
    setOpen(false);
    setSelectedMenuId("");
    setMessage(false);
    deleteFromLocal("flag");
    setMenuImage("");
    handleClose();
    setIsPstType1(false);
    setSelectedModifiers([]);
    setModifierExists(false);
    setModifierOptions([
      {
        modifierName: "",
        modifierPrice: "0",
      },
    ]);
    setModifier({
      type: "",
      multiSelect: false,
      isMandatory: false,
    });
    setTempTags({
      gf: false,
      df: false,
      vegan: false,
    });
    setPstApplicable(false);
  };

  const handleChange = (event) => {
    setMenuItemDetails((prevValues) => ({
      ...prevValues,
      [event.target.name]: event.target.value,
      category: selectedCategory,
    }));
  };

  // const [anchorEl, setAnchorEl] = React.useState(null);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClose = () => {
    deleteFromLocal("flag");
    // setAnchorEl(null);
  };

  const toggleMenuStatus = (status, id) => {
    const data = {
      _id: id,
      status: status === "Online" ? "Offline" : "Online",
      restId: _id,
    };
    tempSocket.current.emit("changeItemStatus", {
      menuId: selectedMenuId,
      status: status === "Online" ? "Offline" : "Online",
    });
    dispatch(updateMenuItemStatus(data, navigate));
    // setAnchorEl(null);
  };

  const updateItem = (message, id) => {
    setFlag(true);

    setMessage(message);
    setOpen(true);
  };

  const [modifierOptions, setModifierOptions] = useState([
    {
      modifierName: "",
      modifierPrice: "0",
    },
  ]);

  const [modifier, setModifier] = useState({
    type: "",
    multiSelect: false,
    isMandatory: false,
  });

  const [currrentModifierId, setCurrentModifierId] = useState("");

  const handleMenuModifierUpdate = async (id, type, modifierId) => {
    setSelectedMenuId(id);
    try {
      const res = await getMenuItem(id);

      res.modifiers.map((item) => {
        if (item.type === type) {
          setModifier({
            type: item.type,
            multiSelect: item.multiSelect,
            isMandatory: item.isMandatory ? item.isMandatory : false,
          });
          setModifierOptions(item.options);
          setCurrentModifierId(modifierId);
        }
      });
    } catch (error) {
      setAlertMessage(error.message);
    }

    handleModifierActions("Update Menu Modifier");
  };

  const handleUploadClick = () => {
    upload.click();
  };

  const SingleFileChange = async (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    let temp = new Compressor(file, {
      quality: 0.2,
      success(result) {
        reader.readAsDataURL(result);
        reader.onload = function () {
          temp = reader.result;

          // setMenuImage(temp);
          if (message === "Update Menu Item") {
            const formData = new FormData();
            formData.append("file", result, result.name);
            //uploaded on aws s3
            UploadMenuImage(formData, makeDecrypt("restaurantId"), selectedMenuId).then(
              (newMenuData) => {
                setImageLoading(false);
                setMenuImage(newMenuData?.file?.location);
                setMenuItemDetails((prevValues) => ({
                  ...prevValues,
                  imageUrl: newMenuData?.file?.location,
                }));
              }
            );
          } else {
            setMenuImage(reader.result);
            setImageLoading(false);
          }
        };
      },
    });
  };

  const handleUpdateItem = async () => {
    dispatch({ type: LOADING_MENU });
    dispatch({ type: LOADING_NOT_COMPLETED });

    let thisData = menuItemDetails;
    thisData.tags = selectedTags;

    Object.keys(tempTags).map(function (key, index) {
      if (tempTags[key] === true) {
        if (key === "gf") thisData.tags.push("Gluten Free");
        if (key === "df") thisData.tags.push("Dairy Free");
        if (key === "vegan") thisData.tags.push("Vegan");
      }
    });
    thisData.pstApplicable = pstApplicable;
    thisData.pstPercentage = pstPercentage;
    let newModifiersToAdd = [];
    if (addModifierFlag) {
      let currentOptions = Object.keys(modifierOptions).map((item, index) => {
        return modifierOptions[item];
      });

      let justModifier = {
        type: type,
        options: currentOptions,
        multiSelect: multiSelect,
        isMandatory: isMandatory,
      };
      newModifiersToAdd = [justModifier];

      if (justModifier && !modifierExists) {
        dispatch(createModifierForRestaurant(justModifier, _id));
      }
    } else if (copyModifierFlag) {
      let modifiersToTransfer = allModifiers.filter((item) =>
        selectedModifiers.some((thisName) => thisName == item.type)
      );

      // for (const thisModifier of modifiersToTransfer) {
      modifiersToTransfer.forEach((thisModifier) => {
        thisModifier._id = undefined;
        if (thisModifier.options.length) {
          thisModifier.options.forEach((thisOption) => {
            thisOption._id = undefined;
          });
        }
      });
      newModifiersToAdd = modifiersToTransfer;
    }

    try {
      if (
        thisData.description &&
        thisData.name &&
        (thisData.price === 0 || thisData.price !== "")
      ) {
        setOpen(false);
        const res = await UpdateMenuItem(
          {
            name: thisData.name,
            description: thisData.description,
            price: thisData.price,
            imageUrl: thisData.imageUrl,
            tags: thisData.tags,
            pstApplicable: thisData.pstApplicable,
            pstPercentage: thisData.pstPercentage,
            quantity: thisData.quantity,
            limitedQuantity: thisData.limitedQuantity,
            newModifiersToAdd: newModifiersToAdd,
            availability: thisData.availability,
            discount: thisData.discount,
            instagramVideoUrl: thisData.instagramVideoUrl,
            tiktokVideoUrl: thisData.tiktokVideoUrl,
            facebookVideoUrl: thisData.facebookVideoUrl,
          },
          thisData.restId,
          selectedMenuId,
          dispatch
        );
        handleDialogClose();
      } else {
        setAlertMessage("All fields are required");
      }
    } catch (error) {
      setAlertMessage(error.message);
    }
  };

  const handleChangePstApplcable = (event) => {
    setPstApplicable(event.target.checked);
  };

  useState(() => {
    setAvailableTags(restaurantTags);
  }, [restaurantTags]);

  const addNewTag = () => {
    !newTag == "" &&
      axiosInstance
        .put(`/restaurant/add-new-tag/${makeDecrypt("restaurantId")}`, {
          tag: newTag,
        })
        .then((res) => {
          setAvailableTags((prevValues) => [...prevValues, newTag]);
        });
  };

  const [personName, setPersonName] = React.useState([]);

  const handleChangeTag = (value) => {
    setSelectedTags(value);
  };
  const handleChangeModifiers = (value) => {
    setSelectedModifiers(value);
  };

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setPersonName(value);
  };

  const handleDelete = (chipToDelete) => () => {
    setSelectedTags((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  const handleChangePstPercentage = (pstType, isPstType) => {
    setPstPercentage(pstType);
    setIsPstType1(!isPstType);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const { type, multiSelect, isMandatory } = modifier;
  const [modifierExists, setModifierExists] = useState(false);
  const handleModifierChange = (e) => {
    if (e.target.name === "type") {
      setAlertMessage(``);
      setModifierExists(false);
      if (
        modifiersNameList.some(
          (item) => item.toLowerCase() === e.target.value.toLowerCase()
        )
      ) {
        setModifierExists(true);
        setAlertMessage(`modifier type ${e.target.value} already exists`);
      }
      // else {
      // setAlertMessage("");
      setModifier((prevValues) => ({ ...prevValues, type: e.target.value }));
      // }
    } else if (e.target.name === "multiSelect") {
      setModifier((prevValues) => ({
        ...prevValues,
        multiSelect: e.target.checked,
      }));
    } else if (e.target.name === "isMandatory") {
      setModifier((prevValues) => ({
        ...prevValues,
        isMandatory: e.target.checked,
      }));
    }
  };

  const handleModifierActions = (message) => {
    setMessage(message);
    setOpen(true);
  };

  const handlePlusClick = () => {
    let currentOptions = Object.keys(modifierOptions).map((item, index) => {
      return modifierOptions[item];
    });
    currentOptions.push({
      modifierName: "",
      modifierPrice: "0",
    });
    setModifierOptions({ ...currentOptions });
  };

  const handleModifierOptions = (e, index) => {
    let tempModifiers = { ...modifierOptions };
    if (e.target.name.includes("modifierName"))
      tempModifiers[index].modifierName = e.target.value;
    else tempModifiers[index].modifierPrice = e.target.value;
    // else tempModifiers[index].modifierPrice = parseInt(e.target.value);
    setModifierOptions(tempModifiers);
  };

  const handleSaveModifier = async () => {
    // e.preventDefault();
    dispatch({ type: LOADING_MENU });
    dispatch({ type: LOADING_NOT_COMPLETED });

    let currentOptions = Object.keys(modifierOptions).map((item, index) => {
      return modifierOptions[item];
    });

    let previousModifiers = [];
    try {
      const menuItem = await getMenuItem(selectedMenuId);
      if (menuItem.modifiers) {
        previousModifiers = menuItem.modifiers;
      }
    } catch (error) {
      setAlertMessage(error.message);
    }

    let modifierToSend = {
      type: type,
      options: currentOptions,
      multiSelect: multiSelect,
      isMandatory: isMandatory,
    };

    previousModifiers.push(modifierToSend);

    let data = {
      modifiers: previousModifiers,
    };

    if (modifierToSend && !modifierExists) {
      dispatch(createModifierForRestaurant(modifierToSend, _id));
    }

    try {
      if (!modifierExists) {
        const res = await UpdateMenuItem(
          data,
          makeDecrypt("restaurantId"),
          selectedMenuId,
          dispatch
        );
        handleDialogClose();
        // dispatch(setReload());
        // setThisReload(!thisReload);
      } else {
        dispatch({ type: STOP_LOADING_MENU });
        dispatch({ type: LOADING_COMPLETED });
        setSnackOpen(true);
        setSnackType("error");
        setAlertMessage("Modifier type already exists");
      }
    } catch (error) {
      setAlertMessage(error.message);
    }
  };

  const handleUpdateModifier = async () => {
    let currentOptions = Object.keys(modifierOptions).map((item, index) => {
      return modifierOptions[item];
    });

    let previousModifiers = [];
    try {
      const menuItem = await getMenuItem(selectedMenuId);
      if (menuItem.modifiers) {
        previousModifiers = menuItem.modifiers;
      }
    } catch (error) {
      setAlertMessage(error.message);
    }

    previousModifiers.map((item, index) => {
      if (currrentModifierId === item._id) {
        previousModifiers[index] = {
          ...item,
          type: type,
          options: currentOptions,
          multiSelect: multiSelect,
          isMandatory: isMandatory,
        };
      }
    });

    let data = {
      modifiers: previousModifiers,
    };

    try {
      if (!modifierExists) {
        const res = await UpdateMenuItem(
          data,
          makeDecrypt("restaurantId"),
          selectedMenuId
        );
        handleDialogClose();
        dispatch(setReload());
        setThisReload(!thisReload);
      } else {
        setAlertMessage("This modifier type already exists");
      }
    } catch (error) {
      setAlertMessage(error.message);
    }
  };

  const handleRemoveModifier = async () => {
    let currentOptions = Object.keys(modifierOptions).map((item, index) => {
      return modifierOptions[item];
    });

    let previousModifiers = [];
    try {
      const menuItem = await getMenuItem(selectedMenuId);
      if (menuItem.modifiers) {
        previousModifiers = menuItem.modifiers;
      }
    } catch (error) {
      setAlertMessage(error.message);
    }

    previousModifiers = previousModifiers.filter(
      (item, index) => item._id !== currrentModifierId
    );

    let data = {
      modifiers: previousModifiers,
    };

    try {
      const res = await UpdateMenuItem(data, makeDecrypt("restaurantId"), selectedMenuId);
      handleDialogClose();
      dispatch(setReload());
      setThisReload(!thisReload);
    } catch (error) {
      setAlertMessage(error.message);
    }
  };
  const handleDeleteOptionsField = (index) => {
    let currentOptions = Object.keys(modifierOptions).map((item, thisIndex) => {
      return modifierOptions[item];
    });
    currentOptions = currentOptions.filter((item, thisIndex) => index !== thisIndex);
    setModifierOptions({ ...currentOptions });
  };

  useEffect(() => {
    if (alwaysAvailable)
      setMenuItemDetails((prevValues) => ({
        ...prevValues,
        availability: {
          start: "00:01",
          end: "23:59",
        },
      }));
  }, [alwaysAvailable]);
  useEffect(() => {
    const getCustomerFacingTrueDiscounts = (array) => {
      return array.filter(obj => obj.customerFacing !== true || false);
    };
    const result = getCustomerFacingTrueDiscounts(discounts);
    setCustomerFacingDiscounts(result);

  }, [discounts])

  const addModifierContent = (
    <>
      <DialogTitle id="alert-dialog-title">
        {message === "Add Menu Modifier" || message === "Add Menu Item"
          ? "Add menu modifier"
          : "Edit menu modifier"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <DialogContentText>
            {message !== "Add Menu Item" ? message : ""}
          </DialogContentText>
          <>
            <TextField
              autoFocus
              margin="dense"
              name="type"
              id="type"
              label="Type"
              fullWidth
              value={type}
              onChange={handleModifierChange}
            />
            {Object.keys(modifierOptions).map((item, index) => {
              return (
                <Box key={index} display="flex" alignItems="center">
                  <>
                    <TextField
                      autoFocus
                      margin="dense"
                      name={`modifierName${index}`}
                      label="Name"
                      value={modifierOptions[index].modifierName}
                      onChange={(e) => handleModifierOptions(e, index)}
                      style={{ marginRight: "5px" }}
                    />
                    <TextField
                      autoFocus
                      margin="dense"
                      name={`modifierPrice${index}`}
                      label="Price"
                      value={modifierOptions[index].modifierPrice}
                      // type="number"
                      onChange={(e) => handleModifierOptions(e, index)}
                      style={{ marginLeft: "5px" }}
                    />
                    {/* <CloseIcon onClick={(index) => handleCrossClick(index)} /> */}
                    {modifierOptions.length !== 1 && modifierOptions[1] && (
                      <DeleteIcon onClick={() => handleDeleteOptionsField(index)} />
                    )}
                  </>
                </Box>
              );
            })}
          </>
          <Button onClick={handlePlusClick}>
            <AddIcon />
          </Button>
          <FormControlLabel
            control={
              <Checkbox
                name="multiSelect"
                id="multiselect"
                label="MultiSelect"
                checked={multiSelect}
                onChange={handleModifierChange}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            }
            label="Multiselect"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="isMandatory"
                id="isMandatory"
                label="Mandatory"
                checked={isMandatory}
                onChange={handleModifierChange}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            }
            label="Mandatory"
          />
        </DialogContentText>

        {message !== "Add Menu Item" && message !== "Update Menu Item" && (
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Close
            </Button>
            {message === "Add Menu Modifier" ? (
              <Button onClick={handleSaveModifier} color="primary" autoFocus>
                Save Modifier
              </Button>
            ) : (
              <>
                <Button onClick={handleRemoveModifier} color="primary" autoFocus>
                  Remove Modifier
                </Button>
                <Button onClick={handleUpdateModifier} color="primary" autoFocus>
                  Update Modifier
                </Button>
              </>
            )}
          </DialogActions>
        )}
      </DialogContent>
    </>
  );

  const addOrUpdateItem = (
    <>
      <Box display={"flex"} justifyContent="space-between" sx={{ px: 3, py: 1 }}>
        <Box sx={{ pt: 2 }}>
          <Typography variant="h3">{message}</Typography>
          <Typography style={{ fontSize: "12px", marginTop: "5px" }}>
            {message === "Add Menu Item"
              ? "Add menu item to " + selectedCategory
              : "Edit menu item from " + selectedCategory}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection={"column"}
          alignItems="center"
          sx={{ position: "relative" }}
        >
          <Box
            display={"flex"}
            sx={{
              borderRadius: "50%",
              overflow: "hidden",
              width: "100px",
              height: "100px",
              boxShadow:
                " rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
            }}
          >
            {imageLoading ? (
              <Skeleton variant="circleSkeleton" width={100} height={100} />
            ) : (
              <img src={menuImage} width="100px" height="100px" />
            )}
          </Box>
          <Box
            sx={{
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
              position: "absolute",
              bottom: "0px",
              left: "0px",
              cursor: "pointer",
              backgroundColor: "white",
              borderRadius: "50%",
              width: "35px",
              height: "35px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AddPhotoAlternateIcon
              style={{ fontSize: "30px" }}
              onClick={handleUploadClick}
            />
          </Box>
        </Box>
      </Box>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={1}>
            <input
              type="file"
              className="form-control"
              onChange={(e) => {
                SingleFileChange(e);
                setImageLoading(true);
              }}
              ref={(e) => (upload = e)}
              style={{ display: "none" }}
              accept="image/*"
            />
            <Grid item xs={12}>
              <TextField
                error={!name && alertMessage === "All fields required"}
                autoFocus
                margin="dense"
                name="name"
                id="name"
                label="Name"
                fullWidth
                value={name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={!description && alertMessage === "All fields required"}
                autoFocus
                margin="dense"
                name="description"
                id="description"
                label="Description"
                fullWidthr
                value={description}
                onChange={handleChange}
                rows={3}
                multiline
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={!price && alertMessage === "All fields required"}
                autoFocus
                margin="dense"
                name="price"
                id="price"
                label="Price"
                fullWidth
                value={price}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                name="instagramVideoUrl"
                id="instagramVideoUrl"
                label="Instagram Video URL"
                fullWidth
                value={instagramVideoUrl}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                name="tiktokVideoUrl"
                id="tiktokVideoUrl"
                label="TikTok Video URL"
                fullWidth
                value={tiktokVideoUrl}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                name="facebookVideoUrl"
                id="facebookVideoUrl"
                label="Facebook Video URL"
                fullWidth
                value={facebookVideoUrl}
                onChange={handleChange}
              />
            </Grid>
            {/* <Box className={classes.tagsCont}>
              <Box
                container
                justifyContent="center"
                alignItems="center"
                className={classes.selectTagCont}
              >
                <FormControl
                  className={classes.formControl}
                  variant="outlined"
                  style={{minWidth: "150px"}}
                >
                  <InputLabel id="demo-mutiple-checkbox-label">Select Tags</InputLabel>
                  <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    value={selectedTags}
                    onChange={handleChangeTag}
                    input={<Input />}
                    variant="outlined"
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    {restaurantTags.map((tag) => (
                      <MenuItem key={tag} value={tag}>
                        <Checkbox checked={selectedTags.indexOf(tag) > -1} />
                        <ListItemText primary={tag} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                
              </Box>
              <Divider sx={{mb: 1}} />
              <Typography variant="h5">Selected Tags</Typography>
              <Box
                container
                className={classes.selectedTagCont}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  overflow: "auto",
                  minHeight: "50px",
                  liststyleTypes: "none",
                  padding: "10px",
                }}
              >
                <Autocomplete
                  multiple
                  limitTags={2}
                  id="multiple-limit-tags"
                  options={restaurantTags}
                  getOptionLabel={(option) => option}
                  onChange={(event, value) => handleChangeTag(value)}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="limitTags"
                      placeholder="Favorites"
                    />
                  )}
                />
              </Box>
            </Box> */}
            <Grid item xs={12}>
              <Box className={classes.tagsCont}>
                <Autocomplete
                  multiple
                  limitTags={2}
                  id="multiple-limit-tags"
                  options={availbleTags}
                  getOptionLabel={(option) => option}
                  value={selectedTags}
                  onChange={(event, value) => handleChangeTag(value)}
                  style={{ width: "60%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select Tags"
                    // placeholder="Favorites"
                    />
                  )}
                />
                <Box
                  style={{
                    display: "flex",
                    width: "37%",
                    maxHeight: "56px",
                  }}
                  className={classes.addTagCont}
                >
                  <TextField
                    variant="outlined"
                    value={newTag}
                    label="Create New Tag"
                    onChange={(e) => setNewTag(e.target.value)}
                    style={{ width: "150px" }}
                  />
                  <Button variant="outlined" onClick={addNewTag} style={{ height: "56px" }}>
                    ADD
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={pstApplicable}
                    onChange={handleChangePstApplcable}
                    name="pstApplicable"
                  />
                }
                label="PST Applicable"
              />

              {pstApplicable && (
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Typography style={{ marginRight: "10px" }}>
                    Select PST Percentage
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isPstType1}
                        onChange={(event) =>
                          handleChangePstPercentage(pstType1, isPstType1)
                        }
                        name={pstType1}
                      />
                    }
                    label={pstType1 + "%"}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!isPstType1}
                        onChange={(event) =>
                          handleChangePstPercentage(pstType2, isPstType1)
                        }
                        name={pstType2}
                      />
                    }
                    label={pstType2 + "%"}
                  />
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={limitedQuantity}
                    onChange={(event) =>
                      setMenuItemDetails((prevValues) => ({
                        ...prevValues,
                        limitedQuantity: event.target.checked,
                      }))
                    }
                    name="limitedQuantity"
                  />
                }
                label="Limited Quantity Applicable"
              />
              {limitedQuantity && (
                <TextField
                  autoFocus
                  margin="dense"
                  name="quantity"
                  id="quantity"
                  label="Quantity"
                  value={quantity}
                  type="quantity"
                  disabled={!limitedQuantity}
                  fullWidth
                  onChange={handleChange}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {/* {message === "Update Menu Item" && modifiers ? (
                <Box
                sx={{
                  display: "flex",
                  // flexDirection: "column",
                  maxWidth: "150px",
                  overflow: "auto",
                  mb: 2,
                  py: 1,
                }}
                >
                  {menuItemDetails.modifiers.id &&
                  menuItemDetails.modifiers.modifiers.length
                    ? menuItemDetails.modifiers.modifiers.map((item) => {
                        return (
                          <Chip
                            // key={index._id}
                            label={item?.type}
                            onDelete={() =>
                              handleMenuModifierUpdate(
                                menuItemDetails.modifiers.id,
                                item.type,
                                item._id
                              )
                            }
                            variant="outlined"
                            deleteIcon={<EditIcon fontSize="small" />}
                            color="primary"
                            style={{margin: "3px", width: "max-content"}}
                          />
                        );
                      })
                    : ""}
                </Box>
              ) : (
                ""
              )} */}
              {(message === "Add Menu Item" || message === "Update Menu Item") && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={addModifierFlag}
                      onChange={() => {
                        setAddModifierFlag(!addModifierFlag);
                        if (copyModifierFlag) setCopyModifierFlag(!copyModifierFlag);
                      }}
                      name="addModifierFlag"
                    />
                  }
                  label="Add new modifier"
                />
              )}
              {(message === "Add Menu Item" || message === "Update Menu Item") && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={copyModifierFlag}
                      onChange={() => {
                        setCopyModifierFlag(!copyModifierFlag);
                        if (addModifierFlag) setAddModifierFlag(!addModifierFlag);
                      }}
                      name="copyModifierFlag"
                    />
                  }
                  label="Copy modifiers from other menu item"
                />
              )}
            </Grid>

            {copyModifierFlag && modifiersNameList.length ? (
              <Autocomplete
                multiple
                limitTags={2}
                id="modifiers"
                options={modifiersNameList}
                getOptionLabel={(option) => option}
                value={selectedModifiers}
                onChange={(event, value) => handleChangeModifiers(value)}
                style={{ width: "60%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Select Modifiers"
                  // placeholder="Favorites"
                  />
                )}
              />
            ) : (
              ""
            )}

            <Grid item xs={12}>
              {(message === "Add Menu Item" || message === "Update Menu Item") &&
                addModifierFlag
                ? addModifierContent
                : ""}
              <Typography className={classes.selectTimeMsg}>Availability</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={alwaysAvailable}
                    onChange={(e) => {
                      setAlwaysAvailable(e.target.checked);
                    }}
                    color="primary"
                    name="checkedB"
                  />
                }
                label={"Always available"}
              />
              {!alwaysAvailable ? (
                <Box>
                  <TextField
                    id="start"
                    name="start"
                    label="START TIME"
                    type="time"
                    defaultValue={availability?.start}
                    size="small"
                    onChange={(e) => {
                      setMenuItemDetails((prevValues) => ({
                        ...prevValues,
                        availability: {
                          start: e.target.value,
                          end: prevValues.availability.end,
                        },
                      }));
                    }}
                    className={classes.timePicker}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                  />

                  <TextField
                    id="end"
                    name="end"
                    label="END TIME"
                    type="time"
                    defaultValue={availability?.end}
                    size="small"
                    onChange={(e) => {
                      setMenuItemDetails((prevValues) => ({
                        ...prevValues,
                        availability: {
                          end: e.target.value,
                          start: prevValues.availability.start,
                        },
                      }));
                    }}
                    className={classes.timePicker}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                  />
                </Box>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ mt: 2, width: "100%" }}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Discount
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={discount}
                    name="discount"
                    onChange={handleChange}
                    label="Select Discount"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {customerFacingDiscounts?.map((discount) => (
                      <MenuItem value={discount._id} key={discount._id}>
                        {discount.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} variant="outlined" color="primary">
          Close
        </Button>
        {message === "Add Menu Item" ? (
          <Button
            onClick={handleCreateMenu}
            color="primary"
            variant="contained"
            autoFocus
          >
            Add Item
          </Button>
        ) : (
          <Button
            onClick={handleUpdateItem}
            color="primary"
            variant="contained"
            autoFocus
          >
            Update Item
          </Button>
        )}
      </DialogActions>
    </>
  );

  const mangeMenuItemTags = (
    <Tags
      message={message}
      open={open}
      setOpen={setOpen}
      handleClose={handleClose}
      setThisReload={setThisReload}
      thisReload={thisReload}
    />
  );

  const handleOpenFooterOption = (type) => {
    setJustOpen({ ...setJustOpen, limitOpen: true });
  };

  const handleCloseFooterOption = (type) => {
    setJustOpen({ ...setJustOpen, limitOpen: false });
  };

  const changeRowsPerPage = (currnetRows) => {
    setValues({ ...values, limit: currnetRows });
  };

  const handleRowClick = (row) => {
    makeEncrypt("flag", true);

    const toggleStatus = row.status == "Online" ? "Offline" : "Online";
    handleSelectMenuItem(row.id, toggleStatus, row);
  };

  const [tempQuery, setTempQuery] = useState("");

  const handleQuerySearch = (e) => {
    e.preventDefault();

    setValues({ ...values, searchQuery: tempQuery });
    // setTempQuery("");
  };
  const [closeConfirmBlock, setCloseConfirmBlock] = useState(false);

  const handleDeleteMenuItem = (id) => {
    dispatch({ type: LOADING_MENU });
    dispatch({ type: LOADING_NOT_COMPLETED });

    deleteMenuItem(id, dispatch)
      .then((data) => {
        setCloseConfirmBlock(!closeConfirmBlock);
        setAlertMessage("Menu item deleted successfully");
        setSnackOpen(true);
        setSnackType("success");
      })
      .catch((error) => setAlertMessage(error.message));
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* {addOrUpdateContent} */}
        {message === "Add Menu Item" || message === "Update Menu Item"
          ? addOrUpdateItem
          : message === "Menu item tags"
            ? mangeMenuItemTags
            : addModifierContent}
      </Dialog>
      <Card {...rest}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: 2,
            py: 3,
          }}
        >
          <Grid container spacing={1}>
            <Grid item sm={3} lg={2}>
              <Typography variant="h3" color="textPrimary">
                {selectedMainMenu ? "Menu Items" : "Special dishes"}
              </Typography>
            </Grid>
            {selectedMainMenu && (
              <Grid item sm={9} lg={10}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} lg={6}>
                    <form onSubmit={handleQuerySearch}>
                      <FormControl fullWidth>
                        <TextField
                          name="searchQuery"
                          // label="Enter the name"
                          onChange={(e) => setTempQuery(e.target.value)}
                          value={tempQuery}
                          style={{ backgroundColor: "" }}
                          size="small"
                          variant="outlined"
                          InputProps={{
                            endAdornment: !tempQuery ? (
                              <InputAdornment position="start">
                                <SearchIcon style={{ cursor: "pointer" }} />
                              </InputAdornment>
                            ) : (
                              <InputAdornment position="start">
                                <CloseIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setTempQuery("");
                                    setValues({ ...values, searchQuery: "" });
                                  }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        ></TextField>
                      </FormControl>
                    </form>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid container justifyContent="right" spacing={1}>
                      {role === "ROLE_ADMIN" &&
                        selectedCategory !== "none" &&
                        selectedCategory !== "" && (
                          <>
                            <Grid item>
                              <Button
                                color="primary"
                                variant="outlined"
                                startIcon={<AddCircleOutlineIcon />}
                                onClick={() => handleClickOpen("Menu item tags")}
                              >
                                Menu Item Tags
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                color="primary"
                                variant="outlined"
                                startIcon={<AddCircleOutlineIcon />}
                                onClick={() => handleClickOpen("Add Menu Item")}
                                disabled={loadingMenu}
                              >
                                Add Menu Item
                              </Button>
                            </Grid>
                          </>
                        )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
        <Divider />
        {loadingMenu && <LinearProgress />}
        <PerfectScrollbar>
          {rowsss.length && rowsss?.[0].name ? (
            <>
              <TableContainer component={Paper} style={{ padding: "12px" }}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    {/* { image, name, description, price, status ,modifiers , id , restaurant}; */}
                    <TableRow>
                      <TableCell align="left">
                        <Typography variant="h4" color="textPrimary">
                          Name
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="h4" color="textPrimary">
                          Price
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="h4" color="textPrimary">
                          Online
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="h4" color="textPrimary">
                          Modifiers
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="h4" color="textPrimary">
                          Options
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsss.length ? (
                      rowsss.map((row, index) => {
                        return (
                          <TableRow key={index} onClick={() => handleRowClick(row)}>
                            <TableCell align="left">
                              <Typography variant="h5" fontSize="17px">
                                <ShowMoreText
                                  /* Default options */
                                  lines={1}
                                  more="Show more"
                                  less="Show less"
                                  className="content-css"
                                  anchorClass="my-anchor-css-class"
                                  expanded={false}
                                  width={200}
                                  truncatedEndingComponent={"... "}
                                >
                                  {row.name}
                                </ShowMoreText>
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography>
                                {" "}
                                {currencySign} {row.price}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Switch
                                checked={row.status == "Online" ? true : false}
                                onChange={() => toggleMenuStatus(row.status, row.id)}
                                name={row.status}
                                color="primary"
                              />
                            </TableCell>
                            <TableCell align="left">
                              <Box
                                sx={{
                                  display: "flex",
                                  // flexDirection: "column",
                                  maxWidth: "150px",
                                  overflow: "auto",
                                  mb: 2,
                                  py: 1,
                                }}
                              >
                                {row.modifiers.modifiers.length
                                  ? row.modifiers.modifiers.map((item) => {
                                    return (
                                      <Chip
                                        key={index._id}
                                        label={item?.type}
                                        onDelete={() =>
                                          handleMenuModifierUpdate(
                                            row.modifiers.id,
                                            item.type,
                                            item._id
                                          )
                                        }
                                        variant="outlined"
                                        deleteIcon={<EditIcon fontSize="small" />}
                                        color="primary"
                                        style={{ margin: "3px", width: "max-content" }}
                                      />
                                    );
                                  })
                                  : ""}
                              </Box>
                              <Button
                                color="primary"
                                onClick={() => handleModifierActions("Add Menu Modifier")}
                                variant="outlined"
                                startIcon={<AddCircleOutlineIcon />}
                                size="small"
                                style={{ widht: "fit-content", margin: "auto" }}
                              >
                                Modifier
                              </Button>
                            </TableCell>
                            <TableCell align="right">
                              <div className={classes.editItem}>
                                <MenuActions
                                  updateItem={updateItem}
                                  actionsOpen={actionsOpen}
                                  setActionsOpen={setActionsOpen}
                                  setPstApplicable={setPstApplicable}
                                  toggleMenuStatus={toggleMenuStatus}
                                  row={row}
                                  closeConfirmBlock={closeConfirmBlock}
                                  setCloseConfirmBlock={setCloseConfirmBlock}
                                  handleDeleteMenuItem={handleDeleteMenuItem}
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <Box>
                        {loadingMenu && !loadingCompleted ? (
                          <CircularProgress />
                        ) : (
                          <Typography>
                            There are no menu items in this category
                          </Typography>
                        )}
                      </Box>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell>
                        <FormControl
                          className={classes.formControl}
                          fullWidth
                          variant="outlined"
                          style={{ maxWidth: "200px" }}
                        >
                          <InputLabel id="demo-controlled-open-select-label-limit">
                            Rows Per Page
                          </InputLabel>
                          <Select
                            labelId="demo-controlled-open-select-label-limit"
                            id="demo-controlled-open-select-limit"
                            label="Rows Per Page"
                            open={limitOpen}
                            onClose={() => handleCloseFooterOption("limit")}
                            onOpen={() => handleOpenFooterOption("limit")}
                            value={rows}
                            onChange={(e) => {
                              changeRowsPerPage(e.target.value);
                            }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </>
          ) : (
            <Box className={classes.loaderBox}>
              {loadingMenu && !loadingCompleted ? (
                <CircularProgress />
              ) : (
                <Typography>There are no menu items in this category</Typography>
              )}
            </Box>
          )}
        </PerfectScrollbar>
      </Card>
      <Snackbar
        open={snackOpen}
        autoHideDuration={3000}
        onClose={() => setSnackOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        key={"bottom" + "center"}
      >
        <Alert onClose={() => setSnackOpen(false)} severity={snackType} variant="filled">
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default MenuListResults;