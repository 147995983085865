import React, {useEffect, useState, useRef} from "react";

import {useTheme} from "@mui/material/styles";
import {useSelector} from "react-redux";
import {makeStyles} from "tss-react/mui";
import {Helmet} from "react-helmet";
import axiosInstance from "src/axios";
import {makeDecrypt} from "src/utils/securels";
import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Typography,
  Link,
} from "@mui/material";
import {DataGrid, GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid";
import {getOrdersData} from "src/components/accounting";
import OrderTable from "src/components/accounting/OrderTable";
import axios from "../axios";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {DollarSign} from "react-feather";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router";
import {FileDownload} from "@mui/icons-material";
import { ExportToCsv } from "export-to-csv-fix-source-map";
import { AccountingCsvOptions } from "src/components/accounting/helpers/data";


const useStyles = makeStyles()((theme) => {
  return {
    cardWrap: {
      // width: "70%",
      // display: "flex",
      // justifyContent: "space-between",
    },
    priceCard: {
      padding: "0.7rem",
      // width: "300px",
      // height: "80px",
      // marginRight: "20px",
      // alignItems: "center",
      // overflow: "auto",
    },
    gridWrap: {
      marginRight: "20px",
    },
    dataGrid: {
      display: "flex",
      justifyContent: "flex-end",
      marginLeft: "auto",
      border: "1px solid gray",
    },
    breadCrumbs: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "2px 8px ",
      borderRadius: "20px",
      backgroundColor: "#ffffff",
      border: "1px solid #FF5757",
      fontSize: "15px",
      fontWeight: "500",
      "& .MuiBreadcrumbs-separator svg": {
        fontSize: "20px !important",
      },
    },
  };
});

const Accounting = () => {
  const {classes} = useStyles();
  const navigate = useNavigate();
  //   const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date().toISOString());
  const [endDate, setEndDate] = useState(new Date("2021-12-10").toISOString());
  const [orders, setOrders] = useState([]);
  const [totalTax, setTotalTax] = useState(0);
  const [totalPst, setTotalPst] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);
  const [appFee, setAppFee] = useState(0);
  const [errMsg, setErrMsg] = useState("");
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [values, setValues] = useState({
    days: 10,
    page: 1,
    limit: 5,
    count: 1,
  });
  const serviceFee = useSelector(
    (state) => state.restaurant?.dinify_payment_plan?.application_service_fee
  );
  const {currencySign} = useSelector((state) => state.restaurant);
  const restId = makeDecrypt("restaurantId");
  const [progress, setProgress] = useState("");
  const [exportData, setExportData] = useState([]);
  const csvExporter = new ExportToCsv(AccountingCsvOptions);

  //   useEffect(() => {
  //     axiosInstance
  //       .post(`/order/get-order-details/${makeDecrypt("restaurantId")}`, {
  //         startDate: startDate,
  //         endDate: endDate,
  //       })
  //       .then((res) => {
  //         setOrders(res.data);
  //       })
  //       .catch((err) => {
  //       });
  //   }, []);

  const {days, page, limit, count} = values;

  useEffect(() => {
    const getOrders = async () => {
      try {
        const response = await getOrdersData(days, page, limit, setProgress)
        setOrders(response.result);
          const temp = response.result.map((order) => {
          return {id: order._id, totalPrice: order.totalPrice, gst: order.gstValue.toFixed(2), pst: order.pstValue, fee: order.appliedFee};
          });
        setExportData(temp)
      } catch (error) {
        console.log(error.message)
      }

      // axios
      //   .get(`/order/${restId}/${days}/completed-orders?page=${page}&limit=${limit}`, {
      //     headers: {Authorization: makeDecrypt("jwt")},
      //     onDownloadProgress: (d) => {
      //       setProgress(Math.round((d.loaded / d.total) * 100));
      //     },
      //   })

      // response.error && setErrMsg(response.error);
      // setValues({...values, count: response.maxPages});
    };
    getOrders();
  }, [page, days, limit]);

  const handleChange = (val) => {
    setValues({...values, page: val});
  };

  const [open, setOpen] = useState({
    daysOpen: false,
    limitOpen: false,
  });
  const {daysOpen, limitOpen} = open;

  const handleClose = (type) => {
    if (type == "days") setOpen({...open, daysOpen: false});
    else setOpen({...open, limitOpen: false});
  };

  const handleOpen = (type) => {
    if (type == "days") setOpen({...open, daysOpen: true});
    else setOpen({...open, limitOpen: true});
  };

  useEffect(() => {
    // setAppFee(0);

    setTotalPst(orders?.reduce((n, {pstValue}) => n + pstValue, 0));

    setTotalTax(orders?.reduce((n, {tax}) => n + Number(tax), 0));

    setTotalIncome(orders?.reduce((n, {totalPrice}) => n + totalPrice, 0));
    // orders.map((order) => {
    //   order.pushToCustomer && setAppFee(appFee + 1);
    // });

    setAppFee(
      orders.reduce(function (n, order) {
        return (
          n +
          (order.pushToCustomer == true
            ? 0
            : order.halfToCustomer == true
            ? serviceFee / 2
            : serviceFee)
        );
      }, 0)
    );
  }, [orders]);

  useEffect(() => {
    setTotalIncome((prevVal) => prevVal - appFee);
  }, [appFee]);

  // function MyExportButton() {
  //   return (
  //     <GridToolbarContainer>
  //       <GridToolbarExport />
  //     </GridToolbarContainer>
  //   );
  // }

  // useEffect(() => {
  //   setRows([]);

  //   setColumns([
  //     {field: "id", headerName: "ORDER ID", width: 230},
  //     {field: "totalPrice", headerName: "TOTAL PRICE", width: 130},
  //     {field: "gst", headerName: "GST", width: 100},
  //     {field: "pst", headerName: "PST", width: 100},
  //     {field: "appFee", headerName: "APP FEE", width: 100},
  //   ]);

  //   orders.length &&
  //     orders.map((order, index) => {
  //       setRows((preValue) => [
  //         ...preValue,
  //         {
  //           id: order._id,
  //           totalPrice: `${currencySign}  ${order.totalPrice}`,
  //           gst: `${currencySign}  ${(order.tax - order.pstValue).toFixed(2)}`,
  //           pst: `${currencySign}  ${order.pstValue}`,
  //           appFee: `${currencySign}  ${order.pushToCustomer ? serviceFee : 0}`,
  //         },
  //       ]);
  //     });
  // }, [orders]);

  const handleExportData = () => {
    csvExporter.generateCsv(exportData);
  };

  return (
    <>
      <Helmet>
        <title>Accounting | Dinify</title>
      </Helmet>

      <Box sx={{p: 3, height: "-webkit-fill-available"}}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          style={{marginBottom: "30px"}}
        >
          <Link
            color="primary"
            onClick={() => navigate("/app/dashboard")}
            style={{cursor: "pointer"}}
          >
            <Box className={classes.breadCrumbs}>
              <HomeIcon color="primary" style={{fontSize: "15px", marginRight: "2px"}} />
              Home
            </Box>
          </Link>
          <Link
            color="primary"
            // href="/app/orders"
            style={{cursor: "pointer"}}
          >
            <Box className={classes.breadCrumbs}>
              <DollarSign size={15} style={{fontSize: "15px", marginRight: "2px"}} />
              Accounting
            </Box>
          </Link>
        </Breadcrumbs>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h3"> Account Statement</Typography>
          <Box sx={{ display: "flex" }}>
            {/* <Box className={classes.gridWrap}>
              {orders.length > 0 && (
                <DataGrid
                  rows={rows}
                  columns={columns}
                  components={{
                    Toolbar: MyExportButton,
                  }}
                  hideFooterPagination={true}
                  hideFooter={true}
                  className={classes.dataGrid}
                />
              )}
            </Box> */}

            <Button
                color="primary"
                onClick={handleExportData}
                startIcon={<FileDownload />}
                variant="contained"
              size="small"
              sx={{mr: 3}}
              >
                Export
              </Button>

            <FormControl size="small" style={{width: "130px"}}>
              <InputLabel id="demo-controlled-open-select-label-days">
                Select Range
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label-days"
                id="demo-controlled-open-select-days"
                open={daysOpen}
                onClose={() => handleClose("days")}
                onOpen={() => handleOpen("days")}
                value={days}
                onChange={(e) => {
                  setValues({...values, days: e.target.value});
                }}
                className={classes.select}
                label="Select Range"
              >
                <MenuItem value={10}>10 Days</MenuItem>
                <MenuItem value={30}>30 Days</MenuItem>
                <MenuItem value={120}>4 months</MenuItem>
                <MenuItem value={240}>8 months</MenuItem>
                <MenuItem value={365}>Year</MenuItem>
                <MenuItem value={1000}>All</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box sx={{pl: 1}}>
          <Box
            sx={{
              py: 2,
            }}
          >
            <Box
              sx={{display: "flex", flexDirection: "row"}}
              className={classes.cardWrap}
            >
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Box className={classes.priceCard} boxShadow={5}>
                    <Typography variant="h4">
                      {currencySign} {(totalTax - totalPst).toFixed(2)}
                    </Typography>
                    <Typography>Total GST Collected</Typography>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box className={classes.priceCard} boxShadow={5}>
                    <Typography variant="h4">
                      {currencySign} {totalPst.toFixed(2)}
                    </Typography>
                    <Typography>Total PST Collected</Typography>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box className={classes.priceCard} boxShadow={5}>
                    <Typography variant="h4">
                      {currencySign} {appFee}
                    </Typography>
                    <Typography>Total App Fees</Typography>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box
                    className={classes.priceCard}
                    boxShadow={5}
                    // style={{backgroundColor: "#FF5757", color: "white"}}
                  >
                    <Typography variant="h4">
                      {currencySign} {totalIncome.toFixed(2)}
                    </Typography>
                    <Typography>Total Income</Typography>
                  </Box>
                </Grid>
                <Box></Box>
              </Grid>
            </Box>
          </Box>

          <Typography style={{marginBottom: "10px"}}>
            Showing statement for {days == 1000 ? `all orders` : `past ${days} days`}.
          </Typography>

          <OrderTable orders={orders} page={page} limit={limit} progress={progress} />
        </Box>
      </Box>
    </>
  );
};

export default Accounting;
