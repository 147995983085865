import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";

function MultiOrderSelection({
  showOrders,
  setActiveOrder,
  activeOrder,
  liveOrders,
  tableNo,
  tableOrders,
  setShowOrders,
  setShowModal,
  handleCombineBill,
}) {
  return (
    <Dialog fullWidth maxWidth={"xs"} open={showOrders}>
      <DialogTitle>
        <Typography variant="h4">Select Order</Typography>
      </DialogTitle>
      <DialogContent>
        <FormControl
          //   className={classes.formControl}
          label="Orders"
          sx={{minWidth: "100px", maxWidth: "300px", m: 1}}
          size={window.innerWidth < 800 ? "small" : "medium"}
          fullWidth
        >
          <InputLabel htmlFor="age-native-simple">Orders</InputLabel>
          <Select
            value={activeOrder}
            onChange={(e) => setActiveOrder(e.target.value)}
            inputProps={{
              name: "age",
              id: "age-native-simple",
            }}
            label="Orders"
            // size={window.innerWidth < 800 ? "small" : "medium"}
            size="small"
          >
            <MenuItem>
              <Box>None</Box>
            </MenuItem>
            {liveOrders.map((order, index) => {
              if (order.tableNumber == tableNo) {
                return (
                  <MenuItem value={order} key={order.orderName}>
                    <Box>{order.orderName}</Box>
                  </MenuItem>
                );
              }
            })}
          </Select>
        </FormControl>
        <Box m={1}>
          <Typography variant="h4" mt={1}>
            {`Number of orders = ${tableOrders.length}`}
          </Typography>
          {tableOrders.map((tableOrder, index) => {
            return (
              <Typography variant="h6" key={tableOrder.orderName}>
                {`Order: ${tableOrder.orderName} Total: ${tableOrder.grandTotal}`}
              </Typography>
            );
          })}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            setShowOrders(false);
          }}
          size="small"
        >
          Cancel
        </Button>
        {tableOrders.every((currentOrder) =>
          currentOrder.items.every((item) => item.isAccepted === true)
        ) && (
          <Button variant="contained" onClick={handleCombineBill} size="small">
            Combine Bill
          </Button>
        )}
        <Button
          variant="contained"
          onClick={() => {
            setShowModal(true);
            // setShowOrders(false);
          }}
          size="small"
          disabled={!activeOrder}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MultiOrderSelection;
