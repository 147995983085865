import { Box, Divider, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import EachOrder from "./EachOrder";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => {
  return {
    ordersBlock: {
      // margin: `${theme.spacing(2)} 0`,
      backgroundColor: "#f8f9fa",
      marginBottom: "20px",
      padding: "10px",
    },
  };
});


function WaitingOrders(props) {
  const { classes } = useStyles();
  const { children, value, index, order, tempSocket, ...other } = props;
  const [waitingOrders, setWaitingOrders] = useState([]);

  useEffect(() => {
    if (order) {
      setWaitingOrders(order.waitingOrders);
    }
  }, [order]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index &&
        (order ? (
          <Box>
            <Box className={classes.ordersBlock} boxShadow={3}>
              <Typography variant="h4">Waiting Orders</Typography>
              <Divider style={{ margin: "10px 0" }} />
              <Box sx={{ px: 2 }}>
                {waitingOrders && !waitingOrders.length ? (
                  <Typography variant="h5" color="textSecondary" textAlign="center">
                    There are no waiting orders
                  </Typography>
                ) : (
                  order.waitingOrders.map((order) => {
                    return (
                      <EachOrder
                        order={order}
                        needAction={true}
                        key={order._id}
                        tempSocket={tempSocket}
                        hideAcceptBtn={true}
                        multipleOrders={[]}
                        areMultipleOrders={false}
                      />
                    );
                  })
                )}
              </Box>
            </Box>
          </Box>
        ) : (
          <Box>{children}</Box>
        ))}
    </div>
  );
}

WaitingOrders.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default WaitingOrders;
