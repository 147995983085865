import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Checkbox,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {makeStyles} from "tss-react/mui";
import React, {useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {
  deleteKdsItem,
  updateKdsTable,
  updateKdsItemMenuItems,
} from "../../redux/actions/kdsItemsActions";
import DeleteIcon from "@mui/icons-material/Delete";
import {useEffect} from "react";
import {io} from "socket.io-client";
import Timer from "./Timer";
const useStyles = makeStyles()(() => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      minWidth: 265,
      maxWidth: 300,
    },
    list: {
      padding: "0px",
    },
    listItem: {
      textAlign: "left",
    },
    checkbox: {
      margin: "10px 0",
      height: "20px",
      width: "30px",
    },
  };
});

export default function ItemsCard({
  table,
  setItemsTables,
  reload,
  setReload,
  isWaiting,
  tempSocket,
}) {
  const {classes} = useStyles();
  // const [tempSocket, setTempSocket] = useState(null);
  const dispatch = useDispatch();
  const socket = useRef();
  const [menuItemIds, setMenuItemIds] = useState([]);
  const [intervalId, setIntervalId] = useState("");
  const [itemsTable, setItemsTable] = useState(null);

  const [alreadyServedItemsLength, setAlreadyServedItemsLength] = useState(0);
  useEffect(() => {
    if (table) {
      setItemsTable(table);
      let servedItems = table.items.filter((item) => item.readyToServe);
      setAlreadyServedItemsLength(servedItems.length);
    }
  }, [table]);

  useEffect(() => {}, [menuItemIds]);
  const handleRemoveItem = () => {
    // setItemsTables([]);
    dispatch(
      deleteKdsItem(
        table.restaurant,
        table._id,
        setItemsTables,
        intervalId,
        reload,
        setReload
      )
    );
  };

  const handleReadyToServe = () => {
    tempSocket.current.emit("ready-to-serve", {
      tableNo: table.tableNo,
      restaurantId: table.restaurant,
    });

    table.items.forEach((item) => {
      if (!item.readyToServe) item.readyToServe = true;
    });

    dispatch(
      updateKdsTable(
        table.restaurant,
        table._id,
        {
          status: "served",
          tableItems: table.items,
        },
        intervalId,
        handleRemoveItem
      )
    );
  };

  const handleItemsMarkAsReadyToSerce = () => {
    table.items.forEach((item) => {
      if (menuItemIds.some((value) => value === item._id)) item.readyToServe = true;
    });
    tempSocket.current.emit("ready-to-serve", {
      tableNo: table.tableNo,
      restaurantId: table.restaurant,
    });
    dispatch(
      updateKdsItemMenuItems(
        table.restaurant,
        table._id,
        {
          menuItemIds: menuItemIds,
          tableItems: table.items,
        },
        setMenuItemIds
      )
    );
  };

  const handleChange = (e) => {
    if (e.target.checked) {
      setMenuItemIds(
        menuItemIds.find((item) => item === e.target.value)
          ? menuItemIds
          : [...menuItemIds, e.target.value]
      );
    } else {
      setMenuItemIds(menuItemIds.filter((item) => item !== e.target.value));
    }
  };

  return (
    <Box>
      {itemsTable ? (
        <Card className={classes.root}>
          {/* <Typography varinat="h3">{table.createdAt}</Typography> */}
          <CardHeader
            title={`Table: ${isWaiting ? table.tableNumber : itemsTable.tableNo}`}
            subheader={
              <Typography sx={{color: "white"}}>
                {`Order Name : ${itemsTable.orderName}`}
              </Typography>
            }
            action={
              !isWaiting && (
                <Box display="flex" flexDirection="column">
                  {/* <IconButton aria-label="settings" onClick={handleRemoveItem}>
                  <DeleteIcon style={{color: "white"}} />
                </IconButton> */}
                  <Timer
                    timeOfCreation={itemsTable.createdAt}
                    setIntervalId={setIntervalId}
                    status={itemsTable.status}
                    table={itemsTable}
                  />
                </Box>
              )
            }
            style={{
              backgroundColor: itemsTable.status === "notServed" ? "#389B3A" : "#F24E40",
              color: "white",
            }}
          />

          <Divider />
          <CardContent style={{padding: "10px 0px"}}>
            {/* <List dense={true} className={classes.list}> */}
            {itemsTable.items.map((item) => (
              <Box display="flex" key={item._id}>
                {!isWaiting &&
                  (item.readyToServe ? (
                    <input
                      className={classes.checkbox}
                      type="checkbox"
                      value={item._id}
                      checked={true}
                    />
                  ) : (
                    <input
                      className={classes.checkbox}
                      type="checkbox"
                      value={item._id}
                      onChange={(e) => handleChange(e)}
                    />
                  ))}
                <Accordion style={{flex: "1"}}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{display: "flex"}}
                  >
                    <Box>
                      <Typography className={classes.heading} fontWeight="bold">
                        {item.quantity +
                          " x " +
                          (!isWaiting ? item.itemName : item.name) +
                          "\n"}
                      </Typography>
                      <Typography variant="subtitle2" fontWeight="300">
                        {" "}
                        {item.specialInstructions
                          ? "Note: " + item.specialInstructions
                          : ""}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <>
                      <Typography className={classes.heading}>
                        {item.modifiers.length ? "Modifiers: " : ""}
                      </Typography>
                      <List dense={true} className={classes.list}>
                        {item.modifiers.map((thisItem) => (
                          <ListItem
                            className={classes.listItem}
                            key={thisItem.selectedTypeId}
                          >
                            <ListItemText
                              primary={"Name: " + thisItem.selectedTypeId}
                              secondary={thisItem.selectedOptions.map(
                                (option, thisIndex) =>
                                  ` ${option.modifierName} ${
                                    thisItem.selectedOptions[thisIndex + 1] ? ", " : ""
                                  } `
                              )}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </>
                  </AccordionDetails>
                </Accordion>
              </Box>
            ))}
          </CardContent>
          {!isWaiting && (
            <CardActions style={{marginTop: "auto"}}>
              {menuItemIds.length &&
              alreadyServedItemsLength + menuItemIds.length !== table.items.length ? (
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleItemsMarkAsReadyToSerce}
                  disabled={itemsTable.status === "served"}
                >
                  Mark {menuItemIds.length} item as Ready To Serve
                </Button>
              ) : (
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleReadyToServe}
                  disabled={itemsTable.status === "served"}
                >
                  Mark All items as Ready To Serve
                </Button>
              )}
            </CardActions>
          )}
        </Card>
      ) : (
        ""
      )}
    </Box>
  );
}
