import {
  LOADING_RESTAURANT,
  SET_RESTAURANT,
  UPDATE_RESTAURANT,
  SET_RESTAURANT_VERIFIED,
  ADD_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  DELETE_TABLE,
  SET_THROTTLING,
  SET_TABLE,
  UPDATE_TABLE,
} from "../types";

const initialState = {
  _id: "",
  restaurantAddress: {},
  isVerified: false,
  paymentModes: [],
  taxTypes: {},
  restaurantLicenseNumber: "",
  establishmentDate: "",
  cuisine: [],
  alcoholServed: true,
  logoUrl: "",
  onBoardingDate: "",
  restaurantEmail: "",
  menuCategories: [
    {
      name: "",
      status: "",
    },
  ],
  restaurantName: "",
  tags: [],
  admin: "",
  loadingRestaurant: true,
  restaurantBranches: [],
  notifications: [],
  currencySign: "",
  orderThrottling: {
    active: "",
    limit: "",
  },
  tables: [],
};

export default function restaurantReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_RESTAURANT:
      return {
        ...state,
        loadingRestaurant: true,
      };
    case SET_RESTAURANT:
      return {
        ...state,
        ...action.payload,
        loadingRestaurant: false,
      };
    case UPDATE_RESTAURANT:
      return {
        ...state,
        ...action.payload,
        // restaurant: { ...action.payload },
        loadingRestaurant: false,
      };
    case ADD_CATEGORY:
      return {
        ...state,
        menuCategories: [...state.menuCategories, ...action.payload],
        loadingRestaurant: false,
        // restaurant: { ...action.payload },
        // loading: false
      };
    case UPDATE_CATEGORY:
      return {
        ...state,
        menuCategories: [
          state.menuCategories.map((category) =>
            category === action.payload ? {...action.payload} : category
          ),
        ],
        loadingRestaurant: false,
      };
    case DELETE_CATEGORY:
      return {
        ...state,
        menuCategories: [
          state.menuCategories.filter((category) => category !== action.payload),
        ],
        loadingRestaurant: false,
      };
    case SET_RESTAURANT_VERIFIED:
      return {
        ...state,
        isVerified: true,
      };
    case "SET_RESTAURANT_BRANCHES":
      return {
        ...state,
        restaurantBranches: action.payload,
      };
    case "UPDATE_TAGS":
      return {
        ...state,
        tags: action.payload,
      };
    case "SET_CURRENCY_SYMBOL":
      return {
        ...state,
        currencySign: action.payload,
      };
    case SET_THROTTLING:
      return {
        ...state,
        orderThrottling: action.payload,
      };
    case SET_TABLE:
      return {
        ...state,
        tables: action.payload,
        loadingRestaurant: false,
      };
    case DELETE_TABLE:
      return {
        ...state,
        tables: state.tables.filter((table) => table._id !== action.payload),
        loadingRestaurant: false,
      };
    case UPDATE_TABLE:
      return {
        ...state,
        tables: state.tables.map((table) => {
          if (table._id === action.payload.id) {
            return {...table, tableNo: action.payload.updateNo};
          } else {
            return table;
          }
        }),
        loadingRestaurant: false,
      };

    default:
      return state;
  }
}
