import {Box, Breadcrumbs, Container, Link} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import CustomerListResults from "src/components/customer/CustomerListResults";
import CustomerListToolbar from "src/components/customer/CustomerListToolbar";
import {makeDecrypt} from "src/utils/securels";
import {getEmployees} from "../redux/actions/employeeActions";
import Spinner from "../utils/spinner/spinner";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {makeStyles} from "tss-react/mui";
import {Users} from "react-feather";
import HomeIcon from "@mui/icons-material/Home";

const useStyles = makeStyles()((theme) => {
  return {
    breadCrumbs: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "2px 8px ",
      borderRadius: "20px",
      backgroundColor: "#ffffff",
      border: "1px solid #FF5757",
      fontSize: "15px",
      fontWeight: "500",
      "& .MuiBreadcrumbs-separator svg": {
        fontSize: "20px !important",
      },
    },
  };
});

const CustomerList = () => {
  const {classes} = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const [search, setSearch] = useState('');

  // const handleSearch = event => {
  //   setSearch(event.target.value)
  // }

  const {authenticated, loading, role} = useSelector((state) => state.auth);
  const {_id} = useSelector((state) => state.restaurant);
  const {employee, loadingEmployee} = useSelector((state) => state.employee);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const onboarded = makeDecrypt("onboarded") == true ? true : false;
    const isVerified = makeDecrypt("verified") == true ? true : false;

    dispatch(getEmployees(makeDecrypt("restaurantId"), searchQuery));

    if (!authenticated) {
      navigate("/login", {replace: true});
    }
    if (onboarded == false && loading == false) {
      navigate("/restaurant/onboarding", {replace: true});
    } else if (isVerified == false) {
      navigate("/verification/pendingVerification", {replace: true});
    }
  }, [
    _id,
    authenticated,
    dispatch,
    loading,
    navigate,
    makeDecrypt("onboarded"),
    makeDecrypt("verified"),
    searchQuery,
  ]);

  return (
    <>
      <Helmet>
        <title>Manage Employees | Dinify</title>
      </Helmet>
      {loading === true && loadingEmployee === true ? (
        <Spinner />
      ) : (
        <Box
          sx={{
            backgroundColor: "background.default",
            minHeight: "100%",
            py: 3,
          }}
        >
          <Box sx={{m: 1, mb: 5}}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link
                color="primary"
                onClick={() => navigate("/app/dashboard")}
                style={{cursor: "pointer"}}
              >
                <Box className={classes.breadCrumbs}>
                  <HomeIcon
                    color="primary"
                    style={{fontSize: "15px", marginRight: "2px"}}
                  />
                  Home
                </Box>
              </Link>
              <Link
                color="primary"
                // href="/app/orders"
                style={{cursor: "pointer"}}
              >
                <Box className={classes.breadCrumbs}>
                  <Users size={15} style={{marginRight: "2px"}} />
                  Employees
                </Box>
              </Link>
            </Breadcrumbs>
          </Box>
          {role === "ROLE_ADMIN" && (
            <Container maxWidth={false} sx={{mt: 2}}>
              <CustomerListToolbar setSearchQuery={setSearchQuery} />
              <Box sx={{pt: 3}}>
                <CustomerListResults customers={employee} />
              </Box>
            </Container>
          )}
        </Box>
      )}
    </>
  );
};

export default CustomerList;
