import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fade,
  Grid,
  Link,
  Menu,
  MenuItem,
  Modal,
  Paper,
  TextField,
  Typography,
  FormControl,
  FormLabel,
  Tooltip,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  LinearProgress,
  CircularProgress,
  Switch,
  IconButton,
} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import EditIcon from "@mui/icons-material/Edit";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
  editCategoryName,
  toggleCategoryStatus,
} from "../../redux/actions/restaurantActions";
import {
  MAIN_MENU_LOADING,
  MAIN_MENU_LOADING_NOT_COMPLETED,
  SELECTED_CATEGORY,
  STATUS_LOADING,
  STOP_CATEGORY_LOADING,
  CATEGORY_LOADING,
} from "../../redux/types";
import Spinner from "../../utils/spinner/spinner";
import {deleteCategory, deleteMenu} from "./helpers";
import {
  editMenuName,
  getMainMenus,
  toggleMenuStatus,
} from "src/redux/actions/mainMenuActions";
import AlertMsg from "../AlertMsg";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: "fit-content",
      width: "220px",
      padding: 0,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      width: "fit-content",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2),
    },
    selectTimeMsg: {
      margin: "20px !important",
      color: "black",
    },

    timePicker: {
      margin: "10px 30px auto 0px",
    },
    menuHeading: {
      padding: "15px",
      textDecoration: "none",
      backgroundColor: theme.palette.background,
      color: "black",
      // fontSize: "18px",
      width: "100%",
      cursor: "pointer",
    },
  };
});

const EachMenuCard = ({
  name,
  description,
  printer,
  selectedDate,
  status,
  tempSocket,
  menuId,
  setThisReload,
  thisReload,
  ...rest
}) => {
  const {classes} = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [editedMenuName, setEditedMenuName] = useState(false);

  const {loading, role} = useSelector((state) => state.auth);
  const {loadingRestaurant, _id, printers} = useSelector((state) => state.restaurant);
  const {loadingMenu, selectedCategory} = useSelector((state) => state.menu);
  const {mainMenu, selectedMainMenu, loadingCompleted, statusLoading} = useSelector(
    (state) => state.mainMenu
  );
  const [newDescription, setNewDescription] = useState("");
  let toggleStatus = status == "Online" ? "Offline" : "Online";
  const [warning, setWarning] = useState(false);
  const [newPrinter, setNewPrinter] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [newDate, setNewDate] = useState({
    start: "",
    end: "",
  });
  const handleClickOpen = (message) => {
    setOpen(true);
    setAnchorEl(null);
  };

  //TODO:Online Offline actions should reflect suddenly

  const handleSelectedMainMenu = (event) => {
    // dispatch({
    //   type: SELECTED_CATEGORY,
    //   payload: event.target.name
    // })
    dispatch({
      type: "SELECTED_MAIN_MENU",
      payload: name,
    });
    history.pushState({}, "page title", `?menu=${event.target.name}`);
  };

  useEffect(() => {
    setNewDescription(description);
    setEditedMenuName(name);
    setNewDate(selectedDate);
    setNewPrinter(printer);
    // let date = new Date();
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDialogClose = () => {
    setOpen(false);
    setThisReload(!thisReload);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditMenu = (event) => {
    dispatch({type: MAIN_MENU_LOADING});
    dispatch({type: MAIN_MENU_LOADING_NOT_COMPLETED});
    const data = {
      name: editedMenuName,
      description: newDescription,
      availability: newDate,
      printer: newPrinter,
    };
    dispatch(editMenuName(data, menuId, navigate));
    // handleDialogClose();
    setNewDescription(description);
    setEditedMenuName(name);
    setNewDate(selectedDate);
    setNewPrinter(printer);
    setOpen(false);
  };

  const handleChange = (event) => {
    setEditedMenuName(event.target.value);
  };

  const handleCloseWarning = () => {
    setWarning(false);
    // setThisReload(!thisReload)
  };

  const handleDeleteMenu = (e) => {
    e.preventDefault();
    dispatch({type: MAIN_MENU_LOADING});
    dispatch({type: MAIN_MENU_LOADING_NOT_COMPLETED});
    deleteMenu(menuId, dispatch)
      .then((data) => {
        handleCloseWarning();
        // handleDialogClose();
      })
      .catch((error) => setAlertMessage(error.message));
  };

  const [showStatusLoading, setShowStatusLoading] = useState();
  const changeMenuStatus = (event) => {
    const data = {
      status: toggleStatus,
    };
    setShowStatusLoading(true);
    dispatch({type: STATUS_LOADING});
    setShowStatusLoading(true);
    dispatch({type: MAIN_MENU_LOADING});
    dispatch({type: CATEGORY_LOADING});

    tempSocket.current.emit("changeMainMenuStatus", {
      categories: mainMenu.find((item) => item.name === name).categories,
      status: toggleStatus,
      restaurantId: _id,
    });

    dispatch(toggleMenuStatus(data, menuId, navigate));

    // toggleStatus = status == 'Online' ? 'Offline' : 'Online'
    // setThisReload(!thisReload);
  };

  useEffect(() => {
    !statusLoading && setShowStatusLoading(false);
  }, [statusLoading]);

  const handleNewDateChange = (e) => {
    setNewDate({...newDate, [e.target.name]: e.target.value});
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"EDIT MENU DESCRIPTION "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <DialogContentText>{/* {message} */}</DialogContentText>
            <>
              <TextField
                autoFocus
                margin="dense"
                name="name"
                id="name"
                label="Name"
                fullWidth
                value={editedMenuName}
                onChange={handleChange}
              />
              <TextField
                autoFocus
                type="text"
                margin="dense"
                name="description"
                id="description"
                label="Menu Description"
                fullWidth
                value={newDescription}
                onChange={(e) => setNewDescription(e.target.value)}
              />
              <FormControl sx={{mt: 2}}>
                <InputLabel htmlFor="age-native-simple">Select Printer</InputLabel>
                <Select
                  native
                  value={newPrinter}
                  onChange={(e) => setNewPrinter(e.target.value)}
                  inputProps={{
                    name: "Select Printer",
                    id: "age-native-simple",
                  }}
                  label="Select Printer"
                >
                  {printers?.map((printer, index) => (
                    <option key={printer._id} value={printer._id}>
                      {printer.customName}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <Typography className={classes.selectTimeMsg}>
                Select Timeframe in which the menu will be available
              </Typography>

              <TextField
                id="start"
                name="start"
                label="START TIME"
                type="time"
                defaultValue={newDate.start}
                size="small"
                onChange={handleNewDateChange}
                className={classes.timePicker}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />

              <TextField
                id="end"
                name="end"
                label="END TIME"
                type="time"
                defaultValue={newDate.end}
                size="small"
                onChange={handleNewDateChange}
                className={classes.timePicker}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />

              {/* </FormControl> */}
            </>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Close
          </Button>
          <Button onClick={handleEditMenu} color="primary" name={name} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {loading == true && loadingMenu == true && loadingRestaurant == true ? (
        <Spinner />
      ) : (
        <>
          <Box
            // boxShadow={selectedMainMenu == name ? 15 : 1}
            sx={{
              backgroundColor: selectedMainMenu == name ? "#FFEBEB" : "white",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;",
            }}
            className={classes.root}
            {...rest}
          >
            <Box onClick={handleSelectedMainMenu} className={classes.menuHeading}>
              <Typography variant="h5"> {name}</Typography>
            </Box>
            <Box sx={{p: 1}}>
              {role === "ROLE_ADMIN" && (
                <Box display={"flex"} alignItems="center">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={handleClick}
                  >
                    <MoreVertIcon color="action" />
                  </IconButton>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={status == "Online" ? true : false}
                            onChange={changeMenuStatus}
                            name={name}
                            color="primary"
                            size="small"
                          />
                        }
                        label={status}
                      />
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleClickOpen("Add Menu Item")}
                      name={name}
                    >
                      EDIT
                    </MenuItem>
                    <MenuItem onClick={() => setWarning(true)} name={name}>
                      DELETE
                    </MenuItem>
                  </Menu>
                </Box>
              )}
            </Box>
          </Box>
          <Dialog open={warning}>
            <Box>
              <form noValidate autoComplete="off" onSubmit={handleDeleteMenu}>
                <Box>
                  <DialogContent>
                    <Typography>
                      Do you really want to delete {name} menu.All items and categories
                      under this menu will also get deleted
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="outlined"
                      onClick={() => setWarning(false)}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button variant="contained" color="primary" type="submit">
                      Ok
                    </Button>
                  </DialogActions>
                </Box>
              </form>
            </Box>
          </Dialog>
        </>
      )}
      <AlertMsg alertMessage={alertMessage} />
    </>
  );
};

export default EachMenuCard;
