import axios from "../../../axios";
import {makeDecrypt} from "src/utils/securels";
export const getCustomerDetailsByPage = async (
  StartingDate,
  EndingDate,
  page,
  limit,
  type
) => {
  const restId = makeDecrypt("restaurantId");
  const start_date = new Date(StartingDate).toISOString().split("T")[0];
  const end_date = new Date(EndingDate).toISOString().split("T")[0];
  return await axios
    .get(
      `/crm/${restId}/customer?start=${start_date}&end=${end_date}&page=${page}&limit=${limit}&type=${type}`,
      {
        headers: {Authorization: makeDecrypt("jwt")},
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.message;
    });
};

export const getOrderHistory = async (days, page, limit, setProgress, id) => {
  return await axios
    .get(`/crm/orderHistory/${id}/${days}?page=${page}&limit=${limit}`, {
      headers: {Authorization: makeDecrypt("jwt")},
      onDownloadProgress: (d) => {
        if (setProgress) setProgress(Math.round((d.loaded / d.total) * 100));
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.message;
    });
};
