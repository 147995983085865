import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  Hidden,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Select,
  Typography,
} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import PropTypes from "prop-types";
import {useEffect, useRef, useState} from "react";
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  Truck as TruckIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon,
  DollarSign as DollarIcon,
  MapPin as LocationIcon,
  Monitor as KdsIcon,
  Target as TargetIcon,
} from "react-feather";
import {MdQrCode2, MdCardMembership} from "react-icons/md";
import {useSelector, useDispatch} from "react-redux";
import {Link as RouterLink, useLocation} from "react-router-dom";
import axios from "src/axios";
import NavItem from "./NavItem";
import {RiLayoutGridFill} from "react-icons/ri";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import {useNavigate} from "react-router-dom";
import store from "../redux/store";
import {changeBranch, getUserData, logoutAction} from "src/redux/actions/authActions";
import {
  createDashboardLink,
  createOnboardingLink,
  getRestaurantBranches,
} from "src/redux/actions/restaurantActions";
import {getSubscription} from "./account/helpers";
import {makeDecrypt, makeEncrypt} from "src/utils/securels";
import {io} from "socket.io-client";
import InputIcon from "@mui/icons-material/Input";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import clsx from "clsx";
import {FaStripeS} from "react-icons/fa";
import {SiRazorpay} from "react-icons/si";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
const drawerWidth = 210;

const useStyles = makeStyles()((theme) => ({
  list: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    margin: 0,
    alignItems: "center",
  },
  listItem: {
    backgroundColor: "#fff",
    borderRadius: "7px",
    // padding: "8px 0px 8px 15px",
    minHeight: "48px",
    margin: "7px",
    cursor: "pointer",
    border: "0px",
    "&:hover": {
      backgroundColor: "#FF5757",
      color: "#F4F6F8 !important",
    },
    transition: "all 0.1s ease",
  },
  activeListItem: {
    backgroundColor: "#FF5757",
    color: "#F4F6F8 !important",
    minHeight: "48px",
    borderRadius: "7px",
    // padding: "8px 0px 8px 15px",
    margin: "7px",
    cursor: "pointer",
  },
  Icon: {
    color: "inherit",
    marginRight: "-15px",
    "&:hover": {
      color: "inherit",
    },
  },
  activeIcon: {
    color: "#F4F6F8 !important",
    marginRight: "-15px",
  },
  logout: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
    [theme.breakpoints.down("lg")]: {},
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    transition: "all 0.2s ease",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 75,
    [theme.breakpoints.up("sm")]: {
      width: 75,
      // width: theme.spacing(9) + 1,
    },
  },
  openBtn: {
    position: "fixed",
    top: "50%",
    backgroundColor: "#FF5757",
    zIndex: 999,
    width: "30px",
    height: "100px",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transform: "translate(0%, -50%)",
    cursor: "pointer",
    transition: "all 0.2s ease",
  },
  closeBox: {
    position: "absolute",
    top: 10,
    right: 10,
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    // // width: "25px",
    // // height: "25px",
    // padding: "10px",
    // cursor: "pointer",
    // borderRadius: "50%",
    // "&:hover": {
    //   backgroundColor: "red",
    // },
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const DashboardSidebar = ({onMobileClose, openMobile, open, setOpen}) => {
  const {classes} = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const activeItem = window.location.pathname.split("/")[2];
  const navigate = useNavigate();
  const socket = useRef();
  const {name, role} = useSelector((state) => state.auth);
  const {country} = useSelector((state) => state.restaurant.restaurantAddress);
  const {restaurant, auth} = useSelector((state) => state);
  const {stripeDashboardLink, stripeAccountId, logoUrl, _id, stripeOnboardingLink} =
    useSelector((state) => state.restaurant);
  const [showPrompt, setShowPrompt] = useState(false);
  const [tempItem, setTempItem] = useState();
  const [selectedBranch, setSelectedBranch] = useState("");
  const [tempSocket, setTempSocket] = useState(null);

  const user = {
    avatar: "/static/IMAGE.png",
    jobTitle: role == "ROLE_ADMIN" ? "Owner" : "Employee",
    name: name,
  };

  useEffect(() => {
    if (!role) {
      dispatch(getUserData(navigate, false));
    }
  }, [role]);

  useEffect(() => {
    if (selectedBranch) {
      dispatch(changeBranch(selectedBranch));
    }
  }, [selectedBranch]);

  useEffect(() => {
    const getData = async () => {
      if (restaurant.dinify_payment_plan && restaurant.status === "active") {
        return getSubscription(
          restaurant.dinify_payment_plan.subscription_id,
          restaurant.restaurantAddress.country
        )
          .then((data) => {
            if (data.status !== "active" && data.status !== "trialing") {
              navigate("/app/manageaccount", {replace: true});
            }
          })
          .catch((error) => console.log(error));
      }
      if (restaurant.status && restaurant.status !== "active") {
        navigate("/needActivation", {replace: true});
      }
    };
    getData();
  }, [_id]);

  useEffect(() => {
    if (restaurant.restaurantAddress.country)
      if (restaurant.restaurantAddress.country === "INDIA") {
        dispatch({type: "SET_CURRENCY_SYMBOL", payload: "₹"});
      } else if (restaurant.restaurantAddress.country === "NEPAL") {
        dispatch({type: "SET_CURRENCY_SYMBOL", payload: "रु"});
      } else {
        dispatch({type: "SET_CURRENCY_SYMBOL", payload: "$"});
      }
  }, [restaurant._id]);

  useEffect(() => {
    const makeConnection = async () => {
      socket.current = io(process.env.REACT_APP_WEB_SOCKET_URL, {
        transports: ["websocket"],
      });
      setTempSocket(socket);

      socket.current.emit("desktopAppConnection", makeDecrypt("restaurantId"));
    };
    makeConnection();
  }, []);

  const items = !(role == "ROLE_EMPLOYEE")
    ? [
        {
          href: "/app/dashboard",
          icon: <BarChartIcon />,
          title: "Dashboard",
          className: "dashboard",
        },
        {
          href: "/app/orders",
          icon: <TruckIcon />,
          title: "Orders",
          className: "orders",
        },
        {
          href: "/app/floorlayout",
          icon: <RiLayoutGridFill size="24px" />,
          title: "Floor Layout",
          className: "floorlayout",
        },
        {
          href: "/app/products",
          icon: <ShoppingBagIcon />,
          title: "Manage Menu",
          className: "products",
        },
        {
          href: "/app/customers",
          icon: <UsersIcon />,
          title: "Employees",
          className: "customers",
        },
        {
          href: "/app/accounting",
          icon: <DollarIcon />,
          title: "Accounting",
          className: "accounting",
        },
        {
          href: "/app/CRM",
          icon: <TargetIcon />,
          title: "CRM",
          className: "CRM",
        },
        {
          href: "/app/account",
          icon: <UserIcon />,
          title: "Profile",
          className: "account",
        },
        {
          href: "/app/manageaccount",
          icon: <SettingsIcon />,
          title: "Settings",
          className: "manageaccount",
        },
        {
          href: "/app/kds",
          icon: <KdsIcon size="24px" />,
          title: "KDS",
          className: "kds",
        },
        {
          href: "/app/knowledgebase",
          icon: <MenuBookIcon size="24px" />,
          title: "Knowledge Base",
          className: "knowledgebase",
        },
        restaurant.multilocation && {
          href: "/app/managelocation",
          icon: <LocationIcon />,
          title: "Manage Locations",
          className: "managelocation",
        },
      ]
    : [
        {
          href: "/app/orders",
          icon: <TruckIcon />,
          title: "Orders",
        },
        {
          href: "/app/floorlayout",
          icon: <RiLayoutGridFill size="24px" />,
          title: "Floor Layout",
          className: "floorlayout",
        },
        {
          href: "/app/kds",
          icon: <KdsIcon size="24px" />,
          title: "KDS",
          className: "kds",
        },
      ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const [stripeLink, setStripeLink] = useState("");

  //https://business.dinify.io
  useEffect(() => {
    if (stripeAccountId && role === "ROLE_ADMIN") {
      //for first time creating onboarding link cause previous one which is saved can get expired
      // getOnboardingLink(stripeAccountId);
      getDashboardLink(stripeAccountId);

      if (!stripeDashboardLink) {
        getOnboardingLink(stripeAccountId);
      } else {
        getDashboardLink(stripeAccountId);
      }
    }
  }, [stripeAccountId, stripeDashboardLink]);

  const getDashboardLink = async (stripeAccountId) => {
    try {
      const stripeDashboardLinkData = await createDashboardLink(stripeAccountId);
      setStripeLink(stripeDashboardLinkData.url);
      makeEncrypt("stripeLink", stripeDashboardLinkData.url);
      updateRestaurant("stripeDashboardLink", stripeDashboardLinkData.url);
    } catch (error) {
      getOnboardingLink(stripeAccountId);
    }
  };

  const getOnboardingLink = (stripeAccountId) => {
    createOnboardingLink(stripeAccountId)
      .then((onboardingLinkData) => {
        setStripeLink(onboardingLinkData.url);
        makeEncrypt("stripeLink", onboardingLinkData.url);
        updateRestaurant("stripeOnboardingLink", onboardingLinkData.url);
      })
      .catch((error) => {});
  };

  const updateRestaurant = async (type, link) => {
    await axios.put(
      `/restaurant/restaurant-details/${makeDecrypt("restaurantId")}`,
      {
        [type]: link,
      },
      {headers: {Authorization: makeDecrypt("jwt")}}
    );
  };

  const handleStripeClick = () => {
    if (stripeLink.includes("onboarding")) getOnboardingLink(stripeAccountId);
    else getDashboardLink(stripeAccountId);
  };

  const handleNavigate = (item) => {
    makeDecrypt("layoutSaved")
      ? navigate(item.href, {replace: true})
      : confirm("Save changes before leaving the page")
      ? ""
      : navigate(item.href, {replace: true});
  };

  const onLogout = () => {
    store.dispatch(logoutAction());
  };

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          p: 2,
        }}
      >
        <Avatar
          component={RouterLink}
          src={logoUrl ? logoUrl : user.avatar}
          sx={{
            cursor: "pointer",
            width: 64,
            height: 64,
          }}
          to="/app/account"
        />
        <Hidden lgDown>
          {open && (
            <Typography color="textPrimary" variant="h5">
              {user.name}
            </Typography>
          )}
        </Hidden>
        <Hidden lgUp>
          <Typography color="textPrimary" variant="h5">
            {user.name}
          </Typography>
        </Hidden>
        <Typography color="textSecondary" variant="body2">
          {user.jobTitle}
        </Typography>

        {restaurant.restaurantBranches.length && role === "ROLE_ADMIN" ? (
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-age-native-simple">Select Branch</InputLabel>
            <Select
              native
              value={selectedBranch}
              onChange={(e) => setSelectedBranch(e.target.value)}
              label="Branches"
              inputProps={{
                name: "branches",
              }}
            >
              <option aria-label="None" value="" disabled />
              {restaurant.restaurantBranches?.length &&
                restaurant.restaurantBranches.map((branch) => (
                  <option
                    value={branch._id}
                    disabled={_id === branch._id}
                    key={branch._id}
                  >
                    {branch.name}
                  </option>
                ))}
            </Select>
          </FormControl>
        ) : (
          ""
        )}
      </Box>
      <Divider />
      <Box sx={{p: 1}}>
        <List className={classes.list}>
          {items.map((item, index) => {
            if (item)
              return (
                <ListItem
                  key={index}
                  onClick={() => {
                    navigate(item.href, {replace: true});
                  }}
                  className={[
                    item.className === activeItem
                      ? classes.activeListItem
                      : classes.listItem,
                    item.className,
                  ].join(" ")}
                >
                  <ListItemIcon
                    className={
                      item.className === activeItem ? classes.activeIcon : classes.Icon
                    }
                  >
                    {item.icon}
                  </ListItemIcon>
                  <Hidden lgDown>
                    <ListItemText primary={open ? item.title : ""}></ListItemText>
                  </Hidden>
                  <Hidden lgUp>
                    <ListItemText primary={item.title}></ListItemText>
                  </Hidden>
                </ListItem>
              );
          })}
          <ListItem
            onClick={() => onLogout()}
            className={[classes.listItem, classes.logout, "logout"].join(" ")}
          >
            <ListItemIcon className={classes.Icon}>
              <InputIcon />
            </ListItemIcon>
            <ListItemText>LOGOUT</ListItemText>
          </ListItem>
        </List>
      </Box>
      <Box sx={{flexGrow: 1}} />
      <Divider />
      <Box
        sx={{
          backgroundColor: "background.default",
          // mb: 2,
          p: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            // pt: 2
          }}
          id="stripeButton"
        >
          {makeDecrypt("stripeLink") &&
          role == "ROLE_ADMIN" &&
          country?.toUpperCase() == "CANADA" ? (
            open ? (
              <Button
                color="primary"
                component="a"
                href={stripeLink}
                variant="outlined"
                onClick={handleStripeClick}
                target="_blank"
                sx={{
                  width: 200,
                  p: 1.2,
                  fontSize: 16,
                  borderRadius: 20,
                }}
              >
                Stripe Dashboard
              </Button>
            ) : (
              <>
                <Hidden lgDown>
                  <FaStripeS
                    style={{
                      width: 200,
                      p: 1.2,
                      fontSize: 24,
                      borderRadius: 20,
                      cursor: "pointer",
                    }}
                  />
                </Hidden>
                <Hidden lgUp>
                  <Button
                    color="primary"
                    component="a"
                    href={stripeLink}
                    variant="outlined"
                    onClick={handleStripeClick}
                    target="_blank"
                    sx={{
                      width: 200,
                      p: 1.2,
                      fontSize: 16,
                      borderRadius: 20,
                    }}
                  >
                    Stripe Dashboard
                  </Button>
                </Hidden>
              </>
            )
          ) : (
            role == "ROLE_ADMIN" &&
            country?.toUpperCase() == "INDIA" &&
            (open ? (
              <Button
                color="primary"
                component="a"
                href={stripeLink}
                variant="outlined"
                onClick={() => window.open(stripeOnboardingLink, "_blank")}
                target="_blank"
                sx={{
                  width: 200,
                  p: 1.2,
                  fontSize: 14,
                  borderRadius: 20,
                }}
              >
                Razorpay Dashboard
              </Button>
            ) : (
              <>
                <Hidden lgDown>
                  <SiRazorpay
                    style={{
                      width: 200,
                      p: 1.2,
                      fontSize: 24,
                      borderRadius: 20,
                    }}
                  />
                </Hidden>
                <Hidden lgUp>
                  <Button
                    color="primary"
                    component="a"
                    href={stripeLink}
                    variant="outlined"
                    onClick={() => window.open(stripeOnboardingLink, "_blank")}
                    target="_blank"
                    sx={{
                      width: 200,
                      p: 1.2,
                      fontSize: 14,
                      borderRadius: 20,
                    }}
                  >
                    Razorpay Dashboard
                  </Button>
                </Hidden>
              </>
            ))
          )}
        </Box>
      </Box>
    </Box>
  );

  <Dialog
    open={showPrompt}
    onClose={() => setShowPrompt(false)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      Save changes before leaving the page
    </DialogTitle>

    <DialogActions>
      <Button
        onClick={() => {
          makeEncrypt("layoutSaved", true);
          navigate(tempItem.href, {replace: true});
        }}
        color="primary"
      >
        Cancel
      </Button>
      <Button onClick={() => setShowPrompt(false)} color="primary" autoFocus>
        Ok
      </Button>
    </DialogActions>
  </Dialog>;

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          // anchor="left"
          // open={open}
          // variant="permanant"
          // PaperProps={{
          //   sx: {
          //     width: 210,
          //     top: 64,
          //     height: "calc(100% - 64px)",
          //   },
          // }}
          // className={classes.drawer}
          // variant="persistent"
          // anchor="left"
          // open={open}
          // classes={{
          //   paper: classes.drawerPaper,
          // }}
          PaperProps={{
            sx: {
              width: 210,
              top: 64,
              height: "calc(100% - 64px)",
            },
          }}
          // onMouseEnter={() => setOpen(true)}
          // onMouseLeave={() => setOpen(false)}
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          {content}
          <Box
            className={classes.openBtn}
            onClick={() => setOpen(!open)}
            style={{left: open ? 209 : "74px"}}
          >
            {open ? (
              <KeyboardArrowLeftIcon fontSize="large" />
            ) : (
              <KeyboardArrowRightIcon fontSize="large" />
            )}
          </Box>
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default DashboardSidebar;
