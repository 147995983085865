import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  colors,
  Divider,
  useTheme,
} from "@mui/material";
import {format} from "date-fns";
import {useEffect, useState} from "react";
import {Bar} from "react-chartjs-2";
import {useSelector} from "react-redux";
import {getTotalRevenueForClient} from "./helpers";
const Sales = ({
  startingDate,
  endingDate,
  restId,
  setDoughnutData,
  setLoaded,
  loaded,
  ...rest
}) => {
  const theme = useTheme();
  const [weeks, setWeeks] = useState([]);
  const [graphData, setGraphData] = useState({
    graphValues: [],
    graphLabels: [],
  });
  const particularRestaurant = useSelector((state) => state.restaurant);
  useEffect(() => {
    getDates(new Date(startingDate), new Date(endingDate));
  }, [startingDate, endingDate, particularRestaurant._id]);

  useEffect(() => {
    const loadData = async () => {
      if (weeks.length && restId) {
        let tempData = {
          graphValues: [],
          graphLabels: [],
        };
        for (const week of weeks) {
          setLoaded(false);
          let res = await getTotalRevenueForClient(1.99, restId, week.begin, week.end);
          tempData.graphValues.push(res.totalOrders);
          tempData.graphLabels.push({
            start: format(new Date(week.begin), "do/MMM"),
            end: format(new Date(week.end), "do/MMM"),
          });
        }
        setGraphData(tempData);
        setDoughnutData({
          datasets: [
            {
              data: tempData.graphValues,
              backgroundColor: [
                colors.indigo[500],
                colors.red[600],
                colors.orange[600],
                colors.purple[600],
                colors.blue[600],
                colors.teal[600],
              ],
              label: "Orders",
            },
          ],
          labels: tempData.graphLabels.map((item) => item.start + " - " + item.end),
        });
        setLoaded(true);
      }
    };
    loadData();
  }, [weeks]);

  const getDates = (startDate, endDate) => {
    let dates = [];
    const addDays = function (days) {
      var date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    let currentDate = startDate;
    if (currentDate.getDay() > 0) {
      currentDate.setDate(currentDate.getDate() - currentDate.getDay());
    }

    while (currentDate <= endDate) {
      let endWeekDate = addDays.call(currentDate, 6);
      dates.push({
        begin: new Date(currentDate).toISOString(),
        end: new Date(endWeekDate).toISOString(),
      });
      currentDate = addDays.call(currentDate, 7);
    }

    setWeeks(dates);
  };

  const [data, setData] = useState(null);

  useEffect(() => {
    let tempLabels = [];
    if (graphData.graphValues.length && graphData.graphLabels.length) {
      for (const label of graphData.graphLabels) {
        tempLabels.push(label.start + " - " + label.end);
      }
      setData({
        datasets: [
          {
            backgroundColor: colors.indigo[500],
            data: graphData.graphValues,
            label: "Orders this week",
          },
        ],
        labels: tempLabels,
      });
    }
  }, [graphData]);

  const options = {
    animation: true,
    cornerRadius: 20,
    layout: {padding: 0},
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
        ticks: {
          fontColor: theme.palette.text.secondary,
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        ticks: {
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          min: 0,
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider,
        },
      },
    },
    plugins: {
      tooltips: {
        backgroundColor: theme.palette.background.paper,
        bodyFontColor: theme.palette.text.secondary,
        borderColor: theme.palette.divider,
        borderWidth: 1,
        enabled: true,
        footerFontColor: theme.palette.text.secondary,
        intersect: false,
        mode: "index",
        titleFontColor: theme.palette.text.primary,
      },
      legend: {display: false},
    },
  };

  return (
    <Card {...rest}>
      <CardHeader title="Orders by Weeks" />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 400,
            position: "relative",
          }}
        >
          {data && loaded ? <Bar data={data} options={options} /> : <CircularProgress />}
        </Box>
      </CardContent>
    </Card>
  );
};

export default Sales;
