import axios from "src/axios";

export const getKdsItems = (restId, setProgress) => (dispatch) => {
  axios
    .request({
      method: "get",
      url: `/kdsItems/get-kds-items/${restId}`,
      onDownloadProgress: (p) => {
        setProgress(Math.round((p.loaded / p.total) * 100));
      },
    })
    // .get(`/kdsItems/get-kds-items/${restId}`)
    .then((res) => {
      // window.location.reload();
      dispatch({
        type: "SET_KDS_ITEMS",
        payload: res.data,
      });
    })
    .catch((error) => {
      return error.message;
    });
};

export const updateKdsItemMenuItems =
  (restId, kdsItemId, data, setMenuItemIds) => (dispatch) => {
    axios
      .put(`/kdsItems/update-items/${kdsItemId}/${restId}`, data)
      .then((res) => {
        dispatch({
          type: "UPDATE_KDS_ITEMS",
          payload: res.data,
        });
        setMenuItemIds([]);
      })
      .catch((error) => {
        return error.message;
      });
  };

export const updateKdsTable =
  (restId, kdsItemId, data, intervalId, handleReadyToServe) => (dispatch) => {
    axios
      .put(`/kdsItems/update-kds-table/${restId}/${kdsItemId}`, data)
      .then((res) => {
        dispatch({
          type: "UPDATE_KDS_ITEMS",
          payload: res.data,
        });
        if (intervalId) clearInterval(intervalId);
        handleReadyToServe();
      })
      .catch((error) => {
        return error.message;
      });
  };

export const deleteKdsItem =
  (restId, kdsItemId, setItemsTables, intervalId, reload, setReload) => (dispatch) => {
    axios
      .delete(`/kdsItems/delete-kds-items/${restId}/${kdsItemId}`)
      .then((res) => {
        if (intervalId) clearInterval(intervalId);
        // dispatch({
        //   type: "REMOVE_ITEM",
        //   payload: kdsItemId,
        // });
        setReload(!reload);
        setItemsTables([]);
      })
      .catch((error) => {
        return error.message;
      });
  };
