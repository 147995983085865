import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React, {useState} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {deleteEmployee} from "../../redux/actions/employeeActions";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {updateEmployee} from "./helpers";
import {makeDecrypt} from "src/utils/securels";
import {getEmployees} from "../../redux/actions/employeeActions";
const CustomerListResults = ({customers, ...rest}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [indexToDelete, setIndexToDelete] = useState("");
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [loading, setLoading] = useState();
  const data = customers
    .filter(function (item) {
      return item.role == "ROLE_EMPLOYEE";
    })
    .map((customer) => {
      return [
        customer.name,
        customer.email,
        customer.role,
        customer.phone,
        customer.createdAt.split("T")[0],
        customer._id,
        customer.restaurant,
      ];
    });

  function createData(name, email, role, phone, time, _id, restaurant) {
    return {name, email, role, phone, time, _id, restaurant};
  }

  const rowsss = [];

  customers
    // .filter(function (item) {
    //   return item.role == "ROLE_EMPLOYEE";
    // })
    .map((customer) => {
      rowsss.push(
        createData(
          customer.name,
          customer.email,
          customer.role,
          customer.phone,
          customer.createdAt.split("T")[0],
          customer._id,
          customer.restaurant
        )
      );
    });

  const handleSelectEmployee = (employeeId) => {
    setSelectedEmployeeId(employeeId);
  };

  const onRowSelectionChange = (index) => {
    handleSelectEmployee([index]);
  };

  const onRowsDelete = async (employee) => {
    // const employeeData = {
    //   restaurantId: data[selectedEmployeeId ? selectedEmployeeId : index][6],
    //   _id: data[selectedEmployeeId ? selectedEmployeeId : index][5],
    // };
    let employeeId = employee._id;
    let result = await dispatch(deleteEmployee(employeeId, navigate));
    let employeees = await dispatch(getEmployees());
    setLoading(false);
    setDeleteConfirm(false);

    // window.location.reload();
  };

  const handleEmployeeDetailsChange = (e, name) => {
    setCurrentEmployee({...currentEmployee, [name]: e.target.value});
  };
  const handleUpdateEmployeeData = async () => {
    try {
      let tempData = await updateEmployee(
        currentEmployee,
        currentEmployee._id,
        makeDecrypt("restaurantId")
      );
      if (tempData) {
        setOpenEdit(false);
        dispatch(getEmployees("", ""));
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box {...rest}>
      <PerfectScrollbar>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Registration Date</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsss.map((row, index) => (
                <TableRow key={row.name} onClick={() => onRowSelectionChange(index)}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.role}</TableCell>
                  <TableCell>{row.phone}</TableCell>
                  <TableCell>{row.time}</TableCell>
                  <TableCell>
                    <DeleteIcon
                      style={{cursor: "pointer"}}
                      onClick={() => {
                        setDeleteConfirm(true);
                        setIndexToDelete(row);
                      }}
                    />
                    <EditIcon
                      style={{marginLeft: "30px", cursor: "pointer"}}
                      onClick={() => {
                        setOpenEdit(true);
                        setIndexToDelete(index);
                        setCurrentEmployee(row);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
        component="div"
        count={customers.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      /> */}
        <Dialog
          open={deleteConfirm}
          onClose={() => setDeleteConfirm(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Typography variant="h4">Are you sure to delete employee?</Typography>
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => setDeleteConfirm(false)}
              variant="outlined"
              color="primary"
            >
              CANCEL
            </Button>
            <Box sx={{margin: 1, position: "relative"}}>
              <Button
                onClick={() => {
                  setLoading(true);
                  onRowsDelete(indexToDelete);
                }}
                variant="contained"
                color="primary"
                disabled={loading}
                autoFocus
              >
                DELETE
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  style={{
                    // color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
            </Box>
          </DialogActions>
        </Dialog>
        {currentEmployee && (
          <Dialog
            open={openEdit}
            onClose={() => setOpenEdit(false)}
            style={{padding: "30px !important"}}
          >
            <DialogTitle id="alert-dialog-title">
              <Typography variant="h4">Edit Details</Typography>
            </DialogTitle>
            <Box p={3}>
              <TextField
                autoFocus
                margin="dense"
                name="name"
                id="name"
                label="Name"
                fullWidth
                value={currentEmployee.name}
                onChange={(e) => handleEmployeeDetailsChange(e, "name")}
              />
              <TextField
                autoFocus
                margin="dense"
                name="email"
                id="email"
                label="Email Address"
                type="email"
                fullWidth
                value={currentEmployee.email}
                onChange={(e) => handleEmployeeDetailsChange(e, "email")}
              />
              <TextField
                autoFocus
                margin="dense"
                name="role"
                id="role"
                label="Change Role"
                fullWidth
                select
                value={currentEmployee.role}
                onChange={(e) => handleEmployeeDetailsChange(e, "role")}
              >
                {["ROLE_EMPLOYEE", "ROLE_ADMIN"].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                autoFocus
                margin="dense"
                name="phone"
                id="phone"
                label="Phone"
                type="phone"
                fullWidth
                value={currentEmployee.phone}
                onChange={(e) => handleEmployeeDetailsChange(e, "phone")}
              />
            </Box>
            <DialogActions>
              <Button onClick={() => setOpenEdit(false)} color="primary">
                CANCEL
              </Button>
              <Button onClick={handleUpdateEmployeeData} color="primary" autoFocus>
                SAVE
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </PerfectScrollbar>
    </Box>
  );
};

CustomerListResults.propTypes = {
  customers: PropTypes.array.isRequired,
};

export default CustomerListResults;
