import {Navigate} from "react-router-dom";
import DashboardLayout from "src/components/DashboardLayout";
import MainLayout from "src/components/MainLayout";
import Account from "src/pages/Account";
// import OnBoarding from 'src/pages/OnBoarding';
import CustomerList from "src/pages/CustomerList";
import Dashboard from "src/pages/Dashboard";
import Login from "src/pages/Login";
import AfterSignUp from "src/pages/AfterSignUp";
import VerificationPending from "src/pages/VerificationPending";
import NotFound from "src/pages/NotFound";
import ProductList from "src/pages/ProductList";
import Register from "src/pages/Register";
import Settings from "src/pages/Settings";
import Order from "src/pages/Order";
import ManageAccount from "src/pages/ManageAccount";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import OnboardingSteps from "./pages/OnboardingSteps";
import FloorLayout from "./pages/FloorLayout";
import NeedActivation from "./pages/NeedActivation";
import Accounting from "./pages/Accounting";
import ManageLocation from "./pages/ManageLocation";
import KitchenDisplaySystem from "./pages/KitchenDisplaySystem";
import KnowledgeBase from "./pages/KnowledgeBase";
import Crm from "./pages/Crm";
import OrderHistory from "./pages/OrderHistory";
// isLoggedIn ? ( (isOnboarded === "true") ? ((isVerified === "true") ? <DashboardLayout /> : <Navigate to="/verification/pendingVerification" /> ) : <Navigate to="/restaurant/onboarding" /> ) : <Navigate to="/login" />,

const routes = (isLoggedIn) => [
  {
    path: "app",
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      {path: "account", element: <Account />},
      {path: "customers", element: <CustomerList />},
      {path: "floorlayout", element: <FloorLayout />},
      {path: "dashboard", element: <Dashboard />},
      {path: "products", element: <ProductList />},
      {path: "orders", element: <Order />},
      {path: "settings", element: <Settings />},
      {path: "CRM", element: <Crm />},
      {path: "CRM/:customerId", element: <OrderHistory />},
      {path: "404", element: <NotFound />},
      {path: "onboarding", element: <OnboardingSteps />},
      {path: "manageaccount", element: <ManageAccount />},
      {path: "accounting", element: <Accounting />},
      {path: "managelocation", element: <ManageLocation />},
      {path: "kds", element: <KitchenDisplaySystem />},
      {path: "knowledgebase", element: <KnowledgeBase />},
      {path: "*", element: <Navigate to="/404" />},
    ],
  },
  {
    path: "/verification",
    element: isLoggedIn ? <MainLayout /> : <Navigate to="/login" />,
    children: [{path: "pendingVerification", element: <VerificationPending />}],
  },
  {
    path: "/restaurant/onboarding",
    element: isLoggedIn ? <OnboardingSteps /> : <Navigate to="/login" />,
    // children: [{path: "onboarding", element: <OnboardingSteps />}],
  },

  {
    path: "/",
    element: !isLoggedIn ? <MainLayout /> : <Navigate to="/app/dashboard" />,
    children: [
      {path: "login", element: <Login />},
      // { path: 'onboarding-steps', element: <OnboardingSteps /> },
      {path: "afterSignUp", element: <AfterSignUp />},
      {path: "register", element: <Register />},
      {path: "forgot-password", element: <ForgotPassword />},
      {path: "reset-password/:id/:token", element: <ResetPassword />},
      // { path: '404', element: <NotFound /> },
      {path: "/", element: <Navigate to="/app/orders" />},
      {path: "*", element: <Navigate to="/404" />},
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [{path: "needActivation", element: <NeedActivation />}],
  },
];

export default routes;
