import {Avatar, Card, CardContent, Grid, Typography} from "@mui/material";
import {indigo} from "@mui/material/colors";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import {useSelector} from "react-redux";

const TotalProfit = ({revenueGenerated, ...rest}) => {
  const {currencySign} = useSelector((state) => state.restaurant);
  return (
    <Card {...rest}>
      <CardContent>
        <Grid container spacing={3} sx={{justifyContent: "space-between"}}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              TOTAL REVENUE
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {currencySign}
              {revenueGenerated ? revenueGenerated.toFixed(2) : 0}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: indigo[600],
                height: 56,
                width: 56,
              }}
            >
              <AttachMoneyIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TotalProfit;
