import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";
import { Helmet } from "react-helmet";
import axiosInstance from "src/axios";
import { makeDecrypt } from "src/utils/securels";
import {
  Box,
  Breadcrumbs,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Typography,
  Link,
} from "@mui/material";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import HistoryTable from "src/components/CRM/HistoryTable";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router";
import { getOrderHistory } from "src/components/CRM/helpers";
import { Users } from "react-feather";
import { UserPlus } from "react-feather";
const useStyles = makeStyles()((theme) => {
  return {
    priceCard: {
      padding: "0.7rem",
    },
    gridWrap: {
      marginRight: "20px",
    },
    dataGrid: {
      display: "flex",
      justifyContent: "flex-end",
      marginLeft: "auto",
      border: "1px solid gray",
    },
    breadCrumbs: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "2px 8px ",
      borderRadius: "20px",
      backgroundColor: "#ffffff",
      border: "1px solid #FF5757",
      fontSize: "15px",
      fontWeight: "500",
      "& .MuiBreadcrumbs-separator svg": {
        fontSize: "20px !important",
      },
    },
  };
});

const OrderHistory = ({ match }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  let { customerId } = useParams();
  const [searchParams] = useSearchParams();
  const [orders, setOrders] = useState([]);
  const [totalTax, setTotalTax] = useState(0);
  const [totalPst, setTotalPst] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);
  const [appFee, setAppFee] = useState(0);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [values, setValues] = useState({
    days: 10,
    page: 1,
    limit: 5,
    count: 1,
  });
  const serviceFee = useSelector(
    (state) => state.restaurant?.dinify_payment_plan?.application_service_fee
  );
  const { currencySign } = useSelector((state) => state.restaurant);
  const [progress, setProgress] = useState("");

  const { days, page, limit, count } = values;

  const getOrders = async () => {
    getOrderHistory(days, page, limit, setProgress, customerId).then((result) => {
      setOrders(result.result);
    });
  };
  useEffect(() => {
    setOrders([]);
    getOrders();
  }, [page, days, limit]);

  const handleChange = (val) => {
    setValues({ ...values, page: val });
  };

  const [open, setOpen] = useState({
    daysOpen: false,
    limitOpen: false,
  });
  const { daysOpen, limitOpen } = open;

  const handleClose = (type) => {
    if (type == "days") setOpen({ ...open, daysOpen: false });
    else setOpen({ ...open, limitOpen: false });
  };

  const handleOpen = (type) => {
    if (type == "days") setOpen({ ...open, daysOpen: true });
    else setOpen({ ...open, limitOpen: true });
  };

  useEffect(() => {
    setTotalPst(orders?.reduce((n, { pstValue }) => n + pstValue, 0));
    setTotalTax(orders?.reduce((n, { tax }) => n + Number(tax), 0));
    setTotalIncome(orders?.reduce((n, { totalPrice }) => n + totalPrice, 0));

    setAppFee(
      orders.reduce(function (n, order) {
        return (
          n +
          (order.pushToCustomer == true
            ? 0
            : order.halfToCustomer == true
              ? serviceFee / 2
              : serviceFee)
        );
      }, 0)
    );
  }, [orders]);

  useEffect(() => {
    setTotalIncome((prevVal) => prevVal - appFee);
  }, [appFee]);

  function MyExportButton() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    setRows([]);

    setColumns([
      { field: "id", headerName: "ORDER ID", width: 230 },
      { field: "totalPrice", headerName: "TOTAL PRICE", width: 130 },
      { field: "gst", headerName: "GST", width: 100 },
      { field: "pst", headerName: "PST", width: 100 },
      { field: "appFee", headerName: "APP FEE", width: 100 },
    ]);

    orders.length &&
      orders.map((order, index) => {
        setRows((preValue) => [
          ...preValue,
          {
            id: order._id,
            totalPrice: `${currencySign}  ${order.totalPrice}`,
            gst: `${currencySign}  ${(order.tax - order.pstValue).toFixed(2)}`,
            pst: `${currencySign}  ${order.pstValue}`,
            appFee: `${currencySign}  ${order.pushToCustomer ? serviceFee : 0}`,
          },
        ]);
      });
  }, [orders]);

  return (
    <>
      <Helmet>
        <title>Order History | Dinify</title>
      </Helmet>

      <Box sx={{ p: 3, height: "-webkit-fill-available" }}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          style={{ marginBottom: "30px" }}
        >
          <Link
            color="primary"
            onClick={() => navigate("/app/dashboard")}
            style={{ cursor: "pointer" }}
          >
            <Box className={classes.breadCrumbs}>
              <HomeIcon color="primary" style={{ fontSize: "15px", marginRight: "2px" }} />
              Home
            </Box>
          </Link>
          <Link
            color="primary"
            onClick={() => navigate("/app/CRM")}
            style={{ cursor: "pointer" }}
          >
            <Box className={classes.breadCrumbs}>
              <Users size={15} style={{ marginRight: "2px" }} />
              Customer Relationship Management
            </Box>
          </Link>
          <Link color="primary" style={{ cursor: "pointer" }}>
            <Box className={classes.breadCrumbs}>
              <UserPlus size={15} style={{ marginRight: "2px" }} />
              Order Statistics for {searchParams.get("user")}
            </Box>
          </Link>
        </Breadcrumbs>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h3" display="inline-flex">
            {" "}
            Orders Statistics for User {searchParams.get("user")}
            <Box sx={{ typography: "subtitle2" }} marginLeft="2px">
              [{orders.length == 1 ? "New Customer" : "Returning Customer"}]
            </Box>
          </Typography>
          {/* <Box sx={{ display: "flex" }}>
            <Box className={classes.gridWrap}>
              {orders.length > 0 && (
                <DataGrid
                  rows={rows}
                  columns={columns}
                  components={{
                    Toolbar: MyExportButton,
                  }}
                  hideFooterPagination={true}
                  hideFooter={true}
                  className={classes.dataGrid}
                />
              )}
            </Box>

            <FormControl size="small" style={{ width: "130px" }}>
              <InputLabel id="demo-controlled-open-select-label-days">
                Select Range
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label-days"
                id="demo-controlled-open-select-days"
                open={daysOpen}
                onClose={() => handleClose("days")}
                onOpen={() => handleOpen("days")}
                value={days}
                onChange={(e) => {
                  setValues({ ...values, days: e.target.value });
                }}
                className={classes.select}
                label="Select Range"
              >
                <MenuItem value={10}>10 Days</MenuItem>
                <MenuItem value={30}>30 Days</MenuItem>
                <MenuItem value={120}>4 months</MenuItem>
                <MenuItem value={240}>8 months</MenuItem>
                <MenuItem value={365}>Year</MenuItem>
                <MenuItem value={1000}>All</MenuItem>
              </Select>
            </FormControl>
          </Box> */}
        </Box>
        <Box sx={{ pl: 1 }}>
          <Box
            sx={{
              py: 2,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Grid container spacing={2}>
                <Grid item sx={3}>
                  <Box
                    className={classes.priceCard}
                    boxShadow={5}
                  // style={{backgroundColor: "#FF5757", color: "white"}}
                  >
                    <Typography variant="h4">
                      {currencySign} {totalIncome.toFixed(2)}
                    </Typography>
                    <Typography>Total Spend</Typography>
                  </Box>
                </Grid>
                <Box></Box>
              </Grid>
            </Box>
          </Box>
          <Typography style={{ marginBottom: "10px" }}>
            Showing order history for {days == 1000 ? `all orders` : `past ${days} days`}{" "}
            of user {searchParams.get("user")}
          </Typography>

          <HistoryTable orders={orders} page={page} limit={limit} progress={progress} />
        </Box>
      </Box>
    </>
  );
};

export default OrderHistory;
