import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SearchIcon from "@mui/icons-material/Search";
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Skeleton,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteFromLocal, makeDecrypt } from "src/utils/securels";
import Spinner from "src/utils/spinner/spinner";
import { makeStyles } from "tss-react/mui";
import axios from "../../axios";
import { getMainMenus } from "../../redux/actions/mainMenuActions";
import { getLiveOrders } from "../../redux/actions/orderActions";
import { acceptOrder } from "../order/helpers";
import DishScreen from "./DishScreen";
import { createUser } from "./helper";
import CategoryItemAccordion from "./CategoryItemAccordion";
import TabPanel from "../order/TabPanel";
import { getMenuCategoriesN } from "src/redux/actions/categoryActions";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      minWidth: "600px",
      minHeight: "500px",
      // padding: "30px",
    },
    itemCont: {
      // width: "90%",
      // height: "200px",
      overflow: "auto",
      padding: "10px",
      display: "flex",
      flexWrap: "wrap",
      height: "300px",
      // border: "1px solid gray",
    },
    formControl: {
      // width: "90%",
      margin: "20px auto 10px",
    },
    eachItem: {
      marginRight: "10px",
      marginBottom: "10px",
      padding: "5px 10px",
      borderRadius: "5px",
      alignContent: "center",
      color: theme.palette.text.primary,
      cursor: "pointer",
      height: "fit-content",
      boxShadow: "rgb(0 0 0 / 2%) 0px 1px 3px 0px, rgb(27 31 35 / 15%) 0px 0px 0px 1px",
    },
    eachItemSkeleton: {
      // marginRight: "10px",
      marginBottom: "10px",
      // width: "100px",
      height: "50px",
      backgroundColor: "#DFDFDE",
      borderRadius: "5px",
      animation: `$myEffect 700ms ease infinite alternate`,
    },
    "@keyframes myEffect": {
      to: {
        opacity: 0.5,
      },
    },
    searchBox: {
      borderRadius: "10px",
      boxShadow: " rgba(0, 0, 0, 0.16) 0px 1px 4px",
    },
    headerCont: {
      backgroundColor: theme.palette.primary.main,
      // display: "flex",
      padding: "10px",
    },
    nameBox: { borderRadius: "5px", boxShadow: " rgba(0, 0, 0, 0.16) 0px 1px 4px" },
    cartCont: {
      padding: "10px",
      boxShadow:
        " rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
    },
    cartHead: {
      display: "flex",
      justifyContent: "space-between",
    },
    cartItemCont: {
      boxShadow:
        "  rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
      width: "fit-content",
      minWidth: "100px",
      padding: "5px",
      margin: " auto 7px",
      position: "relative",
    },
    removeIcon: {
      position: "absolute",
      top: -10,
      right: -10,
      cursor: "pointer",
    },
  };
});

function AddItem({
  tableNo,
  tempSocket,
  setMenuScreen,
  setShowSuccess,
  newOrder,
  setNewOrder,
}) {
  const { classes } = useStyles();

  const [openDialog, setOpenDialog] = useState(true);

  const [menuItems, setMenuItems] = useState([]);
  const [searchItems, setSearchItems] = useState([]);
  const [noOfItems, setNoOfItems] = useState([]);
  const [isAddItem, setIsAddItem] = useState(false);
  const [menuId, setMenuId] = useState("");
  const [currentItem, setCurrentItem] = useState("");
  const [user, setUser] = useState("");
  const [userId, setUserId] = useState("");
  const [showSelectUser, setShowSelectUser] = useState(false);
  const [showError, setShowError] = useState(false);
  const [paymentMethodId, setPaymentMethodId] = useState();
  const { order } = useSelector((state) => state);
  const { liveOrders, reload } = order;
  const [loadingItem, setLoadingItem] = useState("");
  const [acitveCategory, setCategory] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentCartItems, setCurrentCartItems] = useState();

  const { mainMenu, selectedMainMenu } = useSelector((state) => state.mainMenu);
  const { stripeAccountId, taxTypes, dinify_print_station } = useSelector(
    (state) => state.restaurant
  );
  const { restaurant } = useSelector((state) => state);
  const { menuCategories } = useSelector((state) => state.category);

  const [showSpinner, setShowSpinner] = useState(false);

  const [selection, setSelection] = useState(false);

  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [pstValue, setPstValue] = useState();
  const [orderName, setOrderName] = useState();

  const [dinifySidePayments, setDinifySidePayments] = useState(true);
  const [useRazorpay, setUseRazorpay] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [appliedFee, setAppliedFee] = useState();
  const [categoriesByMenu, setCategoriesByMenu] = useState([]);
  const [value, setValue] = useState(0);
  const itemsPerPage = 15;

  useEffect(() => {
    dispatch(getMainMenus(navigate));
    dispatch(getMenuCategoriesN(navigate));

    axios.get(`/menu/${makeDecrypt("restaurantId")}/menu-items`).then((result) => {
      setMenuItems(result.data);
    });
  }, []);

  useEffect(() => {
    if (restaurant) {
      setDinifySidePayments(
        restaurant.dinifySidePayments === undefined ? true : restaurant.dinifySidePayments
      );
      setUseRazorpay(restaurant.restaurantAddress.country === "INDIA");
    }
  }, [restaurant]);

  useEffect(() => {
    if (newOrder && !userId && tableNo != 'takeout') {
      setOrderName("default")
    }
  }, []);

  const getItemsAsync = (key) => {
    let url = `/menu/${makeDecrypt("restaurantId")}/search-menu-item?name=${key}`;

    axios.get(url).then((results) => {
      setSearchItems(results.data);
    });
    // .then( (response) => {
    //     return response;
    // })
  };

  const handleKeyChange = (e) => {
    if (e.target.value.length > 2) {
      getItemsAsync(e.target.value);
    } else setSearchItems([]);
  };

  const handleSelectItem = async () => {
    // !newOrder && (userId ? setIsAddItem(true) : setShowError(true));
    if (newOrder && !userId) {
      // await setOrderName("default")
      if (orderName) {
        const tempUser = await createUser();
        setUser(tempUser);
        setUserId(tempUser._id);
        setIsAddItem(true);
      } else {
        setErrorMsg("Please enter user name.");
        setOpen(true);
      }
    } else {
      userId ? setIsAddItem(true) : setShowError(true);
    }
    setSelection(true);
  };

  const handleSelectUser = (userId) => {
    setUserId(userId);
    try {
      return axios
        .post(`/user/get-user-details`, {
          userId: userId,
        })
        .then(async (response) => {
          setUser(response.data);
          let paymentMethodObj = response.data.stripeDetails.paymentMethod;
          if (!paymentMethodId) {
            if (paymentMethodObj.paymentMethodId)
              setPaymentMethodId(paymentMethodObj.paymentMethodId);
            else if (paymentMethodObj.nativePaymentMethodId)
              setPaymentMethodId(paymentMethodObj.nativePaymentMethodId);
          }
        });
    } catch (error) {
      //   setAlertMessage(error.message);
    }
  };

  const cloneThePaymentMethod = async () => {
    try {
      const data = await axios.post("/stripe/clone-payment-method", {
        paymentMethodId: paymentMethodId,
        customerId: user.stripeDetails.customerId,
        stripeAccountId: stripeAccountId,
      });
      //   makeEncrypt('paymentMethodId' , data.data.paymentMethod.id)
      return data;
    } catch (error) {
      //   setAlertMessage(error.message);
    }
  };
  const completeOrderByUser = (thisPaymentIntentId) => {
    axios
      .put(
        `/order/${userId}/${makeDecrypt("restaurantId")}/${makeDecrypt(
          "orderId"
        )}/complete-order-user`,
        {
          phone: user.phone,
          paymentIntentId: thisPaymentIntentId,
        }
      )
      .then((res) => {
        deleteFromLocal("orderId");
        // if(res.data.order._id)
        // setOrderIndex(res.data.order.orderId ? res.data.order.orderId : res.data.order._id)

        // socket.current.emit("orderStatusChange", {
        //   orderId: makeDecrypt("orderId"),
        //   paymentIntentId: "",
        // });

        // if (res.data.message) setAlertMessage(res.data.message);
        // else setAlertMessage("Something went wrong");
        // setAlertOpen(true);
      })
      .catch((error) => {
        // setAlertMessage("Order Could Not Complete");
        // setAlertOpen(true);
      });
  };
  const capturePaymentIntent = (thisPaymentIntentId) => {
    axios
      .post("/stripe/capturePayment", {
        PaymentIntentId: thisPaymentIntentId,
        stripeAccountId: restaurant.stripeAccountId,
      })
      .then((res) => {
        if (res.data.status === "succeeded") {
          // this.props.history.push('/orderComplete')
          // setOpen(true);
          completeOrderByUser(thisPaymentIntentId);
          // axios
          //   .put(
          //     `/order/${userId}/${makeDecrypt("restaurantId")}/${makeDecrypt(
          //       "orderId"
          //     )}/complete-order-user`,
          //     {
          //       phone: user.phone,
          //       paymentIntentId: thisPaymentIntentId,
          //     }
          //   )
          //   .then((res) => {
          //     deleteFromLocal("orderId");
          //     // if(res.data.order._id)
          //     // setOrderIndex(res.data.order.orderId ? res.data.order.orderId : res.data.order._id)

          //     // socket.current.emit("orderStatusChange", {
          //     //   orderId: makeDecrypt("orderId"),
          //     //   paymentIntentId: "",
          //     // });

          //     // if (res.data.message) setAlertMessage(res.data.message);
          //     // else setAlertMessage("Something went wrong");
          //     // setAlertOpen(true);
          //   })
          //   .catch((error) => {
          //     // setAlertMessage("Order Could Not Complete");
          //     // setAlertOpen(true);
          //   });
        } else {
          // setAlertMessage("Order Could Not Complete");
          // setAlertOpen(true);
        }
      })
      .catch((error) => {
        //   if (error.message)
        //     setAlertMessage(error.message);
        //   else setAlertMessage("cannot capture payment");
        //   setAlertOpen(true);
      });
  };

  const getMenuItem = async (id) => {
    return await axios
      .get(`/menu/${makeDecrypt("restaurantId")}/${id}/menu-item`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        setErrorMsg(error);
        setOpen(true);
      });
  };

  const printAtTheRestaurantEnd = async (order) => {
    let dataForPrint = [];
    let count = 0;
    let temp = [];
    for (const item of order.items) {
      // order.items.map(async (item) => {
      const menuItem = await getMenuItem(item.itemId);

      if (item.isAccepted === false) {
        ++count;
        let tempModifiers = [];
        if (item.modifiers) {
          // let tempModifiers = [];
          for (const modifier of item.modifiers) {
            // item.modifiers.map((modifier) => {
            tempModifiers.push({
              type: modifier.selectedTypeId,
              options: modifier.selectedOptions.map((option) => {
                return { name: option.modifierName };
              }),
            });
          }
          // });
        }
        temp = dataForPrint;
        // dataForPrint.push({
        //   name: menuItem[0].name,
        //   quantity: item.quantity,
        //   specialInstructions: item.specialInstructions,
        //   modifiers: tempModifiers,
        // });

        if (temp.length) {
          if (
            temp.find(
              (tempItem) =>
                tempItem.printerName ===
                restaurant.printers.find((printer) => printer._id == menuItem[0].printer)
                  .defaultName
            )
          ) {
            temp[
              temp.findIndex(
                (item) =>
                  item.printerName ===
                  restaurant.printers.find(
                    (printer) => printer._id == menuItem[0].printer
                  ).defaultName
              )
            ].items.push({
              name: menuItem[0].name,
              quantity: item.quantity,
              specialInstructions: item.specialInstructions,
              modifiers: tempModifiers,
            });
          } else {
            temp.push({
              printerName: restaurant.printers.find(
                (printer) => printer._id == menuItem[0].printer
              ).defaultName,
              items: [
                {
                  name: menuItem[0].name,
                  quantity: item.quantity,
                  specialInstructions: item.specialInstructions,
                  modifiers: tempModifiers,
                },
              ],
            });
          }
        } else
          temp.push({
            printerName: restaurant.printers.find(
              (printer) => printer._id == menuItem[0].printer
            )?.defaultName,
            items: [
              {
                name: menuItem[0].name,
                quantity: item.quantity,
                specialInstructions: item.specialInstructions,
                modifiers: tempModifiers,
              },
            ],
          });

        // setDataForPrint((prevValues) => [
        //   ...prevValues ,
        //   {
        //     name:menuItem.name,
        //     quantity:item.quantity,
        //     specialInstructions:item.specialInstructions,
        //     modifiers:tempModifiers
        //   }
        // ])
        let tempLength = 0;

        temp.forEach((tem) => {
          tempLength = tem.items.length + tempLength;
        });
        if (
          order.items.filter((thisItem) => thisItem.isAccepted === false).length ===
          tempLength
        ) {
          // tableNumber = order.tableNumber;
          // setOrderForPrint(order)
          // setReadyToPrint(true)
          // setActualDataToPrint(temp)
        }
      }
      // });
    }
    doPrint(temp, order);

    // setTimeout(() => {

    //   doPrint()
    // },3000)
  };

  const doPrint = (dataForPrint, orderForPrint) => {
    dataForPrint.forEach((item) => {
      item.restaurantId = makeDecrypt("restaurantId");
      item.orderId = orderForPrint._id;
      item.orderName = orderForPrint.orderName ? orderForPrint.orderName : "";
      item.table = orderForPrint.tableNumber
        ? orderForPrint.tableNumber
        : makeDecrypt("tableNo");
      item.restaurantName = restaurant.name;
      item.timeOfCreation = orderForPrint.createdAt;
      axios
        .post(
          `/order/${item.restaurantId}/${item.orderId}/print-order?dinify_print_station=${dinify_print_station}`,
          item
        )
        .then((result) => {
          // setAlertMessage("Order successfully placed and accepted");
          // setAlertOpen(true);
          // makeEncrypt("orderId", order._id);
          // dispatch(refreshCheckout());

          !newOrder &&
            tempSocket.current.emit("added-item", {
              orderId: item.orderId,
            });
          setShowSpinner(false);
          setMenuScreen(false);
          setShowSuccess(true);
        })
        .catch((error) => {
          // setAlertMessage("Order is successfully placed and accepted but printing failed");
          // setAlertOpen(true);
        });
    });
  };

  const acceptAndPrint = (orderId) => {
    acceptOrder(orderId, false)
      .then((res) => {
        // makeEncrypt("orderId", orderId);
        if (!useRazorpay) printAtTheRestaurantEnd(res.order);
      })
      .catch((error) => {
        console.log(error)
        // setAlertMessage("Order Cannot be placed");
        // setAlertOpen(true);
      });
  };
  const makeOrder = (thisPaymentIntentId, justItems) => {
    let currentOrder = liveOrders.find((item) => item.user == userId);
    let tempPrice = 0;
    let temp = 0;
    for (const order of justItems) {
      tempPrice = tempPrice + order.order.price * order.noOfItems;

      if (order.order.pstApplicable) {
        temp =
          temp + ((order.order.price * parseFloat(taxTypes.PST)) / 100) * order.noOfItems;
      }
    }
    // justItems.map((order) => {

    // });

    let value = parseFloat((restaurant.taxTypes.GST * parseFloat(tempPrice)) / 100);
    let finalValue = parseFloat(parseFloat(tempPrice) + value).toFixed(2);

    // setPstValue(temp.toFixed(2))
    // setTotalPrice(tempPrice);

    let grandTotal = parseFloat(
      parseFloat(tempPrice) +
      (newOrder ? 0 : currentOrder.orderedByRest ? 0 : parseFloat(currentOrder.tip)) +
      parseFloat(value) +
      (currentOrder ? parseFloat(currentOrder.appliedFee) : 0) +
      parseFloat(temp)
    ).toFixed(2);

    axios
      .post(`/order/${userId}/${makeDecrypt("restaurantId")}/place-order`, {
        tableNumber: tableNo,
        email: "default@dinify.com",
        tip: !newOrder && currentOrder.tip,
        phone: !newOrder && user.phone,
        totalPrice: parseFloat(
          parseFloat(finalValue) + (dinifySidePayments ? parseFloat(appliedFee) : 0)
        ).toFixed(2),
        tax: (parseFloat(value) + parseFloat(temp)).toFixed(2),
        orderName: newOrder ? orderName : currentOrder.orderName,
        pstValue: parseFloat(temp.toFixed(2)),
        paymentDone: false,
        paymentThroughApp: !newOrder ? currentOrder.paymentThroughApp : false,
        userId: user._id,
        orderStatus: "Live",
        orderedByRest: newOrder && true,
        grandTotal: grandTotal,
        subTotal: tempPrice,
        gstValue: value,
      })
      .then((res) => {
        //Accept And Print

        acceptAndPrint(res.data.order._id);
        dispatch(getLiveOrders());
        setShowSpinner(false);
        setMenuScreen(false);
        setShowSuccess(true);
        setNewOrder(false);

        // if (res.data.order._id && currentOrder.tableNumber === "takeout") {
        //   makeEncrypt("orderId", res.data.order._id);
        //   acceptAndPrint(res.data.order._id);
        //   if (dinifySidePayments && !useRazorpay && currentOrder.paymentThroughApp)
        //     return capturePaymentIntent(thisPaymentIntentId);
        //   else {
        //     if (!useRazorpay && !dinifySidePayments && !currentOrder.paymentThroughApp)
        //       completeOrderByUser("");
        //   }
        // }

        !newOrder &&
          tempSocket.current.emit("added-item", {
            orderId: res.data.order._id,
            paymentIntentId: thisPaymentIntentId,
          });
        tempSocket.current.emit("added-item", {
          orderId: res.data.order._id,
          paymentIntentId: thisPaymentIntentId,
        });

        // if (res.data.message)
        //   setAlertMessage(res.data.message);
        // else setAlertMessage("Something went wrong");
        // setAlertOpen(true);
        // makeEncrypt("orderId", res.data.order._id);
        // setReload(!reload);

        // dispatch(refreshCheckout());

        if (user.phone) {
          sendMessage(
            parseFloat(
              finalValue +
              (restaurant.dinify_payment_plan.push_to_customer
                ? restaurant.dinify_payment_plan.application_service_fee
                : 0)
            ).toFixed(2)
          );
        }
      })
      .catch((error) => {
        // setAlertMessage("Order Cannot be placed");
        // setAlertOpen(true);
      });
  };

  let justItems = [];

  const createPaymentIntent = async (type, thisUser) => {
    try {
      let totalPrice = 0;
      let tempPst = 0;
      let currentOrder;

      for (const item of thisUser.cart.items) {
        let menuItem = await axios.get(`/menu/${item.itemId}/menu-item-detail`);
        menuItem = menuItem.data;
        justItems.push({
          order: {
            objectId: item._id,
            category: menuItem.category,
            price: item.price,
            _id: menuItem._id,
            name: menuItem.name,
            imageUrl: menuItem.imageUrl,
            pstApplicable: menuItem.pstApplicable,
            pstPercentage: menuItem.pstPercentage,
            specialInstructions: item.specialInstructions,
            modifiers: item.modifiers,
            status: menuItem.status,
          },
          noOfItems: item.quantity,
          isOrdered: item.isOrdered,
        });

        if (menuItem.pstApplicable) {
          tempPst =
            tempPst +
            ((item.price *
              (menuItem.pstPercentage
                ? menuItem.pstPercentage
                : parseFloat(restaurant.taxTypes.PST))) /
              100) *
            item.quantity;
        }

        totalPrice = parseFloat(totalPrice) + parseFloat(item.quantity * item.price);

        // if (justItems.length == response.data.cart.items.length)
      }
      currentOrder = liveOrders.find((item) => item.user == userId);
      if (newOrder || currentOrder?.orderedByRest) {
        setCurrentCartItems(justItems);
        makeOrder("", justItems);
      }
      setPstValue(tempPst.toFixed(2));

      if (!newOrder && !currentOrder?.orderedByRest) {
        axios
          .post("/stripe/create-payment-intent", {
            orderdetails: justItems,
            tip: liveOrders.find((item) => item.user == userId).tip,
            currency: "CAD",
            customerId: user.stripeDetails.customerId,
            payment_method: paymentMethodId,
            restaurantId: makeDecrypt("restaurantId"),
            description: user.email,
            stripeAccountId: stripeAccountId,
            taxValue: parseFloat(
              (restaurant.taxTypes.GST * parseFloat(totalPrice)) / 100
            ),
            pstValue: tempPst.toFixed(2),
          })
          .then((res) => {
            //   dispatch(setPaymentIntentId(res.data.id));
            //   makeEncrypt("paymentIntentId", res.data.id);
            //   socket.current.emit("orderStatusChange", {
            //     orderId: makeDecrypt("orderId"),

            let thisPaymentIntentId = res.data.id;
            //   });
            axios
              .put(`/user/${userId}/update-payment-method`, {
                expMonth: user.stripeDetails.paymentMethod.expMonth,
                expYear: user.stripeDetails.paymentMethod.expYear,
                lastDigits: user.stripeDetails.paymentMethod.lastDigits,
                paymentMethodId: paymentMethodId,
                paymentId: user.stripeDetails.paymentMethod.paymentId,
                setupIntentId: user.stripeDetails.paymentMethod.setupIntentId,
                customerId: user.stripeDetails.customerId,
                phone: user.phone,
                email: user.email,
                paymentIntentId: res.data.id,
              })
              .then(async (response) => {
                setUser(response.data);
                justItems = [];

                for (const item of response.data.cart.items) {
                  let menuItem = await axios.get(`/menu/${item.itemId}/menu-item-detail`);
                  menuItem = menuItem.data;
                  justItems.push({
                    order: {
                      objectId: item._id,
                      category: menuItem.category,
                      price: item.price,
                      _id: menuItem._id,
                      name: menuItem.name,
                      imageUrl: menuItem.imageUrl,
                      pstApplicable: menuItem.pstApplicable,
                      pstPercentage: menuItem.pstPercentage,
                      specialInstructions: item.specialInstructions,
                      modifiers: item.modifiers,
                      status: menuItem.status,
                    },
                    noOfItems: item.quantity,
                    isOrdered: item.isOrdered,
                  });

                  if (justItems.length == response.data.cart.items.length)
                    setCurrentCartItems(justItems);
                }

                //   .map(async (item) => {

                //     });
                makeOrder(thisPaymentIntentId, justItems);

                // setAlertMessage("Order placed with latest intent");
              })
              .catch((error) => {
                setErrorMsg(error);
                setOpen(true);
              });

            //   setCurrentCartItems(user.cart.items);

            //   setValues({ ...values, paymentIntentId: res.data.id });
            //   setValues({ ...values, isCompleteOrder: true });
          })
          .catch((error) => {
            //   setAlertMessage("cannot create intent:", error.message);
            //   setAlertOpen(true);
          });
      }
    } catch (error) {
      //   setAlertMessage(error.message);
      //   setAlertOpen(true);
    }
  };

  const startBillButton = async (type, thisUser) => {
    setShowSpinner(true);
    let currentOrder = liveOrders.find((item) => item.user == userId);
    if (dinifySidePayments && !useRazorpay && currentOrder?.paymentThroughApp) {
      if (user.stripeDetails.paymentMethod.paymentIntentId) {
        axios
          .post("/stripe/cancelpayment", {
            payIntId: user.stripeDetails.paymentMethod.paymentIntentId,
            stripeAccountId: stripeAccountId,
          })
          .then((response) => {
            try {
              createPaymentIntent(type, thisUser);
              // makeOrder();
              // setAlertMessage("Order placed with latest intent");
            } catch (error) {
              //     setAlertMessage(error.message);
              //    return setAlertOpen(true);
            }
          })
          .catch((error) => {
            //   setAlertMessage(error.message);
            //   setAlertOpen(true);
          });
      } else {
        try {
          createPaymentIntent(type, thisUser);
          // makeOrder();
          // setAlertMessage("Order placed with latest intent");
        } catch (error) {
          // setAlertMessage(error.message);
          // setAlertOpen(true);
        }
      }
    } else {
      setUser(thisUser);
      justItems = [];

      for (const item of thisUser.cart.items) {
        let menuItem = await axios.get(`/menu/${item.itemId}/menu-item-detail`);
        menuItem = menuItem.data;
        justItems.push({
          order: {
            objectId: item._id,
            category: menuItem.category,
            price: item.price,
            _id: menuItem._id,
            name: menuItem.name,
            imageUrl: menuItem.imageUrl,
            pstApplicable: menuItem.pstApplicable,
            pstPercentage: menuItem.pstPercentage,
            specialInstructions: item.specialInstructions,
            modifiers: item.modifiers,
            status: menuItem.status,
          },
          noOfItems: item.quantity,
          isOrdered: item.isOrdered,
        });

        if (justItems.length == thisUser.cart.items.length)
          setCurrentCartItems(justItems);
      }

      makeOrder("", justItems);
    }

    // dispatch(isAddToBill(false));
  };

  const handleCloseSnack = () => {
    setShowError(false);
    setSelection(false);
  };

  const removeCartItem = (menuItem) => {
    setLoadingItem(menuItem._id);
    axios
      .put(
        `/user/${userId}/${makeDecrypt("restaurantId")}/${menuItem.itemId
        }/delete-cart-item`,
        {
          menuItemId: menuItem.itemId,
          phone: makeDecrypt("user"),
          objectId: menuItem._id,
        }
      )
      .then((res) => {
        setCurrentCartItems(res.data.user.cart.items);
      })
      .catch((error) => { });
  };

  useEffect(() => {
    let count = 0;
    let tempOrder;
    liveOrders.map((order) => {
      if (order.tableNumber.replace(/[^\d-]/g, "") === tableNo.replace(/[^\d-]/g, "") && tableNo != 'takeout') {
        count = count + 1;
        tempOrder = order;
      }
    });
    if (count == 1) {
      handleSelectUser(tempOrder.user);
    }
  }, [liveOrders]);

  const sendMessage = async (totalAmount) => {
    let tempMsg = `Your dine-in order at ${restaurant.name
      } is placed successfully. View your order ${`https://dine.dinify.io/restaurant/menu/${restaurant._id}`}`;

    if (user.phone)
      axios.post("order/send-message", {
        userPhoneNo: user.phone,
        msg: tempMsg,
      });
  };

  useEffect(() => {
    if (restaurant)
      setAppliedFee(
        restaurant.dinify_payment_plan.push_to_customer
          ? restaurant.dinify_payment_plan.application_service_fee
          : restaurant.dinify_payment_plan.half_to_customer
            ? restaurant.dinify_payment_plan.application_service_fee / 2
            : 0
      );
  }, []);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(()=>{
    const menuCategoriesByMenu = mainMenu.map(menu => ({ name: menu.name, _id: menu._id, categories: menuCategories?.filter(cat => menu.categories.includes(cat._id)) }))
    setCategoriesByMenu(menuCategoriesByMenu)
  }, [restaurant, mainMenu])

  return (
    <Box className={classes.root}>
      {showSpinner ? (
        <Spinner />
      ) : (
        <Box>
          <Box className={classes.headerCont}>
            <Typography textAlign={"center"} style={{ color: "white" }} variant="h4">
              {tableNo === "takeout" ? "Takeout" : `Table No - ${tableNo}`}
            </Typography>
          </Box>
          <Box sx={{ px: 2 }} style={{ marginBottom: '15px' }}>
            {newOrder ? (
              tableNo == 'takeout' && (
                <Box>
                  <TextField
                    onChange={(e) => setOrderName(e.target.value)}
                    className={[classes.formControl, classes.nameBox]}
                    label="Enter customer name"
                    required
                    size="small"
                    fullWidth
                    InputProps={{
                      // Usually you don't need className, the `classes` object will
                      // be sufficient. However, you can also use it and this will
                      // add your class to the div of the InputBase.
                      classes: {
                        root: classes.nameBox,
                        focused: classes.nameBoxFocus,
                        // The list of keys you pass here depend on your variant
                        // If for example you used variant="outlined", then you need
                        // to check the CSS API of the OutlinedInput.
                      },
                    }}
                  />
                </Box>
              )
            ) : tableNo == 'takeout' && (
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel id="demo-controlled-open-select-label">
                  Select User
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  label="Select User"
                  open={showSelectUser}
                  onClose={() => setShowSelectUser(false)}
                  onOpen={() => setShowSelectUser(true)}
                  value={userId}
                  onChange={(e) => handleSelectUser(e.target.value)}
                  disabled={selection}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {liveOrders.map((order) => {
                    if (
                      order.tableNumber.replace(/[^\d-]/g, "") == tableNo ||
                      order.tableNumber == tableNo
                    ) {
                      return <MenuItem value={order.user}>{order.orderName}</MenuItem>;
                    }
                  })}
                </Select>
              </FormControl>
            )}

            {currentCartItems?.length ? (
              <Box className={classes.cartCont}>
                <Box className={classes.cartHead}>
                  <Typography variant="h4">CART ITEMS</Typography>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => startBillButton("", user)}
                  >
                    PLACE ORDER
                  </Button>
                </Box>
                <Divider style={{ margin: "10px auto" }} />
                <Box display={"flex"} flexDirection="row">
                  {currentCartItems.map((item) =>
                    !(loadingItem == item._id) ? (
                      <Box className={classes.cartItemCont}>
                        {newOrder && (
                          <Box className={classes.removeIcon}>
                            <HighlightOffIcon
                              onClick={() => {
                                removeCartItem(item);
                              }}
                            />
                          </Box>
                        )}
                        <Typography variant="h5">{item.name}</Typography>
                        <Typography variant="body2">Qty. - {item.quantity}</Typography>
                        {/* <Typography variant="body2">Note- {item.specialInstructions}</Typography> */}

                      </Box>
                    ) : (
                      <Skeleton width={100} height={80} />
                    )
                  )}
                </Box>
              </Box>
            ) : (
              ""
            )}

            {!isAddItem && (
              <Box>
                {/* <Typography variant="h6" fontSize={"23px"}>
                  Add to order
                </Typography> */}
                <Box sx={{ px: 1 }}>
                  <TextField
                    placeholder="Search Item"
                    onChange={handleKeyChange}
                    className={classes.searchBox}
                    sx={{
                      my: 1,
                    }}
                    fullWidth
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="basic tabs example">
                        {
                          categoriesByMenu?.map((singleMenu, index) => (<Tab key={index} label={singleMenu?.name} {...a11yProps(index)} />))
                        }
                      </Tabs>
                    </Box>
                    <Box className={classes.itemCont}>
                      {searchItems.length ? (
                        searchItems.map((item) => {
                          return (
                            <Button
                              variant="outlined"
                              className={classes.eachItem}
                              onClick={() => {
                                setMenuId(item._id);
                                handleSelectItem();
                                // setCategory(item.category)
                              }}
                              color="black"
                            >
                              {item.name}
                            </Button>
                          );
                        })
                      ) : categoriesByMenu.length ? (
                        <Box width={'100%'}>
                          {
                            categoriesByMenu?.map((singleMenu, index) => (
                              <TabPanel value={value} index={index}>
                                <Box >
                                  {
                                    singleMenu.categories.length === 0 && <Typography>No Items Here</Typography>
                                  }
                                  {singleMenu?.categories?.map((item, index) => {
                                    if (
                                      index >= itemsPerPage * (currentPage - 1) &&
                                      index < itemsPerPage * currentPage
                                    )
                                      return (
                                        <CategoryItemAccordion categoryData={item} key={item._id} menuItems={menuItems} setMenuId={setMenuId} handleSelectItem={handleSelectItem} />
                                      );
                                  })}
                                </Box>
                              </TabPanel>
                            ))
                          }
                        </Box>
                      ) : (
                        <>
                          {
                            [1,2,3,4, 5].map(item => <Box
                              sx={{ width: "100%" }}
                              className={classes.eachItemSkeleton}
                            ></Box>)
                          }
                        </>
                      )}
                    </Box>
                    {/* <Box sx={{ m: "auto", mt: 5, mb: 2, width: "fit-content" }}>
                      <Pagination
                        page={currentPage}
                        onChange={(e, page) => setCurrentPage(page)}
                        count={parseInt(
                          menuItems.length && menuItems.length / itemsPerPage + 1
                        )}
                        variant="outlined"
                        color="primary"
                      />
                    </Box> */}
                  </Box>
                </Box>
              </Box>
            )}
            {isAddItem && (
              <DishScreen
                menuId={menuId}
                userId={userId}
                setUserId={setUserId}
                startBillButton={startBillButton}
                user={user}
                setUser={setUser}
                setPrevPage={setIsAddItem}
                setShowSpinner={setShowSpinner}
                setSelection={setSelection}
                setMenuScreen={setMenuScreen}
                newOrder={newOrder}
                setCurrentCartItems={setCurrentCartItems}
              />
            )}

            <Snackbar
              open={showError}
              autoHideDuration={6000}
              onClose={() => handleCloseSnack()}
            >
              <Alert
                onClose={() => handleCloseSnack()}
                variant="filled"
                severity="error"
                style={{ margin: "auto" }}
              >
                Please select user!
              </Alert>
            </Snackbar>
          </Box>
        </Box>
      )}
      <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
        <Alert
          onClose={() => setOpen(false)}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default AddItem;
