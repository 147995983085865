import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import {makeStyles} from "tss-react/mui";
import "intro.js/introjs.css";
import "intro.js/themes/introjs-modern.css";
import React, {useEffect, useRef, useState} from "react";
import Draggable from "react-draggable";
import {Helmet} from "react-helmet";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {io} from "socket.io-client";
import {getReservations, updateFloorLayout} from "src/components/FloorLayout/helper";
import {getUserData} from "src/redux/actions/authActions";
import Spinner from "../utils/spinner/spinner";
import {getLiveOrders, setReload} from "src/redux/actions/orderActions";
import CloseIcon from "@mui/icons-material/Close";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import {deleteTable, updateRestaurant} from "src/redux/actions/restaurantActions";
import Table from "src/components/FloorLayout/Table";
import {makeDecrypt, makeEncrypt} from "src/utils/securels";
import {TransformWrapper, TransformComponent} from "react-zoom-pan-pinch";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "../axios";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {RiLayoutGridFill} from "react-icons/ri";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import HomeIcon from "@mui/icons-material/Home";

const useStyles = makeStyles()((theme) => {
  return {
    mainWrapper: {
      [theme.breakpoints.up("lg")]: {
        // width: "75vw",
      },
      // [theme.breakpoints.down("lg")]: {
      //   width: "95vw",
      // },
      // width: "3000px",
      height: "3000px",
    },
    btnWrapper: {
      // display: "flex",
      // width: "90vw",
    },
    actionBtn: {
      // marginRight: "20px",
    },
    table: {
      position: "absolute",
      minHeight: "75px",
      width: "75px",
      borderRadius: "3px",
      textAlign: "center",
    },
    toolBox: {
      display: "flex",
      width: "max-content",
      marginLeft: "auto",
    },
    tablesWrapper: {
      // height: "75vh",
      // [theme.breakpoints.up("lg")]: {
      //   width: "75vw",
      // },
      // [theme.breakpoints.down("lg")]: {
      //   width: "94vw",
      // },
      // padding: "10px",
      height: "3000px",
      width: "3000px",
    },
    viewBtn: {
      margin: " 10px",
      background: "white",
      padding: "5px 15px",
      borderRadius: "10px",
      boxShadow:
        "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    viewBtnIcon: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
    },
    wrapper: {
      position: "relative",
      // display: "flex",
      // justifyContent: "center",
      // alignItems: "center",
    },
    buttonProgress: {
      color: theme.palette.primary,
      position: "absolute",
      transform: "translate(-50%,-50%)",
      top: "50%",
      left: "50%",
      marginTop: "-3px",
      marginLeft: "-6px",
    },
    transformDiv: {
      width: "1500px",
      height: "1500px",
      border: "1px solid black",
    },
    heading: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
    },
    breadCrumbs: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "2px 8px ",
      borderRadius: "20px",
      backgroundColor: "#ffffff",
      border: "1px solid #FF5757",
      fontSize: "15px",
      fontWeight: "500",
      "& .MuiBreadcrumbs-separator svg": {
        fontSize: "20px !important",
      },
    },
  };
});

const options = ["VIEW TAB", "DISABLE"];

const ITEM_HEIGHT = 48;

const FloorLayout = () => {
  const {classes} = useStyles();
  const navigate = useNavigate();
  const {loading} = useSelector((state) => state.auth);
  const savedTables = useSelector((state) => state.restaurant.tables);
  const {loadingRestaurant, _id} = useSelector((state) => state.restaurant);
  const {restaurant} = useSelector((state) => state);
  const paymentPlan = useSelector(
    (state) => state.restaurant?.dinify_payment_plan?.plan_name
  );
  const dispatch = useDispatch();

  const [controlledPosition, setControlledPosition] = useState({x: 200, y: 200});
  const dragHandlers = {grid: [5, 5], scale: 1, bounds: "parent"};
  const [tables, setTables] = useState([]);
  const [isChanged, setIsChanged] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [msg, setMsg] = useState("");
  const [Reservations, setReservations] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const {order} = useSelector((state) => state);
  const {liveOrders, reload} = order;
  const {role} = useSelector((state) => state.auth);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const socket = useRef();
  const [tempSocket, setTempSocket] = useState(null);
  const [printerStatus, setPrinterStatus] = useState(false);

  const [flag, setFlag] = useState(false);

  const [occupiedTables, setOccupiedTables] = useState();

  const [incomingTable, setIncomingTable] = useState();

  const [openSnack, setOpenSnack] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [tableLimit, setTableLimit] = useState();
  const [upgradeDialog, setUpgradeDialog] = useState(false);
  const [askTableNo, setAskTableNo] = useState(false);
  const [currentTableNo, setCurrentTableNo] = useState();
  const [showOptions, setShowOptions] = useState();
  const [anchorE3, setAnchorE3] = useState();
  const [activeTable, setActiveTable] = useState();
  const [askSection, setAskSection] = useState(false);
  const [showSections, setShowSections] = useState();
  const [activeSection, setActiveSection] = useState("");
  const [sectionTables, setSectionTables] = useState([]);
  const [confirmDeleteSection, setConfirmDeleteSection] = useState(false);
  const [cloverDeviceConnected, setCloverDeviceConnected] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [newSection, setNewSection] = useState("");

  let currentPage = location.href;
  const [ReservationInfo, setReservationInfo] = useState([]);
  useEffect(() => {
    const getReservationData = async () => {
      getReservations().then((data) => {
        setReservationInfo(data.result);
      });
    };
    getReservationData();
  }, []);

  useEffect(() => {
    const makeConnection = async () => {
      // socket.current = io("wss://api.dinify.io", {transports: ["websocket"]});
      socket.current = io(process.env.REACT_APP_WEB_SOCKET_URL, {
        transports: ["websocket"],
      });
      setTempSocket(socket);
      socket.current.on("refreshOrders", (data) => {
        if (data.restaurantId == makeDecrypt("restaurantId")) {
          dispatch(setReload());
          setIncomingTable(data.tableNo);
        }
      });

      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic MzVmMDRyVHM1Q284YUdaNGNyMlhuTDg0UkZtd2FobXFoLWpGQ3ZVMkRBVTo="
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      let test = await fetch("https://api.printnode.com/printers", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result[0].state === "online") {
            setPrinterStatus(true);
            makeEncrypt("printerStatus", true);
          } else {
            setPrinterStatus(false);
            makeEncrypt("printerStatus", false);
          }
        })
        .catch((error) => {
          setErrorMsg(error);
          setOpenSnack(true);
        });

      socket.current.emit("checkPrinterConnection", makeDecrypt("restaurantId"));

      socket.current.on("printer-status", (data) => {
        if (makeDecrypt("restaurantId") == data.restaurantId) {
          if (data.online) {
            setPrinterStatus(true);
            makeEncrypt("printerStatus", true);
          } else {
            setPrinterStatus(false);
            makeEncrypt("printerStatus", false);
          }
        }
      });
    };
    makeConnection();
    // socket.current.on("tableStatus", (data) => {});
  }, []);

  useEffect(() => {
    dispatch(getLiveOrders());
  }, [dispatch, reload]);

  useEffect(() => {
    let tempTables = [];
    if (savedTables?.length) {
      savedTables.map((table) => {
        if (!table.section && activeSection == "main") tempTables.push(table);
        else if (table.section == activeSection) tempTables.push(table);
      });
    }
    setSectionTables(tempTables);
    setTables(savedTables);
  }, [savedTables, activeSection]);

  useEffect(() => {
    if (paymentPlan == "Basic Plan") {
      setTableLimit(100);
    } else if (paymentPlan == "Premium Plan") {
      setTableLimit(100);
    } else {
      setTableLimit(10000);
    }
  }, []);

  const [tablesToSave, setTablesToSave] = useState([]);
  useEffect(() => {
    setTablesToSave(tables);
  }, [tables]);

  const editPos = (e, location, tableNo) => {
    const {x, y} = location;
    const tempTables = tables;
    let tableIndex = tempTables.findIndex((table) => table.tableNo == tableNo);

    tempTables[tableIndex].posX = x;
    tempTables[tableIndex].posY = y;

    setTablesToSave(tempTables);
  };

  const addTable = () => {
    let totalTables = tables?.length;
    let duplicate = false;

    tables.map((table) => {
      if (table.tableNo == currentTableNo) duplicate = true;
    });

    if (totalTables < tableLimit && !duplicate) {
      let newTable = {
        tableNo: currentTableNo,
        posX: 0,
        posY: 0,
        isDisabled: false,
        section: activeSection,
      };
      setTables((prevTable) => [...prevTable, newTable]);
      setSectionTables((prevTable) => [...prevTable, newTable]);
      setMsg("New table has been added to top left corner!");
      handleClickSnackBar();
      setAskTableNo(false);
    } else if (duplicate) {
      setErrorMsg("This number is already taken");
      setOpenSnack(true);
    } else {
      setUpgradeDialog(true);
      setAskTableNo(false);
    }
  };

  const handleSave = () => {
    updateFloorLayout(tablesToSave, dispatch).then((data) => {
      setMsg("Layout has been saved successfully!");
      handleClickSnackBar();
      setIsDisabled(true);
    });
    makeEncrypt("layoutSaved", true);
  };

  // const deleteTable = () => {
  //   let newTable = setTables(tables.filter((table) => table.tableNo !== tables.length));

  //   handleClickWarning();
  // };

  const handleClickSnackBar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleClickWarning = () => {
    setOpenWarning(true);
  };

  const handleCloseWarning = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenWarning(false);
  };

  // useEffect(() => {
  //   liveOrders.map((order) => {
  //     if (incomingTable) {
  //       document.getElementById(incomingTable).style.backgroundColor = "#FF5757";
  //       // document.getElementById(`tableStatus${incomingTable}`).innerHTML = "VACANT";
  //       // document.getElementById(`tableStatus${incomingTable}`).style.color = "#6b778c";
  //       document.getElementById(`tableName${incomingTable}`).style.color = "#F4F6F8";
  //       document.getElementById(`tableButtons${incomingTable}`).style.display = "none";
  //     }

  //     let createdAt = new Date(restaurant.createdAt);
  //     let tempDate = new Date("May 01 2021");

  //     let occupiedTableNo;

  //     if (createdAt < tempDate)
  //       occupiedTableNo = order.tableNumber.replace(/[^\d-]/g, "");
  //     else occupiedTableNo = order.tableNumber;

  //     let occupiedTable = document.getElementById(occupiedTableNo);

  //     if (occupiedTable) {
  //       occupiedTable.style.backgroundColor = "#660708";
  //       // document.getElementById(`tableStatus${occupiedTableNo}`).innerHTML = "OCCUPIED";
  //       // document.getElementById(`tableStatus${occupiedTableNo}`).style.color = "#FF5757";
  //       // document.getElementById(`tableName${occupiedTableNo}`).style.color = "#333644";
  //       document.getElementById(`tableButtons${occupiedTableNo}`).style.display = "flex";
  //       document.getElementById(`moreMenuIcon${occupiedTableNo}`).style.display = "none";
  //       document.getElementById(`tableOrderBtn${occupiedTableNo}`).style.display = "none";
  //       document.getElementById(`totalBox${occupiedTableNo}`).style.display = "flex";
  //     }
  //   });
  // }, [liveOrders]);

  useEffect(() => {
    tables != savedTables
      ? makeEncrypt("layoutSaved", false)
      : makeEncrypt("layoutSaved", true);
  }, [tables?.length]);

  window.onbeforeunload = function () {
    if (tables?.length != savedTables?.length)
      alert("Data will be lost if you leave the page, are you sure?");
  };

  const addSection = (e) => {
    e.preventDefault();
    const newSection = e.target.sectionName.value;

    if (restaurant.sections) {
      const tempSections = [...restaurant.sections, newSection];
      dispatch(updateRestaurant({sections: tempSections}));
      setAskSection(false);
      setActiveSection(newSection);
      setNewSection("");
    }
  };
  const childSnackBar = (severity, msg) => {
    if (severity == "sucess") {
      setMsg(msg);
      setOpenSnackbar(true);
    } else {
      setErrorMsg(msg);
      setOpenSnack(true);
    }
  };

  useEffect(() => {
    if (!activeSection) {
      setActiveSection("main");
    }
  }, [restaurant]);

  const deleteSection = () => {
    let tempSections = restaurant?.sections;

    const index = tempSections.indexOf(activeSection);
    if (index > -1) {
      tempSections.splice(index, 1);
    }

    dispatch(updateRestaurant({sections: tempSections}));
    setConfirmDeleteSection(false);
    setActiveSection("main");
  };

  useEffect(() => {
    if (restaurant?.clover?.deviceId && !cloverDeviceConnected) {
      setStatusLoading(true);
      axios
        .post("/clover/check-device-connectivity", {
          deviceId: restaurant?.clover?.deviceId,
          apiToken: restaurant?.clover?.apiToken,
          restaurantName: restaurant?.name,
        })
        .then((response) => {
          setCloverDeviceConnected(true);
          setStatusLoading(false);
        })
        .catch((error) => {
          setCloverDeviceConnected(false);
          setStatusLoading(false);

          // return error;
        });
    }
  }, [restaurant]);

  return (
    <>
      <Helmet>
        <title>Dashboard | Dinify</title>
      </Helmet>

      <Box className="parentDiv">
        {loading == true && loadingRestaurant == true && order.loadingOrders == true ? (
          <Spinner />
        ) : (
          <Box
            sx={{
              backgroundColor: "background.default",
              pt: 1,
            }}
          >
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
              style={{margin: "15px"}}
            >
              <Link
                color="primary"
                onClick={() => navigate("/app/dashboard")}
                style={{cursor: "pointer"}}
              >
                <Box className={classes.breadCrumbs}>
                  <HomeIcon
                    color="primary"
                    style={{fontSize: "15px", marginRight: "2px"}}
                  />
                  Home
                </Box>
              </Link>
              <Link
                color="primary"
                // href="/app/orders"
                style={{cursor: "pointer"}}
              >
                <Box className={classes.breadCrumbs}>
                  <RiLayoutGridFill
                    color="primary"
                    style={{fontSize: "15px", marginRight: "2px"}}
                  />
                  Floor Layout
                </Box>
              </Link>
            </Breadcrumbs>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems="center"
              sx={{p: 1}}
            >
              <Box>
                <Typography variant="h3" color="textPrimary" className={classes.heading}>
                  Floor Layout
                </Typography>
              </Box>
              <Box>
                {isDisabled ? (
                  <Button
                    startIcon={<VisibilityIcon />}
                    variant="outlined"
                    onClick={() => setIsDisabled(false)}
                    // disabled={(!(role == "ROLE_ADMIN") && true) || !isDisabled}
                    size="small"
                  >
                    Options
                  </Button>
                ) : (
                  <Button
                    startIcon={<CloseIcon />}
                    variant="outlined"
                    onClick={() => {
                      setIsDisabled(true);
                      setReservations(false);
                    }}
                    // disabled={(!(role == "ROLE_ADMIN") && true) || isDisabled}
                    size="small"
                  >
                    Options
                  </Button>
                )}
              </Box>
            </Box>

            {role == "ROLE_ADMIN" && !isDisabled && (
              <Box sx={{p: 1}}>
                <Divider style={{marginBottom: "10px"}} />
                <Grid container className={classes.btnWrapper} spacing={1}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      disabled={isDisabled}
                      className={classes.actionBtn}
                      startIcon={<AddCircleIcon />}
                      onClick={() => setAskTableNo(true)}
                      size={window.innerWidth < 800 ? "small" : "medium"}
                    >
                      ADD TABLE
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      variant="outlined"
                      className={classes.actionBtn}
                      sx={{ml: "auto"}}
                      onClick={handleSave}
                      disabled={isDisabled}
                      startIcon={<SaveIcon />}
                      size={window.innerWidth < 800 ? "small" : "medium"}
                    >
                      SAVE
                    </Button>
                  </Grid>
                  <Grid item>
                    <Box>
                      <FormControl
                        className={classes.formControl}
                        label="Sections"
                        sx={{minWidth: "100px"}}
                        size={window.innerWidth < 800 ? "small" : "medium"}
                      >
                        <InputLabel htmlFor="active-section-selection">
                          Sections
                        </InputLabel>
                        <Select
                          // native
                          value={activeSection}
                          onChange={(e) => setActiveSection(e.target.value)}
                          inputProps={{
                            name: "select-active-section",
                            id: "active-section-selection",
                          }}
                          label="Sections"
                          // size={window.innerWidth < 800 ? "small" : "medium"}
                          size="small"
                        >
                          <MenuItem value={"main"}>
                            <Box>{"Main Section"}</Box>
                          </MenuItem>
                          {restaurant?.sections?.map((section) => {
                            return (
                              <MenuItem value={section} key={section}>
                                <Box display={"flex"} justifyContent="space-between">
                                  <Box>{section}</Box>
                                </Box>
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      className={classes.actionBtn}
                      startIcon={<AddCircleIcon />}
                      onClick={() => setAskSection(true)}
                      size={window.innerWidth < 800 ? "small" : "medium"}
                    >
                      ADD SECTION
                    </Button>
                  </Grid>
                  {restaurant?.sections?.length ? (
                    <Grid item>
                      <Button
                        variant="outlined"
                        className={classes.actionBtn}
                        color="error"
                        disabled={isDisabled}
                        startIcon={<DeleteIcon />}
                        onClick={() => setConfirmDeleteSection(true)}
                        size={window.innerWidth < 800 ? "small" : "medium"}
                      >
                        DELETE SECTION
                      </Button>
                    </Grid>
                  ) : (
                    ""
                  )}
                  <Grid item>
                    <Button
                      variant="outlined"
                      disabled={isDisabled}
                      className={classes.actionBtn}
                      startIcon={<BookmarkIcon />}
                      onClick={() => setReservations(true)}
                      size={window.innerWidth < 800 ? "small" : "medium"}
                    >
                      RESERVATIONS
                    </Button>
                  </Grid>
                  <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackBar}
                    anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                  >
                    <Alert
                      onClose={handleCloseSnackBar}
                      variant="filled"
                      severity="success"
                    >
                      {msg}
                    </Alert>
                  </Snackbar>
                  <Snackbar
                    open={openWarning}
                    autoHideDuration={6000}
                    onClose={handleCloseWarning}
                    anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                  >
                    <Alert onClose={handleCloseWarning} variant="filled" severity="error">
                      Table has been deleted!
                    </Alert>
                  </Snackbar>
                </Grid>
              </Box>
            )}

            <Divider />
            <Box>
              <Box
                // display="flex"
                flexDirection="column"
                height="90vh"
                style={{border: "1px solid #c9c9c9"}}
                className={classes.mainWrapper}
              >
                <Box sx={{position: "relative"}}>
                  <TransformWrapper
                    initialScale={isDisabled ? 1.5 : 1}
                    defaultPositionX={200}
                    defaultPositionY={100}
                    disabled={!isDisabled}
                    // panning={
                    //   disabled:true
                    // }
                  >
                    {({zoomIn, zoomOut, resetTransform, ...rest}) => (
                      <React.Fragment>
                        <Box display={"flex"} alignItems="center" sx={{px: 1}}>
                          {restaurant?.clover?.acceptPayment && (
                            <Box
                              display={"flex"}
                              alignItems="center"
                              width={"fit-content"}
                              sx={{my: 2}}
                            >
                              {statusLoading ? (
                                <CircularProgress size={20} />
                              ) : (
                                <FiberManualRecordIcon
                                  fontSize="small"
                                  style={{
                                    color: cloverDeviceConnected ? "green" : "gray",
                                    margin: "auto",
                                  }}
                                />
                              )}
                              <Typography sx={{ml: 1}}>
                                {statusLoading
                                  ? "Fetching device status"
                                  : cloverDeviceConnected
                                  ? "Clover device online"
                                  : "Clover device offline"}
                              </Typography>
                            </Box>
                          )}
                          <Box className={classes.toolBox}>
                            <Box className={classes.viewBtn} onClick={() => zoomIn()}>
                              <ZoomInIcon className={classes.viewBtnIcon} />
                            </Box>
                            <Box className={classes.viewBtn} onClick={() => zoomOut()}>
                              <ZoomOutIcon className={classes.viewBtnIcon} />
                            </Box>
                            <Box
                              className={classes.viewBtn}
                              onClick={() => resetTransform()}
                            >
                              <FindReplaceIcon className={classes.viewBtnIcon} />
                            </Box>
                          </Box>
                        </Box>

                        <TransformComponent className={classes.transformDiv}>
                          <div className={classes.tablesWrapper} id="refBox">
                            {sectionTables?.map((table, index) => {
                              return (
                                <Table
                                  table={table}
                                  tables={tables}
                                  setTables={setTables}
                                  isDisabled={isDisabled}
                                  key={table.tableNo}
                                  Reservations={Reservations}
                                  ReservationData={ReservationInfo}
                                  editPos={editPos}
                                  tempSocket={tempSocket}
                                  cloverDeviceConnected={cloverDeviceConnected}
                                  childSnackBar={childSnackBar}
                                />
                              );
                            })}
                          </div>
                        </TransformComponent>
                      </React.Fragment>
                    )}
                  </TransformWrapper>
                </Box>
              </Box>
            </Box>

            <Snackbar
              open={openSnack}
              autoHideDuration={6000}
              onClose={() => setOpenSnack(false)}
            >
              <Alert
                onClose={() => setOpenSnack(false)}
                severity="error"
                variant="filled"
                sx={{width: "100%"}}
              >
                {errorMsg}
              </Alert>
            </Snackbar>
            <Dialog
              open={upgradeDialog}
              // TransitionComponent={Transition}
              keepMounted
              onClose={() => setUpgradeDialog(false)}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title" variant="h2">
                Upgrade Plan
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Your current plan contains limited number of tables. Upgrade plan to add
                  more.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setUpgradeDialog(false);
                    handleSave();
                  }}
                  color="primary"
                >
                  Later
                </Button>
                <Button
                  variant="contained"
                  onClick={() => navigate("/app/manageaccount", {replace: true})}
                  color="primary"
                >
                  Upgrade Now
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={askTableNo}
              // TransitionComponent={Transition}
              keepMounted
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <Box sx={{p: 2}}>
                <Typography variant="h4"> Enter Table Number</Typography>
                <Divider style={{margin: "5px 0"}} />
                <Box sx={{mt: 2, p: 1}}>
                  <TextField
                    label="Table Number"
                    value={currentTableNo}
                    onChange={(e) => setCurrentTableNo(e.target.value)}
                  />
                </Box>
                <Box display={"flex"} justifyContent={"right"} sx={{mt: 1, p: 1}}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setAskTableNo(false);
                    }}
                    color="primary"
                    style={{marginRight: "10px"}}
                  >
                    CANCEL
                  </Button>
                  <Button variant="contained" onClick={() => addTable()} color="primary">
                    ADD
                  </Button>
                </Box>
              </Box>
            </Dialog>

            <Dialog
              open={askSection}
              // TransitionComponent={Transition}
              keepMounted
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <Box sx={{p: 2}}>
                <Typography variant="h4"> Enter Section Name</Typography>
                <Divider style={{margin: "5px 0"}} />
                <form onSubmit={addSection}>
                  <Box sx={{mt: 2, p: 1}}>
                    <TextField
                      label="Section Name"
                      name="sectionName"
                      value={newSection}
                      onChange={({target: {value}}) => setNewSection(value)}
                    />
                  </Box>
                  <Box display={"flex"} justifyContent={"right"} sx={{mt: 1, p: 1}}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setAskSection(false);
                      }}
                      color="primary"
                      style={{marginRight: "10px"}}
                    >
                      CANCEL
                    </Button>
                    <Button variant="contained" type="submit" color="primary">
                      ADD
                    </Button>
                  </Box>
                </form>
              </Box>
            </Dialog>

            <Dialog
              open={confirmDeleteSection}
              keepMounted
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <Box sx={{p: 2}}>
                <Typography variant="h4">Select Section to delete.</Typography>
                <Box sx={{my: 2}}>
                  <FormControl
                    className={classes.formControl}
                    label="Sections"
                    sx={{minWidth: "100px"}}
                    size={window.innerWidth < 800 ? "small" : "medium"}
                    fullWidth
                  >
                    <InputLabel htmlFor="deletion-active-selection">Sections</InputLabel>
                    <Select
                      // native
                      value={activeSection}
                      onChange={(e) => setActiveSection(e.target.value)}
                      inputProps={{
                        name: "select-active-section",
                        id: "deletion-active-selection",
                      }}
                      label="Sections"
                      // size={window.innerWidth < 800 ? "small" : "medium"}
                      size="small"
                    >
                      <MenuItem value={"main"}>
                        <Box>None</Box>
                      </MenuItem>
                      {restaurant?.sections?.map((section) => {
                        return (
                          <MenuItem value={section} key={section}>
                            <Box display={"flex"} justifyContent="space-between">
                              <Box>{section}</Box>
                            </Box>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
                <Divider style={{margin: "5px 0"}} />
                <Box display={"flex"} justifyContent={"right"} sx={{mt: 1, p: 1}}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setConfirmDeleteSection(false);
                    }}
                    color="error"
                    style={{marginRight: "10px"}}
                  >
                    CANCEL
                  </Button>
                  <Button variant="contained" onClick={deleteSection} color="error">
                    DELETE
                  </Button>
                </Box>
              </Box>
            </Dialog>
          </Box>
        )}
      </Box>
    </>
  );
};

export default FloorLayout;
