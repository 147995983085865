import React from "react";
import {Box} from "@mui/material";
import OnBoardingProfile from "src/components/account/OnBoardingProfile";
import {makeStyles} from "tss-react/mui";
const useStyles = makeStyles()((theme) => {
  return {
    root: {
      padding: "30px",
      minHeight: "500px",
    },
    radioWrapper: {
      marginBottom: "20px",
      padding: "10px",
    },
    planCard: {
      width: "150px",
      backgroundColor: "#FFF",
      margin: "20px",
      padding: "10px",
      color: "#000",
    },
    cardWrrpper: {
      display: "flex",
      flexDirection: "row",
    },
  };
});

export default function RestDetails(props) {
  const {values} = props;
  const {setValues} = props;

  const {classes} = useStyles();

  return (
    <>
      <Box className={classes.root} boxShadow="3">
        {/* <OnBoardingProfile logoImage={logoImage} setLogoImage={setLogoImage}/> */}
        <OnBoardingProfile values={values} setValues={setValues} />
      </Box>
    </>
  );
}
