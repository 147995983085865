import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  FormControl,
  Pagination,
  TextField,
  Typography,
  Button,
  TextareaAutosize,
  Dialog,
  Divider,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";

import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { io } from "socket.io-client";
import axios from "src/axios";
import AlertMsg from "src/components/AlertMsg";
import { getAllModifiers } from "src/components/product/helpers";
import MenuListResults from "src/components/product/MenuListResults";
import MenuListToolbar from "src/components/product/MenuListToolbar";
import ProductListToolbar from "src/components/product/ProductListToolbar";
import { getUserData } from "src/redux/actions/authActions";
import { getMainMenus } from "src/redux/actions/mainMenuActions";
import {
  DESELECT_CATEGORY,
  ERASE_MENU,
  LOADING_COMPLETED,
  LOADING_MENU,
  LOADING_NOT_COMPLETED,
} from "src/redux/types";
import { makeDecrypt } from "src/utils/securels";
import { getMenuCategories, updateRestaurant } from "../redux/actions/restaurantActions";
import Spinner from "../utils/spinner/spinner";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { getDailySpecials } from "src/redux/actions/menuActions";
import Discount from "src/components/product/discount/Discount";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import CategoryIcon from "@mui/icons-material/Category";
import { makeStyles } from "tss-react/mui";
import HomeIcon from "@mui/icons-material/Home";
import { getMenuCategoriesN } from "src/redux/actions/categoryActions";

const useStyles = makeStyles()((theme) => {
  return {
    breadCrumbs: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "2px 8px ",
      borderRadius: "20px",
      backgroundColor: "#ffffff",
      border: "1px solid #FF5757",
      fontSize: "15px",
      fontWeight: "500",
      "& .MuiBreadcrumbs-separator svg": {
        fontSize: "20px !important",
      },
    },
  };
});

const ProductList = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const [search, setSearch] = useState('');

  const [tempSocket, setTempSocket] = useState(null);
  const socket = useRef();
  const { selectedCategory } = useSelector((state) => state.menu);
  // const handleSearch = event => {
  //   setSearch(event.target.value)
  // }

  const { authenticated, loading } = useSelector((state) => state.auth);
  const { loadingRestaurant, _id } = useSelector(
    (state) => state.restaurant
  );
  const { loadingMenu, menu } = useSelector((state) => state.menu);
  const { mainMenu, selectedMainMenu } = useSelector((state) => state.mainMenu);
  const { menuCategories } = useSelector((state) => state.category);
  const { restaurant } = useSelector((state) => state);
  const { reload } = useSelector((state) => state.order);
  const [thisReload, setThisReload] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [menuItemsLoader, setMenuItemsLoader] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);

  const [values, setValues] = useState({
    page: 1,
    limit: 5,
    count: 1,
    searchQuery: "",
  });
  const { page, limit, count, searchQuery } = values;

  const onboarded = makeDecrypt("onboarded") == true ? true : false;
  const isVerified = makeDecrypt("verified") == true ? true : false;
  const [showChefMesssage, setShowChefMesssage] = useState(false);

  useEffect(() => {
    // dispatch(getMenuItems())
    // dispatch(getUserData(navigate, true));
    // dispatch(getMenuCategories(navigate));
    dispatch(getMenuCategoriesN(navigate));
    dispatch(getMainMenus(navigate, selectedMainMenu));
    dispatch(getAllModifiers(_id));
    if (!authenticated) {
      navigate("/login", { replace: true });
    }
    if (onboarded == false && loading == false) {
      navigate("/restaurant/onboarding", { replace: true });
    } else if (isVerified == false) {
      navigate("/verification/pendingVerification", { replace: true });
    }
  }, [thisReload, _id]);

  const [currentCategories, setCurrentCategories] = useState([]);

  useEffect(() => {
    if (mainMenu.length && selectedMainMenu) {
      let thisMenu = mainMenu.find((men) => men.name == selectedMainMenu);
      // console.log(thisMenu, thisMenu.restaurant)
      if (thisMenu)
        setCurrentCategories(
          menuCategories.filter((category) => {
            return thisMenu.categories.find((cate) => cate === category._id);
          })
        );
    }
    if (!mainMenu.length) setCurrentCategories([]);
  }, [mainMenu, selectedMainMenu]);

  useEffect(() => {
    if (currentCategories.length) {
      if (
        selectedCategory &&
        currentCategories.some((currentCat) => currentCat.name === selectedCategory)
      )
        dispatch({
          type: "SELECTED_CATEGORY",
          payload: selectedCategory,
        });
      else
        dispatch({
          type: "SELECTED_CATEGORY",
          payload: currentCategories[0].name,
        });
    } else {
      dispatch({
        type: "SELECTED_CATEGORY",
        payload: "none",
      });
    }
  }, [currentCategories]);

  useEffect(() => {
    if (selectedCategory) {
      if (selectedCategory == "none") {
        dispatch({ type: ERASE_MENU });
      } else {
        dispatch({ type: ERASE_MENU });
        dispatch({ type: LOADING_MENU });
        dispatch({ type: LOADING_NOT_COMPLETED });
        axios
          .get(
            `/menu/${makeDecrypt("employeeId")}/${makeDecrypt(
              "restaurantId"
            )}/${selectedCategory}/menuItems?page=${page}&limit=${limit}&name=${searchQuery ? searchQuery : ""
            }`,
            { headers: { Authorization: makeDecrypt("jwt") } }
          )
          .then((res) => {
            setValues({ ...values, count: res.data.maxPages });
            dispatch({
              type: "GET_ALL_MENU",
              payload: res.data.result,
            });
            dispatch({ type: LOADING_COMPLETED });
          })
          .catch((error) => setAlertMessage(error.message));
      }
    }
  }, [page, limit, selectedCategory, searchQuery, _id]);

  useEffect(() => {
    //socket connection
    const makeConnection = () => {
      // socket.current = io("wss://api.dinify.io", {transports: ["websocket"]});
      socket.current = io(process.env.REACT_APP_WEB_SOCKET_URL, {
        transports: ["websocket"],
      });

      setTempSocket(socket);
    };
    makeConnection();
    // menuCategories.sort((a, b) => a.sequence.localeCompare(b.sequence));
  }, []);

  const handleChange = (val) => {
    setValues({ ...values, page: val });
  };

  useEffect(() => {
    if (restaurant) dispatch(getDailySpecials(restaurant.specialDish));
  }, [restaurant._id]);

  const handleDeselectMainMenu = () => {
    dispatch({
      type: "DESELECT_MAIN_MENU",
    });
    dispatch({
      type: DESELECT_CATEGORY,
    });
    if (restaurant) dispatch(getDailySpecials(restaurant.specialDish));
    window.history.replaceState({}, document.title, "/" + "app/products");
  };

  return (
    <>
      <Helmet>
        <title>Menu Management | Dinify</title>
      </Helmet>
      {(loading == true && loadingMenu == true && loadingRestaurant == true) ||
        loading == true ||
        loadingRestaurant == true ? (
        <Spinner />
      ) : (
        <Box
          sx={{
            backgroundColor: "background.default",
            minHeight: "100%",
            py: 3,
          }}
        >
          <Container maxWidth={false}>
            <Box>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <Link
                  color="primary"
                  onClick={() => navigate("/app/dashboard")}
                  style={{ cursor: "pointer" }}
                >
                  <Box className={classes.breadCrumbs}>
                    <HomeIcon
                      color="primary"
                      style={{ fontSize: "15px", marginRight: "2px" }}
                    />
                    Home
                  </Box>
                </Link>
                <Link
                  color="primary"
                  // href="/app/products"
                  style={{ cursor: "pointer" }}
                  onClick={handleDeselectMainMenu}
                >
                  <Box className={classes.breadCrumbs}>
                    <RestaurantMenuIcon
                      color="primary"
                      style={{ fontSize: "15px", marginRight: "2px" }}
                    />
                    Menu
                  </Box>
                </Link>
                {selectedMainMenu ? (
                  <Box className={classes.breadCrumbs}>
                    <CategoryIcon
                      color="primary"
                      style={{ fontSize: "15px", marginRight: "2px" }}
                    />
                    <Typography color="primary" style={{ fontSize: "15px" }}>
                      {selectedMainMenu}
                    </Typography>
                  </Box>
                ) : (
                  ""
                )}
              </Breadcrumbs>
            </Box>

            {!selectedMainMenu ? (
              <Box sx={{ width: "fit-content", mt: 3 }}>
                <Button onClick={() => setShowChefMesssage(true)} variant="outlined">
                  Chef Message
                </Button>
              </Box>
            ) : (
              ""
            )}

            {!selectedMainMenu ? (
              <>
                <MenuListToolbar
                  menus={mainMenu}
                  tempSocket={tempSocket}
                  setThisReload={setThisReload}
                  thisReload={thisReload}
                  setShowBackdrop={setShowBackdrop}
                />
                <Discount />
              </>
            ) : (
              ""
            )}

            {selectedMainMenu ? (
              <>
                <ProductListToolbar
                  menuCategories={currentCategories}
                  tempSocket={tempSocket}
                  setThisReload={setThisReload}
                  thisReload={thisReload}
                  setCurrentCategories={setCurrentCategories}
                  setMenuItemsLoader={setMenuItemsLoader}
                  setShowBackdrop={setShowBackdrop}
                  page={page}
                  limit={limit}
                  selectedMainMenu={selectedMainMenu}
                />
              </>
            ) : (
              ""
            )}
            <Box sx={{ pt: 3 }}>
              {currentCategories.length && tempSocket ? (
                <MenuListResults
                  tempSocket={tempSocket}
                  rows={limit}
                  values={values}
                  setValues={setValues}
                  thisReload={thisReload}
                  setThisReload={setThisReload}
                  setMenuItemsLoader={setMenuItemsLoader}
                  menuItemsLoader={menuItemsLoader}
                  setShowBackdrop={setShowBackdrop}
                  selectedMainMenu={selectedMainMenu}
                />
              ) : (
                <MenuListResults
                  tempSocket={tempSocket}
                  rows={limit}
                  values={values}
                  setValues={setValues}
                  thisReload={thisReload}
                  setThisReload={setThisReload}
                  setMenuItemsLoader={setMenuItemsLoader}
                  menuItemsLoader={menuItemsLoader}
                  setShowBackdrop={setShowBackdrop}
                  selectedMainMenu={selectedMainMenu}
                />
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                pt: 3,
              }}
            >
              <Pagination
                color="primary"
                count={count}
                size="small"
                onChange={(e, val) => handleChange(val)}
              />
            </Box>
            <Backdrop
              open={showBackdrop}
            // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Container>
        </Box>
      )}
      <AlertMsg alertMessage={alertMessage} />

      <Dialog open={showChefMesssage}>
        <Formik
          initialValues={{
            chefMessage: restaurant?.chefMessage ? restaurant.chefMessage : "",
          }}
          validationSchema={Yup.object().shape({
            chefMessage: Yup.string("Please enter chef message").required(
              "Please enter chef message"
            ),
          })}
          onSubmit={(values, { setSubmitting, setFieldError, setErrors, setStatus }) => {
            let chefMessage = values.chefMessage;
            dispatch(updateRestaurant({ chefMessage: chefMessage }))
              .then(() => {
                setSubmitting(false);
              })
              .catch((error) => console.log(error));
          }}
        >
          {({
            errors,
            error,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              {error && <div>{error}</div>}
              <Box sx={{ p: 2, position: "relative", minWidth: "400px" }}>
                <Box
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    width: "fit-content",
                  }}
                >
                  <CloseIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowChefMesssage(false)}
                  />
                </Box>
                <Typography variant="h4">Chef Message</Typography>
                <Divider />
                <Box sx={{ mt: 2 }}>
                  <TextareaAutosize
                    aria-label="empty textarea"
                    placeholder="Chef Message"
                    error={Boolean(touched.chefMessage && errors.chefMessage)}
                    helperText={touched.chefMessage && errors.chefMessage}
                    label="Chef Message"
                    name="chefMessage"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.chefMessage}
                    minRows={10}
                    style={{ borderRadius: "5px", padding: "10px", width: "100%" }}
                  />
                </Box>
                <Box sx={{ py: 2, position: "relative" }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </Button>
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: -12,
                        marginLeft: -12,
                      }}
                    />
                  )}
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default ProductList;
