//user reducers
export const LOADING_USER = "LOADING_USER";
export const SET_USER = "SET_USER";
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_ONBOARDED = "SET_ONBOARDED";
export const EDIT_USER = "EDIT_USER";

//employee reducers
export const LOADING_EMPLOYEE = "LOADING_EMPLOYEE";
export const GET_EMPLOYEE = "GET_EMPLOYEE";
export const ADD_EMPLOYEE = "ADD_EMPLOYEE";
export const GET_ALL_EMPLOYEES = "GET_ALL_EMPLOYEES";
export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";

//restaurant
export const LOADING_RESTAURANT = "LOADING_RESTAURANT";
export const SET_RESTAURANT = "SET_RESTAURANT";
export const UPDATE_RESTAURANT = "UPDATE_RESTAURANT";
export const SET_RESTAURANT_VERIFIED = "SET_RESTAURANT_VERIFIED";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const ADD_PRINTER = "ADD_PRINTER";
export const SET_THROTTLING = "SET_THROTTLING";
export const DELETE_TABLE = "DELETE_TABLE";
export const SET_TABLE = "SET_TABLE";
export const UPDATE_TABLE = "UPDATE_TABLE";

//menu
export const LOADING_MENU = "LOADING_MENU";
export const STOP_LOADING_MENU = "STOP_LOADING_MENU";
export const LOADING_COMPLETED = "LOADING_COMPLETED";
export const LOADING_NOT_COMPLETED = "LOADING_NOT_COMPLETED";
export const ADD_MENU = "ADD_MENU";
export const DELETE_MENU = "DELETE_MENU";
export const ERASE_MENU = "ERASE_MENU";
export const UPDATE_MENU = "UPDATE_MENU";
export const GET_ALL_MENU = "GET_ALL_MENU";
export const SELECTED_CATEGORY = "SELECTED_CATEGORY";
export const DESELECT_CATEGORY = "DESELECT_CATEGORY";

//mainMenu
export const MAIN_MENU_LOADING = "MAIN_MENU_LOADING";
export const UPDATE_MAIN_MENU = "UPDATE_MAIN_MENU";
export const MAIN_MENU_LOADING_COMPLETED = "MAIN_MENU_LOADING_COMPLETED";
export const MAIN_MENU_LOADING_NOT_COMPLETED = "MAIN_MENU_LOADING_NOT_COMPLETED";
export const CATEGORY_LOADING = "CATEGORY_LOADING";
export const STOP_CATEGORY_LOADING = "STOP_CATEGORY_LOADING";
export const STATUS_LOADING = "STATUS_LOADING";

//menuCategories
export const ADD_N_CATEGORY = "ADD_CATEGORY";
export const DELETE_N_CATEGORY = "DELETE_CATEGORY";
export const UPDATE_N_CATEGORY = "UPDATE_CATEGORY";