import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from 'tss-react/mui';
import { useState, useEffect } from 'react';
const useStyles = makeStyles()((theme) => {
    return {
        itemCont: {
            overflow: "auto",
            padding: "10px 10px 0 10px",
            display: "flex",
            flexWrap: "wrap",
        },
        eachItem: {
            marginRight: "10px",
            marginBottom: "10px",
            padding: "5px 10px",
            borderRadius: "5px",
            alignContent: "center",
            color: theme.palette.text.primary,
            cursor: "pointer",
            height: "fit-content",
            boxShadow: "rgb(0 0 0 / 2%) 0px 1px 3px 0px, rgb(27 31 35 / 15%) 0px 0px 0px 1px",
        },
        summary: {
            minHeight: "50px",
        }
    };
});


const CategoryItemAccordion = ({ categoryData, setMenuId, menuItems, handleSelectItem }) => {
    const [items, setItems] = useState([]);
    const { classes } = useStyles();
    const { name } = categoryData;
    useEffect(() => {
        const items = menuItems?.filter(item => item.category === name);
        setItems(items)
    }, [name, menuItems])
    return (
        <Accordion>
            <AccordionSummary
                className={classes.summary}
                aria-setsize={'50px'}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)', padding: '0px 15px', minHeight: '50px', height: '20px' }}
            >
                <Typography style={{ padding: '0px' }}>{name.toUpperCase()}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: "0px" }}>
                <Box className={classes.itemCont}>
                    {
                        items?.length > 0 ? items?.map(item => <Box
                            className={classes.eachItem}
                            onClick={() => {
                                setMenuId(item._id);
                                handleSelectItem();
                                // setCategory(item.category)
                            }}
                        >
                            <Typography>{item?.name}</Typography>
                        </Box>)
                        : <Typography style={{marginBottom: "10px"}}>No items under this category!</Typography>
                    }
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}

export default CategoryItemAccordion