import {
  ADD_MENU,
  DELETE_MENU,
  DESELECT_CATEGORY,
  ERASE_MENU,
  GET_ALL_MENU,
  LOADING_COMPLETED,
  LOADING_MENU,
  LOADING_NOT_COMPLETED,
  SELECTED_CATEGORY,
  STOP_LOADING_MENU,
  UPDATE_MENU,
} from "../types";

const initialState = {
  menu: [
    {
      name: "",
      category: "",
      description: "",
      imageUrl: "",
      price: 0,
      status: "Online",
      allergens: [],
      availability: "",
      diet: [],
      tags: [],
      _id: "",
      discount: "",
      instagramVideoUrl: "",
      tiktokVideoUrl: "",
      facebookVideoUrl: "",
    },
  ],
  loadingMenu: true,
  loadingCompleted: false,
  selectedCategory: "",
  allModifiers: [],
};

export default function menuReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_MENU:
      return {
        ...state,
        loadingMenu: true,
      };
    case STOP_LOADING_MENU:
      return {
        ...state,
        loadingMenu: false,
      };
    case ADD_MENU:
      return {
        ...state,
        menu: [...state.menu, action.payload],
        loadingMenu: false,
        loadingCompleted: true,
      };
    case SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: action.payload,
        loadingMenu: false,
      };
    case DESELECT_CATEGORY:
      return {
        ...state,
        selectedCategory: null,
        loadingMenu: false,
      };
    case GET_ALL_MENU:
      return {
        ...state,
        menu: action.payload.map((menuItem) => menuItem),
        loadingMenu: false,
      };
    case UPDATE_MENU:
      return {
        ...state,
        menu: [
          ...state.menu.map((menuItem) =>
            menuItem._id == action.payload._id ? {...action.payload} : menuItem
          ),
        ],
        loadingMenu: false,
        loadingCompleted: true,
      };
    case DELETE_MENU:
      return {
        ...state,
        menu: state.menu.filter((menuItem) => menuItem._id !== action.payload._id),
        loadingMenu: false,
        loadingCompleted: true,
      };
    case "GET_ALL_MODIFIERS":
      return {
        ...state,
        allModifiers: action.payload,
      };
    case ERASE_MENU:
      return {
        ...state,
        menu: [],
      };
    case LOADING_COMPLETED:
      return {
        ...state,
        loadingCompleted: true,
      };
    case LOADING_NOT_COMPLETED:
      return {
        ...state,
        loadingCompleted: false,
      };
    default:
      return state;
  }
}
