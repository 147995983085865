import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import React, {useEffect, useState} from "react";
import AddItem from "./AddItem";
import TableActions from "./TableActions";
import {useRef} from "react";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import Draggable from "react-draggable";
import CloseIcon from "@mui/icons-material/Close";
import { io } from "socket.io-client";
import OrderModal from "../order/OrderModal";
import {makeDecrypt} from "src/utils/securels";
import {
  acceptOrder,
  cancelOrder,
  capturePayment,
  completeOrder,
  getMenuItem,
  getUser,
  paymentRequestToClover,
  printTheReceipt,
} from "../order/helpers";
import {getLiveOrders, setReload} from "src/redux/actions/orderActions";
import axios from "../../axios";
const _ = require("lodash");
import async from "async";
import clsx from "clsx";
const useStyles = makeStyles()(() => {
  return {
    table: {
      position: "absolute",
      minHeight: "75px",
      width: "75px",
      borderRadius: "3px",
      textAlign: "center",
    },
    viewBtn: {
      margin: " 10px",
    },
    wrapper: {
      position: "relative",
    },
  };
});

function Table({
  table,
  tables,
  setTables,
  isDisabled,
  editPos,
  Reservations,
  ReservationData,
  tempSocket,
  incomingTable,
  cloverDeviceConnected,
  childSnackBar,
}) {
  const {classes} = useStyles();

  const navigate = useNavigate();
  const {role} = useSelector((state) => state.auth);
  const dragHandlers = {grid: [5, 5], scale: 1, bounds: "parent"};
  const [addItemBtnLoading, setAddItemBtnLoading] = useState();
  const [menuScreen, setMenuScreen] = useState(false);
  const [tableNo, setTableNo] = useState("");
  const [manageBtnLoading, setManageBtnLoading] = useState();
  const [statusBtnLoading, setStatusBtnLoading] = useState(false);
  const [newOrder, setNewOrder] = useState();
  const [showSuccess, setShowSuccess] = useState(false);
  const [occupied, setOccupied] = useState();
  const [tableTotal, setTableTotal] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [activeOrder, setActiveOrder] = useState(null);
  const [showOrders, setShowOrders] = useState(false);
  const [openInnerModal, setOpenInnerModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [rows2, setRows2] = useState([]);
  const [acceptBtnLoading, setAcceptBtnLoading] = useState(false);
  const [completeBtnLoading, setCompleteBtnLoading] = useState(false);
  const [dinifySidePayments, setDinifySidePayments] = useState(true);
  const socket = useRef();
  const [newSocket, setNewSocket] = useState(null);
  const {restaurant} = useSelector((state) => state);
  const [alertMessage, setAlertMessage] = useState("");
  const {waitingOrders, reload, liveOrders} = useSelector((state) => state.order);
  const [useRazorpay, setUseRazorpay] = useState(false);
  const [dataForPrint, setDataForPrint] = useState([]);
  const [errorSnack, setErrorSnack] = useState(false);
  const [askConfirmation, setAskConfirmation] = useState(false);
  const [showCloverAlert, setShowCloverAlert] = useState(false);
  const [tableOrders, setTableOrders] = useState([]);
  const [isMerged, setIsMerged] = useState(false);
  let {stripeAccountId, dinify_print_station, name, printers} = useSelector(
    (state) => state.restaurant
  );

  const dispatch = useDispatch();

  useEffect(() => {
    !menuScreen && setAddItemBtnLoading();
  }, [menuScreen]);
  useEffect(() => {
    const makeConnection = async () => {
      // socket.current = io("wss://api.dinify.io", {transports: ["websocket"]});
      socket.current = io(process.env.REACT_APP_WEB_SOCKET_URL, {
        transports: ["websocket"],
      });
      //       socket.current = io("ws://localhost:3003");
      setNewSocket(socket);
      socket.current.on("ready-to-serve", (data) => {
        if (makeDecrypt("restaurantId") === data.restaurantId) {
          const currentTable = document.getElementById(`tableCard${data.tableNo}`);
          showActiveOrder(currentTable);
        }
      });
    };
    makeConnection();
  }, []);
  useEffect(() => {
    if (restaurant) {
      setDinifySidePayments(
        restaurant.dinifySidePayments === undefined ? true : restaurant.dinifySidePayments
      );
      setUseRazorpay(restaurant.restaurantAddress.country === "INDIA");
    }
  }, [restaurant]);

  useEffect(() => {
    setOccupied(liveOrders.some((order) => order.tableNumber == table.tableNo));
  }, [liveOrders]);

  useEffect(() => {
    if (incomingTable == table.tableNo) setOccupied(true);
  }, [incomingTable]);

  useEffect(() => {
    if (occupied) {
      let temp = 0;
      liveOrders.map((order) => {
        if (order.tableNumber == table.tableNo) temp += order.grandTotal;
      });
      setTableTotal(temp);
    }
  }, [occupied, liveOrders]);

  useEffect(() => {
    if (rows.length === activeOrder?.items.length) {
      const newRow = [];
      rows.map((row) => {
        if (newRow.some((eachRow) => eachRow.desc === row.desc)) {
          newRow.forEach((item) => {
            if (item.desc === row.desc) {
              item.qty = item.qty + row.qty;
              item.unit = item.unit + row.unit;
              item.price = item.price + row.price;
            }
          });
        } else newRow.push(row);
      });
      if (JSON.stringify(newRow) != JSON.stringify(rows)) {
        setRows(newRow);
      }
    } else if (rows.length === 0 && activeOrder?.items.length) {
      setTableData(activeOrder);
    }
  }, [rows]);

  useEffect(() => {
    if (activeOrder) {
      setRows([]);
      setRows2([]);
      setTableData(activeOrder);
    }
  }, [activeOrder]);

  const handleManage = () => {
    let temp = 0;
    liveOrders.map((order, index) => {
      if (order.tableNumber == table.tableNo) {
        temp += 1;
      }
    });
    let tempTableOrders = liveOrders.filter(
      (order) => order.tableNumber == table.tableNo
    );
    setTableOrders(tempTableOrders);
    if (temp > 1) {
      // setShowOrders(true);
      setActiveOrder(tempTableOrders[0]);
      setShowModal(true);
    } else {
      setActiveOrder(liveOrders.find((order) => order.tableNumber == table.tableNo));
      setShowModal(true);
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setActiveOrder(null);
    setRows([]);
    setRows2([]);
    setIsMerged(false);
    setCompleteBtnLoading(false);
    // if (setInnerModalOpen) {
    //   setInnerModalOpen(false);
    // }
    setCompleteBtnLoading(false);
  };
  const handleComplete = async (id) => {
    setCompleteBtnLoading(true);
    try {
      if (
        dinifySidePayments &&
        restaurant.restaurantAddress.country?.toLowerCase() !== "india"
      ) {
        activeOrder?.orderedByRest ||
        activeOrder?.paymentDone ||
        !activeOrder?.paymentThroughApp
          ? completeOrderOnly(id)
          : getPayment(id, false);
      } else {
        if (
          restaurant.restaurantAddress.country?.toLowerCase() == "canada" &&
          restaurant.clover?.acceptPayment
        ) {
          if (cloverDeviceConnected) requestPaymetToClover(id);
          else {
            setShowCloverAlert(true);
            completeOrder(id, "")
              .then((data) => {
                makeOrderLive();
                newSocket.current.emit("complete-order", {
                  orderId: activeOrder._id,
                  seqValue: activeOrder.seqValue,
                });
                newSocket.current.emit("orderStatus", {
                  id: activeOrder._id,
                  status: "Completed",
                  restaurantId: makeDecrypt("restaurantId"),
                });
                printTheReceipt(activeOrder._id, {
                  items: rows,
                  activeOrder,
                });
                dispatch(setReload());
                setCompleteBtnLoading(false);
                setAlertMessage("Order completed");
                // setSuccessSnack(true);
              })
              .catch((error) => {
                setAlertMessage(error.message);
                setCompleteBtnLoading(false);
              });
          }
        } else {
          completeOrder(id, "")
            .then((data) => {
              makeOrderLive();
              newSocket.current.emit("complete-order", {
                orderId: activeOrder._id,
                seqValue: activeOrder.seqValue,
              });
              newSocket.current.emit("orderStatus", {
                id: activeOrder._id,
                status: "Completed",
                restaurantId: makeDecrypt("restaurantId"),
              });
              printTheReceipt(activeOrder._id, {
                items: rows,
                activeOrder,
              });
              dispatch(setReload());
              setCompleteBtnLoading(false);
              setAlertMessage("Order completed");
              // setSuccessSnack(true);
            })
            .catch((error) => {
              setAlertMessage(error.message);
              setCompleteBtnLoading(false);
            });
        }
      }

      // dispatch(setReload());
    } catch (error) {
      setAlertMessage(error.message);
      setCompleteBtnLoading(false);
    }
  };

  const requestPaymetToClover = async (id) => {
    return axios
      .post("/clover/request-payment-to-clover", {
        order: activeOrder,
        deviceId: restaurant.clover.deviceId,
        apiToken: restaurant.clover.apiToken,
        restaurantName: restaurant.name,
      })
      .then((response) => {
        if (response)
          completeOrder(id, "")
            .then((data) => {
              handleClose();
              makeOrderLive();
              newSocket.current.emit("complete-order", {
                orderId: activeOrder._id,
                seqValue: activeOrder.seqValue,
              });
              newSocket.current.emit("orderStatus", {
                id: activeOrder._id,
                status: "Completed",
                restaurantId: makeDecrypt("restaurantId"),
              });
              printTheReceipt(activeOrder._id, {
                items: rows,
                activeOrder,
              });
              dispatch(setReload());
              setCompleteBtnLoading(false);
              setAlertMessage("Order completed");
              // setSuccessSnack(true);
            })
            .catch((error) => {
              setAlertMessage(error.message);
              setCompleteBtnLoading(false);
            });
        else {
          setAlertMessage("Payment not received.");
          setErrorSnack(true);
          setCompleteBtnLoading(false);
          handleClose();
        }
      })
      .catch((error) => {
        // return error;
        setAlertMessage("Payment not received");
        setErrorSnack(true);
        setCompleteBtnLoading(false);
      });
  };
   
  const completeOrderOnly = (id) => {
    setAskConfirmation(false);
    completeOrder(id, "")
      .then((data) => {
        handleClose();      
        sendCompleteOrderMsg(data.order.totalPrice); 
        dispatch(setReload());
        setCompleteBtnLoading(false);
        setAlertMessage("Order completed");
        setShowSuccess(true);
        setShowModal(false);
        newSocket.current.emit("complete-order", {
          orderId: activeOrder?._id,
          seqValue: activeOrder?.seqValue,
        });
        newSocket.current.emit("orderStatus", {
          id: activeOrder?._id,
          status: "Completed",
          restaurantId: makeDecrypt("restaurantId"),
        });
        printTheReceipt(activeOrder?._id, {
          items: rows,
          order: activeOrder,
        });
        makeOrderLive();
      })
      .catch((error) => {
        setAlertMessage(error.message);
        setCompleteBtnLoading(false);
      });
  };

  const getPayment = (id, isCompleteOrder) => {
    getUser(activeOrder?.user)
      .then((res) => {
        capturePayment(res.stripeDetails.paymentMethod.paymentIntentId, stripeAccountId)
          .then((result) => {
            if (isCompleteOrder) {
              completeOrder(id, res.stripeDetails.paymentMethod.paymentIntentId)
                .then((data) => {
                  newSocket.current.emit("complete-order", {
                    orderId: activeOrder?._id,
                  });
                  newSocket.current.emit("orderStatus", {
                    id: activeOrder?._id,
                    status: "Completed",
                    restaurantId: makeDecrypt("restaurantId"),
                  });
                  makeOrderLive();
                  sendCompleteOrderMsg(data.order.totalPrice);
                  printTheReceipt(activeOrder?._id, {
                    items: rows,
                    order: activeOrder,
                  });
                  handleClose();
                  setAlertMessage("Order completed");
                  setShowSuccess(true);
                })

                .catch((error) => {
                  setAlertMessage(error.message);
                  setCompleteBtnLoading(false);
                });
            }
          })
          .catch((error) => {
            setAlertMessage(error.message);
            setCompleteBtnLoading(false);
          });
      })
      .catch((error) => {
        setAlertMessage(error.message);
        setCompleteBtnLoading(false);
      });
  };

  const makeOrderLive = () => {
    axios
      .get(`/order/${makeDecrypt("restaurantId")}/make-order-live`)
      .then((res) => {
        if (res.data.user) {
          newSocket.current.emit("order-is-live", {userId: res.data.user});
          let tempWaitingOrders = [];
          if (waitingOrders.length) {
            waitingOrders.map((order) => {
              if (order.user != res.data.user) tempWaitingOrders.push(order.user);
            });
          }
          newSocket.current.emit("refresh-queue", {
            tempWaitingOrders: tempWaitingOrders,
          });
        }

        if (restaurant.printDirect && res.data._id) acceptAndPrint(res.data._id);
        dispatch(setReload());
        setCompleteBtnLoading(false);
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.log(error));
  };

  const acceptAndPrint = (orderId) => {
    acceptOrder(orderId, false)
      .then((res) => {
        // makeEncrypt("orderId", orderId);
        if (!useRazorpay) printAtTheRestaurantEnd(res.data.order);
      })
      .catch((error) => {
        // setAlertMessage("Order Cannot be placed");
        // setAlertOpen(true);
      });
  };

  const printAtTheRestaurantEnd = async (order) => {
    let dataForPrint = [];
    let count = 0;
    let temp = [];
    for (const item of order.items) {
      // order.items.map(async (item) => {
      const menuItem = await getMenuItem(item.itemId);

      if (item.isAccepted === false) {
        ++count;
        let tempModifiers = [];
        if (item.modifiers) {
          // let tempModifiers = [];
          for (const modifier of item.modifiers) {
            // item.modifiers.map((modifier) => {
            tempModifiers.push({
              type: modifier.selectedTypeId,
              options: modifier.selectedOptions.map((option) => {
                return {name: option.modifierName};
              }),
            });
          }
          // });
        }
        temp = dataForPrint;

        if (temp.length) {
          if (
            temp.find(
              (tempItem) =>
                tempItem.printerName ===
                restaurant.printers.find((printer) => printer._id == menuItem[0].printer)
                  .defaultName
            )
          ) {
            temp[
              temp.findIndex(
                (item) =>
                  item.printerName ===
                  restaurant.printers.find(
                    (printer) => printer._id == menuItem[0].printer
                  ).defaultName
              )
            ].items.push({
              name: menuItem[0].name,
              quantity: item.quantity,
              specialInstructions: item.specialInstructions,
              modifiers: tempModifiers,
            });
          } else {
            temp.push({
              printerName: restaurant.printers.find(
                (printer) => printer._id == menuItem[0].printer
              ).defaultName,
              items: [
                {
                  name: menuItem[0].name,
                  quantity: item.quantity,
                  specialInstructions: item.specialInstructions,
                  modifiers: tempModifiers,
                },
              ],
            });
          }
        } else
          temp.push({
            printerName: restaurant.printers.find(
              (printer) => printer._id == menuItem[0].printer
            )?.defaultName,
            items: [
              {
                name: menuItem[0].name,
                quantity: item.quantity,
                specialInstructions: item.specialInstructions,
                modifiers: tempModifiers,
              },
            ],
          });

        let tempLength = 0;

        temp.forEach((tem) => {
          tempLength = tem.items.length + tempLength;
        });
      }
      // });
    }
    doPrint(temp, order);

    // setTimeout(() => {

    //   doPrint()
    // },3000)
  };

  const doPrint = (dataForPrint, orderForPrint) => {
    dataForPrint.forEach((item) => {
      item.restaurantId = makeDecrypt("restaurantId");
      item.orderId = orderForPrint._id;
      item.orderName = orderForPrint.orderName ? orderForPrint.orderName : "";
      item.table = orderForPrint.tableNumber
        ? orderForPrint.tableNumber
        : makeDecrypt("tableNo");
      item.restaurantName = restaurant.name;
      item.timeOfCreation = orderForPrint.createdAt;
      axios
        .post(
          `/order/${item.restaurantId}/${item.orderId}/print-order?dinify_print_station=${dinify_print_station}`,
          item
        )
        .then((result) => {
          // setAlertMessage("Order successfully placed and accepted");
          // setAlertOpen(true);
          // makeEncrypt("orderId", order._id);
          // dispatch(refreshCheckout());
        })
        .catch((error) => {
          // setAlertMessage("Order is successfully placed and accepted but printing failed");
          // setAlertOpen(true);
        });
    });
  };

  const handleCancel = async (id) => {
    if (
      restaurant.restaurantAddress.country === "india" ||
      (restaurant.restaurantAddress.country !== "india" && !restaurant.dinifySidePayments)
    ) {
      await cancelOrder(id);
      handleClose();
      dispatch(setReload());
      tempSocket.current.emit("orderStatus", {
        id: id,
        status: "Canceled",
      });
    } else {
      getUser(activeOrder?.user)
        .then((res) => {
          axios
            .post("/stripe/cancelpayment", {
              payIntId: res.stripeDetails.paymentMethod.paymentIntentId,
              stripeAccountId: stripeAccountId,
            })
            .then(async (response) => {
              await cancelOrder(id);
              handleClose();
              dispatch(setReload());
              tempSocket.current.emit("orderStatus", {
                id: id,
                status: "Canceled",
              });
            })
            .catch((error) => setAlertMessage(error.message));
        })
        .catch((error) => setAlertMessage(error.message));
    }
  };

  function priceRow(qty, unit) {
    return qty * unit;
  }

  function createRow(desc, note, qty, unit, _id) {
    const price = priceRow(qty, unit);
    return {
      desc,
      note,
      qty,
      unit,
      price,
      _id,
      id: _id,
      subTotalForBill: 0,
      selected: false,
      applicablePst: 0,
    };
  }
  const setTableData = async (orderParam) => {
    let tempRows2 = [];
    let tempRows = [];

    if (orderParam.items) {
      for (const item of orderParam.items) {
        // order.items.map(async (item) => {
        const menuItem = await getMenuItem(item.itemId);
        if (item.isAccepted !== true) {
          let tempModifiers = [];
          if (item.modifiers) {
            // let tempModifiers = [];
            item.modifiers.map((modifier) => {
              tempModifiers.push({
                type: modifier.selectedTypeId,
                options: modifier.selectedOptions.map((option) => {
                  return {name: option.modifierName};
                }),
              });
            });
          }

          //logic for separating data according to the printers
          let temp = dataForPrint;
          if (temp.length) {
            if (
              temp.find(
                (tempItem) =>
                  tempItem.printerName ===
                  printers.find((printer) => printer._id == menuItem.printer)?.defaultName
              )
            ) {
              temp[
                temp.findIndex(
                  (item) =>
                    item.printerName ===
                    printers.find((printer) => printer._id == menuItem.printer)
                      ?.defaultName
                )
              ].items.push({
                name: menuItem.name,
                quantity: item.quantity,
                specialInstructions: item.specialInstructions,
                modifiers: tempModifiers,
              });
            } else {
              temp.push({
                printerName: printers.find((printer) => printer._id == menuItem.printer)
                  ?.defaultName,
                items: [
                  {
                    name: menuItem.name,
                    quantity: item.quantity,
                    specialInstructions: item.specialInstructions,
                    modifiers: tempModifiers,
                  },
                ],
              });
            }
          } else
            temp.push({
              printerName: printers.find((printer) => printer._id == menuItem.printer)
                ?.defaultName,
              items: [
                {
                  name: menuItem.name,
                  quantity: item.quantity,
                  specialInstructions: item.specialInstructions,
                  modifiers: tempModifiers,
                },
              ],
            });

          setDataForPrint(temp);
          tempRows2.push(createRow(menuItem.name, item.specialInstructions, item.quantity, item.price, item._id));
          // setRows2((prevValues) => [
          //   ...prevValues,
          //   createRow(menuItem.name, item.quantity, item.price, item._id),
          // ]);
        } else {
          tempRows.push(createRow(menuItem.name, item.specialInstructions, item.quantity, item.price, item._id));
          // setRows((prevValues) => [
          //   ...prevValues,
          //   createRow(menuItem.name, item.quantity, item.price, item._id),
          // ]);
        }
        // });
      }
    }

    if (tempRows2.length) setRows2(tempRows2);
    if (tempRows.length) setRows(tempRows);
    setOpenInnerModal(false);
  };

  const sendCompleteOrderMsg = async (totalAmount) => {
    let tempMsg = "";
    let user = await getUser(activeOrder?.user);

    if (restaurant.restaurantAddress.country?.toLowerCase() === "canada") {
      if (dinifySidePayments) {
        tempMsg = `Payment of $${totalAmount} is successful. Thank you for ordering with Dinify!`;
      } else {
        tempMsg = `Your order is completed, please pay $${totalAmount} to cashier.`;
      }
    } else {
      if (dinifySidePayments) {
        tempMsg = `Thank you for ordering with Dinify!`;
      } else {
        tempMsg = `Your order is completed, please pay ₹${totalAmount} to cashier.`;
      }
    }

    if (user.phone)
      axios.post("order/send-complete-order-message", {
        userPhoneNo: user.phone,
        msg: tempMsg,
      });
  };

  const closeSnack = () => {
    setShowSuccess(false);
    setErrorSnack(false);
    setAlertMessage();
  };

  const handleCloseCloverAlert = () => {
    setCompleteBtnLoading(false);
    setShowCloverAlert(false);
  };

  const handleCombineBill = () => {
    setIsMerged(true);
    let mergeOrder = null;
    mergeOrder = {...tableOrders[0]};
    mergeOrder.appliedFee = _.sumBy(tableOrders, function (o) {
      return o.appliedFee;
    });
    mergeOrder.discount = _.sumBy(tableOrders, function (o) {
      return o.discount;
    });
    mergeOrder.grandTotal = _.sumBy(tableOrders, function (o) {
      return o.grandTotal;
    });
    mergeOrder.gstValue = _.sumBy(tableOrders, function (o) {
      return o.gstValue;
    });
    mergeOrder.pstValue = _.sumBy(tableOrders, function (o) {
      return o.pstValue;
    });
    mergeOrder.subTotal = _.sumBy(tableOrders, function (o) {
      return o.subTotal;
    });
    mergeOrder.tax = _.sumBy(tableOrders, function (o) {
      return parseFloat(o.tax);
    });
    mergeOrder.tip = _.sumBy(tableOrders, function (o) {
      return parseFloat(o.tip);
    });
    mergeOrder.totalPrice = _.sumBy(tableOrders, function (o) {
      return o.totalPrice;
    });
    let tempItems = [];
    for (const o of tableOrders) {
      tempItems = tempItems.concat(o.items);
    }

    mergeOrder.items = tempItems;
    setActiveOrder(mergeOrder);
    setShowModal(true);
  };

  const handleMergeOrderComplete = () => {
    setCompleteBtnLoading(false);

    async.series(
      {
        one: function (callback) {
          setTimeout(async function () {
            try {
              let res = !cloverDeviceConnected ? true : false;
              if (cloverDeviceConnected) {
                res = await paymentRequestToClover(
                  activeOrder,
                  restaurant.clover.deviceId,
                  restaurant.clover.apiToken,
                  restaurant.name
                );
              }
              if (res) {
                for (const thisOrder of tableOrders) {
                  completeOrder(thisOrder._id, "")
                    .then((data) => {
                      sendCompleteOrderMsg(data.order.totalPrice);
                      newSocket.current.emit("complete-order", {
                        orderId: thisOrder._id,
                        seqValue: thisOrder.seqValue,
                      });
                      newSocket.current.emit("orderStatus", {
                        id: thisOrder._id,
                        status: "Completed",
                        restaurantId: makeDecrypt("restaurantId"),
                      });

                      makeOrderLive();
                    })
                    .catch((error) => {
                      setAlertMessage(error.message);
                      setCompleteBtnLoading(false);
                    });
                }
              }
            } catch (error) {
              setAlertMessage(error.message);
            }
            callback(null, 1);
          }, 1000);
        },
        two: function (callback) {
          setTimeout(function () {
            if (!cloverDeviceConnected) {
              printTheReceipt(activeOrder?._id, {
                items: rows,
                order: activeOrder,
              });
            }
            setAlertMessage("Order completed");
            setShowSuccess(true);
            setShowModal(false);
            setShowOrders(false);
            handleClose();
            dispatch(getLiveOrders());
            callback(null, 2);
          }, 1200);
        },
      },
      function (err, results) {
        // results is now equal to: {one: 1, two: 2}
      }
    );
  };

  return (
    <React.Fragment>
      <Draggable
        {...dragHandlers}
        defaultPosition={{x: table.posX, y: table.posY}}
        onStart={() => !isDisabled}
        onStop={(e, location) => editPos(e, location, table.tableNo)}
      >
        <Paper
          // elevation="5"
          className={clsx("box", classes.table)}
          disabled={table.isDisabled}
        >
          <Box
            id={table.tableNo}
            className={clsx("vacantBg", classes.header)}
            sx={{
              p: 0.3,
              pl: 1,
              backgroundColor: table.isDisabled
                ? "#495057"
                : occupied
                ? "#660708"
                : Reservations && table.isReserved
                ? "green"
                : "#FF5757",
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
              position: "relative",
              minHeight: "24px",
              borderRadius: "3px",
            }}
          >
            <Typography
              id={`tableName${table.tableNo}`}
              className={"vacantName"}
              variant="h6"
              color="secondary"
              style={{
                fontSize: 10,
                position: "absolute",
                left: "0",
                right: "0",
                width: "fit-content",
                margin: "auto",
              }}
            >
              {table.tableNo}
            </Typography>
            {!occupied && (
              <TableActions
                table={table}
                tables={tables}
                ReservationData={ReservationData?.filter((data) =>
                  data.reservation.tableNo.includes(table.tableNo)
                )}
                setTables={setTables}
                isDisabled={isDisabled}
                childSnackBar={childSnackBar}
                style={{marginLeft: "auto", width: "fit-content"}}
              />
            )}
          </Box>

          <Box>
            {/* <Typography
                                        variant="h5"
                                        id={`tableStatus${table.tableNo}`}
                                        color={
                                          table.isDisabled ? "#950101" : "textSecondary"
                                        }
                                        className={"vacantStat"}
                                        style={{fontSize: 10}}
                                      >
                                        {table.isDisabled ? "DISABLED" : "VACANT"}
                                      </Typography> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              {occupied && (
                <Box
                  id={`tableButtons${table.tableNo}`}
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto",
                  }}
                >
                  <div className={classes.wrapper}>
                    <Button
                      variant="outlined"
                      sx={{fontSize: 9, p: 0, mt: 0.8}}
                      onClick={() => {
                        setAddItemBtnLoading(table.tableNo);
                        setMenuScreen(true);
                        setTableNo(table.tableNo);
                      }}
                      disabled={
                        table.isDisabled ||
                        !isDisabled ||
                        addItemBtnLoading == table.tableNo
                      }
                      onTouchStart={() => {
                        setAddItemBtnLoading(table.tableNo);
                        setMenuScreen(true);
                        setTableNo(table.tableNo);
                      }}
                    >
                      ADD ITEM
                    </Button>
                    {addItemBtnLoading == table.tableNo && (
                      <CircularProgress
                        size={10}
                        style={{
                          color: "primary",
                          // position: "absolute",
                          transform: "translate(-50%,-50%)",
                          top: "50%",
                          left: "50%",
                          marginTop: "-3px",
                          marginLeft: "-6px",
                        }}
                      />
                    )}
                  </div>

                  <div className={classes.wrapper}>
                    <Button
                      variant="outlined"
                      sx={{fontSize: 9, p: 0, mt: 0.8}}
                      // onClick={()=>navigate(`/orders`)}
                      // onClick={() => {
                      //   setManageBtnLoading(table.tableNo);
                      //   navigate(`/app/orders?tableNo=${table.tableNo}`, {replace: true});
                      // }}
                      onClick={() => {
                        handleManage();
                      }}
                      disabled={
                        table.isDisabled ||
                        !isDisabled ||
                        manageBtnLoading == table.tableNo
                      }
                      // onTouchStart={() => {
                      //   setManageBtnLoading(table.tableNo);
                      //   window.location.replace(
                      //     `${process.env.REACT_APP_HOME_URL}/app/orders?tableNo=${table.tableNo}`
                      //   );
                      // }}
                      onTouchStart={() => {
                        handleManage();
                      }}
                    >
                      MANAGE
                    </Button>
                    {manageBtnLoading == table.tableNo && (
                      <CircularProgress
                        size={10}
                        style={{
                          color: "primary",
                          position: "absolute",
                          transform: "translate(-50%,-50%)",
                          top: "50%",
                          left: "50%",
                          marginTop: "-3px",
                          marginLeft: "-6px",
                        }}
                      />
                    )}
                  </div>
                </Box>
              )}
              {!occupied && (
                <Box>
                  {!table.isDisabled && (
                    <div
                      // className={classes.wrapper}
                      style={{
                        // marginTop: "8px",
                        position: "relative",
                      }}
                    >
                      <Button
                        variant="outlined"
                        sx={{fontSize: 9, p: 0, mt: 0.8}}
                        id={`tableOrderBtn${table.tableNo}`}
                        onClick={() => {
                          setAddItemBtnLoading(table.tableNo);
                          setMenuScreen(true);
                          setTableNo(table.tableNo);
                          setNewOrder(true);
                        }}
                        onTouchStart={() => {
                          setAddItemBtnLoading(table.tableNo);
                          setMenuScreen(true);
                          setTableNo(table.tableNo);
                          setNewOrder(true);
                        }}
                        // href={`http://localhost:3000/app/orders?tableNo=${table.tableNo}`}
                        disabled={!isDisabled || statusBtnLoading == table.tableNo}
                      >
                        NEW ORDER
                      </Button>
                      {statusBtnLoading == table.tableNo && (
                        <CircularProgress
                          size={10}
                          style={{
                            color: "primary",
                            position: "absolute",
                            transform: "translate(-50%,-50%)",
                            top: "50%",
                            left: "50%",
                            marginTop: "-3px",
                            marginLeft: "-6px",
                          }}
                        />
                      )}
                    </div>
                  )}
                </Box>
              )}
            </Box>
          </Box>
          {occupied && (
            <Box sx={{mt: 0.5}}>
              <Divider />
              <Box sx={{p: 0.5}}>
                <Typography fontSize={10} fontWeight={500} variant="body2">
                  Total - ${tableTotal}
                </Typography>
              </Box>
            </Box>
          )}
        </Paper>
      </Draggable>
      <Dialog aria-labelledby="simple-dialog-title" open={menuScreen}>
        {tempSocket && (
          <>
            <CloseIcon
              style={{
                position: "absolute",
                right: "10px",
                top: "5px",
                // margin: "10px",
                cursor: "pointer",
                fontSize: "30px",
                color: "#fff",
              }}
              onClick={() => setMenuScreen(false)}
            />
            <AddItem
              tableNo={tableNo}
              tempSocket={tempSocket}
              setMenuScreen={setMenuScreen}
              setShowSuccess={setShowSuccess}
              newOrder={newOrder}
              setNewOrder={setNewOrder}
            />
          </>
        )}
      </Dialog>

      <Dialog open={showModal} onClose={() => setShowModal(false)}>
        <Box>
          {activeOrder && (
            <OrderModal
              order={activeOrder}
              handleClose={handleClose}
              // needAction={!activeOrder.items.every((item) => item.isAccepted === true)}
              needAction={!restaurant?.printDirect}
              handleComplete={handleComplete}
              tempSocket={tempSocket}
              newSocket={newSocket}
              handleCancel={handleCancel}
              rows={rows}
              setRows={setRows}
              setRows2={setRows2}
              rows2={rows2}
              openInnerModal={openInnerModal}
              setOpenInnerModal={setOpenInnerModal}
              showUpdateBtn={true}
              completeBtnLoading={completeBtnLoading}
              isMerged={isMerged}
              handleMergeOrderComplete={handleMergeOrderComplete}
              getPayment={getPayment}
              multipleOrders={tableOrders}
              setClonedOrder={setActiveOrder}
              handleCombineBill={handleCombineBill}
            />
          )}
        </Box>
      </Dialog>

      <Dialog fullWidth maxWidth={"xs"} open={showOrders}>
        <DialogTitle>
          <Typography variant="h4">Select Order</Typography>
        </DialogTitle>
        <DialogContent>
          <FormControl
            className={classes.formControl}
            label="Orders"
            sx={{minWidth: "100px", maxWidth: "300px", m: 1}}
            size={window.innerWidth < 800 ? "small" : "medium"}
            fullWidth
          >
            <InputLabel htmlFor="age-native-simple">Orders</InputLabel>
            <Select
              value={activeOrder}
              onChange={(e) => setActiveOrder(e.target.value)}
              inputProps={{
                name: "age",
                id: "age-native-simple",
              }}
              label="Orders"
              // size={window.innerWidth < 800 ? "small" : "medium"}
              size="small"
            >
              <MenuItem>
                <Box>None</Box>
              </MenuItem>
              {liveOrders.map((order, index) => {
                if (order.tableNumber == table.tableNo) {
                  return (
                    <MenuItem value={order} key={index}>
                      <Box>{order.orderName}</Box>
                    </MenuItem>
                  );
                }
              })}
            </Select>
          </FormControl>
          <Box m={1}>
            <Typography variant="h4" mt={1}>
              {" "}
              Number of orders = {tableOrders.length}
            </Typography>
            {tableOrders.map((tableOrder, index) => {
              return (
                <Typography variant="h6" key={tableOrder}>
                  {" "}
                  Order: {tableOrder.orderName} Total: {tableOrder.grandTotal}
                </Typography>
              );
            })}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setShowOrders(false);
            }}
            size="small"
          >
            CANCEL
          </Button>
          {tableOrders.every((currentOrder) =>
            currentOrder.items.every((item) => item.isAccepted === true)
          ) && (
            <Button variant="contained" onClick={handleCombineBill} size="small">
              COMBINE BILL
            </Button>
          )}
          <Button
            variant="contained"
            onClick={() => {
              setShowModal(true);
              setShowOrders(false);
            }}
            size="small"
            disabled={!activeOrder}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => setShowSuccess(false)}
      >
        <Alert
          onClose={() => setShowSuccess(false)}
          variant="filled"
          severity="success"
          style={{margin: "auto"}}
        >
          {newOrder
            ? "New Order has been added successfully!"
            : "Item has been added successfully!"}
        </Alert>
      </Snackbar>
      <Snackbar open={errorSnack} autoHideDuration={6000} onClose={closeSnack}>
        <Alert onClose={closeSnack} severity="error">
          {alertMessage}
        </Alert>
      </Snackbar>

      <Dialog
        open={showCloverAlert}
        onClose={handleCloseCloverAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h5">Payment machine is offline</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography>
              {" "}
              {`Payment machine is offline or is not accepting payments, please turn on the
              payment device or select "Proceed Anyway" to mark the order as complete`}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAskConfirmation(true);
              setShowCloverAlert(false);
            }}
            color="primary"
            variant="contained"
          >
            Proceed anyway
          </Button>

          <Button
            onClick={handleCloseCloverAlert}
            color="primary"
            variant="outlined"
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={askConfirmation}
        onClose={() => setAskConfirmation(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h5">Are you sure?</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography>
              By selecting yes, you are confirming that you have collected the payment
              from the customer.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAskConfirmation(false);
              handleClose();
            }}
            color="primary"
            variant="outlined"
            autoFocus
          >
            No
          </Button>
          <Button
            onClick={() => completeOrderOnly(activeOrder._id)}
            color="primary"
            variant="contained"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Table;
