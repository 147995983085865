import {
  SET_USER,
  LOADING_USER,
  SET_UNAUTHENTICATED,
  SET_AUTHENTICATED,
  SET_ONBOARDED,
  EDIT_USER,
} from "../types";

const initialState = {
  isVerified: "",
  authenticated: false,
  onboarded: false,
  loading: true,
  email: "",
  name: "",
  role: "",
  _id: "",
  phone: "",
  restaurant: "",
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        authenticated: true,
        ...action.payload,
        loading: false,
      };
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
      };
    case SET_ONBOARDED:
      return {
        ...state,
        onboarded: true,
      };
    case SET_UNAUTHENTICATED:
      return initialState;
    case LOADING_USER:
      return {
        ...state,
        loading: true,
      };
    case EDIT_USER:
      return {
        ...state,
        // items: state.items.map((item) =>
        //   item._id === action.payload._id ? { ...action.payload } : item
        // ),
      };

    default:
      return state;
  }
}
