import {createRoot} from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import store from "./redux/store";
import App from "./App";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import {CssBaseline} from "@mui/material";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <CssBaseline />
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);

// serviceWorker.register();