import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

axios.interceptors.request.use(function (config) {
  config.headers.Authorization = localStorage.getItem("jwt");
  return config;
});

export default axiosInstance;
