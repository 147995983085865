import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateRestaurant } from "src/redux/actions/restaurantActions";
import { makeDecrypt } from "src/utils/securels";
import * as Yup from "yup";
import AlertMsg from "../AlertMsg";
import { UploadCover, UploadLogo } from "./helpers";

const states = [
  {
    value: "british-columbia",
    label: "British Columbia",
  },
];

const cuisineList = ["Indian", "South-Indian", "Italian", "Fast-Food", "American"];

const alcoholServedOptions = [
  {
    value: "true",
    label: "Yes",
  },
  {
    value: "false",
    label: "No",
  },
];

const AccountProfileDetails = ({logoImage, setLogoImage, coverPhoto, setCoverPhoto, ...rest}) => {
  // const { restaurantName } = useSelector((state) => state.auth);


  const {
    paymentModes,
    restaurantLicenseNumber,
    establishmentDate,
    cuisine,
    alcoholServed,
    logoUrl,
    coverPhotoUrl,
    restaurantEmail,
    restaurantName,
  } = useSelector((state) => state.restaurant);
  const restaurant = useSelector((state) => state.restaurant);

  const [values, setValues] = useState({
    restaurantName: restaurantName,
    // lastName: 'Smith',
    email: restaurantEmail,
    phone: "",
    state: "",
    country: "",
    restaurantLicenseNumber: restaurantLicenseNumber,
    establishmentDate: establishmentDate,
    paymentModes: paymentModes,
    gst: "",
    pst: "",
    pst2: "",
    cuisine: cuisine?.join(','),
    logo: logoUrl,
    cover: coverPhotoUrl,
    menuCategories: [],
    alcoholServed: alcoholServed,
    timeZone: moment.tz.guess(),
    tiktokEmbed: "",
    instagramEmbed: "",
    facebookEmbed: "",
    googleReviewPlaceId: "",
    facebookPixelId: ""
  });
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const handleChange = (event) => {
  //   setValues({
  //     ...values,
  //     [event.target.name]: event.target.value
  //   })
  // }

  useEffect(() => {
    if (restaurant)
      setValues({
        restaurantName: restaurant.name,
        // lastName: 'Smith',
        email: restaurant.restaurantEmail,
        phone: restaurant.restaurantAddress.phoneNo,
        country: restaurant.restaurantAddress.country,
        state: restaurant.restaurantAddress.provinceOrState,
        restaurantLicenseNumber: restaurant.restaurantLicenseNumber,
        establishmentDate: establishmentDate,
        paymentModes: paymentModes,
        gst: restaurant.taxTypes.GST,
        pst: restaurant.taxTypes.PST,
        pst2: restaurant.taxTypes.PST2 ? restaurant.taxTypes.PST2 : 0,
        cuisine: restaurant?.cuisine.join(',') ,
        logo: logoUrl,
        cover: coverPhotoUrl,
        menuCategories: [],
        alcoholServed: alcoholServed,
        timeZone: restaurant.restaurantAddress.timeZone,
        tiktokEmbed: restaurant.tiktokEmbed,
        instagramEmbed: restaurant.instagramEmbed,
        facebookEmbed: restaurant.facebookEmbed,
        googleReviewPlaceId: restaurant.googleReviewPlaceId,
        facebookPixelId: restaurant.facebookPixelId
      });
      
  }, [restaurant]);


  return (
    <Formik
      enableReinitialize
      initialValues={{
        restaurantName: values.restaurantName,
        email: values.email,
        phone: values.phone,
        state: values.state,
        country: values.country,
        restaurantLicenseNumber: values.restaurantLicenseNumber,
        establishmentDate: values.establishmentDate,
        paymentModes: paymentModes,
        gst: values.gst,
        pst: values.pst,
        pst2: values.pst2,
        cuisine: [values.cuisine],
        logo: "",
        cover: "",
        menuCategories: [],
        alcoholServed: "true",
        timeZone: values.timeZone,
        tiktokEmbed: values.tiktokEmbed,
        instagramEmbed: values.instagramEmbed,
        facebookEmbed: values.facebookEmbed,
        googleReviewPlaceId: values.googleReviewPlaceId,
        facebookPixelId: values.facebookPixelId
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        restaurantName: Yup.string().required("Required field"),
        establishmentDate: Yup.string().required("Required field"),
        phone: Yup.string().required("Required field"),
        country: Yup.string().required("Required field"),
        restaurantLicenseNumber: Yup.string().required("Required field"),
        // paymentModes: Yup.string().required('Required field'),
        gst: Yup.string().required("Required field"),
        pst: Yup.string().required("Required field"),
        pst2: Yup.string().required("Required field"),
        // cuisine: Yup.string().required('Required field'),
        logo: Yup.string(),
        cover: Yup.string(),
        // menuCategories: Yup.string().required('Required field'),
        // alcoholServed: Yup.string().required('Required field')
      })}
      onSubmit={async (values) => {
        // const userData = { email: values.email, password: values.password }

        const formData = new FormData();
        formData.append("file", logoImage);
        formData.append("cover", coverPhoto);
        try {
          let newData;
          if (logoImage) {
            newData = await UploadLogo(formData, makeDecrypt("restaurantId"));
         }
          let newData2;
          if (coverPhoto) {
            newData2 = await UploadCover(formData, makeDecrypt("restaurantId"));
          }
          dispatch(
            updateRestaurant({
              name: values.restaurantName,
              restaurantEmail: values.email,
              restaurantAddress: {
                phoneNo: values.phone,
                country: values.country,
                timeZone: values.timeZone,
              },
              restaurantLicenseNumber: values.restaurantLicenseNumber,
              taxTypes: {
                GST: values.gst,
                PST: values.pst,
                PST2: values.pst2,
              },
              alcoholServed: values.alcoholServed,
              cuisine: values?.cuisine?.split(',').map((cuisine) => cuisine.trim()),
              logoUrl: logoImage ? newData?.file?.location : logoUrl,
              coverPhotoUrl: coverPhoto ? newData2?.cover?.location : coverPhotoUrl,
              establishmentDate: values.establishmentDate,
              tiktokEmbed: values.tiktokEmbed,
              instagramEmbed: values.instagramEmbed,
              facebookEmbed: values.facebookEmbed,
              googleReviewPlaceId: values.googleReviewPlaceId,
              facebookPixelId: values.facebookPixelId
            })
          );
        } catch (error) {
          setErrorMessage(error.message);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values,
      }) => (
        <form autoComplete="off" noValidate {...rest}>
          <Card>
            <CardHeader subheader="The information can be edited" title="Profile" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.restaurantName && errors.restaurantName)}
                    helperText={touched.restaurantName && errors.restaurantName}
                    fullWidth
                    label="Restaurant name"
                    name="restaurantName"
                    onChange={handleChange}
                    required
                    value={values.restaurantName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    fullWidth
                    label="Email Address"
                    name="email"
                    onChange={handleChange}
                    required
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={touched.phone && errors.phone}
                    fullWidth
                    label="Phone Number"
                    name="phone"
                    onChange={handleChange}
                    // type="number"
                    value={values.phone}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(
                      touched.restaurantLicenseNumber && errors.restaurantLicenseNumber
                    )}
                    helperText={
                      touched.restaurantLicenseNumber && errors.restaurantLicenseNumber
                    }
                    fullWidth
                    label="Restaurant License Number"
                    name="restaurantLicenseNumber"
                    onChange={handleChange}
                    value={values.restaurantLicenseNumber}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.establishmentDate && errors.establishmentDate)}
                    helperText={touched.establishmentDate && errors.establishmentDate}
                    id="establishmentDate"
                    label="Date of Establishment (YYYY-MM-DD)"
                    name="establishmentDate"
                    type="date"
                    fullWidth
                    required
                    value={values.establishmentDate}
                    onChange={handleChange}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={true}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.country && errors.country)}
                    helperText={touched.country && errors.country}
                    fullWidth
                    label="Country"
                    name="country"
                    onChange={handleChange}
                    required
                    value={values.country}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.state && errors.state)}
                    helperText={touched.state && errors.state}
                    fullWidth
                    label="State"
                    name="state"
                    onChange={handleChange}
                    value={values.state}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.gst && errors.gst)}
                    helperText={touched.gst && errors.gst}
                    fullWidth
                    label="GST %"
                    name="gst"
                    onChange={handleChange}
                    required
                    value={values.gst}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.pst && errors.pst)}
                    helperText={touched.pst && errors.pst}
                    fullWidth
                    label="PST1 %"
                    name="pst"
                    onChange={handleChange}
                    required
                    value={values.pst}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.pst2 && errors.pst2)}
                    helperText={touched.pst2 && errors.pst2}
                    fullWidth
                    label="PST2 %"
                    name="pst2"
                    onChange={handleChange}
                    required
                    value={values.pst2}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Alcohol Served"
                    name="alcoholServed"
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{native: true}}
                    variant="outlined"
                  >
                    {alcoholServedOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.timeZone && errors.timeZone)}
                    helperText={touched.timeZone && errors.timeZone}
                    fullWidth
                    label="Select timezone"
                    name="timeZone"
                    onChange={handleChange}
                    select
                    value={values.timeZone}
                    variant="outlined"
                    defaultValue={values.timeZone}
                  >
                    {moment.tz.names().map((timezone) => {
                      return (
                        <MenuItem value={timezone} key={timezone}>
                          {timezone}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="TikTok Embed URL"
                    name="tiktokEmbed"
                    onChange={handleChange}
                    value={values.tiktokEmbed}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Instagram Embed URL"
                    name="instagramEmbed"
                    onChange={handleChange}
                    value={values.instagramEmbed}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Facebook Embed URL"
                    name="facebookEmbed"
                    onChange={handleChange}
                    value={values.facebookEmbed}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Google Review Place ID"
                    name="googleReviewPlaceId"
                    onChange={handleChange}
                    value={values.googleReviewPlaceId}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Facebook Pixel ID"
                    name="facebookPixelId"
                    onChange={handleChange}
                    value={values.facebookPixelId}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Cuisines"
                    name="cuisine"
                    onChange={handleChange}
                    value={values.cuisine}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 2,
              }}
            >
              <Button color="primary" variant="contained" onClick={handleSubmit}>
                Update Details
              </Button>
            </Box>
          </Card>
          <AlertMsg alertMessage={errorMessage} />
        </form>
      )}
    </Formik>
  );
};

export default AccountProfileDetails;
