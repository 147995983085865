import {
  Box,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Typography,
} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import React, {useEffect, useState} from "react";
import Spinner from "src/utils/spinner/spinner";
import {getOrdersByPage} from "./helpers";
import PastOrdersTable from "./PastOrdersTable";

const useStyles = makeStyles()((theme) => {
  return {
    tableContainer: {
      display: "flex",
      flexDirection: "column",
      padding: "0",
    },
    root: {
      display: "flex",
      justifyContent: "space-between",
    },
    button: {
      display: "block",
      marginTop: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    select: {
      // width: "100px",
    },
    loadingBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "300px",
    },
    filterBox: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "space-between",
      },
      [theme.breakpoints.up("sm")]: {
        justifyContent: "left",
      },
    },
    paginationBox: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
      [theme.breakpoints.up("sm")]: {
        justifyContent: "right",
      },
    },
  };
});

function PastOrders({tempSocket, tab}) {
  const {classes} = useStyles();
  const [orders, setOrders] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    days: 10,
    page: 1,
    limit: 5,
    count: 1,
    order: "all",
  });

  const {days, page, limit, count, order} = values;
  useEffect(() => {
    setLoading(true);
    const getOrders = async () => {
      let response = await getOrdersByPage(days, page, limit, tab, order);
      setOrders(response.result);
      response.error && setErrMsg(response.error);
      setValues({...values, count: response.maxPages});
      setLoading(false);
    };
    getOrders();
  }, [page, days, limit, tab, order]);

  const handleChange = (val) => {
    setValues({...values, page: val});
  };

  const [open, setOpen] = useState({
    daysOpen: false,
    limitOpen: false,
    orderChange: false,
  });
  const {daysOpen, limitOpen, orderChange} = open;

  const handleClose = (type) => {
    if (type == "days") setOpen({...open, daysOpen: false});
    else if (type == "order") setOpen({...open, orderChange: false});
    else setOpen({...open, limitOpen: false});
  };

  const handleOpen = (type) => {
    if (type == "days") setOpen({...open, daysOpen: true});
    else if (type == "order") setOpen({...open, orderChange: true});
    else setOpen({...open, limitOpen: true});
  };

  return (
    <Box className={classes.tableContainer}>
      {loading ? (
        <Box className={classes.loadingBox}>
          <CircularProgress />
        </Box>
      ) : orders && orders.length ? (
        <PastOrdersTable orders={orders} tempSocket={tempSocket} tab={tab} />
      ) : (
        <Typography variant="h3" color="textSecondary" textAlign="center">
          No past orders do show
        </Typography>
      )}
      <Box className={classes.root}>
        <Grid container display={"flex"} alignItems="center">
          <Grid item sm={6} xs={12}>
            <Box className={classes.filterBox}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-controlled-open-select-label-days"> Days</InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label-days"
                  id="demo-controlled-open-select-days"
                  open={daysOpen}
                  onClose={() => handleClose("days")}
                  onOpen={() => handleOpen("days")}
                  value={days}
                  onChange={(e) => {
                    setValues({...values, days: e.target.value});
                  }}
                  className={classes.select}
                  label="Days"
                  size="small"
                >
                  <MenuItem value={10}>10 Days</MenuItem>
                  <MenuItem value={30}>30 Days</MenuItem>
                  <MenuItem value={120}>4 months</MenuItem>
                  <MenuItem value={240}>8 months</MenuItem>
                  <MenuItem value={365}>Year</MenuItem>
                  <MenuItem value={1000}>All</MenuItem>
                </Select>
              </FormControl>

              <FormControl className={classes.formControl}>
                <InputLabel id="demo-controlled-open-select-label-limit">
                  Rows/Page
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label-limit"
                  id="demo-controlled-open-select-limit"
                  open={limitOpen}
                  onClose={() => handleClose("limit")}
                  onOpen={() => handleOpen("limit")}
                  value={limit}
                  onChange={(e) => setValues({...values, limit: e.target.value})}
                  className={classes.select}
                  size="small"
                  label="Rows/Page"
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                </Select>
              </FormControl>
              {tab !== "tips" && (
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-controlled-open-select-order-limit">
                    Takeout/DineIn
                  </InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-order-limit"
                    id="demo-controlled-open-select-order"
                    open={orderChange}
                    onClose={() => handleClose("order")}
                    onOpen={() => handleOpen("order")}
                    value={order}
                    onChange={(e) => setValues({...values, order: e.target.value})}
                    className={classes.select}
                    style={{width: 150}}
                    size="small"
                    label="Takeout/DineIn"
                  >
                    <MenuItem value={"Takeout"}>TakeOut</MenuItem>
                    <MenuItem value={"DineIn"}>DineIn</MenuItem>
                    <MenuItem value={"all"}>All</MenuItem>
                  </Select>
                </FormControl>
              )}
            </Box>
          </Grid>

          <Grid item sm={6} xs={12}>
            <Box className={classes.paginationBox}>
              <Pagination
                count={count}
                color="primary"
                onChange={(e, val) => handleChange(val)}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
export default PastOrders;
