import {Doughnut} from "react-chartjs-2";
import "chart.js/auto";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  useTheme,
  CircularProgress,
} from "@mui/material";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import PhoneIcon from "@mui/icons-material/Phone";
import TabletIcon from "@mui/icons-material/Tablet";
import {useSelector} from "react-redux";
import {setDefaultLocale} from "react-datepicker";
import {useEffect, useState} from "react";
import {te} from "date-fns/locale";

const TrafficByDevice = ({overallRevenue, doughnutData, loaded, ...rest}) => {
  const theme = useTheme();
  // const {restaurants} = useSelector((state) => state.restaurants);
  const [data, setData] = useState(null);
  // const [loaded, setLoaded] = useState(false);
  const [number, setNumber] = useState(3);
  useEffect(() => {
    if (doughnutData) setData(doughnutData);
  }, [doughnutData]);

  const options = {
    animation: true,
    cutoutPercentage: 50,
    layout: {padding: 0},
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      tooltips: {
        backgroundColor: theme.palette.background.paper,
        bodyFontColor: theme.palette.text.secondary,
        enabled: true,
        footerFontColor: theme.palette.text.secondary,
        intersect: false,
        mode: "index",
        titleFontColor: theme.palette.text.primary,
      },
      legend: {
        display: false,
      },
    },
  };

  const [devices, setDevices] = useState([]);
  useEffect(() => {
    let tempDevices = [];
    if (data) {
      let index = 0;
      for (const item of data.datasets[0].data) {
        tempDevices.push({
          title: data.labels[index],
          value: parseFloat((item / overallRevenue) * 100).toFixed(2),
          // icon: PhoneIcon,
          color: data.datasets[0].backgroundColor[index++],
        });
      }
      setDevices(tempDevices);
    }
  }, [data, overallRevenue]);

  return (
    <Card {...rest}>
      <CardHeader title="Revenue of last few weeks" />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 300,
            position: "relative",
          }}
        >
          {loaded && data ? (
            <Doughnut data={data} options={options} />
          ) : (
            <CircularProgress />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: 2,
          }}
          flexdirection="column"
          flexWrap="wrap"
        >
          {devices.length && loaded
            ? devices.map(({color, title, value}) => (
                <Box
                  key={title}
                  sx={{
                    p: 1,
                    textAlign: "center",
                  }}
                >
                  {/* <Icon color="action" /> */}
                  <Typography color="textPrimary" variant="body1">
                    {title}
                  </Typography>
                  <Typography style={{color}} variant="h4">
                    {isNaN(value) ? 0 : value}%
                  </Typography>
                </Box>
              ))
            : ""}
        </Box>
      </CardContent>
    </Card>
  );
};

export default TrafficByDevice;
