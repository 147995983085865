import React, {useEffect, useState} from "react";
import {Box, Button, Divider, TextField, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {updateRestaurant} from "src/redux/actions/restaurantActions";
import {useDispatch} from "react-redux";
const useStyles = makeStyles()((theme) => {
  return {
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  };
});

function WorkingHours({resWorkingHours, setAlertMessage, setOpenWorkingHours}) {
  const {classes} = useStyles();
  const dispatch = useDispatch();
  const [workingHours, setWorkingHours] = useState([]);

  useEffect(() => {
    if (resWorkingHours) setWorkingHours(resWorkingHours);
  }, [resWorkingHours]);

  const handleChange = (event, index) => {
    let temp = [...workingHours];
    if (event.target.name === "closed") {
      temp[index][event.target.name] = !temp[index][event.target.name];
    } else {
      temp[index][event.target.name] = event.target.value;
    }
    setWorkingHours(temp);
  };
  const handleUpdate = () => {
    dispatch(updateRestaurant({resWorkingHours: workingHours}));
    setAlertMessage("Working hours updated");
    setTimeout(() => {
      setOpenWorkingHours(false);
    }, 1000);
  };
  return (
    <Box mt={2} display="flex" flexDirection="column" justifyContent="center">
      {workingHours.map((eachWorkingHour, index) => (
        <>
          <Box display="flex" flexWrap="wrap" alignItems="center" mt={1} key={index}>
            <Typography style={{minWidth: "100px"}}>{eachWorkingHour.day}: </Typography>
            <TextField
              id="time"
              label="Opening time"
              type="time"
              defaultValue={eachWorkingHour.openingTime}
              className={classes.textField}
              name="openingTime"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              onChange={(e) => handleChange(e, index)}
              disabled={eachWorkingHour.closed}
              sx={{mt: 2}}
            />
            <TextField
              id="time"
              label="Closing time"
              type="time"
              defaultValue={eachWorkingHour.closingTime}
              className={classes.textField}
              name="closingTime"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              onChange={(e) => handleChange(e, index)}
              disabled={eachWorkingHour.closed}
              sx={{mt: 2}}
            />
            <Box display="flex" alignItems="center" sx={{mt: 2}}>
              <label style={{size: "18px", fontWeight: "500"}}>Closed : </label>
              <input
                name="closed"
                type="checkbox"
                style={{marginLeft: "10px", height: "20px", width: "20px"}}
                checked={eachWorkingHour.closed}
                onChange={(e) => handleChange(e, index)}
              />
            </Box>
          </Box>
          <Divider style={{marginTop: "10px"}} />
        </>
      ))}
      <Box style={{marginTop: "20px"}} display="flex" alignItems="center">
        <Button
          variant="outlined"
          style={{width: "fit-content", marginLeft: "auto"}}
          onClick={() => setOpenWorkingHours(false)}
        >
          Close
        </Button>
        <Button
          variant="contained"
          style={{width: "fit-content", marginLeft: "20px"}}
          onClick={handleUpdate}
        >
          Update
        </Button>
      </Box>
    </Box>
  );
}

export default WorkingHours;
