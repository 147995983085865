import {makeDecrypt} from "src/utils/securels";
import axios from "../../../axios";

export const getAllProducts = async () => {
  return await axios
    .get("/stripe/getAllProducts")
    .then((res) => res.data)
    .catch((error) => {
      return error.message;
    });
};

export const getAllPrices = async () => {
  if (makeDecrypt("country") == "CANADA") {
    return await axios
      .get("/stripe/getAllPrices")
      .then((res) => res.data)
      .catch((error) => {
        return error.message;
      });
  } else if (makeDecrypt("country") == "INDIA") {
    return await axios
      .get("/stripe/get-all-plans")
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return error.message;
      });
  }
};

export const getAllTaxRates = async () => {
  return await axios
    .get("/stripe/getAllTaxRates")
    .then((res) => res.data)
    .catch((error) => {
      return error.message;
    });
};
export const getAllCoupons = async () => {
  return await axios
    .get("/stripe/getAllCoupons")
    .then((res) => res.data)
    .catch((error) => {
      return error.message;
    });
};

export const getPromoCodes = async () => {
  return await axios
    .get("/stripe/getPromoCodes")
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.message;
    });
};

export const getCouponData = async (id) => {
  return await axios
    .post("/stripe/getCouponData", {id})
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.message;
    });
};
