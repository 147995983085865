import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import CreateIcon from "@mui/icons-material/Create";
import {makeStyles} from "tss-react/mui";
import {updateRestaurant} from "src/redux/actions/restaurantActions";
import {useDispatch, useSelector} from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const useStyles = makeStyles()((theme) => {
  return {
    options: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "auto",
      // width:'fit-content',
      padding: "15px 30px 15px 30px",
      // zIndex:11,
      // backgroundColor: 'red',
      // backgroundColor:"white",
      // position:'absolute',
      // top:0,
      // right:0
    },
  };
});
function MenuActions({
  updateItem,
  row,
  toggleMenuStatus,
  closeConfirmBlock,
  setCloseConfirmBlock,
  handleDeleteMenuItem,
  setPstApplicable,
  actionsOpen,
  setActionsOpen,
}) {
  const {classes} = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmDelete, setconfirmDelete] = useState(false);
  const dispatch = useDispatch();
  const restaurant = useSelector((state) => state.restaurant);

  useEffect(() => {
    if (!actionsOpen) {
      handleThisClose();
    }
  }, [actionsOpen]);
  useEffect(() => {
    if (!closeConfirmBlock) {
      closeConfirmDelete();
    }
  }, [closeConfirmBlock]);
  const handleThisClick = (event) => {
    setAnchorEl(event.currentTarget);
    setActionsOpen(true);
  };

  const handleThisClose = () => {
    setAnchorEl(null);
    setPstApplicable(false);
  };

  const openConfirmDelete = () => {
    setconfirmDelete(true);
    setCloseConfirmBlock(true);
  };

  const closeConfirmDelete = () => {
    setconfirmDelete(false);
    handleThisClose();
  };

  const handleSpecialDish = () => {
    let temp = restaurant?.specialDish?.find((item) => item == row.id);
    let tempArray = [];
    tempArray = restaurant?.specialDish;
    if (!temp) {
      tempArray.push(row.id);
      dispatch(updateRestaurant({specialDish: tempArray}));
    } else {
      tempArray = tempArray.filter((item) => item !== row.id);
      dispatch(updateRestaurant({specialDish: tempArray}));
    }
    handleThisClose();
  };

  return (
    <>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={handleThisClick}
      >
        <MoreVertIcon color="action" />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMountedb
        open={Boolean(anchorEl)}
        onClose={handleThisClose}
        className={classes.options}
      >
        <MenuItem onClick={() => toggleMenuStatus(row.status, row.id)}>
          Mark {row.status === "Online" ? "Offline" : "Online"}
        </MenuItem>
        <MenuItem onClick={() => updateItem("Update Menu Item", row.id)}>
          Edit details
        </MenuItem>
        <MenuItem onClick={() => openConfirmDelete()}>Delete Item</MenuItem>
        <MenuItem onClick={() => handleSpecialDish()}>
          {restaurant?.specialDish == row.id ? "Remove from" : "Set as"}
          {`Today's Special`}
        </MenuItem>
      </Menu>
      <Dialog
        open={confirmDelete}
        onClose={closeConfirmDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete This Item?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The Item will be deleted permanently.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleDeleteMenuItem(row.id)} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default MenuActions;
