import {Link as RouterLink} from "react-router-dom";
import {AppBar, Toolbar, Typography} from "@mui/material";
import Logo from "./Logo";

const MainNavbar = (props) => {
  return (
    <AppBar elevation={0} {...props}>
      <Toolbar sx={{height: 64}}>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Typography color="inherit" variant="h4">
          Dinify
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default MainNavbar;
