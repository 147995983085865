import {Outlet} from "react-router-dom";
import MainNavbar from "./MainNavbar";
import {styled} from "@mui/material/styles";

const MainLayoutRoot = styled("div")(({theme}) => ({
  backgroundColor: theme.palette.background.paper,
  display: "flex",
  flex: "1 1 auto",
  height: "100%",
  overflow: "hidden",
}));

const MainLayoutWrapper = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: 64,
});

const MainLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const MainLayoutContent = styled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
});

const MainLayout = () => (
  <MainLayoutRoot>
    <MainNavbar />
    <MainLayoutWrapper>
      <MainLayoutContainer>
        <MainLayoutContent>
          <Outlet />
        </MainLayoutContent>
      </MainLayoutContainer>
    </MainLayoutWrapper>
  </MainLayoutRoot>
);

export default MainLayout;
