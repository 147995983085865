import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import {
  createDiscount,
  getAllDiscounts,
  updateDiscount,
} from "src/redux/actions/discountActions";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import moment from "moment";

function DiscountForm({ setOpen, discount }) {
  const [days, setDays] = useState([
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]);
  const [customerFacing, setCustomerFacing] = useState(true);
  const [visibleToCustomers, setVisibleToCustomers] = useState(true);
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState();
  const [discountType, setDiscountType] = useState("amount");
  const [applicableFor, setApplicableFor] = useState("both");

  const validationSchema = yup.object({
    name: yup.string("Enter discount name"),
    // .name("Enter a valid name")
    // .required("Name is required"),
    percentOff: yup.number("Enter percentage off"),
    amountOff: yup.number("Enter amount off"),
    day: yup.string("Enter day"),
    startDate: yup.string("Enter start date "),
    endDate: yup.string("Enter end time "),
    startTime: yup.string("Enter start time "),
    endTime: yup.string("Enter start time"),
    customerFacing: yup.boolean("Enter if customer facing or not"),
    visibleToCustomers: yup.boolean("Enter if visible to customer or not"),
    valid: yup.boolean("Enter if its a valid discount or not"),
    minOrderValue: yup.number('Enter minimum order value'),
    limitPerCustomer: yup.number('Enter minimum limit per customer'),
    applicableFor: yup.string('Enter for which order type the discount will applicable.'),
  });

  const formik = useFormik({
    initialValues: {
      name: discount ? discount.name : "",
      amountOff: discount ? discount.amountOff : 0,
      percentOff: discount ? discount.percentOff : 0,
      startDate: discount ? discount.startDate : moment().format('YYYY-MM-DD'),
      endDate: discount ? discount.endDate : moment().format('YYYY-MM-DD'),
      startTime: discount ? discount.startTime : "00:00",
      endTime: discount ? discount.endTime : "23:59",
      // customerFacing: discount ? discount.customerFacing : true,
      valid: discount ? discount.valid : true,
      minOrderValue: discount ? discount.minOrderValue : 0,
      limitPerCustomer: discount ? discount.limitPerCustomer : 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.days = days;
      values.customerFacing = customerFacing;
      values.visibleToCustomers = visibleToCustomers;
      values.applicableFor = applicableFor;
      dispatch({
        type: "START_LOADING",
      });
      setSubmitting(true);
      if (discount)
        dispatch(updateDiscount(discount._id, values, dispatch))
          .then((res) => {
            setOpen(false);
            setSubmitting(false);
            dispatch(getAllDiscounts());
          })
          .catch((error) => {
            setOpen(false);
            setSubmitting(false);
          });
      else
        dispatch(createDiscount(values, dispatch))
          .then(() => {
            setOpen(false);
            setSubmitting(false);
            dispatch(getAllDiscounts(dispatch));
          })
          .catch((error) => {
            setOpen(false);
            setSubmitting(false);
          });
    },
  });

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleChangeDays = (event) => {
    let day = event?.target.name;

    let flag = days.find((item) => item == day);

    if (flag) {
      setDays((prevState) => prevState.filter((item) => item !== day));
    } else {
      setDays((prevState) => [...prevState, day]);
    }
  };
  const handleApplicable = (event) => {
    setApplicableFor(event.target.value);
  }
  useEffect(() => {
    if (discount) {
      setDays(discount.days);
      setCustomerFacing(discount?.customerFacing);
      setVisibleToCustomers(discount?.visibleToCustomers);
      setApplicableFor(discount?.applicableFor);
    }
  }, [discount]);
  // useEffect(() => {
  //   if (discount) setCustomerFacing(discount?.customerFacing);
  // }, [discount]);

  return (
    <div>
      {" "}
      <Dialog
        open={true}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Box display={"flex"} sx={{ position: "relative" }}>
            <Typography variant="h4" style={{ margin: "auto" }} align="center">
              {discount ? "Update Discount" : "Create new Discount"}
            </Typography>
            <CloseIcon
              style={{
                marginLeft: "auto",
                position: "absolute",
                right: "10px",
                cursor: "pointer",
              }}
              onClick={() => setOpen(false)}
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display={"flex"} sx={{ mt: 2 }}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    label="Discount Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Discount Type</FormLabel>
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="gender1"
                      value={discountType}
                      onChange={(e) => setDiscountType(e.target.value)}
                    >
                      <FormControlLabel
                        value="amount"
                        control={<Radio />}
                        label="Amount Off"
                      />
                      <FormControlLabel
                        value="percent"
                        control={<Radio />}
                        label="Percent Off"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  {discountType == "percent" ? (
                    <TextField
                      fullWidth
                      id="percentOff"
                      name="percentOff"
                      label="Percentage Off"
                      type="number"
                      value={formik.values.percentOff}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.percentOff && Boolean(formik.errors.percentOff)
                      }
                      helperText={formik.touched.percentOff && formik.errors.percentOff}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      id="amountOff"
                      name="amountOff"
                      label="Amount Off"
                      type="number"
                      value={formik.values.amountOff}
                      onChange={formik.handleChange}
                      error={formik.touched.amountOff && Boolean(formik.errors.amountOff)}
                      helperText={formik.touched.amountOff && formik.errors.amountOff}
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Customer Facing</FormLabel>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={customerFacing || false}
                          onChange={() => setCustomerFacing(!customerFacing)}
                          name="customerFacing"
                        />
                      }
                      labelPlacement="end"
                      label={customerFacing ? "Stop Customer Facing" : "Customer Facing"}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Visible To Customers</FormLabel>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={visibleToCustomers || false}
                          onChange={() => setVisibleToCustomers(!visibleToCustomers)}
                          name="visibleToCustomers"
                        />
                      }
                      labelPlacement="end"
                      label={visibleToCustomers ? "Disable Customer Visibility" : "Enable Customer Visibility"}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Applicable For</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={applicableFor}
                      label="Applicable For"
                      onChange={handleApplicable}
                    >
                      <MenuItem value={"dine_in"}>Dine In</MenuItem>
                      <MenuItem value={"take_out"}>Take Out</MenuItem>
                      <MenuItem value={"both"}>Both</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="minOrderValue"
                    name="minOrderValue"
                    label="Minimum Order Value"
                    type="number"
                    value={formik.values.minOrderValue}
                    onChange={formik.handleChange}
                    error={formik.touched.minOrderValue && Boolean(formik.errors.minOrderValue)}
                    helperText={formik.touched.minOrderValue && formik.errors.minOrderValue}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="limitPerCustomer"
                    name="limitPerCustomer"
                    label="Limit per customer"
                    type="number"
                    value={formik.values.limitPerCustomer}
                    onChange={formik.handleChange}
                    error={formik.touched.limitPerCustomer && Boolean(formik.errors.limitPerCustomer)}
                    helperText={formik.touched.limitPerCustomer && formik.errors.limitPerCustomer}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="startDate"
                    name="startDate"
                    label="Start Date"
                    type="date"
                    value={moment(new Date(formik.values.startDate)).format("YYYY-MM-DD")}
                    defaultValue={moment(new Date(formik.values.startDate)).format(
                      "YYYY-MM-DD"
                    )}
                    // defaultValue="2017-05-24"
                    onChange={formik.handleChange}
                    error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                    helperText={formik.touched.startDate && formik.errors.startDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="endDate"
                    name="endDate"
                    label="End Date"
                    type="date"
                    value={moment(new Date(formik.values.endDate)).format("YYYY-MM-DD")}
                    onChange={formik.handleChange}
                    defaultValue={moment(new Date(formik.values.endDate)).format(
                      "YYYY-MM-DD"
                    )}
                    error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                    helperText={formik.touched.endDate && formik.errors.endDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="startTime"
                    name="startTime"
                    label="Start Time"
                    type="time"
                    defaultValue="00:00"
                    value={formik.values.startTime}
                    onChange={formik.handleChange}
                    error={formik.touched.startTime && Boolean(formik.errors.startTime)}
                    helperText={formik.touched.startTime && formik.errors.startTime}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="endTime"
                    name="endTime"
                    label="End Time"
                    type="time"
                    defaultValue="23:59"
                    value={formik.values.endTime}
                    onChange={formik.handleChange}
                    error={formik.touched.endTime && Boolean(formik.errors.endTime)}
                    helperText={formik.touched.endTime && formik.errors.endTime}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>Select Days</Typography>
                  <Grid container spacing={1}>
                    {[
                      "Sunday",
                      "Monday",
                      "Tuesday",
                      "Wednesday",
                      "Thursday",
                      "Friday",
                      "Saturday",
                    ].map((day) => {
                      return (
                        <Grid item xs={6} key={day}>
                          <Box display={"flex"}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={days?.find((item) => item == day) || false}
                                  onChange={(e) => handleChangeDays(e)}
                                  name={day}
                                />
                              }
                              labelPlacement="end"
                              label={day}
                            />
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={submitting}
                      fullWidth
                      type="submit"
                    >
                      {submitting
                        ? !discount
                          ? "Submitting"
                          : "Updating"
                        : !discount
                          ? "Submit"
                          : "Update"}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
        {/* <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                onClick={handleDialogClose}
                variant="outlined"
                color="primary"
                fullWidth
              >
                Close
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={createDiscount}
                variant="contained"
                color="primary"
                autoFocus
                fullWidth
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}

export default DiscountForm;
