import {Helmet} from "react-helmet";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import React, {useEffect} from "react";
import Spinner from "src/utils/spinner/spinner";
import {makeDecrypt} from "src/utils/securels";
import {Box, Container, Typography} from "@mui/material";

const VerificationPending = () => {
  const navigate = useNavigate();
  const {authenticated, loading} = useSelector((state) => state.auth);

  useEffect(() => {
    if (!authenticated) {
      return navigate("/login", {replace: true});
    }

    if (!(makeDecrypt("onboarded") === "true")) {
      return navigate("/restaurant/onboarding", {replace: true});
    }

    if (makeDecrypt("verified") === "true") {
      return navigate("/app/dashboard", {replace: true});
    }
  }, [authenticated]);

  return (
    <>
      <Helmet>
        <title>Account pending verification</title>
      </Helmet>
      {!loading ? (
        <Box
          sx={{
            backgroundColor: "background.default",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <Container maxWidth="md">
            <Typography align="center" color="textPrimary" variant="h1">
              Please wait, your account is pending verification, kindly come back later.
            </Typography>
            <Typography
              align="center"
              color="textPrimary"
              variant="subtitle2"
            ></Typography>
            <Box sx={{textAlign: "center"}}></Box>
          </Container>
        </Box>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default VerificationPending;
