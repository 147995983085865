import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router";
import AccountProfile from "src/components/account/AccountProfile";
import OnBoardingProfile from "src/components/account/OnBoardingProfile";
import {makeStyles} from "tss-react/mui";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {makeDecrypt, makeEncrypt} from "src/utils/securels";
import {Formik} from "formik";
import * as Yup from "yup";

const useStyles = makeStyles()((theme) => {
  return {
    logoCont: {
      // height: "700px",
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    featureCont: {
      marginTop: "20px ",
      marginRight: "auto",
      boxShadow:
        "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
      padding: "10px 30px 10px 10px",
      width: "fit-content",
      borderRadius: "7px",
    },
  };
});

const OnBoarding = (props) => {
  const {classes} = useStyles();

  const {
    logoImage,
    setLogoImage,
    newBranch,
    setRepresentativeName,
    setInitialData,
    setActiveStep,
  } = props;
  // const [logoImage , setLogoImage] = useState("");

  const navigate = useNavigate();
  const {authenticated} = useSelector((state) => state.auth);
  const onboarded = useSelector((state) => state.auth.onboarded);

  useEffect(() => {
    // if (!authenticated) {
    //  return navigate('/login', { replace: true })
    // }

    if (makeDecrypt("onboarded") === "true" && !(makeDecrypt("verified") === "true")) {
      return navigate("/verification/pendingVerification", {replace: true});
    }
  }, []);

  let restData = null;
  if (makeDecrypt("restData")) restData = JSON.parse(makeDecrypt("restData"));

  return (
    <>
      <Helmet>
        <title>Account | Dinify</title>
      </Helmet>
      <Container container className={classes.logoCont}>
        <Box display={"flex"} flexDirection="column" marginBottom={"20px"}>
          <Typography variant="h1">Profile Details</Typography>
        </Box>
        <Grid container>
          <Grid item xs={6}>
            <Formik
              initialValues={
                restData
                  ? {
                      restaurantName: restData.restaurantName,
                      email: restData.email,
                      phone: restData.restaurantAddress?.phoneNo,
                      legalRepresentative: restData.legalRepresentative,
                      state: restData.restaurantAddress?.provinceOrState,
                      country: restData.restaurantAddress?.country,
                      restaurantLicenseNumber: restData.restaurantLicenseNumber,
                      establishmentDate: restData.establishmentDate,
                      paymentModes: restData.cuisine,
                      gst: restData.taxTypes?.GST,
                      pst: restData.taxTypes?.PST,
                      pst2: restData.taxTypes?.PST2,
                      cuisine: restData.cuisine,
                      logo: "",
                      menuCategories: [],
                      alcoholServed: restData.alcoholServed,
                      city: restData.restaurantAddress?.city,
                      zipCode: restData.restaurantAddress?.zipCode,
                      streetNo: restData.restaurantAddress?.streetNo,
                      streetName: restData.restaurantAddress?.streetName,
                      unitNo: restData.restaurantAddress?.unitNo,
                      multilocation: restData.multilocation,
                      dinifySidePayments: restData.dinifySidePayments,
                    }
                  : {
                      restaurantName: "",
                      email: "",
                      phone: "",
                      legalRepresentative: "",
                    }
              }
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Must be a valid email")
                  .max(255)
                  .required("Email is required"),
                restaurantName: Yup.string().required("Required field"),
                phone: Yup.string()
                  .min(10, "Phone number should be of 10 digits.")
                  .required("Required field"),
                legalRepresentative: Yup.string().required("Required field"),
              })}
              onSubmit={(values) => {
                const restaurantData = {
                  email: values.email,
                  restaurantName: values.restaurantName,
                  paymentModes: values.paymentModes,
                  restaurantLicenseNumber: values.restaurantLicenseNumber,
                  establishmentDate: values.establishmentDate,
                  cuisine: values.cuisine,
                  alcoholServed: values.alcoholServed,
                  logoUrl: values.logoUrl,
                  // menuCategories: values.email,
                  restaurantAddress: {
                    phoneNo: values.phone,
                    provinceOrState:
                      values.country === "CANADA" ? "British Columbia" : "Maharashtra",
                    country: values.country,
                    city: values.city,
                    zipCode: values.zipCode,
                    streetNo: values.streetNo,
                    streetName: values.streetName,
                    unitNo: values.unitNo,
                  },
                  taxTypes: {GST: values.gst, PST: values.pst, PST2: values.pst2},
                  legalRepresentative: values.legalRepresentative,
                  multilocation: values.multilocation,
                  dinifySidePayments: values.dinifySidePayments,
                };

                setRepresentativeName(restaurantData.legalRepresentative);
                setInitialData(restaurantData);
                makeEncrypt("restData", JSON.stringify(restaurantData));

                setActiveStep((prevActiveStep) => prevActiveStep + 1);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                touched,
                values,
              }) => {
                return (
                  <form autoComplete="off" noValidate {...props}>
                    <Box style={{height: "10%"}}>
                      <Grid container spacing={4} sx={{py: 2}}>
                        <Grid item xs={12}>
                          <TextField
                            error={Boolean(
                              touched.restaurantName && errors.restaurantName
                            )}
                            helperText={touched.restaurantName && errors.restaurantName}
                            fullWidth
                            label="Restaurant name"
                            name="restaurantName"
                            onChange={handleChange}
                            value={values.restaurantName}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            error={Boolean(
                              touched.legalRepresentative && errors.legalRepresentative
                            )}
                            helperText={
                              touched.legalRepresentative && errors.legalRepresentative
                            }
                            fullWidth
                            label="Personell Full name"
                            name="legalRepresentative"
                            onChange={handleChange}
                            value={values.legalRepresentative}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                            fullWidth
                            label="Email Address"
                            name="email"
                            onChange={handleChange}
                            value={values.email}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            error={Boolean(touched.phone && errors.phone)}
                            helperText={touched.phone && errors.phone}
                            fullWidth
                            label="Phone Number"
                            name="phone"
                            onChange={handleChange}
                            type="number"
                            value={values.phone}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          p: 2,
                        }}
                        style={{display: "none"}}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={handleSubmit}
                          type="submit"
                          id="hiddenButtonInitial"
                        >
                          Save details
                        </Button>
                      </Box>
                    </Box>
                  </form>
                );
              }}
            </Formik>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{py: 2}}>
              <Typography variant="body2" marginBottom={"10px"}>
                {`Restaurant's Logo.`}
              </Typography>
              <AccountProfile
                logoImage={logoImage}
                setLogoImage={setLogoImage}
                newBranch={newBranch}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default OnBoarding;
