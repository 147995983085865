import {Container, Grid, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Spinner from "src/utils/spinner/spinner";
import TotalCustomers from "../dashboard/TotalCustomers";
import TotalProfit from "../dashboard/TotalProfit";
import {getOrderByDate, getTotalRevenueForClient} from "./helpers";
import Sales from "./Sales";
import TrafficByDevice from "./TrafficByDevice";

const useStyles = makeStyles()((theme) => {
  return {
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      width: "fit-content",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2),
    },
  };
});

function GridContainers({startingDate, endingDate}) {
  const particularRestaurant = useSelector((state) => state.restaurant);
  const [totalNumberOfOrders, setTotalNumberOfOrders] = useState(0);
  const [latestOrders, setLatestOrders] = useState([]);
  const [revenueGenerated, setRevenueGenerated] = useState(0);
  const [order, setOrder] = useState(null);
  const [ordersReload, setOrdersReload] = useState(false);
  const [loadedOrders, setLoadedOrders] = useState(false);
  const [open, setOpen] = useState(false);
  const {classes} = useStyles();
  const [values, setValues] = useState({
    days: 10,
    page: 1,
    limit: 5,
    count: 1,
  });
  const [doughnutData, setDoughnutData] = useState(null);
  const {days, page, limit, count} = values;
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (particularRestaurant._id && startingDate && endingDate && limit && page && days) {
      return getOrders(particularRestaurant._id, page, days, limit);
    }
  }, [particularRestaurant, page, days, limit, startingDate, endingDate]);

  const getOrders = (resId) => {
    getOrderByDate(limit, page, days, resId)
      .then((data) => {
        setLatestOrders(data.result);

        getTotalRevenueForClient(
          1.99,
          particularRestaurant._id,
          startingDate,
          endingDate
        ).then((data) => {
          setTotalNumberOfOrders(data.totalOrders);
          setRevenueGenerated(data.tempRevenue);
          setLoadedOrders(true);
        });

        setValues({...values, count: data.maxPages});
      })
      .catch((error) => {});
  };

  return (
    <div>
      {loadedOrders ? (
        <Container maxWidth={false}>
          <Typography variant="h4" sx={{mb: 2}}>
            Analytics
          </Typography>

          <Grid container spacing={3}>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalCustomers
                sx={{height: "100%"}}
                totalNumberOfOrders={totalNumberOfOrders}
              />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalProfit sx={{height: "100%"}} revenueGenerated={revenueGenerated} />
            </Grid>
            <Grid item lg={8} md={12} xl={9} xs={12}>
              <Sales
                startingDate={startingDate}
                endingDate={endingDate}
                restId={particularRestaurant._id}
                setDoughnutData={setDoughnutData}
                setLoaded={setLoaded}
                loaded={loaded}
              />
            </Grid>
            <Grid item lg={4} md={6} xl={3} xs={12}>
              {/* <Grid item lg={8} md={12} xl={9} xs={12}> */}
              <TrafficByDevice
                sx={{height: "100%"}}
                // revenueFromAllClients={revenueFromAllClients}
                overallRevenue={revenueGenerated}
                doughnutData={doughnutData}
                loaded={loaded}
              />
            </Grid>
            {/* <Grid item lg={3} sm={6} xl={3} xs={12}>
              {particularRestaurant && <PlanMangement sx={{height: "100%"}} />}
            </Grid> */}
          </Grid>
        </Container>
      ) : (
        <Spinner />
      )}
    </div>
  );
}

export default GridContainers;
