import {makeDecrypt} from "src/utils/securels";
import { getRestaurant} from "src/redux/actions/restaurantActions";
import axios from "../../../axios";
import {getMainMenus} from "src/redux/actions/mainMenuActions";
import {DELETE_MENU, STOP_LOADING_MENU, UPDATE_MENU} from "src/redux/types";
import { getMenuCategoriesN } from "src/redux/actions/categoryActions";

export const UploadMenuImage = async (data, restaurantId, menuItemId) => {
  try {
    return await axios
      .post(
        `/restaurant/restaurant-logo?restaurantId=${restaurantId}&menuItemId=${menuItemId}`,
        data,
        {headers: {Authorization: makeDecrypt("jwt")}}
      )
      .then((res) => res.data)
      .catch((error) => {
        return error.message;
      });
  } catch (error) {
    return error.message;
  }
};

export const UpdateMenuItem = async (data, restaurantId, menuItemId, dispatch) => {
  try {
    return await axios
      .put(`/menu/${restaurantId}/${menuItemId}/update-menu-item`, data, {
        headers: {Authorization: makeDecrypt("jwt")},
      })
      .then((res) => {
        dispatch({type: UPDATE_MENU, payload: res.data});
        res.data;
      })
      .catch((error) => {
        dispatch({type: STOP_LOADING_MENU});
        return error.message;
      });
  } catch (error) {
    return error.message;
  }
};

export const deleteCategory = async (name, id, dispatch, navigate, selectedMainMenu) => {
  try {
    return await axios
      .put(
        `/restaurant/restaurant-details/${name}/${id}/${makeDecrypt("restaurantId")}`,
        {},
        {headers: {Authorization: makeDecrypt("jwt")}}
      )
      .then((res) => {
        dispatch(getMainMenus(navigate, selectedMainMenu));
        res.data;
      })
      .catch((error) => {
        return error.message;
      });
  } catch (error) {
    return error.message;
  }
};
export const deleteCategoryN = async (name, id, dispatch, navigate, selectedMainMenu) => {
  try {
    return await axios
      .put(
        `/category/restaurant-details/${name}/${id}/${makeDecrypt("restaurantId")}`,
        {},
        {headers: {Authorization: makeDecrypt("jwt")}}
      )
      .then((res) => {
        dispatch(getMenuCategoriesN());
        dispatch(getMainMenus(navigate, selectedMainMenu));
        res.data;
      })
      .catch((error) => {
        return error.message;
      });
  } catch (error) {
    return error.message;
  }
};

export const deleteMenuItem = async (id, dispatch) => {
  try {
    return await axios
      .delete(`/menu/${makeDecrypt("restaurantId")}/${id}/delete-menu-item`, {
        headers: {Authorization: makeDecrypt("jwt")},
      })
      .then((res) => {
        dispatch({
          type: DELETE_MENU,
          payload: res.data.deletedMenuItem,
        });
        res.data.deletedMenuItem;
      })
      .catch((error) => {
        return error.message;
      });
  } catch (error) {
    return error.message;
  }
};

export const deleteMenu = async (id, dispatch) => {
  try {
    return await axios
      .delete(`/mainMenu/delete-main-menu/${makeDecrypt("restaurantId")}/${id}`, {
        headers: {Authorization: makeDecrypt("jwt")},
      })
      .then((res) => {
        dispatch(getMainMenus());
        res.data;
      })
      .catch((error) => {
        return error.message;
      });
  } catch (error) {
    return error.message;
  }
};

export const copyAllMenuItems = (
  restId,
  setThisReload,
  thisReload,
  dispatch,
  setProgress
) => {
  try {
    axios
      .get(`/mainMenu/${restId}/all-menu`, {
        headers: {Authorization: makeDecrypt("jwt")},
      })
      .then((res) => {
        axios
          .request({
            method: "post",
            url: `/restaurant/copy-whole-menu-from-main-branch/${makeDecrypt(
              "restaurantId"
            )}`,
            data: {
              mainMenu: res.data,
              mainRestaurantId: restId,
            },
            headers: {headers: {Authorization: makeDecrypt("jwt")}},
            onUploadProgress: (p) => {
              setProgress(Math.round((p.loaded / p.total) * 100));
            },
          })
          //     .then((res) => res.data);

          // axios
          //   .post(
          //     `/restaurant/copy-whole-menu-from-main-branch/${makeDecrypt(
          //       "restaurantId"
          //     )}`,
          //     {
          //       mainMenu: res.data,
          //       mainRestaurantId: restId,
          //     },
          //     {headers: {Authorization: makeDecrypt("jwt")}}
          //   )
          .then((thisRes) => {
            setThisReload(!thisReload);
          })
          .catch((error) => {
            return error.message;
          });
      })
      .catch((error) => console(error));
  } catch (error) {
    return error.message;
  }
};

export const deleteTag = (tag, restId, dispatch) => {
  return axios
    .put(`/restaurant/delete-tag/${restId}`, {tag: tag})
    .then((res) => {
      dispatch({
        type: "UPDATE_TAGS",
        payload: res.data.tags,
      });

      return res.data;
    })
    .catch((error) => {});
};

export const addThisNewTag = (tag, restId, dispatch) => {
  return axios
    .put(`/restaurant/add-new-tag/${restId}`, {tag: tag})
    .then((res) => {
      dispatch({
        type: "UPDATE_TAGS",
        payload: res.data.tags,
      });

      return res.data;
    })
    .catch((error) => {});
};

export const getAllModifiers = (restaurantId) => async (dispatch) => {
  try {
    return await axios
      .get(`/modifier/all-modifiers/${restaurantId}`)
      .then((res) => {
        dispatch({type: "GET_ALL_MODIFIERS", payload: res.data});
        return res.data;
      })
      .catch((error) => {
        return error.message;
      });
  } catch (error) {
    return error.message;
  }
};

export const createModifierForRestaurant = (data, restId) => async (dispatch) => {
  return axios
    .post(`/modifier/create-modifier/${restId}`, data)
    .then((res) => {
      dispatch(getAllModifiers(restId));
      return res.data;
    })
    .catch((error) => {});
};
