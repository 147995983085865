import { Helmet } from "react-helmet";
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Paper,
  Switch,
  Typography,
  Divider,
  Link,
} from "@mui/material";
import AccountProfile from "src/components/account/AccountProfile";
import AccountProfileDetails from "src/components/account/AccountProfileDetails";
import { useState } from "react";
import {
  setHalfToCustomer,
  setPrintDirect,
  setPushToCustomer,
} from "src/components/account/helpers";
import { makeStyles } from "tss-react/mui";
import { useSelector } from "react-redux";
import { makeDecrypt } from "src/utils/securels";
import { User } from "react-feather";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router";
import RestaurantCoverPhoto from "src/components/account/RestaurantCoverPhoto";

const useStyles = makeStyles()({
  cont: {
    display: "flex",
    flexDirection: "column",
    width: "300px",
    margin: "10px auto",
    padding: "10px",
    textAlign: "center",
    justifyContent: "space-around",
    marginTop: '100px'
  },
  breadCrumbs: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2px 8px ",
    borderRadius: "20px",
    backgroundColor: "#ffffff",
    border: "1px solid #FF5757",
    fontSize: "15px",
    fontWeight: "500",
    "& .MuiBreadcrumbs-separator svg": {
      fontSize: "20px !important",
    },
  },
});

const Account = () => {
  const { classes } = useStyles();

  const [logoImage, setLogoImage] = useState("");
  const [coverPhoto, setCoverPhoto] = useState("");
  const navigate = useNavigate();

  const { printDirect, _id } = useSelector((state) => state.restaurant);

  return (
    <>
      <Helmet>
        <title>Account | Dinify</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          style={{ marginBottom: "10px", marginLeft: "20px" }}
        >
          <Link
            color="primary"
            onClick={() => navigate("/app/dashboard")}
            style={{ cursor: "pointer" }}
          >
            <Box className={classes.breadCrumbs}>
              <HomeIcon color="primary" style={{ fontSize: "15px", marginRight: "2px" }} />
              Home
            </Box>
          </Link>
          <Link
            color="primary"
            // href="/app/orders"
            style={{ cursor: "pointer" }}
          >
            <Box className={classes.breadCrumbs}>
              <User size={15} style={{ fontSize: "15px", marginRight: "2px" }} />
              Account
            </Box>
          </Link>
        </Breadcrumbs>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12} style={{ alignItems: "center" }}>
              <Box style={{ position: 'relative' }}>
                <RestaurantCoverPhoto coverPhoto={coverPhoto} setCoverPhoto={setCoverPhoto} />
                <Box style={{ position: 'absolute', top: '50%', left: '0', right: '0' }}>
                  <AccountProfile logoImage={logoImage} setLogoImage={setLogoImage} />
                </Box>
              </Box>
              <Paper className={classes.cont}>
                <Typography variant="h5">Restaurant ID</Typography>
                <Typography textAlign="center">{`${_id}`}</Typography>
                <Divider />

                <Divider />
              </Paper>
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <AccountProfileDetails logoImage={logoImage} setLogoImage={setLogoImage} coverPhoto={coverPhoto} setCoverPhoto={setCoverPhoto} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Account;
