import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { format } from "date-fns";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { getLiveOrders, setReload } from "src/redux/actions/orderActions";
import {
  capturePayment,
  completeOrder,
  getUser,
  paymentRequestToClover,
  printTheReceipt,
  refundPayment,
} from "./helpers";
import OrderModal from "./OrderModal";
import { useLocation } from "react-router-dom";
import AlertMsg from "../AlertMsg";
import { makeDecrypt, makeEncrypt } from "src/utils/securels";
import {
  acceptOrder,
  cancelOrder,
  getLoggedInEmployee,
  getMenuItem,
  printData,
} from "./helpers";
import { differenceInDays } from "date-fns";
import axios from "../../axios";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MultiOrderSelection from "./MultiOrderSelection";
import _ from "lodash";
import async from "async";
import longNotification from "../../Notification/long-notification.mp3";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      margin: `${theme.spacing(2)} 0`,
      // "&:hover": {
      //   boxShadow: theme.shadows[10],
      //   backgroundColor: "#edf2fb",
      // },
    },
    eachOrder: {
      fontSize: "18px",
      padding: theme.spacing(1),
      margin: `${theme.spacing(2)} 0`,
      // background: "#f4f4f4",
      alignItems: "center",
      backgroundColor: "white",
      boxShadow: theme.shadows[2],
      borderRadius: "10px",
      "&:hover": {
        boxShadow: theme.shadows[10],
        backgroundColor: "#FFEBEB",
      },
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      width: "fit-content",
      // boxShadow: theme.shadows[5],
      // padding: theme.spacing(1),
      border: "1px solid #d4d4d4",
      padding: "20px 30px",
      borderRadius: "5px"
    },
    orderRow: {
      cursor: "pointer",
      display: "flex",
      // marginRight: "10px",
      // width: "70%",
      // alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
      },
    },
    wrapper: {
      position: "relative",
      // display: "flex",
      // justifyContent: "center",
      // alignItems: "center",
      width: "fit-content",
    },
    buttonProgress: {
      color: theme.palette.primary,
      position: "absolute",
      transform: "translate(-50%,-50%)",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    orderActionTime: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    actionBtn: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    moreIcon: {
      display: "flex",
      justifyContent: "right",
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },

  };
});

function EachOrder({
  order,
  needAction,
  tempSocket,
  hideAcceptBtn,
  setAcceptedItems,
  setNotAcceptedItems,
  setTakeout,
  setInnerModalOpen,
  cloverDeviceConnected,
  setAlertMessage,
  multipleOrders,
  areMultipleOrders,
}) {
  const { classes } = useStyles();
  const [open, setOpen] = React.useState(false);
  let { stripeAccountId, dinify_print_station, name, printers } = useSelector(
    (state) => state.restaurant
  );
  const {  liveOrders, waitingOrders, reload  } = useSelector((state) => state.order);
  const [openCancelWarning, setOpenCancelWarning] = useState(false);
  const [warningRefund, setWarningRefund] = useState(false);
  const [refundAmount, setRefundAmount] = useState("");
  // const [alertMessage, setAlertMessage] = useState("");
  const [dataForPrint, setDataForPrint] = useState([]);
  const [acceptBtnLoading, setAcceptBtnLoading] = useState(false);
  const [completeBtnLoading, setCompleteBtnLoading] = useState(false);
  const { restaurant } = useSelector((state) => state);
  const { role } = useSelector((state) => state.auth);
  const { searchedTable } = useLocation();
  const [useRazorpay, setUseRazorpay] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rows, setRows] = useState([]);
  const [rows2, setRows2] = useState([]);
  const [openInnerModal, setOpenInnerModal] = useState(false);
  const [clonedOrder, setClonedOrder] = useState(null);
  const [waitingTime, setWaitingTime] = useState(10);
  const [successSnack, setSuccessSnack] = useState(false);
  const [errorSnack, setErrorSnack] = useState(false);
  const [askConfirmation, setAskConfirmation] = useState(false);
  const [showCloverAlert, setShowCloverAlert] = useState(false);
  const [showOrders, setShowOrders] = useState(false);
  const [isMerged, setIsMerged] = useState(false);
  const longAudio = new Audio(longNotification);
  const [currentUser, setCurrentUser] = useState(null);
  const [noteExpanded, setNoteExpanded] = useState(false); // expand the special note if it is bigger than 50 chars
  let timer;

  function priceRow(qty, unit) {
    return qty * unit;
  }

  function createRow(desc, note, qty, unit, _id) {
    const price = priceRow(qty, unit);
    return {
      desc,
      note,
      qty,
      unit,
      price,
      _id,
      id: _id,
      subTotalForBill: 0,
      selected: false,
      applicablePst: 0,
    };
  }
  const setTableData = async (orderParm) => {
    let tempRows2 = [];
    let tempRows = [];
    for (const item of orderParm.items) {
      // order.items.map(async (item) => {
      const menuItem = await getMenuItem(item.itemId);
      if (item.isAccepted !== true) {
        let tempModifiers = [];
        if (item.modifiers) {
          // let tempModifiers = [];
          item.modifiers.map((modifier) => {
            tempModifiers.push({
              type: modifier.selectedTypeId,
              options: modifier.selectedOptions.map((option) => {
                return { name: option.modifierName };
              }),
            });
          });
        }

        //logic for separating data according to the printers
        let temp = dataForPrint;
        if (temp.length) {
          if (
            temp.find(
              (tempItem) =>
                tempItem.printerName ===
                printers.find((printer) => printer._id == menuItem.printer)?.defaultName
            )
          ) {
            temp[
              temp.findIndex(
                (item) =>
                  item.printerName ===
                  printers.find((printer) => printer._id == menuItem.printer)?.defaultName
              )
            ].items.push({
              name: menuItem.name,
              quantity: item.quantity,
              specialInstructions: item.specialInstructions,
              modifiers: tempModifiers,
            });
          } else {
            temp.push({
              printerName: printers.find((printer) => printer._id == menuItem.printer)
                ?.defaultName,
              items: [
                {
                  name: menuItem.name,
                  quantity: item.quantity,
                  specialInstructions: item.specialInstructions,
                  modifiers: tempModifiers,
                },
              ],
            });
          }
        } else
          temp.push({
            printerName: printers.find((printer) => printer._id == menuItem.printer)
              ?.defaultName,
            items: [
              {
                name: menuItem.name,
                quantity: item.quantity,
                specialInstructions: item.specialInstructions,
                modifiers: tempModifiers,
              },
            ],
          });

        setDataForPrint(temp);
        tempRows2.push(createRow(menuItem.name, item.specialInstructions, item.quantity, item.price, item._id));
        // setRows2((prevValues) => [
        //   ...prevValues,
        //   createRow(menuItem.name, item.quantity, item.price, item._id),
        // ]);
      } else {
        tempRows.push(createRow(menuItem.name, item.specialInstructions, item.quantity, item.price, item._id));
        // setRows((prevValues) => [
        //   ...prevValues,
        //   createRow(menuItem.name, item.quantity, item.price, item._id),
        // ]);
      }
      // });
    }
    if (tempRows2.length) setRows2(tempRows2);
    if (tempRows.length) setRows(tempRows);
    setOpenInnerModal(false);
  };
  useEffect(() => {
    const getUserForOrder = async () => {
      let thisUser = await getUser(order.user);
      setCurrentUser(thisUser);
      // setInvoiceSubtotal(invoiceSubtotal - order.discount);
    };
    getUserForOrder();
    if (clonedOrder) {
      setTableData(clonedOrder);
    }
  }, [clonedOrder, reload]);

  useEffect(() => {
    //Note:It means execute only when  each order is rendering from past order table
    if ((setAcceptedItems || setInnerModalOpen || setNotAcceptedItems) && order) {
      setTableData(order);
    }
  }, []);

  useEffect(() => {
    if (rows.length === order.items.length) {
      const newRow = [];
      rows.map((row) => {
        if (newRow.some((eachRow) => eachRow.desc === row.desc)) {
          newRow.forEach((item) => {
            if (item.desc === row.desc) {
              item.qty = item.qty + row.qty;
              item.unit = item.unit + row.unit;
              item.price = item.price + row.price;
            }
          });
        } else newRow.push(row);
      });
      if (JSON.stringify(newRow) != JSON.stringify(rows)) {
        setRows(newRow);
      }
    } else if (rows.length === 0 && order.items.length) {
      setTableData(order);
    }
  }, [rows, reload]);

  useEffect(() => {
    if (setAcceptedItems && setNotAcceptedItems) {
      setAcceptedItems(rows);
      setNotAcceptedItems(rows2);
    }
  }, [rows, rows2]);

  useEffect(() => {
    if (setInnerModalOpen) {
      setInnerModalOpen(openInnerModal);
    }
  }, [openInnerModal]);
  const handleOpen = () => {
    setOpen(true);
    if (!areMultipleOrders) setClonedOrder(order);
  };

  const handleClose = () => {
    setOpen(false);
    setClonedOrder(null);
    setRows([]);
    setRows2([]);
    setIsMerged(false);
    if (setInnerModalOpen) {
      setInnerModalOpen(false);
    }
    setCompleteBtnLoading(false);
  };
  const dispatch = useDispatch();

  const socket = useRef();
  const [newSocket, setNewSocket] = useState(null);
  const [dinifySidePayments, setDinifySidePayments] = useState(true);

  useEffect(() => {
    const makeConnection = async () => {
      // socket.current = io("wss://api.dinify.io", {transports: ["websocket"]});
      socket.current = io(process.env.REACT_APP_WEB_SOCKET_URL, {
        transports: ["websocket"],
      });
      //       socket.current = io("ws://localhost:3003");
      setNewSocket(socket);
      socket.current.on("ready-to-serve", (data) => {
        if (makeDecrypt("restaurantId") === data.restaurantId) {
          const currentTable = document.getElementById(`tableCard${data.tableNo}`);
          showActiveOrder(currentTable);
        }
      });
    };
    makeConnection();
  }, []);

  useEffect(() => {
    if (restaurant) {
      setDinifySidePayments(
        restaurant.dinifySidePayments === undefined ? true : restaurant.dinifySidePayments
      );
      setUseRazorpay(restaurant.restaurantAddress.country === "INDIA");
    }
  }, [restaurant]);

  const requestPaymetToClover = async (id) => {
    return axios
      .post("/clover/request-payment-to-clover", {
        order: order,
        deviceId: restaurant.clover.deviceId,
        apiToken: restaurant.clover.apiToken,
        restaurantName: restaurant.name,
      })
      .then((response) => {
        if (response)
          completeOrder(id, "")
            .then((data) => {
              handleClose();
              makeOrderLive();
              newSocket.current.emit("complete-order", {
                orderId: order._id,
                seqValue: order.seqValue,
              });
              newSocket.current.emit("orderStatus", {
                id: order._id,
                status: "Completed",
                restaurantId: makeDecrypt("restaurantId"),
              });
              printTheReceipt(order._id, {
                items: rows,
                order,
              });
              dispatch(setReload());
              setCompleteBtnLoading(false);
              setAlertMessage("Order completed");
              setSuccessSnack(true);
            })
            .catch((error) => {
              setAlertMessage(error.message);
              setCompleteBtnLoading(false);
            });
        else {
          setAlertMessage("Payment not received.");
          setErrorSnack(true);
          setCompleteBtnLoading(false);
          handleClose();
        }
      })
      .catch((error) => {
        setAlertMessage("Payment not received");
        setErrorSnack(true);
        setCompleteBtnLoading(false);
      });
  };
           
  const completeOrderOnly = (id) => {
    setAskConfirmation(false);
    completeOrder(id, "")
      .then((data) => {
        newSocket.current.emit("complete-order", {
          orderId: order._id,
          seqValue: order.seqValue,
        });
        newSocket.current.emit("orderStatus", {
          id: order._id,
          status: "Completed",
          restaurantId: makeDecrypt("restaurantId"),
        });
        printTheReceipt(order._id, {
          items: rows,
          order,
        });
        makeOrderLive();
        dispatch(setReload());
        setCompleteBtnLoading(false);
        setAlertMessage("Order completed");
        setSuccessSnack(true);
        handleClose();
        sendCompleteOrderMsg();
      })
      .catch((error) => {
        setAlertMessage(error.message);
        setCompleteBtnLoading(false);
      });
  };
  const handleComplete = async (id, proceedAnyway) => {
    setCompleteBtnLoading(true);
    try {
      if (
        dinifySidePayments &&
        restaurant.restaurantAddress.country?.toLowerCase() !== "india"
      ) {
        return (order.orderedByRest || order.paymentDone) && !order.paymentThroughApp
          ? completeOrderOnly(id)
          : getPayment(id, true);
      } else {
        if (
          restaurant.restaurantAddress.country?.toLowerCase() == "canada" &&
          restaurant.clover?.acceptPayment
        ) {
          if (cloverDeviceConnected) requestPaymetToClover(id);
          else {
            setShowCloverAlert(true);
            completeOrder(id, "")
              .then((data) => {
                makeOrderLive();
                newSocket.current.emit("complete-order", {
                  orderId: order._id,
                  seqValue: order.seqValue,
                });
                newSocket.current.emit("orderStatus", {
                  id: order._id,
                  status: "Completed",
                  restaurantId: makeDecrypt("restaurantId"),
                });
                printTheReceipt(order._id, {
                  items: rows,
                  order,
                });
                dispatch(setReload());
                setCompleteBtnLoading(false);
                setAlertMessage("Order completed");
                setSuccessSnack(true);
              })
              .catch((error) => {
                setAlertMessage(error.message);
                setCompleteBtnLoading(false);
              });
          }
        } else {
          completeOrder(id, "")
            .then((data) => {
              makeOrderLive();
              newSocket.current.emit("complete-order", {
                orderId: order._id,
                seqValue: order.seqValue,
              });
              newSocket.current.emit("orderStatus", {
                id: order._id,
                status: "Completed",
                restaurantId: makeDecrypt("restaurantId"),
              });
              printTheReceipt(order._id, {
                items: rows,
                order,
              });
              dispatch(setReload());
              setCompleteBtnLoading(false);
              setAlertMessage("Order completed");
              setSuccessSnack(true);
              sendCompleteOrderMsg();
            })
            .catch((error) => {
              setAlertMessage(error.message);
              setCompleteBtnLoading(false);
            });
        }
      }

      // dispatch(setReload());
    } catch (error) {
      setAlertMessage(error.message);
      setCompleteBtnLoading(false);
    }
  };
  // const getMenuItem = async (id) => {
  //   return await axios
  //     .get(`/menu/${makeDecrypt("restaurantId")}/${id}/menu-item`)
  //     .then((res) => {
  //       return res.data;
  //     })
  //     .catch((error) => {
  //       setOpen(true);
  //     });
  // };

  const printAtTheRestaurantEnd = async (order) => {
    let dataForPrint = [];
    let count = 0;
    let temp = [];
    for (const item of order.items) {
      // order.items.map(async (item) => {
      const menuItem = await getMenuItem(item.itemId);

      if (item.isAccepted === false) {
        ++count;
        let tempModifiers = [];
        if (item.modifiers) {
          // let tempModifiers = [];
          for (const modifier of item.modifiers) {
            // item.modifiers.map((modifier) => {
            tempModifiers.push({
              type: modifier.selectedTypeId,
              options: modifier.selectedOptions.map((option) => {
                return { name: option.modifierName };
              }),
            });
          }
          // });
        }
        temp = dataForPrint;
        // dataForPrint.push({
        //   name: menuItem[0].name,
        //   quantity: item.quantity,
        //   specialInstructions: item.specialInstructions,
        //   modifiers: tempModifiers,
        // });

        if (temp.length) {
          if (
            temp.find(
              (tempItem) =>
                tempItem.printerName ===
                restaurant.printers.find((printer) => printer._id == menuItem[0].printer)
                  .defaultName
            )
          ) {
            temp[
              temp.findIndex(
                (item) =>
                  item.printerName ===
                  restaurant.printers.find(
                    (printer) => printer._id == menuItem[0].printer
                  ).defaultName
              )
            ].items.push({
              name: menuItem[0].name,
              quantity: item.quantity,
              specialInstructions: item.specialInstructions,
              modifiers: tempModifiers,
            });
          } else {
            temp.push({
              printerName: restaurant.printers.find(
                (printer) => printer._id == menuItem[0].printer
              ).defaultName,
              items: [
                {
                  name: menuItem[0].name,
                  quantity: item.quantity,
                  specialInstructions: item.specialInstructions,
                  modifiers: tempModifiers,
                },
              ],
            });
          }
        } else
          temp.push({
            printerName: restaurant.printers.find(
              (printer) => printer._id == menuItem[0].printer
            )?.defaultName,
            items: [
              {
                name: menuItem[0].name,
                quantity: item.quantity,
                specialInstructions: item.specialInstructions,
                modifiers: tempModifiers,
              },
            ],
          });

        // setDataForPrint((prevValues) => [
        //   ...prevValues ,
        //   {
        //     name:menuItem.name,
        //     quantity:item.quantity,
        //     specialInstructions:item.specialInstructions,
        //     modifiers:tempModifiers
        //   }
        // ])
        let tempLength = 0;

        temp.forEach((tem) => {
          tempLength = tem.items.length + tempLength;
        });
        if (
          order.items.filter((thisItem) => thisItem.isAccepted === false).length ===
          tempLength
        ) {
          // tableNumber = order.tableNumber;
          // setOrderForPrint(order)
          // setReadyToPrint(true)
          // setActualDataToPrint(temp)
        }
      }
      // });
    }
    doPrint(temp, order);

    // setTimeout(() => {

    //   doPrint()
    // },3000)
  };

  const doPrint = (dataForPrint, orderForPrint) => {
    dataForPrint.forEach((item) => {
      item.restaurantId = makeDecrypt("restaurantId");
      item.orderId = orderForPrint._id;
      item.orderName = orderForPrint.orderName ? orderForPrint.orderName : "";
      item.table = orderForPrint.tableNumber
        ? orderForPrint.tableNumber
        : makeDecrypt("tableNo");
      item.restaurantName = restaurant.name;
      item.timeOfCreation = orderForPrint.createdAt;
      axios
        .post(
          `/order/${item.restaurantId}/${item.orderId}/print-order?dinify_print_station=${dinify_print_station}`,
          item
        )
        .then((result) => {
          // setAlertMessage("Order successfully placed and accepted");
          // setAlertOpen(true);
          // makeEncrypt("orderId", order._id);
          // dispatch(refreshCheckout());
        })
        .catch((error) => {
          // setAlertMessage("Order is successfully placed and accepted but printing failed");
          // setAlertOpen(true);
        });
    });
  };

  const acceptAndPrint = (orderId) => {
    acceptOrder(orderId)
      .then((res) => {
        // makeEncrypt("orderId", orderId);
        if (!useRazorpay) printAtTheRestaurantEnd(res.data.order);
      })
      .catch((error) => {
        // setAlertMessage("Order Cannot be placed");
        // setAlertOpen(true);
      });
  };

  const makeOrderLive = () => {
    axios
      .get(`/order/${makeDecrypt("restaurantId")}/make-order-live`)
      .then((res) => {
        if (res.data.user) {
          newSocket.current.emit("order-is-live", { userId: res.data.user });
          let tempWaitingOrders = [];
          if (waitingOrders.length) {
            waitingOrders.map((order) => {
              if (order.user != res.data.user) tempWaitingOrders.push(order.user);
            });
          }
          newSocket.current.emit("refresh-queue", {
            tempWaitingOrders: tempWaitingOrders,
          });
        }

        if (restaurant.printDirect && res.data._id) acceptAndPrint(res.data._id);
        dispatch(setReload());
        setCompleteBtnLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const getPayment = (id, isCompleteOrder) => {
    getUser(order.user)
      .then((res) => {
        capturePayment(res.stripeDetails.paymentMethod.paymentIntentId, stripeAccountId)
          .then((result) => {
            if (isCompleteOrder) {
              completeOrder(id, res.stripeDetails.paymentMethod.paymentIntentId)
                .then((data) => {
                  newSocket.current.emit("complete-order", {
                    orderId: order._id,
                  });
                  newSocket.current.emit("orderStatus", {
                    id: order._id,
                    status: "Completed",
                    restaurantId: makeDecrypt("restaurantId"),
                  });
                  makeOrderLive();
                  printTheReceipt(order._id, {
                    items: rows,
                    order,
                  });
                  setAlertMessage("Order completed");
                  setSuccessSnack(true);
                })

                .catch((error) => {
                  setAlertMessage(error.message);
                  setCompleteBtnLoading(false);
                });
            }
          })
          .catch((error) => {
            setAlertMessage(error.message);
            setCompleteBtnLoading(false);
          });
      })
      .catch((error) => {
        setAlertMessage(error.message);
        setCompleteBtnLoading(false);
      });
  };
  const handleCancel = async (id) => {
    if (
      restaurant.restaurantAddress.country === "india" ||
      (restaurant.restaurantAddress.country !== "india" &&
        !restaurant.dinifySidePayments) ||
      order.orderedByRest ||
      !order.paymentThroughApp
    ) {
      await cancelOrder(id);
      sendCancelOrderMsg();
      handleClose();
      dispatch(setReload());
      tempSocket.current.emit("orderStatus", {
        id: id,
        status: "Canceled",
      });
    } else {
      getUser(order.user)
        .then(async (res) => {
          axios
            .post("/stripe/cancelpayment", {
              payIntId: res.stripeDetails.paymentMethod.paymentIntentId,
              stripeAccountId: stripeAccountId,
            })
            .then(async (response) => {
              await cancelOrder(id);
              handleClose();
              sendCancelOrderMsg();
              dispatch(setReload());
              tempSocket.current.emit("orderStatus", {
                id: id,
                status: "Canceled",
              });
            })
            .catch((error) => setAlertMessage(error.message));
        })
        .catch((error) => setAlertMessage(error.message));
    }
  };

  // const formatMenuItems = (rows, setRows) => {
  //   const newRow = [];
  //   rows.map((row) => {
  //     if (newRow.some((eachRow) => eachRow.desc === row.desc)) {
  //       newRow.forEach((item) => {
  //         if (item.desc === row.desc) {
  //           item.qty = item.qty + row.qty;
  //           item.unit = item.unit + row.unit;
  //           item.price = item.price + row.price;
  //         }
  //       });
  //     } else newRow.push(row);
  //   });
  //   if (JSON.stringify(newRow) != JSON.stringify(rows)) {
  //     setRows(newRow);
  //   }
  // };

  const showModal = () => {
    return (
      <OrderModal
        order={clonedOrder}
        handleClose={handleClose}
        needAction={needAction}
        handleComplete={handleComplete}
        tempSocket={tempSocket}
        newSocket={newSocket}
        hideAcceptBtn={hideAcceptBtn}
        handleCancel={handleCancel}
        rows={rows}
        setRows={setRows}
        setRows2={setRows2}
        rows2={rows2}
        openInnerModal={openInnerModal}
        setOpenInnerModal={setOpenInnerModal}
        showUpdateBtn={true}
        completeBtnLoading={completeBtnLoading}
        cloverDeviceConnected={cloverDeviceConnected}
        setAlertMessage={setAlertMessage}
        isMerged={isMerged}
        handleMergeOrderComplete={handleMergeOrderComplete}
        getPayment={getPayment}
        multipleOrders={multipleOrders}
        setClonedOrder={setClonedOrder}
        handleCombineBill={handleCombineBill}
      />
    );
  };

  let activeTable = new URLSearchParams(window.location.search).get("tableNo");

  // const glowTable = (tableNo)=>{

  //   if(tableNo === activeTable)
  //   {
  //     setTimeout(() => {
  //       return({backgroundColor:'red'})
  //     }, 4000);
  //     return({backgroundColor:'blue'})
  //   }
  //   // return({backgroundColor:'blue'})

  // }

  const showActiveOrder = (currentTable) => {
    if (currentTable) {
      currentTable.style.backgroundColor = "#ced2f2";
      currentTable.style.transition = "background-color 3s";

      setTimeout(() => {
        currentTable.style.backgroundColor = "#F4F4F4";
      }, 3000);
    }
  };

  useEffect(() => {
    const currentTable = document.getElementById(`tableCard${activeTable}`);
    showActiveOrder(currentTable);
  }, []);

  useEffect(() => {
    const currentTable = document.getElementById(`tableCard${activeTable}`);

    if (currentTable) {
      // currentTable.style.backgroundColor = "#ced2f2";
      // currentTable.style.transition = "background-color 3s";
      // setTimeout(() => {
      //   currentTable.style.backgroundColor = "#F4F4F4";
      // }, 3000);
      handleOpen();
    }
  }, []);

  const [printerStatus, setPrinterStatus] = useState(false);
  const [isContinue, setIsContinue] = useState(false);

  useEffect(() => {
    tempSocket = tempSocket ? tempSocket : newSocket;

    // if(makeDecrypt('restaurantId') == data.restaurantId){
    if (makeDecrypt("printerStatus") == true) {
      // makeDecrypt('printerStatus' , true)
      setPrinterStatus(true);
      setIsContinue(true);
    } else {
      setPrinterStatus(false);
      setIsContinue(false);
      makeEncrypt("printerStatus", false);
    }
    // }

    tempSocket?.current.on("printer-status", (data) => {
      if (makeDecrypt("restaurantId") == data.restaurantId) {
        if (data.online) {
          makeEncrypt("printerStatus", true);
          setPrinterStatus(true);
          setIsContinue(true);
        } else {
          setPrinterStatus(false);
          setIsContinue(false);
          makeEncrypt("printerStatus", false);
        }
      }
    });
  }, []);

  // const handleContinue = () => {
  //   setIsContinue(true);
  //   setOpenInnerModal(false);
  // };

  const createPrintData = async () => {
    let temp;
    // order.items.map(async (item) => {
    for (const item of order.items) {
      const menuItem = await getMenuItem(item.itemId);
      if (item.isAccepted !== true) {
        let tempModifiers = [];
        if (item.modifiers) {
          // let tempModifiers = [];
          item.modifiers.map((modifier) => {
            tempModifiers.push({
              type: modifier.selectedTypeId,
              options: modifier.selectedOptions.map((option) => {
                return { name: option.modifierName };
              }),
            });
          });
        }

        //logic for separating data according to the printers
        temp = dataForPrint;
        if (temp.length) {
          if (
            temp.find(
              (tempItem) =>
                tempItem.printerName ===
                printers.find((printer) => printer._id == menuItem.printer)?.defaultName
            )
          ) {
            temp[
              temp.findIndex(
                (item) =>
                  item.printerName ===
                  printers.find((printer) => printer._id == menuItem.printer)?.defaultName
              )
            ].items.push({
              name: menuItem.name,
              quantity: item.quantity,
              specialInstructions: item.specialInstructions,
              modifiers: tempModifiers,
            });
          } else {
            temp.push({
              printerName: printers.find((printer) => printer._id == menuItem.printer)
                ?.defaultName,
              items: [
                {
                  name: menuItem.name,
                  quantity: item.quantity,
                  specialInstructions: item.specialInstructions,
                  modifiers: tempModifiers,
                },
              ],
            });
          }
        } else
          temp.push({
            printerName: printers.find((printer) => printer._id == menuItem.printer)
              ?.defaultName,
            items: [
              {
                name: menuItem.name,
                quantity: item.quantity,
                specialInstructions: item.specialInstructions,
                modifiers: tempModifiers,
              },
            ],
          });

        setDataForPrint(temp);
        // setDataForPrint((prevValues) => [
        //   ...prevValues,
        //   {
        //     name: menuItem.name,
        //     quantity: item.quantity,
        //     specialInstructions: item.specialInstructions,
        //     modifiers: tempModifiers,
        //     printer:printers.find(printer => printer._id == menuItem.printer).defaultName,
        //   },
        // ]);
      }
    }
    return temp;
  };

  const acceptTheOrder = async (id, iscontinue) => {
    try {
      let isCapturePayment =
        restaurant.dinifySidePayments &&
        !useRazorpay &&
        order.paymentThroughApp &&
        !order.paymentDone &&
        order.tableNumber === "takeout";
      // if (isCapturePayment) {
      //   getPayment(id, false);
      // }
      const response = await acceptOrder(id, isCapturePayment);

      sendAcceptOrderMsg();

      dispatch(setReload());
      tempSocket.current.emit("orderStatus", {
        id: id,
        status: "Accepted",
        restaurantId: order.restaurant,
      });

      let printdata = await createPrintData();

      let res = await sendWaitingTimeMsg();

      if (isContinue) {
        // let dataToSend = {
        //   restaurantId: makeDecrypt('restaurantId'),
        //   orderId:order._id,
        //   table:order.tableNumber,
        //   items:dataForPrint
        // }

        // tempSocket.current.emit("printData", dataToSend);
        printdata.map((item) => {
          item.restaurantId = makeDecrypt("restaurantId");
          item.orderId = order._id;
          item.table = order.tableNumber;
          item.orderName = order.orderName ? order.orderName : "";
          item.restaurantName = name;
          item.timeOfCreation = order.createdAt;
          printData(item, dinify_print_station).then((data) => { });
        });
      }
      setAcceptBtnLoading(false);
    } catch (error) {
      setAcceptBtnLoading(false);
      setAlertMessage(error.message);
    }
  };

  const handleAccept = (id) => {
    longAudio.pause();
    setAcceptBtnLoading(true);
    if (!isContinue) {
      tempSocket.current.emit("checkPrinterConnection", makeDecrypt("restaurantId"));

      if (printerStatus && isContinue) {
        acceptTheOrder(id, true);
      } else {
        setOpenInnerModal(true);
      }
    }
    if (!printerStatus && isContinue) {
      acceptTheOrder(id, false);
    }
    if (printerStatus && isContinue) {
      acceptTheOrder(id, true);
    }
  };

  const sendWaitingTimeMsg = async () => {
    let user = await getUser(order.user);
    let tempMsg = `Your takeout order at ${restaurant.name
      } placed successfully. Please allow minimum ${waitingTime} for your order to be ready. View your order: ${`https://dine.dinify.io/restaurant/menu/${restaurant._id}`}`;

    if (user.phone)
      return axios
        .post("order/send-message", {
          userPhoneNo: user.phone,
          msg: tempMsg,
        })
        .then((response) => {
          return response.message;
        })
        .catch((error) => {
          return error.message;
        });
  };

  const sendAcceptOrderMsg = async () => {
    let user = await getUser(order.user);
    let tempMsg = `Your takeout order is accepted by ${restaurant.name
      }, view order here: ${`https://dine.dinify.io/restaurant/menu/${restaurant._id}`}`;

    if (user.phone && order.tableNumber == "takeout")
      return axios
        .post("order/send-message", {
          userPhoneNo: user.phone,
          msg: tempMsg,
        })
        .then((response) => {
          return response.message;
        })
        .catch((error) => {
          return error.message;
        });
  };

  const sendCancelOrderMsg = async () => {
    let user = await getUser(order.user);
    let tempMsg = ` Your takeout order has been cancelled by ${restaurant.name}`;
    if (user.phone && order.tableNumber == "takeout")
      return axios
        .post("order/send-message", {
          userPhoneNo: user.phone,
          msg: tempMsg,
        })
        .then((response) => {
          return response.message;
        })
        .catch((error) => {
          return error.message;
        });
  };
  const sendCompleteOrderMsg = async () => {
    let user = await getUser(order.user);
    let tempMsg = `Your order at ${restaurant.name
      } is ready to pickup, view here: ${`https://dine.dinify.io/restaurant/menu/${restaurant._id}`}`;
    if (user.phone && order.tableNumber == "takeout")
      return axios
        .post("order/send-message", {
          userPhoneNo: user.phone,
          msg: tempMsg,
        })
        .then((response) => {
          return response.message;
        })
        .catch((error) => {
          return error.message;
        });
  };

  const closeSnack = () => {
    setSuccessSnack(false);
    setErrorSnack(false);
    setAlertMessage();
  };

  const handleCloseCloverAlert = () => {
    setCompleteBtnLoading(false);
    setShowCloverAlert(false);
  };

  const handleOrderClick = () => {
    if (areMultipleOrders) {
      // setShowOrders(true);
      setClonedOrder(multipleOrders[0]);
      handleOpen();
    } else {
      handleOpen();
    }
  };

  const handleCombineBill = () => {
    setIsMerged(true);
    let mergeOrder = null;
    mergeOrder = { ...multipleOrders[0] };
    mergeOrder.appliedFee = _.sumBy(multipleOrders, function (o) {
      return o.appliedFee;
    });
    mergeOrder.discount = _.sumBy(multipleOrders, function (o) {
      return o.discount;
    });
    mergeOrder.grandTotal = _.sumBy(multipleOrders, function (o) {
      return o.grandTotal;
    });
    mergeOrder.gstValue = _.sumBy(multipleOrders, function (o) {
      return o.gstValue;
    });
    mergeOrder.pstValue = _.sumBy(multipleOrders, function (o) {
      return o.pstValue;
    });
    mergeOrder.subTotal = _.sumBy(multipleOrders, function (o) {
      return o.subTotal;
    });
    mergeOrder.tax = _.sumBy(multipleOrders, function (o) {
      return parseFloat(o.tax);
    });
    mergeOrder.tip = _.sumBy(multipleOrders, function (o) {
      return parseFloat(o.tip);
    });
    mergeOrder.totalPrice = _.sumBy(multipleOrders, function (o) {
      return o.totalPrice;
    });
    let tempItems = [];
    for (const o of multipleOrders) {
      tempItems = tempItems.concat(o.items);
    }

    mergeOrder.items = tempItems;
    setClonedOrder(mergeOrder);
    setOpen(true);
  };

  const handleMergeOrderComplete = () => {
    setCompleteBtnLoading(false);

    async.series(
      {
        one: function (callback) {
          setTimeout(async function () {
            try {
              let res = !cloverDeviceConnected ? true : false;
              if (cloverDeviceConnected) {
                res = await paymentRequestToClover(
                  clonedOrder,
                  restaurant.clover.deviceId,
                  restaurant.clover.apiToken,
                  restaurant.name
                );
              }
              if (res) {
                for (const thisOrder of multipleOrders) {
                  completeOrder(thisOrder._id, "")
                    .then((data) => {
                      newSocket.current.emit("complete-order", {
                        orderId: thisOrder._id,
                        seqValue: thisOrder.seqValue,
                      });
                      newSocket.current.emit("orderStatus", {
                        id: thisOrder._id,
                        status: "Completed",
                        restaurantId: makeDecrypt("restaurantId"),
                      });

                      makeOrderLive();
                    })
                    .catch((error) => {
                      setAlertMessage(error.message);
                      setCompleteBtnLoading(false);
                    });
                }
              }
            } catch (error) {
              setAlertMessage(error.message);
            }
            callback(null, 1);
          }, 1000);
        },
        two: function (callback) {
          setTimeout(function () {
            if (!cloverDeviceConnected) {
              printTheReceipt(clonedOrder?._id, {
                items: rows,
                order: clonedOrder,
              });
            }
            setAlertMessage("Order completed");
            setOpen(false);
            setShowOrders(false);
            handleClose();
            dispatch(getLiveOrders());
            callback(null, 2);
          }, 1200);
        },
      },
      function (err, results) {
        // results is now equal to: {one: 1, two: 2}
      }
    );
  };

  // useEffect(() => {
  //   if (
  //     order.tableNumber == "takeout" &&
  //     order.items.some((item) => item.isAccepted !== true) &&
  //     !order.callMade
  //   ) {
  //     // setFlag(true);
  //     longAudio.play();
  //     timer = setTimeout(() => {
  //       longAudio.pause();
  //       makeCall();
  //     }, 30000);
  //   } else if (timer) {
  //     clearTimeout(timer);
  //   } else {
  //     longAudio.pause();
  //   }
  // }, [order]);

  useEffect(() => {
    if (order.items.some((item) => item.isAccepted === true)) longAudio.pause();
  }, [order]);

  const makeCall = () => {
    // setFlag(false);

    if (order.items.some((item) => item.isAccepted !== true) && !order.callMade)
      axios
        .post("/order/make-accept-order-call", {
          userPhoneNo: restaurant.restaurantAddress.phoneNo,
          orderId: order._id,
        })
        .then((response) => {
        })
        .catch((error) => {
          console.log(error);
        });
  };
  const toggleNoteExpanded = () => {
    setNoteExpanded(!noteExpanded);
  };

  return (
    <Box className={classes.root}>
      <Grid
        container
        spacing={1}
        className={classes.eachOrder}
        id={`tableCard${order.tableNumber}`}
      >
        <Grid
          item
          // display="flex"
          xs={10}
          sm={4}
          // alignItems="center"
          onClick={() => handleOrderClick()}
        // className={classes.orderRow}
        >
          <Box
            // className={classes.paper}
            display="flex"
            flexDirection="column"
            // alignItems="center"
            // justifyContent="center"
            style={{ fontSize: "25px", cursor: "pointer" }}
          >
            <Typography variant="h5">
              {order.deliveryOrder && "Delivery Order"}
              {order.tableNumber === "takeout"
                ? (currentUser?.phone ? "Takeout - " + order.orderName : "Takeout - " + order.orderName)
                : "Table No : " + order.tableNumber}
            </Typography>
            <Typography variant="body2" style={{ marginTop: "5px", color: "green" }}>
              {order.tableNumber === "takeout" && currentUser?.phone
                ? "Phone - " + currentUser?.phone
                : ""}
            </Typography>
            {(restaurant.dinifySidePayments && order.paymentDone) ||
              (order.tableNumber == "takeout" && order.paymentThroughApp) ? (
              <Typography variant="body2" style={{ marginTop: "5px", color: "green" }}>
                Bill Paid
              </Typography>
            ) : (
              <Typography variant="body2" style={{ marginTop: "5px", color: "maroon" }}>
                Bill Not Paid
              </Typography>
            )}
          </Box>
        </Grid>

        <Grid xs={2} className={classes.moreIcon}>
          <MoreVertIcon onClick={(e) => setAnchorEl(e.currentTarget)} />
        </Grid>

        {/* <Grid item sm={3} className={classes.actionBtn}>
          <Box display="flex" flexDirection="column">
            <Typography variant="h5">
              Special Instructions:
            </Typography>
            <Typography variant="body2" style={{ marginTop: "5px", whiteSpace: "nowrap" }}>
              {noteExpanded ? order.items[0].specialInstructions : order.items[0].specialInstructions.slice(0, 30)}
              {order.items[0].specialInstructions.length > 30 && (
                <Typography variant="span" style={{color: 'green'}} onClick={toggleNoteExpanded} >
                  {noteExpanded ? "  See less" : "...See more"}
                </Typography>
              )}
            </Typography>
          </Box>
        </Grid> */}

        <Grid item sm={4} xs={10} onClick={() => handleOrderClick()}>
          <Box
            display="flex"
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box display="flex" flexDirection="column" sx={{ cursor: "pointer" }}>
              <Typography variant="body2">
                {needAction
                  ? moment(order.createdAt).fromNow()
                  : moment(order.updatedAt).fromNow()}{" "}
              </Typography>
              <Typography className={classes.orderActionTimeS} variant="body2">
                {needAction
                  ? `Placed at : ${format(
                    new Date(order.createdAt),
                    "dd MMMM yyyy HH:mm"
                  )}`
                  : `Accepted at : ${format(
                    new Date(order.updatedAt),
                    "dd MMMM HH:mm "
                  )}`}
              </Typography>
            </Box>
            <Box className={classes.actionBtn}>
              {needAction && order.tableNumber == "takeout" && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={(e) => {
                    e.stopPropagation();
                    setTakeout(true);
                  }}
                // disabled={acceptBtnLoading}
                >
                  Add to Order
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
        {order.status != "Waiting" ? (
          <Grid
            item
            sm={4}
            // container
            justifyContent="center"
            className={classes.actionBtn}
          >
            <Box className={classes.acceptBtn}>
              {needAction && (
                <Box display="flex" justifyContent="space-evenly" alignItems="center">
                  {order.tableNumber == "takeout" && (
                    <Box>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        size="small"
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Waiting Time
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={waitingTime}
                          defaultValue={10}
                          onChange={(e) => setWaitingTime(e.target.value)}
                          label="Waiting Time"
                        >
                          <MenuItem value={5}>5 minutes</MenuItem>
                          <MenuItem value={10}>10 minutes</MenuItem>
                          <MenuItem value={15}>15 minutes</MenuItem>
                          <MenuItem value={20}>20 minutes</MenuItem>
                          <MenuItem value={25}>25 minutes</MenuItem>
                          <MenuItem value={30}>30 minutes</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  )}
                  <div className={classes.wrapper}>
                    {!areMultipleOrders && (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          handleAccept(order._id);
                        }}
                        disabled={acceptBtnLoading}
                      >
                        Accept Order
                      </Button>
                    )}

                    {acceptBtnLoading && (
                      <CircularProgress size={24} className={classes.buttonProgress} />
                    )}
                  </div>
                </Box>
              )}

              {/* <Box position="absolute">
              
                {!needAction && order.tableNumber == "takeout" && (
                  <div className={classes.wrapper} style={{marginLeft: "auto"}}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setTakeout(true);
                    }}
                    // disabled={acceptBtnLoading}
                  >
                    Add to Order
                  </Button>
                  </div>
                )}
                
              </Box> */}
              {!needAction && (
                <div className={classes.wrapper} style={{ marginLeft: "auto" }}>
                  {/* <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setTakeout(true);
                    }}
                    // disabled={acceptBtnLoading}
                  >
                    Add to Order
                  </Button> */}
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() =>
                      areMultipleOrders
                        ? handleMergeOrderComplete()
                        : handleComplete(order._id)
                    }
                    id="completeOrderBtn"
                    disabled={
                      completeBtnLoading ||
                      (restaurant.dinifySidePayments &&
                        restaurant.restaurantAddress.country === "INDIA" &&
                        !order.paymentDone &&
                        order.paymentThroughApp)
                    }
                  >
                    Complete Order
                  </Button>

                  {completeBtnLoading && (
                    <CircularProgress
                      color="success"
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              )}
            </Box>
          </Grid>
        ) : (
          <Grid
            item
            sm={3}
            x={12}
            container
            justifyContent="center"
            className={classes.actionBtn}
          >
            <span className={classes.acceptBtn}>
              {needAction && (
                <div className={classes.wrapper}>
                  {role === "ROLE_ADMIN" && needAction && (
                    <Button
                      color="error"
                      variant="contained"
                      onClick={() => {
                        setOpenCancelWarning(true);
                      }}
                    >
                      Cancel Order
                    </Button>
                  )}

                  {acceptBtnLoading && (
                    <CircularProgress size={24} className={classes.buttonProgress} />
                  )}
                </div>
              )}
            </span>
          </Grid>
        )}
      </Grid>

      {/* {open && <Box className={classes.paper}>{clonedOrder ? showModal() : ""}</Box>} */}
      {open && clonedOrder && showModal() }
      <Modal
        className={classes.modal}
        open={openCancelWarning}
        onClose={() => setOpenCancelWarning(false)}
        closeAfterTransition
      // BackdropComponent={Backdrop}
      // BackdropProps={{
      //   timeout: 500,
      // }}
      >
        <Fade in={openCancelWarning}>
          <Box
            className={classes.paper}
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            {/* <Paper> */}
            <Typography fontWeight="medium" >Do you really want to cancel this order?</Typography>
            <Box display="flex" justifyContent="space-around" width="100%" mt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleCancel(order._id)}
              >
                Ok
              </Button>
              <Button
                variant="contained"
                color="warning"
                onClick={() => setOpenCancelWarning(false)}
              >
                Close
              </Button>
            </Box>
            {/* </Paper> */}
          </Box>
        </Fade>
      </Modal>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        {order.status != "Waiting" ? (
          needAction ? (
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                handleAccept(order._id);
              }}
            >
              Accept
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                handleComplete(order._id);
              }}
            >
              Complete
            </MenuItem>
          )
        ) : (
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              setOpenCancelWarning(true);
            }}
          >
            Cancel
          </MenuItem>
        )}
        {order.tableNumber == "takeout" && (
          <MenuItem
            onClick={() => {
              setTakeout(true);
            }}
          >
            Add to Order
          </MenuItem>
        )}
      </Menu>

      {/* <AlertMsg alertMessage={alertMessage} /> */}

      {/* <Snackbar open={successSnack} autoHideDuration={6000} onClose={closeSnack}>
        <Alert onClose={closeSnack} severity="success">
          {alertMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorSnack} autoHideDuration={6000} onClose={closeSnack}>
        <Alert onClose={closeSnack} severity="error">
          {alertMessage}
        </Alert>
      </Snackbar> */}

      <Dialog
        open={showCloverAlert}
        onClose={handleCloseCloverAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h5">Payment machine is offline</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography>
              {" "}
              Payment machine is offline or is not accepting payments, please turn on the
              payment device or select "Proceed Anyway" to mark the order as complete
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAskConfirmation(true);
              setShowCloverAlert(false);
            }}
            color="primary"
            variant="contained"
          >
            Proceed anyway
          </Button>

          <Button
            onClick={handleCloseCloverAlert}
            color="primary"
            variant="outlined"
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={askConfirmation}
        onClose={() => setAskConfirmation(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h5">Are you sure?</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography>
              By selecting yes, you are confirming that you have collected the payment
              from the customer.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAskConfirmation(false);
              handleClose();
            }}
            color="primary"
            variant="outlined"
            autoFocus
          >
            No
          </Button>
          <Button
            onClick={() => completeOrderOnly(order._id)}
            color="primary"
            variant="contained"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {areMultipleOrders && (
        <MultiOrderSelection
          showOrders={showOrders}
          setShowOrders={setShowOrders}
          setActiveOrder={setClonedOrder}
          liveOrders={multipleOrders}
          tableNo={multipleOrders[0].tableNumber}
          tableOrders={multipleOrders}
          activeOrder={clonedOrder}
          setShowModal={handleOpen}
          handleCombineBill={handleCombineBill}
        />
      )}
    </Box>
  );
}

export default EachOrder;
