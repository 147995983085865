import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  ListItemAvatar,
  ListItemText,
  ListItem,
  List,
  Avatar,
  OutlinedInput,
  TextField,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {Formik} from "formik";
import {useState} from "react";
import {Helmet} from "react-helmet";
import {useDispatch} from "react-redux";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import FacebookIcon from "src/icons/Facebook";
import GoogleIcon from "src/icons/Google";
import * as Yup from "yup";
import {CheckResturant, loginAction} from "../redux/actions/authActions";
const useStyles = makeStyles()((theme) => {
  return {
    container: {
      display: "flex",
      width: "100%",
      minHeight: "calc(100vh - 240px)",
      flexDirection: "column",
      textAlign: "center",
      padding: "0",
    },
    list: {
      padding: "0px 15px",
    },
    listItem: {
      backgroundColor: "white",
      cursor: "pointer",
      borderRadius: "10px",
      margin: "10px 0px",
      boxShadow:
        "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
    },
  };
});
const Login = () => {
  // const { authenticated } = useSelector(
  //   (state) => state.auth
  // );
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [multipleRestaurants, setmultipleRestaurants] = useState(false);
  const [RestaurantList, setRestaurantList] = useState([]);
  const [credential, setcredential] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {classes} = useStyles();
  const [keepMeSignedInCheck, setKeepMeSignedInCheck] = useState(false);
  const handleClickOpen = (message) => {
    setMessage(message);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // const [email, setEmail] = useState('')
  // const [password, setPassword] = useState('')
  return (
    <>
      <Helmet>
        <title>Login | Dinify</title>
      </Helmet>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Login Unsuccessful"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={multipleRestaurants}
        className={classes.container}
        onClose={() => setmultipleRestaurants(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Select a Restaurant</DialogTitle>
        <DialogContent>
          <List className={classes.list}>
            {RestaurantList?.map((restaurant) => {
              return (
                <ListItem
                  className={classes.listItem}
                  key={restaurant._id}
                  onClick={(e) => {
                    credential.restaurantId = restaurant._id;
                    dispatch(loginAction(credential, navigate)).then((response) => {
                      handleClickOpen(response);
                    });
                  }}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <img src={restaurant.logoUrl} width="100%" height="100%" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={restaurant.name}
                    secondary={restaurant?.restaurantAddress?.streetName}
                  />
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
      </Dialog>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
              password: Yup.string().max(255).required("Password is required"),
            })}
            onSubmit={(values, {setSubmitting, setFieldError, setErrors, setStatus}) => {
              const userData = {
                email: values.email,
                password: values.password,
                keepMeSignedInCheck,
                restaurantId: "",
              };
              setcredential(userData);
              dispatch(CheckResturant(userData)).then((response) => {
                if (!response.message) {
                  dispatch(loginAction(userData, navigate)).then((response) => {
                    // navigate('/app/dashboard', { replace: true });
                    handleClickOpen(response);
                    setSubmitting(false);
                  });
                } else {
                  setRestaurantList(response.data);
                  setSubmitting(false);
                  setmultipleRestaurants(true);
                }
              });
            }}
          >
            {({
              errors,
              error,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                {error && <div>{error}</div>}
                <Box sx={{mb: 3}}>
                  <Typography color="textPrimary" variant="h2">
                    Sign in
                  </Typography>
                  <Typography color="textSecondary" gutterBottom variant="body2">
                    Sign in on the internal platform
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    error={Boolean(touched.password && errors.password)}
                    label="Password"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type={showPassword ? "text" : "password"}
                    value={values.password}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {touched.password && errors.password && (
                    <FormHelperText error id="password-error">
                      {errors.password}
                    </FormHelperText>
                  )}
                </FormControl>
                <Box sx={{py: 2}} display="flex" alignItems="center">
                  <input
                    type="checkbox"
                    id="keepMeSignedIn"
                    onChange={(e) => setKeepMeSignedInCheck(e.target.checked)}
                    checked={keepMeSignedInCheck}
                  />
                  <Typography variant="body2" htmlFor="keepMeSignedIn" ml={1}>
                    Keep me signed in
                  </Typography>
                </Box>

                <Box sx={{py: 2, position: "relative"}}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: -12,
                        marginLeft: -12,
                      }}
                    />
                  )}
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    color="textSecondary"
                    variant="body1"
                    style={{marginRight: "5px"}}
                  >
                    Don&apos;t have an account?
                  </Typography>
                  <Link component={RouterLink} to="/register" variant="h6">
                    Sign up
                  </Link>
                  <Link
                    component={RouterLink}
                    to="/forgot-password"
                    variant="h6"
                    style={{marginLeft: "auto"}}
                  >
                    Forgot Password
                  </Link>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;
