import {
  Box,
  Breadcrumbs,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  Link,
} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import React, {useState} from "react";
import ReactPlayer from "react-player";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import HomeIcon from "@mui/icons-material/Home";
import {useNavigate} from "react-router";

const useStyles = makeStyles()(() => ({
  videoBox: {position: "relative"},
  videoCard: {
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    // pointerEvents: "none",
  },
  musicBox: {
    position: "absolute",
    bottom: "10PX",
    right: "20px",
  },
  playIcon: {
    position: "absolute",
    transform: "translate(-50%,-50%)",
    top: "50%",
    left: "50%",
    fontSize: "35px",
  },
  titleSkeleton: {
    width: "100%",
    height: "30px",
    marginTop: "10px",
    backgroundColor: "grey",
    animation: `$myEffectTitle 700ms ease infinite alternate`,
  },
  "@keyframes myEffectTitle": {
    to: {
      opacity: 0.5,
    },
  },
  videoSkeleton: {
    width: "300px",
    height: "200px",
    backgroundColor: "gray",
    animation: `$myEffect 700ms ease infinite alternate`,
  },
  "@keyframes myEffect": {
    to: {
      opacity: 0.5,
    },
  },
  breadCrumbs: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2px 8px ",
    borderRadius: "20px",
    backgroundColor: "#ffffff",
    border: "1px solid #FF5757",
    fontSize: "15px",
    fontWeight: "500",
    "& .MuiBreadcrumbs-separator svg": {
      fontSize: "20px !important",
    },
  },
}));

export default function KnowledgeBase() {
  const {classes} = useStyles();
  const [activeVid, setActiveVid] = useState();
  const [activeUrl, setActiveUrl] = useState();
  const [open, setOpen] = useState();
  const [isPlaying, setIsPlaying] = useState();
  const [isReady, setIsReady] = useState();
  const navigate = useNavigate();

  const videos = [
    {
      link: "https://dinify-dev.s3.us-west-2.amazonaws.com/knowledge-base/add+menu.mp4",
      title: "How to add menu items",
    },
    {
      link: "https://dinify-dev.s3.us-west-2.amazonaws.com/knowledge-base/add+and+delete+tables.mp4",
      title: "How to add/delete tables?",
    },
    {
      link: "https://dinify-dev.s3.us-west-2.amazonaws.com/knowledge-base/add+tables.mp4",
      title: "How to add tables?",
    },
    {
      link: "https://dinify-dev.s3.us-west-2.amazonaws.com/knowledge-base/enable+disable+tables.mp4",
      title: "How to enable/disable tables?",
    },
  ];

  const handleClick = (video, id) => {
    document.getElementById(id).click();
    setOpen(true);
    setActiveUrl(video.link);
  };

  return (
    <div>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        style={{margin: "20px"}}
      >
        <Link
          color="primary"
          onClick={() => navigate("/app/dashboard")}
          style={{cursor: "pointer"}}
        >
          <Box className={classes.breadCrumbs}>
            <HomeIcon color="primary" style={{fontSize: "15px", marginRight: "2px"}} />
            Home
          </Box>
        </Link>
        <Link
          color="primary"
          // href="/app/orders"
          style={{cursor: "pointer"}}
        >
          <Box className={classes.breadCrumbs}>
            <MenuBookIcon
              color="primary"
              style={{fontSize: "15px", marginRight: "2px"}}
            />
            Knowledge Base
          </Box>
        </Link>
      </Breadcrumbs>
      <Container>
        <Box sx={{mt: 2, mb: 1}}>
          <Typography variant="h3">Knowledge Base</Typography>
        </Box>
        <Divider />
        <Box sx={{px: 2, mt: 2}}>
          <Grid container spacing={3}>
            {videos.length ? (
              videos.map((video, index) => {
                return (
                  <Grid item key={video.title}>
                    <Grid
                      id={index}
                      container
                      className={classes.videoCard}
                      onClick={() => handleClick(video, index)}
                    >
                      <Grid item>
                        <Box className={classes.videoBox}>
                          <ReactPlayer
                            width="300px"
                            height="200px"
                            url={video.link}
                            muted={activeUrl == video.link}
                            playIcon={<PlayCircleOutlineIcon />}
                            config={{
                              youtube: {
                                playerVars: {showinfo: 1},
                              },
                            }}
                            onReady={() => setIsReady(true)}
                          />
                          <PlayCircleOutlineIcon className={classes.playIcon} />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box sx={{pt: 1}}>
                          <Typography style={{fontSize: "20px"}} color="textPrimary">
                            {video.title}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <>
                <Grid item>
                  <Grid container>
                    <Grid className={classes.videoSkeleton} items></Grid>
                    <Grid className={classes.titleSkeleton} items></Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container>
                    <Grid className={classes.videoSkeleton} items></Grid>
                    <Grid className={classes.titleSkeleton} items></Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container>
                    <Grid className={classes.videoSkeleton} items></Grid>
                    <Grid className={classes.titleSkeleton} items></Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container>
                    <Grid className={classes.videoSkeleton} items></Grid>
                    <Grid className={classes.titleSkeleton} items></Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
        <Box className={classes.musicBox}>
          <Typography variant="body2">AUDIO CREDITS - SCOTT HOLMES MUSIC</Typography>
        </Box>
      </Container>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth
      >
        <ReactPlayer
          width="100%"
          height="60%"
          url={activeUrl}
          controls={true}
          playing
          config={{
            youtube: {
              playerVars: {showinfo: 1},
            },
          }}
        />
        {/* <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
