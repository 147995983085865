import {Box, Container, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getSubscription} from "src/components/account/helpers";
import {getUserData} from "src/redux/actions/authActions";

const NeedActivation = () => {
  //   const {role} = useSelector((state) => state.auth);
  //   const dispatch = useDispatch();
  //   const navigate = useNavigate();
  //   useEffect(() => {
  //     if (!role) {
  //       dispatch(getUserData());
  //     }
  //   }, []);

  //   const {restaurant} = useSelector((state) => state);
  //   useEffect(() => {
  //     if (restaurant.status && restaurant.status === "active") {
  //       navigate("/app/dashboard", {replace: true});
  //     }
  //   }, [restaurant]);

  return (
    <>
      <Helmet>
        <title>Account pending activation</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="md">
          <Typography align="center" color="textPrimary" variant="h1">
            Please wait, your account is currently inactive, kindly contact us at
            info@dinify.io
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle2"></Typography>
          <Box sx={{textAlign: "center"}}></Box>
        </Container>
      </Box>
    </>
  );
};

export default NeedActivation;
