import {Box, IconButton, Menu, MenuItem, Typography} from "@mui/material";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {deleteDiscount, getAllDiscounts} from "src/redux/actions/discountActions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DiscountForm from "./DiscountForm";
import {makeStyles} from "tss-react/mui";

const useStyle = makeStyles()(() => {
  return {
    root: {
      padding: "10px",
      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;",
    },
  };
});

function DiscountCard({discount, handleClickOpen}) {
  const {classes} = useStyle();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    dispatch(deleteDiscount(discount._id, dispatch)).then(() => {
      dispatch(getAllDiscounts(dispatch));
      handleClose();
    });
  };

  return (
    <div>
      <Box display={"flex"} alignItems="center" className={classes.root}>
        <Box sx={{cursor: "pointer", mr: 2}}>
          <Typography variant="h5" fontSize={15} onClick={() => setOpen(true)}>
            {discount.name}
          </Typography>
        </Box>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          sx={{backgroundColor: "#FFEBEB"}}
        >
          <MoreVertIcon sx={{fontSize: "20px"}} />
        </IconButton>
      </Box>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => setOpen(true)}>Edit</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
      {open && <DiscountForm setOpen={setOpen} discount={discount} />}
    </div>
  );
}

export default DiscountCard;
