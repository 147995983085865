import {
  Backdrop,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Fade,
  FormControl,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Divider,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setReload, getLiveOrders } from "src/redux/actions/orderActions";
import Spinner from "src/utils/spinner/spinner";
import {
  acceptOrder,
  cancelOrder,
  getLoggedInEmployee,
  getMenuItem,
  getUser,
  refundPayment,
  printData,
  updateUser,
  updateOrder,
  printTheReceipt,
} from "./helpers";

import axios from "src/axios";
import { differenceInDays } from "date-fns";
import AlertMsg from "../AlertMsg";
import { makeDecrypt, makeEncrypt } from "src/utils/securels";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import OrderSplitting from "./OrderSplitting";
import CallIcon from "@mui/icons-material/Call";
const useStyles = makeStyles()((theme) => {
  return {
    table: {
      width: "100%",
      // minWidth: 700,
      // backgroundColor: "red"
    },
    header: {
      backgroundColor: "#fadbc8",
      color: "white",
    },
    tableContainer: {
      maxHeight: "90vh",
      // minWidth: "250px",
      position: "relative",
    },
    previousItemsTable: {
      width: "100%",
    },
    footer: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      padding: "10px",
    },
    btnWrapper: {
      marginLeft: "auto",
      width: "fit-content",
      position: "relative",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      width: "fit-content",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2),
    },
    loaderCont: {
      // position: "absolute",
      // top: "50%",
      // left: "50%",
      // transform: "translate(-50%,-50%)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "400px",
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  };
});

function OrderModal({
  order,
  handleClose,
  needAction,
  handleComplete,
  pastOrder,
  tempSocket,
  newSocket,
  hideAcceptBtn,
  handleCancel,
  rows,
  setRows,
  rows2,
  setRows2,
  setOpenInnerModal,
  openInnerModal,
  showUpdateBtn,
  completeBtnLoading,
  cloverDeviceConnected,
  setAlertMessage,
  isMerged,
  handleMergeOrderComplete,
  getPayment,
  multipleOrders,
  setClonedOrder,
  handleCombineBill,
}) {
  const dispatch = useDispatch();
  const [saveOn, setsaveOn] = useState(true);

  // const [rows, setRows] = useState([]);

  // const [rows2, setRows2] = useState([]);
  const [planPercentageFee, setPlanPercentageFee] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);
  const [dataForPrint, setDataForPrint] = useState([]);
  const { classes } = useStyles();
  let [taxRate, setTaxRate] = useState(0);
  let [tipValue, setTipValue] = useState(0);
  let {
    taxTypes,
    stripeAccountId,
    restaurantName,
    dinify_payment_plan,
    printers,
    name,
    dinify_print_station,
  } = useSelector((state) => state.restaurant);
  const { restaurant } = useSelector((state) => state);
  // const [openInnerModal, setOpenInnerModal] = useState(false);
  const [openRefundModal, setOpenRefundModal] = useState(false);
  const [warningRefund, setWarningRefund] = useState(false);
  const [refundAmount, setRefundAmount] = useState("");
  // const [alertMessage, setAlertMessage] = useState("");
  const [pstPerc, setPstPerc] = useState();
  const [pstVal, setPstVal] = useState();
  const { currencySign } = useSelector((state) => state.restaurant);
  const [currentUser, setCurrentUser] = useState(null);
  const [userCart, setUserCart] = useState([]);
  const [updatedOrderItems, setUpdatedOrderItems] = useState([]);
  const [payThroughApp, setPayThroughApp] = useState(true);
  const [dinifySidePayments, setDinifySidePayments] = useState(true);
  const [useRazorpay, setUseRazorpay] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [pstValue, setPstValue] = useState(0);
  const [taxValue, setTaxValue] = useState(0);
  const [finalValue, setFinalValue] = useState(0);
  const [currentCartItems, setCurrentCartItems] = useState([]);
  const [invoiceSubtotal, setInvoiceSubtotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [allowDeleteItems, setAllowDeleteItems] = useState(false);
  const [orderUpdating, setOrderUpdating] = useState(false);
  const [btnLoading, setBtnLoading] = useState();
  const [accepting, setAccepting] = useState(false);
  const [orderItemsWithPst, setOrderItemsWithPst] = useState([]);
  const [openOrderSplitting, setOpenOrderSplitting] = useState(false);
  const [orderSplitValues, setOrderSplitValues] = useState([]);
  const specialInst = [];
  const spiorder = order.items;

  useEffect(() => {
    if (order) {
      const checkIfAdmin = async () => {
        const role = await getLoggedEmployee();
        if (role === "ROLE_ADMIN") setIsAdmin(true);

        setTaxRate(parseFloat(order.tax));
        !order.orderedByRest && setTipValue(parseFloat(order.tip));
        setOpenInnerModal(false);
      };
      checkIfAdmin();
    }
  }, [order]);

  useEffect(() => {
    if (order) {
      const getUserForOrder = async () => {
        let thisUser = await getUser(order.user);
        setUserCart(thisUser.cart.items);
        setCurrentUser(thisUser);
        setUpdatedOrderItems(order.items);
        setPayThroughApp(order.paymentThroughApp);
        // setInvoiceSubtotal(invoiceSubtotal - order.discount);
      };
      getUserForOrder();
    }
  }, [order]);

  useEffect(() => {
    if (restaurant) {
      setDinifySidePayments(
        restaurant.dinifySidePayments === undefined ? true : restaurant.dinifySidePayments
      );

      setUseRazorpay(restaurant.restaurantAddress.country.toLowerCase() == "india");
    }
  }, [restaurant]);

  //TODO:pstVal,pstValue: calculate only one use that one only
  useEffect(() => {
    let tempPrice = 0;
    let temp = 0;
    currentCartItems.map((order) => {
      tempPrice = tempPrice + order.order.price * order.noOfItems;
      if (order.order.pstApplicable) {
        temp =
          temp +
          ((order.order.price *
            (order.order.pstPercentage
              ? order.order.pstPercentage
              : parseFloat(taxTypes.PST))) /
            100) *
          order.noOfItems;
      }
    });

    if (isMerged) {
      setPstValue(order.pstValue);
    } else {
      setPstValue(temp.toFixed(2));
    }

    setSubTotal(tempPrice);
    order?.discount && (tempPrice = tempPrice - order.discount);

    setTotalPrice(tempPrice);
  }, [currentCartItems]);
  useEffect(() => {
    let justItems = [];
    userCart.map(async (item) => {
      try {
        let menuItem = await axios.get(`/menu/${item.itemId}/menu-item-detail`);
        menuItem = menuItem.data;
        justItems.push({
          order: {
            objectId: item._id,
            category: menuItem.category,
            price: item.price,
            _id: menuItem._id,
            name: menuItem.name,
            imageUrl: menuItem.imageUrl,
            pstApplicable: menuItem.pstApplicable,
            pstPercentage: menuItem.pstPercentage,
            specialInstructions: item.specialInstructions,
            modifiers: item.modifiers,
          },
          noOfItems: item.quantity,
          isOrdered: item.isOrdered,
        });

        if (justItems.length == userCart.length) setCurrentCartItems(justItems);
      } catch (error) {
        setAlertMessage(error.message);
      }
    });
  }, [userCart.length]);

  const [appliedFee, setAppliedFee] = useState(0);

  useEffect(() => {
    let value = parseFloat((parseFloat(taxTypes.GST) * parseFloat(totalPrice)) / 100);
    if (isMerged) {
      setTaxValue(order.gstValue);
    } else {
      setTaxValue(value);
    }

    setFinalValue(parseFloat(parseFloat(totalPrice) + value));
    // setTipPercentage(((tip * 100) / totalPrice).toFixed(2));
    if (restaurant && dinifySidePayments && payThroughApp && order?.appliedFee)
      if (isMerged) {
        setAppliedFee(order.appliedFee);
      } else {
        setAppliedFee(
          restaurant.dinify_payment_plan.push_to_customer
            ? restaurant.dinify_payment_plan.application_service_fee
            : restaurant.dinify_payment_plan.half_to_customer
              ? restaurant.dinify_payment_plan.application_service_fee / 2
              : 0
        );
      }
  }, [totalPrice]);

  useEffect(() => {
    if (rows.length + rows2.length < 2) {
      // setIsEdit(false);
      setAllowDeleteItems(false);
    } else {
      setAllowDeleteItems(true);
    }
  }, [rows.length, rows2.length]);

  //Currently not using this logic
  // useEffect(() => {
  //   if (dinify_payment_plan) {
  //     axios
  //       .post(`/stripe/getPrice`, {priceId: dinify_payment_plan.plan_id})
  //       .then((data) => {
  //         axios.get(`/price/get-price/${data.data.id}`).then((res) => {
  //           setPlanPercentageFee(parseFloat(res.data.application_percentage_fee));
  //         });
  //       })
  //       .catch((error) => error);
  //   }
  // }, [dinify_payment_plan]);

  const getLoggedEmployee = async () => {
    const employee = await getLoggedInEmployee();
    // setIsAdmin(true);
    return employee.employee.role;
  };

  function ccyFormat(num) {
    return `${num.toFixed(2)}`;
  }

  function priceRow(qty, unit) {
    return qty * unit;
  }

  function createRow(desc, note, qty, unit, _id) {
    const price = priceRow(qty, unit);
    return { desc, note, qty, unit, price, _id };
  }

  function subtotal(items) {
    return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
  }

  // const handleCancel = async (id) => {
  //   if (
  //     restaurant.restaurantAddress.country === "india" ||
  //     (restaurant.restaurantAddress.country !== "india" && !restaurant.dinifySidePayments)
  //   ) {
  //     await cancelOrder(id);
  //     handleClose();
  //     dispatch(setReload());
  //     tempSocket.current.emit("orderStatus", {
  //       id: id,
  //       status: "Canceled",
  //     });
  //   } else {
  //     getUser(order.user)
  //       .then((res) => {
  //         axios
  //           .post("/stripe/cancelpayment", {
  //             payIntId: res.stripeDetails.paymentMethod.paymentIntentId,
  //             stripeAccountId: stripeAccountId,
  //           })
  //           .then(async (response) => {
  //             await cancelOrder(id);
  //             handleClose();
  //             dispatch(setReload());
  //             tempSocket.current.emit("orderStatus", {
  //               id: id,
  //               status: "Canceled",
  //             });
  //           })
  //           .catch((error) => setAlertMessage(error.message));
  //       })
  //       .catch((error) => setAlertMessage(error.message));
  //   }
  // };

  const handleCloseInnerModal = () => {
    setOpenInnerModal(false);
  };

  const handleCloseRefundModal = () => {
    setOpenRefundModal(false);
  };

  const handleCloseWarningRefund = () => {
    setWarningRefund(false);
  };

  const [printerStatus, setPrinterStatus] = useState(false);
  const [isContinue, setIsContinue] = useState(false);
  useEffect(() => {
    tempSocket = tempSocket ? tempSocket : newSocket;

    // if(makeDecrypt('restaurantId') == data.restaurantId){
    if (makeDecrypt("printerStatus") == true) {
      // makeDecrypt('printerStatus' , true)
      setPrinterStatus(true);
      setIsContinue(true);
    } else {
      setPrinterStatus(false);
      setIsContinue(false);
      makeEncrypt("printerStatus", false);
    }
    // }

    tempSocket.current.on("printer-status", (data) => {
      if (makeDecrypt("restaurantId") == data.restaurantId) {
        if (data.online) {
          makeEncrypt("printerStatus", true);
          setPrinterStatus(true);
          setIsContinue(true);
        } else {
          setPrinterStatus(false);
          setIsContinue(false);
          makeEncrypt("printerStatus", false);
        }
      }
    });
  }, []);

  const handleContinue = () => {
    setIsContinue(true);
    setOpenInnerModal(false);
    acceptTheOrder(order._id, true);
  };

  const acceptTheOrder = async (id, iscontinue) => {
    setAccepting(true);
    let isCapturePayment =
      restaurant.dinifySidePayments &&
      !useRazorpay &&
      order.paymentThroughApp &&
      !order.paymentDone &&
      order.tableNumber === "takeout";
    if (isCapturePayment && getPayment) {
      getPayment(id, false);
    }
    try {
      const response = await acceptOrder(id);
      sendAcceptOrderMsg();
      handleClose();
      setAccepting(false);
      dispatch(setReload());
      tempSocket.current.emit("orderStatus", {
        id: id,
        status: "Accepted",
        restaurantId: order.restaurant,
      });

      // let dataToSend = {
      //   restaurantId: makeDecrypt('restaurantId'),
      //   orderId:order._id,
      //   table:order.tableNumber,
      //   items:dataForPrint
      // }

      // dataForPrint.forEach(item => {
      //   item.restaurantId = makeDecrypt('restaurantId');
      //   item.orderId = order._id;
      //   item.table = order.tableNumber;
      //   printData(item).then(data => {
      //   })

      // })

      //TODO:Add condition for printer online

      if (isContinue) {
        // let dataToSend = {
        //   restaurantId: makeDecrypt('restaurantId'),
        //   orderId:order._id,
        //   table:order.tableNumber,
        //   items:dataForPrint
        // }

        // tempSocket.current.emit("printData", dataToSend);
        dataForPrint.forEach((item) => {
          item.restaurantId = makeDecrypt("restaurantId");
          item.orderId = order._id;
          item.table = order.tableNumber;
          item.orderName = order.orderName ? order.orderName : "";
          item.restaurantName = name;
          item.timeOfCreation = order.createdAt;
          printData(item, dinify_print_station).then((data) => { });

          // TODO:Make iscontinue , for testing it is false

          // String.prototype.toBytes = function () {
          //   var arr = [];
          //   for (var i = 0; i < this.length; i++) {
          //     arr.push(this[i].charCodeAt(0));
          //   }
          //   return arr;
          // };

          // let esc = "\x1B"; //ESC byte in hex notation
          // let newLine = "\x0A"; //LF byte in hex notation

          // let cmds = esc + "@"; //Initializes the printer (ESC @)
          // cmds += esc + "!" + "\x38"; //Emphasized + Double-height + Double-width mode selected (ESC ! (8 + 16 + 32)) 56 dec => 38 hex
          // cmds += "Table # " + order.tableNumber; //text to print
          // cmds += newLine;
          // cmds += esc + "!" + "\x18"; //Emphasized + Double-height mode selected (ESC ! (16 + 8)) 24 dec => 18 hex

          // var datetime = new Date();
          // var dateString = new Date(
          //   datetime.getTime() - datetime.getTimezoneOffset() * 60000
          // );
          // var curr_time = dateString
          //   .toISOString()
          //   .replace("T", " ")
          //   .substr(0, 19);

          // cmds += esc + "!" + "\x18"; //Emphasized + Double-height mode selected (ESC ! (16 + 8)) 24 dec => 18 hex
          // // cmds += "Table # " + table;
          // cmds += newLine;
          // cmds += "Time: " + curr_time;
          // cmds += esc + "i" + newLine;
          // cmds += `-------------- ` + newLine;

          // // dataForPrint.map((row) => (
          // //   cmds += `${row.name} x${row.quantity}` + newLine,
          // //   row.modifiers !== '' ? cmds += `---------------- ${row.modifiers}` + newLine : '',
          // //   row.specialInstructions !== '' ? cmds += `---------------- ${row.specialInstructions}` + newLine : ''
          // // ))

          // item.items.map(
          //   (row, index) => (
          //     (cmds += `${row.quantity} X ${row.name}` + newLine),
          //     row.modifiers.length
          //       ? // cmds += `Modifiers` + newLine
          //         row.modifiers.map((modifier, index1) => {
          //           cmds += `--- ${modifier.type} - `;
          //           modifier.options.map((option, index2) => {
          //             cmds += ` ${option.name}`;
          //           });
          //           cmds += newLine;
          //         })
          //       : "",
          //     row.specialInstructions !== ""
          //       ? (cmds += `Instructions ${row.specialInstructions}` + newLine)
          //       : "",
          //     (cmds += `-------------- ` + newLine)
          //   )
          // );
          // cmds += newLine;

          // // cmds += esc + '!' + '\x18'; //Emphasized + Double-height mode selected (ESC ! (16 + 8)) 24 dec => 18 hex
          // // cmds += 'Table # ' + order.tableNumber;
          // // cmds += newLine
          // // cmds += 'Time: ' + curr_time;
          // // cmds += esc + 'i'

          // let buff = Buffer.from(
          //   cmds.toBytes().concat([0x01b, 0x64, 10, 0x1d, 0x56, 0x00])
          // );
          // let b64 = buff.toString("base64");

          // var myHeaders = new Headers();
          // myHeaders.append("Content-Type", "application/json");
          // myHeaders.append(
          //   "Authorization",
          //   "Basic MzVmMDRyVHM1Q284YUdaNGNyMlhuTDg0UkZtd2FobXFoLWpGQ3ZVMkRBVTo="
          // );

          // //TODO:add different printerId for this

          // var raw = JSON.stringify({
          //   printerId: item.printerName,
          //   title: makeDecrypt("restaurantName"),
          //   contentType: "raw_base64",
          //   content: b64,
          //   source: "api documentation!",
          // });

          // var requestOptions = {
          //   method: "POST",
          //   headers: myHeaders,
          //   body: raw,
          //   redirect: "follow",
          // };

          // fetch("https://api.printnode.com/printjobs", requestOptions)
          //   .then((response) => response.text())

          // tempSocket.current.emit("printData", {
          //   restaurantId: makeDecrypt("restaurantId"),
          //   orderId: order._id,
          //   table: order.tableNumber,
          //   items: dataForPrint,
          // });
        });
      }
    } catch (error) {
      setAlertMessage(error.message);
    }
  };
  const handleAccept = (id) => {
    if (!isContinue) {
      tempSocket.current.emit("checkPrinterConnection", makeDecrypt("restaurantId"));

      if (printerStatus && isContinue) {
        acceptTheOrder(id, true);
      } else {
        setOpenInnerModal(true);
      }
    }
    if (!printerStatus && isContinue) {
      acceptTheOrder(id, false);
    }
    if (printerStatus && isContinue) {
      acceptTheOrder(id, true);
    }
  };
  const [refundAlert, setRefundAlert] = useState("");

  const handleRefundClick = () => {
    refundPayment(order.paymentIntentId, stripeAccountId, refundAmount)
      .then((result) => {
        setRefundAlert("Refunded Successfully");
        if (result.response.data.statusCode !== 200) {
          setRefundAlert(result.response.data.raw.message);
        }
      })
      .catch((error) => {
        setRefundAlert(error.message);
      });
  };

  // const invoiceSubtotal = subtotal(rows) + subtotal(rows2);

  useEffect(() => {
    let temp = subtotal(rows) + subtotal(rows2);
    temp = temp - order?.discount;
    setInvoiceSubtotal(temp);
  }, [rows, rows2]);
  // const invoiceTaxes =  + invoiceSubtotal;
  //  const invoiceTotal = invoiceTaxes + invoiceSubtotal;
  const handleRefundSubmit = (e) => {
    e.preventDefault();
    handleRefundClick();
  };
  const handleRefundValueChange = (e) => {
    const tempValue =
      parseFloat(invoiceSubtotal) +
      parseFloat(taxRate) +
      parseFloat(tipValue) +
      parseFloat(appliedFee);

    if (e.target.value < parseInt(tempValue.toFixed(2) * 100) + 1)
      setRefundAmount(e.target.value);
  };

  useEffect(() => {
    let tempPstPerc = { type1: "", type2: "" };
    let tempPstVal = { type1: 0, type2: 0 };
    let tempOrderItemsWithPst = [];
    order?.items.map((item) => {
      let tempOrderItemsWithPstVal = { type1: 0, type2: 0 };
      if (item.pstPercentage) {
        if (!tempPstPerc.type1) {
          tempPstPerc.type1 = item.pstPercentage;
          tempPstVal.type1 =
            tempPstVal.type1 + (item.price * item.pstPercentage * item.quantity) / 100;
          tempOrderItemsWithPstVal.type1 =
            (item.price * item.pstPercentage * item.quantity) / 100;
        } else {
          if (tempPstPerc.type1 !== item.pstPercentage) {
            tempPstPerc.type2 = item.pstPercentage;
            tempPstVal.type2 =
              tempPstVal.type2 + (item.price * item.pstPercentage * item.quantity) / 100;
            tempOrderItemsWithPstVal.type2 =
              (item.price * item.pstPercentage * item.quantity) / 100;
          } else {
            tempPstVal.type1 =
              tempPstVal.type1 + (item.price * item.pstPercentage * item.quantity) / 100;
            tempOrderItemsWithPstVal.type1 =
              (item.price * item.pstPercentage * item.quantity) / 100;
          }
        }
        tempOrderItemsWithPst.push({
          name: item.name,
          pstValues: {
            type1: tempOrderItemsWithPstVal.type1,
            type2: tempOrderItemsWithPstVal.type2,
          },
          _id: item._id,
          itemTotalPst: tempOrderItemsWithPstVal.type1 + tempOrderItemsWithPstVal.type2,
        });
      }
    });
    setOrderItemsWithPst(tempOrderItemsWithPst);
    setPstPerc(tempPstPerc);
    setPstVal(tempPstVal);
  }, []);

  useEffect(() => {
    setGrandTotal(
      parseFloat(
        totalPrice + parseFloat(order?.tip) + taxValue + appliedFee + parseFloat(pstValue)
      ).toFixed(2)
    );
  }, [totalPrice, taxValue, appliedFee, pstValue]);

  const handleEditOrder = () => {
    setIsEdit(!isEdit);
  };

  const handleUpdateOrderItems = (rowIndex, accepted, row) => {
    if (!accepted) {
      setRows2(rows2.filter((item, thisIndex) => thisIndex !== rowIndex));
    } else {
      setRows(rows.filter((item, thisIndex) => thisIndex !== rowIndex));
      if (!needAction) {
        let itemToBeDeleted = updatedOrderItems.find((item) => item._id == row._id);
        setUpdatedOrderItems(
          updatedOrderItems.filter((item) => item.itemId !== itemToBeDeleted.itemId)
        );
        setUserCart(userCart.filter((item) => item.itemId !== itemToBeDeleted.itemId));

        return "";
      }
    }
    let indexOfDeletedItem = updatedOrderItems.findIndex((item) => item._id == row._id);
    setUpdatedOrderItems(updatedOrderItems.filter((item) => item._id !== row._id));
    setUserCart(userCart.filter((item, thisIndex) => thisIndex !== indexOfDeletedItem));
  };

  const handleUpdateOrder = () => {
    setOrderUpdating(true);
    updateUser(currentUser._id, {
      cart: { items: userCart, resaturant: currentUser.cart.restaurant },
    })
      .then((ress) => {
        if (restaurant.dinifySidePayments && !useRazorpay && payThroughApp) {
          axios
            .post("/stripe/cancelpayment", {
              payIntId: currentUser.stripeDetails.paymentMethod.paymentIntentId,
              stripeAccountId: restaurant.stripeAccountId,
            })
            .then((response) => {
              try {
                createPaymentIntent("normal", "same_order");
              } catch (error) {
                setAlertMessage(error.message);
                return "";
              }
            })
            .catch((error) => {
              setAlertMessage(error.message);
            });
        } else {
          updateCurrentOrder("same_order");
        }
      })
      .catch((error) => {
        setAlertMessage(error.message);
      });
  };

  const updateCurrentOrder = (orderType, paymentDone) => {
    updateOrder(order._id, {
      totalPrice: parseFloat(finalValue + appliedFee).toFixed(2),
      tax: (parseFloat(taxValue) + parseFloat(pstValue)).toFixed(2),
      items: updatedOrderItems,
      appliedFee,
      gstValue: taxValue,
      subTotal,
      grandTotal,
    })
      .then((res) => {
        handleClose();
        dispatch(setReload());
        setAlertMessage("Order Updated Successfully");

        tempSocket.current.emit("orderUpdate", {
          orderId: order._id,
          restaurantId: order.restaurant,
          userId: order.user,
        });
      })
      .catch((error) => {
        setAlertMessage("Order Cannot be placed");
      });
  };

  const createPaymentIntent = async (type, orderType) => {
    try {
      try {
        axios
          .post("/stripe/create-payment-intent", {
            orderdetails: currentCartItems,
            tip: order.tip,
            currency: "CAD",
            customerId: currentUser.stripeDetails.customerId,
            payment_method: currentUser.stripeDetails.paymentMethod.paymentMethodId,
            restaurantId: makeDecrypt("restaurantId"),
            description: currentUser.email,
            stripeAccountId: restaurant.stripeAccountId,
            taxValue: taxValue,
            pstValue: pstValue,
            discount: (order.discount ? order.discount : 0) * 100,
          })
          .then((res) => {
            axios
              .put(`/user/${currentUser._id}/update-payment-method`, {
                expMonth: currentUser.stripeDetails.paymentMethod.expMonth,
                expYear: currentUser.stripeDetails.paymentMethod.expYear,
                lastDigits: currentUser.stripeDetails.paymentMethod.lastDigits,
                paymentMethodId: currentUser.stripeDetails.paymentMethod.paymentMethodId,
                paymentId: currentUser.stripeDetails.paymentMethod.paymentId,
                setupIntentId: currentUser.stripeDetails.paymentMethod.setupIntentId,
                customerId: currentUser.stripeDetails.customerId,
                nativePaymentMethodId:
                  currentUser.stripeDetails.paymentMethod.nativePaymentMethodId,
                nativeCardLastDigits:
                  currentUser.stripeDetails.paymentMethod.nativeCardLastDigits,
                nativeType: currentUser.stripeDetails.paymentMethod.nativeType,
                phone: currentUser.phone,
                email: currentUser.email,
                paymentIntentId: res.data.id,
              })
              .then((response) => {
                setCurrentUser(response.data);
                if (type === "normal") updateCurrentOrder(orderType);
              })
              .catch((error) => setAlertMessage("Cannot place order"));
          })
          .catch((error) => {
            setAlertMessage("cannot create intent:", error.message);
          });
      } catch (error) {
        setAlertMessage(error.message);
      }
    } catch (error) {
      setAlertMessage(error.message);
    }
  };

  const onSaveOrderSplitting = async () => {
    try {
      let updatedOrder = await updateOrder(order._id, {
        orderSplitValues: orderSplitValues,
        orderSplitted: true,
      });
      dispatch(getLiveOrders());
      setAlertMessage("Order Splitting Updated Successfully");
      setsaveOn(false);
    } catch (error) {
      setAlertMessage("Error in splitting order");
    }
  };

  const sendAcceptOrderMsg = async () => {
    let user = await getUser(order.user);
    let tempMsg = `Your takeout order is accepted by ${restaurant.name
      }, view order here: ${`https://dine.dinify.io/restaurant/menu/${restaurant._id}`}`;

    if (user.phone && order.tableNumber == "takeout")
      return axios
        .post("order/send-message", {
          userPhoneNo: user.phone,
          msg: tempMsg,
        })
        .then((response) => {
          return response.message;
        })
        .catch((error) => {
          return error.message;
        });
  };

  return (
    <Box>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Box display={"flex"}>
            <Box display="flex" flexDirection={"column"}>
              <Box>
                <Typography variant="h4">
                  Order No. - {order?.seqValue ? order?.seqValue : order?._id}
                </Typography>
              </Box>
              <Box sx={{ mt: 1 }} display={"flex"} alignItems="center">
                {order?.tableNumber === "takeout" ? (
                  <Box sx={{ mt: 0.5 }}>
                    <Typography>Takeout Order</Typography>
                  </Box>
                ) : (
                  <Box sx={{ mt: 0.5 }}>
                    <Typography>Table No. - {order?.tableNumber}</Typography>
                  </Box>
                )}
                {order?.tableNumber !== "takeout" ? (
                  <Box
                    sx={{
                      borderRadius: "9%",
                      border: 2,
                      ml: 3,
                    }}
                    style={{ borderColor: "#6ea5ff" }}
                  >
                    {order.paymentThroughApp ? (
                      <Typography variant="body2">Paid online</Typography>
                    ) : (
                      <Typography variant="body2">Not Paid Online</Typography>
                    )}
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
              {order?.tableNumber === "takeout" && order.orderName ? (
                <Box sx={{ mt: 0.5 }}>
                  <Typography>Customer Name: {order?.orderName}</Typography>
                </Box>
              ) : (
                <></>
              )}
              {currentUser?.phone && order.tableNumber == "takeout" ? (
                <Box sx={{ mt: 1 }} display={"flex"} alignItems="center">
                  <CallIcon color="success" fontSize="small" />
                  <Typography variant="body2" sx={{ ml: 0.5 }}>
                    {currentUser?.phone}
                  </Typography>
                  <Box
                    sx={{
                      borderRadius: "9%",
                      border: 2,
                      ml: 3,
                    }}
                    style={{ borderColor: "#6ea5ff" }}
                  >
                    {order.paymentThroughApp ? (
                      <Typography variant="body2">Paid online</Typography>
                    ) : (
                      <Typography variant="body2">Not Paid Online</Typography>
                    )}
                  </Box>
                </Box>
              ) : (
                ""
              )}
            </Box>
            <Box
              style={{ width: "fit-content", marginLeft: "auto", cursor: "pointer" }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </Box>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box style={{ padding: "5px ", minHeight: "400px", position: "relative" }}>
            {multipleOrders.length > 1 && !isMerged && (
              <FormControl
                //   className={classes.formControl}
                label="Orders"
                sx={{ minWidth: "100px", maxWidth: "300px", m: 1 }}
                size={window.innerWidth < 800 ? "small" : "medium"}
                fullWidth
              >
                <InputLabel htmlFor="age-native-simple">Orders</InputLabel>
                <Select
                  value={order}
                  onChange={(e) => setClonedOrder(e.target.value)}
                  inputProps={{
                    name: "age",
                    id: "age-native-simple",
                  }}
                  label="Orders"
                  // size={window.innerWidth < 800 ? "small" : "medium"}
                  size="small"
                >
                  <MenuItem>
                    <Box>None</Box>
                  </MenuItem>

                  {multipleOrders.map((order, index) => {
                    // if (order.tableNumber == tableNo) {
                    return (
                      <MenuItem value={order}>
                        <Box>{order.orderName}</Box>
                      </MenuItem>
                    );
                    // }
                  })}
                </Select>
              </FormControl>
            )}

            <div style={{ width: "100%" }}>
              <Dialog
                className={classes.modal}
                open={openInnerModal}
                onClose={handleCloseInnerModal}
                closeAfterTransition
              >
                <Box>
                  <DialogContent>
                    <Typography variant="h5">
                      Printer is not connected do you still want to continue
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setOpenInnerModal(false)}
                      size="small"
                    >
                      Close
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleContinue}
                      style={{ margin: "0 10px" }}
                      size="small"
                    >
                      Continue
                    </Button>
                  </DialogActions>
                </Box>
              </Dialog>

              {(rows.length > 1 || rows2.length > 1) && !isMerged && showUpdateBtn
                ? !order?.paymentDone && (
                  <Button
                    variant={isEdit ? "contained" : "outlined"}
                    style={{ marginBottom: "5px" }}
                    color="primary"
                    startIcon={<EditIcon />}
                    onClick={handleEditOrder}
                    // disabled={isEdit}
                    size="small"
                  >
                    Update
                  </Button>
                )
                : ""}
            </div>
            <TableContainer component={Paper} className={classes.tableContainer}>
              {!(rows.length || rows2.length) ? (
                <Box className={classes.loaderCont}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <Table className={classes.table} aria-label="spanning table">
                    <TableHead className={classes.header}>
                      {/* <TableRow>
                  <TableCell align="center" colSpan={3}>
                    Items
                  </TableCell>
                  <TableCell align="right">Price</TableCell>
                </TableRow> */}
                      <TableRow>
                        <TableCell>
                          <Typography variant="h5"> Items</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="h5"> Qty.</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="h5"> Price</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="h5"> Sum</Typography>
                        </TableCell>
                        {allowDeleteItems && showUpdateBtn && isEdit && (
                          <TableCell align="right"></TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    {needAction && rows2.length && (
                      <Typography variant="h6" style={{ margin: "10px auto 5px" }}>
                        New items added to the order
                      </Typography>
                    )}
                    <TableBody className={classes.previousItemsTable}>
                      {rows2.map((row, index) => (
                        <>
                          <TableRow key={index}>
                            <TableCell align="left">{row.desc}...</TableCell>
                            <TableCell align="center">{row.qty}</TableCell>
                            <TableCell align="right">{row.unit}</TableCell>
                            <TableCell align="right">
                              {currencySign}
                              {ccyFormat(row.price)}
                            </TableCell>{" "}
                            {allowDeleteItems && showUpdateBtn && isEdit && (
                              <TableCell style={{ cursor: "pointer" }}>
                                <DeleteIcon
                                  onClick={() => {
                                    handleUpdateOrderItems(index, false, row);
                                    // setItemsIndicesToDelete([...itemsIndicesToDelete, index]);
                                  }}
                                  color="primary"
                                />
                              </TableCell>
                            )}
                          </TableRow>
                          {
                            row?.note && (
                              <TableRow>
                                <TableCell style={{ padding: '5px 16px' }} colSpan={4}>
                                  <Typography style={{ fontSize: "12px", color: "green", whiteSpace: "normal" }}>
                                    Note: {row.note}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )
                          }
                        </>
                      ))}
                    </TableBody>
                  </Table>

                  {rows.length ? (
                    <Table
                      className={classes.table}
                      rowHeight={30}
                      aria-label="spanning table"
                    >
                      {needAction && rows.length && (
                        <Typography variant="h6" style={{ margin: "10px auto 5px" }}>
                          Previous items of the order
                        </Typography>
                      )}
                      <TableBody>
                        {rows.map((row, index) => (
                          <>
                            <TableRow key={index}>
                              <TableCell>{row.desc}</TableCell>
                              <TableCell align="right">{row.qty}</TableCell>
                              <TableCell align="right">{row.unit}</TableCell>
                              <TableCell align="right">
                                {currencySign}
                                {ccyFormat(row.price)}
                              </TableCell>
                              {allowDeleteItems && showUpdateBtn && isEdit && (
                                <TableCell style={{ cursor: "pointer" }}>
                                  <DeleteIcon
                                    onClick={() => {
                                      handleUpdateOrderItems(index, true, row);
                                      // setItemsIndicesToDelete([...itemsIndicesToDelete, index]);
                                    }}
                                    color="primary"
                                  />
                                </TableCell>
                              )}
                            </TableRow>
                            {
                              row?.note && (
                                <TableRow>
                                  <TableCell style={{ padding: '5px 16px' }} colSpan={4}>
                                    <Typography style={{ fontSize: "12px", color: "green", whiteSpace: "normal" }}>
                                      Note: {row.note}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              )
                            }
                          </>
                        ))}
                        <Divider />
                        <TableRow>
                          <TableCell rowSpan={1} />

                          <TableCell colSpan={2}>
                            GST ({parseFloat(taxTypes.GST)}%)
                          </TableCell>
                          <TableCell align="right">
                            {currencySign}
                            {taxTypes
                              ? `${taxTypes
                                ? parseFloat(
                                  (parseFloat(taxTypes.GST) *
                                    parseFloat(invoiceSubtotal)) /
                                  100
                                ).toFixed(2)
                                : ""
                              }`
                              : ""}
                          </TableCell>
                        </TableRow>
                        {pstPerc?.type1 && (
                          <TableRow>
                            <TableCell rowSpan={1} />
                            <TableCell colSpan={2}>PST ({pstPerc.type1}%)</TableCell>
                            <TableCell align="right">
                              {currencySign}
                              {pstVal.type1.toFixed(2)}
                            </TableCell>
                          </TableRow>
                        )}
                        {pstPerc?.type2 && (
                          <TableRow>
                            <TableCell rowSpan={1} />
                            <TableCell colSpan={2}>PST ({pstPerc.type2}%)</TableCell>
                            <TableCell align="right">
                              {currencySign}
                              {pstVal.type2.toFixed(2)}
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell rowSpan={1} />
                          <TableCell colSpan={2}>Tips</TableCell>
                          <TableCell align="right">
                            {currencySign}
                            {tipValue ? `${tipValue?.toFixed(2)}` : 0}
                          </TableCell>
                        </TableRow>
                        {appliedFee > 0 && (
                          <TableRow>
                            <TableCell rowSpan={1} />

                            <TableCell colSpan={2}>
                              Dinify Service Fee
                            </TableCell>
                            <TableCell align="right">
                              {currencySign}
                              {parseFloat(appliedFee)}
                            </TableCell>
                          </TableRow>
                        )}
                        {order?.discount > 0 && (
                          <TableRow>
                            <TableCell rowSpan={1} />

                            <TableCell colSpan={2}>
                              Discount ({order?.discountName})
                            </TableCell>
                            <TableCell align="right">
                              {currencySign}
                              {parseFloat(order?.discount)}
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell rowSpan={1} />
                          <TableCell colSpan={2}>
                            <Typography variant="h5"> Total</Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography variant="h5">
                              {currencySign}
                              {showUpdateBtn
                                ? ccyFormat(
                                  parseFloat(invoiceSubtotal) +
                                  parseFloat(
                                    taxTypes
                                      ? taxTypes
                                        ? parseFloat(
                                          (parseFloat(taxTypes.GST) *
                                            parseFloat(invoiceSubtotal)) /
                                          100
                                        ).toFixed(2)
                                        : 0
                                      : 0
                                  ) +
                                  parseFloat(
                                    taxTypes ? (pstValue ? pstValue : 0) : 0
                                  ) +
                                  parseFloat(tipValue) +
                                  parseFloat(appliedFee)
                                )
                                : order.grandTotal}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  ) : (
                    <Typography variant="h6">No items are accepted yet</Typography>
                  )}
                </>
              )}
            </TableContainer>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box className={classes.footer}>
            {isAdmin && needAction && (
              <Button
                color="error"
                variant="contained"
                onClick={() => {
                  handleCancel(order._id);
                }}
              >
                Cancel Order
              </Button>
            )}

            <Box className={classes.btnWrapper}>
              {multipleOrders.length > 1 &&
                multipleOrders.every((currentOrder) =>
                  currentOrder.items.every((item) => item.isAccepted === true)
                ) &&
                !isMerged && (
                  <Button variant="contained" onClick={handleCombineBill}>
                    COMBINE BILL
                  </Button>
                )}
              {needAction && !hideAcceptBtn && !isEdit && (
                <>
                  <Button
                    color="success"
                    variant="contained"
                    onClick={() => {
                      handleAccept(order._id);
                    }}
                    disabled={accepting}
                  >
                    Accept Order
                  </Button>
                  {accepting && (
                    <CircularProgress size={24} className={classes.buttonProgress} />
                  )}
                </>
              )}
              {isEdit && (
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => {
                    handleUpdateOrder();
                  }}
                  disabled={orderUpdating}
                >
                  Update Order
                </Button>
              )}
              {!needAction && !pastOrder && !isEdit && !payThroughApp && !isMerged && (
                <>
                  {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenOrderSplitting(true)}
                    id="splitBillBtn"
                    style={{margin: "0px 10px"}}
                  >
                    Split Bill
                  </Button> */}
                </>
              )}
              {!needAction && !pastOrder && !isEdit && (
                <>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() =>
                      isMerged ? handleMergeOrderComplete() : handleComplete(order?._id)
                    }
                    id="completeOrderBtn"
                    disabled={
                      (restaurant.dinifySidePayments &&
                        restaurant.restaurantAddress.country === "INDIA" &&
                        !order.paymentDone &&
                        order.paymentThroughApp) ||
                      completeBtnLoading
                    }
                  >
                    Complete Order
                  </Button>
                  {completeBtnLoading && (
                    <CircularProgress size={24} className={classes.buttonProgress} />
                  )}
                </>
              )}

              {!needAction &&
                !useRazorpay &&
                pastOrder &&
                order.paymentThroughApp &&
                differenceInDays(new Date(), new Date(order.updatedAt)) < 8 && (
                  <Button
                    variant="contained"
                    color="success"
                    // onClick={handleRefundClick}
                    onClick={() => setOpenRefundModal(true)}
                  >
                    Refund payment
                  </Button>
                )}
              {!needAction && !useRazorpay && pastOrder && (
                <Button
                  variant="contained"
                  color="primary"
                  // onClick={handleRefundClick}
                  onClick={() =>
                    printTheReceipt(order._id, {
                      items: rows,
                      order,
                    })
                  }
                  style={{ marginLeft: "10px" }}
                >
                  Print receipt
                </Button>
              )}
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
      <Modal
        className={classes.modal}
        open={openRefundModal}
        onClose={handleCloseRefundModal}
        closeAfterTransition
      // BackdropComponent={Backdrop}
      // BackdropProps={{
      //   timeout: 500,
      // }}
      >
        <Fade in={openRefundModal}>
          <Box className={classes.paper}>
            <Paper>
              <FormControl>
                <TextField
                  id="outlined-basic"
                  label="Enter amount in cents"
                  variant="outlined"
                  onChange={handleRefundValueChange}
                  value={refundAmount}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setWarningRefund(true)}
                >
                  Ok
                </Button>
              </FormControl>
            </Paper>
          </Box>
        </Fade>
      </Modal>
      <Modal
        className={classes.modal}
        open={warningRefund}
        onClose={handleCloseWarningRefund}
        closeAfterTransition
      // BackdropComponent={Backdrop}
      // BackdropProps={{
      //   timeout: 500,
      // }}
      >
        <Fade in={warningRefund}>
          <Box className={classes.paper}>
            <Paper>
              <form noValidate autoComplete="off" onSubmit={handleRefundSubmit}>
                <FormControl>
                  <Typography>This action cannot be reversed. Are you sure?</Typography>
                  {refundAlert && <Typography>{refundAlert}</Typography>}

                  <Button variant="contained" color="primary" type="submit">
                    Ok
                  </Button>
                </FormControl>
              </form>
            </Paper>
          </Box>
        </Fade>
      </Modal>
      <Dialog
        className={classes.modal}
        style={{ maxWidth: "100vw" }}
        open={openOrderSplitting}
        onClose={() => setOpenOrderSplitting(false)}
        closeAfterTransition
      >
        <DialogTitle id="alert-dialog-title">
          <Box display={"flex"}>
            <Box display="flex" flexDirection={"column"}>
              <Box>
                <Typography variant="h4">Table No. - {order?.tableNumber}</Typography>
              </Box>
              <Box sx={{ mt: 0.5 }}>
                <Typography>
                  {" "}
                  Order No. - {order?.seqValue ? order?.seqValue : order?._id}
                </Typography>
              </Box>
            </Box>
            <Box
              style={{ width: "fit-content", marginLeft: "auto", cursor: "pointer" }}
              onClick={() => setOpenOrderSplitting(false)}
            >
              <CloseIcon />
            </Box>
          </Box>
        </DialogTitle>
        <Box>
          <DialogContent>
            <OrderSplitting
              rows={rows}
              orderItemsWithPst={orderItemsWithPst}
              appliedFee={appliedFee}
              gstValue={taxValue}
              setOrderSplitValues={setOrderSplitValues}
              order={order}
              cloverDeviceConnected={cloverDeviceConnected}
              setAlertMessage={setAlertMessage}
              saveOn={saveOn}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpenOrderSplitting(false)}
              size="small"
            >
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onSaveOrderSplitting}
              style={{ margin: "0 10px" }}
              size="small"
            >
              Save
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      {/* <Modal
        className={classes.modal}
        open={openOrderSplitting}
        onClose={() => setOpenOrderSplitting(false)}
        closeAfterTransition
      >
        <Fade in={openOrderSplitting}>
          <Box className={classes.paper}>
            <Paper></Paper>
          </Box>
        </Fade>
      </Modal> */}
      {/* <AlertMsg alertMessage={alertMessage} /> */}
    </Box>
  );
}

export default OrderModal;