export const columns = [
  {
    accessorKey: "name",
    header: "Name",
    size: 200,
  },
  {
    accessorKey: "email",
    header: "Email",
    size: 200,
  },
  {
    accessorKey: "phone",
    header: "Phone",
    size: 200,
  },
  {
    accessorKey: "orderedAt",
    header: "orderedAt",
    size: 100,
  },
  {
    accessorKey: "type",
    header: "Type",
    size: 50,
  },
  {
    accessorKey: "items",
    header: "Items",
    size: 200,
  },
  {
    accessorKey: "totalPrice",
    header: "TotalPrice",
    size: 400,
  },
];

export const CrmCsvOptions = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  headers: columns.map((c) => c.header),
};
