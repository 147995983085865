import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  List,
  ListItem,
  Select,
  TextField,
  Typography,
  Divider,
  LinearProgress,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import ProductCard from "src/components/product//ProductCard";
import {addCategory, updateRestaurant} from "../../redux/actions/restaurantActions";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {CircularProgress} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import { addCategoryN } from "src/redux/actions/categoryActions";

const useStyles = makeStyles()((theme) => {
  return {
    selectTimeMsg: {
      margin: "20px auto",
      color: "black",
    },

    timePicker: {
      margin: "10px 30px auto 0px",
    },
  };
});

const ProductListToolbar = ({
  menuCategories,
  tempSocket,
  thisReload,
  setThisReload,
  setCurrentCategories,
  setMenuItemsLoader,
  setShowBackdrop,
  page,
  limit,
  ...props
}) => {
  const [printer, setPrinter] = useState("");
  const [open, setOpen] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const [sequence, setSequence] = useState(0);
  const {mainMenu, selectedMainMenu, categoryLoading} = useSelector(
    (state) => state.mainMenu
  );
  const {_id, printers} = useSelector((state) => state.restaurant);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {role} = useSelector((state) => state.auth);
  const {classes} = useStyles();
  const flexContainer = {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    overflowX: "auto",
    scrollBehavior: "auto",
  };

  const root = {
    width: "100%",
    maxWidth: 250,
  };
  const [selectedDate, setSelectedDate] = React.useState({
    start: "00:01",
    end: "23:59",
  });

  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(menuCategories);
  }, [menuCategories]);

  useEffect(() => {
    if (mainMenu.length && selectedMainMenu) {
      setPrinter(
        mainMenu.find((item) => item.name === selectedMainMenu)
          ? mainMenu.find((item) => item.name === selectedMainMenu).printer
          : ""
      );
    }
  }, [mainMenu, selectedMainMenu]);

  const handleChange = (event) => {
    setNewCategory(event.target.value);
  };

  const handleClickOpen = (message) => {
    // setMessage(message)
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
    setSelectedDate({
      start: "00:01",
      end: "23:59",
    });
  };

  const handleAddCategory = (event) => {
    setShowBackdrop(true);
    const data = {
      categoryName: newCategory,
      restaurantId: _id,
      sequence: sequence,
      printer: printer,
      availability: selectedDate,
    };

    dispatch(
      addCategoryN(
        data,
        mainMenu.find((item) => item.name === selectedMainMenu)._id,
        navigate,
        setThisReload,
        thisReload
      )
    );
    setOpen(false);
    setShowBackdrop(false);
  };

  const handleDateChange = (e) => {
    setSelectedDate({...selectedDate, [e.target.name]: e.target.value});
  };

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    margin: "8px",
    // margin: `0 0 ${grid}px 0`,
    background: isDragging ? "lightgreen" : "",
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver, itemsLength) => ({
    background: isDraggingOver ? "#FFEBEB" : "",
    padding: grid,
    width: itemsLength * 255 + 16,
    display: "flex",
  });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedCategories = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    reorderedCategories.map((category, index) => {
      category.sequence = index + 1;
    });

    dispatch(updateRestaurant({menuCategories: reorderedCategories}));

    setItems(reorderedCategories);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Add new category"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <DialogContentText>{/* {message} */}</DialogContentText>
            <>
              <TextField
                autoFocus
                margin="dense"
                name="name"
                id="name"
                label="Category Name"
                fullWidth
                onChange={handleChange}
              />
              <TextField
                autoFocus
                type="number"
                margin="dense"
                name="sequence"
                id="sequence"
                label="Category Sequence"
                fullWidth
                value={sequence}
                onChange={(e) => setSequence(e.target.value)}
              />
              <FormControl style={{margin: "10px 0"}} fullWidth>
                <InputLabel htmlFor="age-native-simple">Select Printer</InputLabel>
                <Select
                  // disabled
                  native
                  label="Select Printer"
                  value={printer}
                  onChange={(e) => setPrinter(e.target.value)}
                  inputProps={{
                    name: "Select Printer",
                    id: "age-native-simple",
                  }}
                >
                  {printers?.map((printer, index) => (
                    <option key={printer._id} value={printer._id}>
                      {printer.customName}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <Typography className={classes.selectTimeMsg}>
                Select Timeframe in which the category will be available
              </Typography>
              <TextField
                id="start"
                name="start"
                label="START TIME"
                type="time"
                defaultValue="00:01"
                size="small"
                onChange={handleDateChange}
                className={classes.timePicker}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />

              <TextField
                id="end"
                name="end"
                label="END TIME"
                type="time"
                defaultValue="23:59"
                size="small"
                onChange={handleDateChange}
                className={classes.timePicker}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
            </>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
          <Button onClick={handleAddCategory} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Box {...props}>
        <Box sx={{mt: 2}}>
          <Card>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 2,
              }}
            >
              <Typography variant="h3" color="textPrimary">
                Categories for selected menu
              </Typography>
              {role === "ROLE_ADMIN" && (
                <Button
                  color="primary"
                  variant="outlined"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={handleClickOpen}
                >
                  Add category
                </Button>
              )}
            </Box>
            <Divider />
            {categoryLoading && <LinearProgress />}
            <Box sx={{p: 2}}>
              {/* {!categoryLoading && ( */}
              {items.length ? (
                <div style={{overflow: "auto"}}>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver, items?.length)}
                          {...provided.droppableProps}
                        >
                          {items.map((product, index) => (
                            <Draggable
                              key={product._id}
                              draggableId={product._id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <ProductCard
                                    name={product.name}
                                    printer={product.printer}
                                    categoryId={product._id}
                                    status={product.status}
                                    sequence={product.sequence ? product.sequence : 0}
                                    setThisReload={setThisReload}
                                    thisReload={thisReload}
                                    tempSocket={tempSocket}
                                    setCurrentCategories={setCurrentCategories}
                                    setMenuItemsLoader={setMenuItemsLoader}
                                    page={page}
                                    limit={limit}
                                    selectedDate={product.availability}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              ) : (
                <Box
                  style={{
                    display: "flex",
                    width: "100%",
                    minHeight: "100px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography textAlign="center"> No Categories Yet</Typography>
                </Box>
              )}
              {/* )} */}
            </Box>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default ProductListToolbar;
