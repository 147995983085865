import {createStore, combineReducers, applyMiddleware, compose} from "redux";
import thunk from "redux-thunk";

// reducers
import userReducer from "./reducers/userReducer";
import employeeReducer from "./reducers/employeeReducer";
import restaurantReducer from "./reducers/restaurantReducer";
import menuReducer from "./reducers/menuReducer";
import orderReducer from "./reducers/orderReducer";
import mainMenuReducer from "./reducers/mainMenuReducer";
import kdsItemsReducer from "./reducers/kdsItemsReducer";
import discountReducer from "./reducers/discountReducer";
import menuCategoryReducer from "./reducers/menuCategoryReducer";

const initialState = {};

const middleware = [thunk];

const reducers = combineReducers({
  auth: userReducer,
  employee: employeeReducer,
  restaurant: restaurantReducer,
  menu: menuReducer,
  order: orderReducer,
  mainMenu: mainMenuReducer,
  kdsItems: kdsItemsReducer,
  discount: discountReducer,
  category: menuCategoryReducer,
});

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(...middleware));
const store = createStore(reducers, initialState, enhancer);

export default store;
