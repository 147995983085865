import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableContent from "./TableContent";
import React, {useState} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
const CustomerTable = ({data, ...rest}) => {
  function createData(
    _id,
    name,
    email,
    orderedAt,
    phone,
    TotalPrice,
    items,
    type,
    newCustomer
  ) {
    return {_id, name, email, orderedAt, phone, TotalPrice, items, type, newCustomer};
  }

  const data_rows = [];

  data?.map((customer) => {
    data_rows.push(
      createData(
        customer._id,
        customer.name,
        customer.email,
        new Date(customer.orderedAt).toUTCString(),
        customer.phone,
        customer.totalPrice,
        customer.items.map((i) => i.name),
        customer.type,
        customer.newCustomer
      )
    );
  });

  return (
    <Box {...rest}>
      <PerfectScrollbar>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Name</TableCell>
                <TableCell>Order Date/Time</TableCell>
                <TableCell>DineIn/Takeout</TableCell>
                <TableCell>Total price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data_rows.map((row, index) => (
                <TableContent row={row} index={index} key={`${row}-${index}`} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PerfectScrollbar>
    </Box>
  );
};

export default CustomerTable;
