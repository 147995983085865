import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  Snackbar,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {createEmployee, getEmployees} from "../../redux/actions/employeeActions";
import SearchIcon from "@mui/icons-material/Search";
import {useEffect} from "react";
import {getRestaurant} from "src/redux/actions/restaurantActions";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const CustomerListToolbar = ({setSearchQuery, ...rest}) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const {_id} = useSelector((state) => state.restaurant);
  const {restaurant} = useSelector((state) => state);
  const [loading, setLoading] = useState();

  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(getRestaurant);
  }, []);

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const [employeeDetails, setEmployeeDetails] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    restId: "",
  });

  const handleChange = (event) => {
    if (event.target.name == "email") {
      setEmployeeDetails({
        ...employeeDetails,
        [event.target.name]:
          event.target.value +
          `@${restaurant?.name?.toLowerCase().replace(/[^A-Z0-9]/gi, "")}.com`,
      });
    } else
      setEmployeeDetails({
        ...employeeDetails,
        [event.target.name]: event.target.value,
      });
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickOpen = (message) => {
    setMessage(message);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateEmployee = async () => {
    employeeDetails.role = "ROLE_EMPLOYEE";
    employeeDetails.restId = _id;
    try {
      const response = await dispatch(
        createEmployee(
          employeeDetails,
          navigate,
          setAlertMsg,
          setShowSuccessMsg,
          setShowErrorMsg
        )
      );

      const employeess = await dispatch(getEmployees());
      setLoading(false);
    } catch (err) {
      console.log(err);
    }

    setOpen(false);
  };
  const [tempQuery, setTempQuery] = useState("");

  const handleQuerySearch = (e) => {
    e.preventDefault();
    setSearchQuery(tempQuery);
    setTempQuery("");
  };

  useEffect(() => {
    dispatch(getEmployees());
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Create employee account"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <>
              <TextField
                autoFocus
                margin="dense"
                name="name"
                id="name"
                label="Name"
                fullWidth
                required
                onChange={handleChange}
              />
              <TextField
                autoFocus
                margin="dense"
                name="email"
                id="email"
                label="Email Address"
                type="email"
                fullWidth
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{`@${restaurant?.name
                      ?.toLowerCase()
                      .replace(/[^A-Z0-9]/gi, "")}.com`}</InputAdornment>
                  ),
                }}
                onChange={handleChange}
              />
              <TextField
                autoFocus
                margin="dense"
                name="password"
                id="password"
                label="Set password"
                type="password"
                fullWidth
                required
                onChange={handleChange}
              />
              <TextField
                autoFocus
                margin="dense"
                name="phone"
                id="phone"
                label="Phone"
                type="phone"
                fullWidth
                required
                onChange={handleChange}
              />
            </>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
          <Box sx={{margin: 1, position: "relative"}}>
            <Button
              onClick={() => {
                setLoading(true);
                handleCreateEmployee();
              }}
              variant="contained"
              color="primary"
              disabled={loading}
              autoFocus
            >
              Create account
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                style={{
                  // color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            )}
          </Box>
        </DialogActions>
      </Dialog>

      <Box {...rest}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <form onSubmit={handleQuerySearch}>
            <FormControl>
              <TextField
                name="searchQuery"
                label="Enter the name"
                onChange={(e) => setTempQuery(e.target.value)}
                value={tempQuery}
                style={{backgroundColor: "#FFF", borderRadius: "10px"}}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </FormControl>
          </form>

          <Button
            color="primary"
            variant="contained"
            onClick={() => handleClickOpen("Hello")}
            startIcon={<AddCircleOutlineIcon />}
          >
            Add employee
          </Button>
        </Box>
      </Box>

      <Snackbar
        open={showSuccessMsg}
        autoHideDuration={6000}
        onClose={() => setShowSuccessMsg(false)}
      >
        <Alert onClose={() => setShowSuccessMsg(false)} severity="success">
          {alertMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={showErrorMsg}
        autoHideDuration={6000}
        onClose={() => setShowErrorMsg(false)}
      >
        <Alert onClose={() => setShowErrorMsg(false)} severity="error">
          {alertMsg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CustomerListToolbar;
