import { makeStyles } from "tss-react/mui";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setThrottling, updateRestaurant } from "src/redux/actions/restaurantActions";
import { FaTools } from "react-icons/fa";
import CloseIcon from "@mui/icons-material/Close";
import { IoSettingsOutline } from "react-icons/io5";

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Drawer,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

const useStyle = makeStyles()((theme) => {
  return {
    root: { cursor: "pointer", display: "flex" },
    closeIcon: {
      position: "absolute",
      top: "10px",
      right: "10px",
      cursor: "pointer",
    },
    drawer: {
      minWidth: "250px",
      padding: "10px",
    },
    saveWrapper: {
      display: "flex",
      width: "fit-content",
      marginLeft: "auto",
      marginTop: "20px",
      position: "relative",
    },
    buttonProgress: {
      // color: primary,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  };
});

function OrderTools() {
  const { classes } = useStyle();
  const [throttlingStatus, setThrottlingStatus] = useState();
  const [liveOrderLimit, setLiveOrderLimit] = useState();
  const [throttlingBtnLoading, setThrottlingBtnLoading] = useState(false);
  const { orderThrottling } = useSelector((state) => state.restaurant);
  
  const { restaurant } = useSelector((state) => state);
  const [btnLoading, setBtnLoading] = useState();
  const dispatch = useDispatch();
  const [open, setOpen] = useState();

  useEffect(() => {
    if (orderThrottling) {
      setThrottlingStatus(orderThrottling.active);
      setLiveOrderLimit(orderThrottling.limit);
    }
  }, [orderThrottling]);

  const setOrderThrottling = () => {
    setBtnLoading(true);
    setThrottlingBtnLoading(true);
    dispatch(
      setThrottling(
        throttlingStatus,
        liveOrderLimit,
        setThrottlingBtnLoading,
        setBtnLoading
      )
    );
  };

  return (
    <div className={classes.root}>
      <IoSettingsOutline fontSize={20} onClick={() => setOpen(true)} />
      <Drawer open={open} anchor={"right"} onClose={() => setOpen(false)}>
        <Box className={classes.drawer}>
          <Box>
            <Typography variant="h4">Order Tools</Typography>
            <CloseIcon className={classes.closeIcon} onClick={() => setOpen(false)} />
          </Box>
          <Box display={"flex"} flexDirection={"column"} gap={"3px"}  sx={{ p: 1, mt: 1 }}>
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={restaurant?.acceptTakeout}
                    onChange={(e) =>
                      dispatch(updateRestaurant({ acceptTakeout: e.target.checked }))
                    }
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Takeout orders"
              />
            </Box>
            <Box className={classes.prinCont}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={throttlingStatus ? throttlingStatus : false}
                    onChange={(e) => {
                      setThrottlingStatus(e.target.checked);
                    }}
                    color="primary"
                    name="checkedB"
                    label="Thorottling"
                  />
                }
                label="Throttling"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              {throttlingStatus && (
                <TextField
                  value={liveOrderLimit}
                  label="Live Order Limit"
                  onChange={(e) => setLiveOrderLimit(e.target.value)}
                  type="number"
                  size="small"
                />
              )}
            </Box>
            <Box className={classes.saveWrapper}>
              <Button
                variant="contained"
                color="primary"
                // className={buttonClassname}
                disabled={btnLoading}
                onClick={() => setOrderThrottling()}
                size="small"
              >
                SAVE
              </Button>
              {throttlingBtnLoading && (
                <CircularProgress size={24} className={classes.buttonProgress} />
              )}
            </Box>
          </Box>

        </Box>
      </Drawer>
    </div>
  );
}

export default OrderTools;
