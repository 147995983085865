import {makeEncrypt, makeDecrypt} from "src/utils/securels";
import {useRef, useState, useEffect} from "react";
import {Link as RouterLink} from "react-router-dom";
import PropTypes from "prop-types";
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  ClickAwayListener,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Tooltip,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/NotificationsOutlined";
import InputIcon from "@mui/icons-material/Input";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Logo from "./Logo";
import {makeStyles} from "tss-react/mui";
import {io} from "socket.io-client";
import store from "../redux/store";
import {logoutAction} from "../redux/actions/authActions";
import {setTourStatus} from "./TourJS/helpers";
import {useDispatch, useSelector} from "react-redux";
import {deletePrinter, getRestaurant} from "src/redux/actions/restaurantActions";
import DeleteIcon from "@mui/icons-material/Delete";
import {deleleAllNotifications, deleteNotification} from "./dashboard/helpers";
import notification_sound from "../Notification/notification_sound.mp3";
import useSound from "use-sound";
import moment from "moment";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import CheckIcon from "@mui/icons-material/Check";
import UpdateIcon from "@mui/icons-material/Update";
import _ from "lodash";

const useStyles = makeStyles()((theme) => {
  return {
    collapseWrap: {
      marginRight: "10px",
    },
    printerCont: {
      position: "absolute",
      width: "120px",
    },
    dropDown: {
      display: "flex",
      width: "120px",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "5px 10px",
      border: "1px solid #F4F4F4",
      borderRadius: "10px",
      cursor: "pointer",
    },
    notification_block: {
      position: "relative",
    },
    demo: {
      boxShadow: "0px 0px 1px #000",
      color: "black",
      //backgroundColor: theme.palette.background.paper,
      position: "absolute",
      top: "40px",
      right: "0px",
      minWidth: "340px",
      maxHeight: "350px",
      overflowY: "scroll",
    },
    quickTour: {
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
    logout: {
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
    dropDownIcon: {
      // marginLeft: "auto",
    },
  };
});

const onLogout = () => {
  store.dispatch(logoutAction());
};

const startTour = () => {
  let tourStatus = "on";
  setTourStatus(tourStatus).then(window.location.replace("/dashboard"));
};

const DashboardNavbar = ({onMobileNavOpen, ...rest}) => {
  const {classes} = useStyles();
  const [updatedNotifications, setUpdatedNotifications] = useState([]);
  const [isCollapse, setIsCollapse] = useState(false);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  // const {logoUrl} = useSelector((state) => state.restaurant);
  const socket = useRef();
  const [play] = useSound(notification_sound);
  const printers = useSelector((state) => state.restaurant.printers);
  const restaurant = useSelector((state) => state.restaurant);
  const notifications = useSelector((state) => state.restaurant.notifications);
  const [anchorE2, setAnchorE2] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState();
  const [activePrinter, setActivePrinter] = useState();
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  makeEncrypt("layoutSaved", true);
  //"wss://api.dinify.io", {transports: ["websocket"]}
  useEffect(() => {
    const makeConnection = async () => {
      // socket.current = io("wss://api.dinify.io", {transports: ["websocket"]});
      socket.current = io(process.env.REACT_APP_WEB_SOCKET_URL, {
        transports: ["websocket"],
      });
      socket.current.on("notification-alert", (data) => {
        if (makeDecrypt("restaurantId") === data.restaurantId) {
          setUpdatedNotifications((prevValues) => [...prevValues, data]);
        }
      });
    };
    makeConnection();
  }, []);

  useEffect(() => {
    dispatch(getRestaurant());
  }, []);

  useEffect(() => {
    if (notifications.length) {
      setUpdatedNotifications(notifications);
    }
    if (!notifications.length) setUpdatedNotifications([]);
  }, [notifications]);

  const handleClick = (event) => {
    setAnchorEl(!anchorEl);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };

  const handleNotificationDelete = (restId, id) => {
    deleteNotification(restId, id)
      .then((res) => {
        setUpdatedNotifications(updatedNotifications.filter((item) => item._id !== id));
      })

      .catch((error) => console.log(error));
  };

  const handleClickAway = () => {
    setAnchorEl(false);
  };

  const handleNotificationsDelete = () => {
    deleleAllNotifications(makeDecrypt("restaurantId"))
      .then((res) => {
        setUpdatedNotifications([]);
      })

      .catch((error) => console.log(error));
  };

  const handleDeletePrinter = async () => {
    setDeleteConfirmation(false);
    setDeleteLoading(true);

    let response = await dispatch(deletePrinter(restaurant, activePrinter._id));
    dispatch(getRestaurant());
    setDeleteLoading(false);
  };

  return (
    <AppBar elevation={0} color="primary" {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Typography color="inherit" variant="h4">
          Dinify
        </Typography>
        <Box sx={{flexGrow: 1}} />
        {/* <Hidden lgDown> */}
        <Tooltip title="Quick Tour" arrow={true} className={classes.quickTour}>
          <IconButton color="inherit" onClick={() => startTour()}>
            <HelpOutlineIcon className="helpButton" />
          </IconButton>
        </Tooltip>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box className={classes.notification_block}>
            <Tooltip title="Notifications" arrow={true}>
              <IconButton color="inherit" onClick={handleClick}>
                <Badge badgeContent={updatedNotifications.length} color="secondary">
                  <NotificationsIcon className="notificationButton" />
                </Badge>
              </IconButton>
            </Tooltip>

            <Box className={classes.demo}>
              {anchorEl && updatedNotifications.length ? (
                <Box width="100%">
                  <Button
                    style={{
                      marginLeft: "auto",
                      display: "flex",
                      justifySelf: "right",
                      marginTop: "10px",
                    }}
                    onClick={handleNotificationsDelete}
                  >
                    Clear All
                  </Button>
                  <List dense={true} flexDirection="row-reverse">
                    {updatedNotifications.reverse().map((item, index) => {
                      return (
                        <ListItem
                          key={index}
                          style={{
                            backgroundColor:
                              item.title === "New Order"
                                ? "#E2F0FE"
                                : item.title === "Paid"
                                ? "#C6ECB9"
                                : "#CDDFD2",
                          }}
                        >
                          <ListItemIcon>
                            {item.title === "New Order" ? (
                              <FiberNewIcon />
                            ) : item.title === "Paid" ? (
                              <CheckIcon />
                            ) : (
                              <UpdateIcon />
                            )}
                          </ListItemIcon>
                          <Box display="flex">
                            <ListItemText
                              primary={`${item.title} - ${
                                item.timeOfCreation
                                  ? moment(item.timeOfCreation).fromNow()
                                  : ""
                              }`}
                              secondary={item.content}
                            />
                          </Box>
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() =>
                                handleNotificationDelete(item.restaurantId, item._id)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                </Box>
              ) : (
                ""
              )}
            </Box>
          </Box>
        </ClickAwayListener>
        <Tooltip title="LogOut" arrow={true}>
          <IconButton
            color="inherit"
            className={classes.logout}
            onClick={() => onLogout()}
          >
            <InputIcon className="logoutButton" />
          </IconButton>
        </Tooltip>
        {/* </Hidden> */}
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>

      <Dialog open={deleteConfirmation}>
        <DialogContent>
          <Typography>Do you want to delete {activePrinter?.name} Printer</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmation(false)} variant="outlined">
            CANCEL
          </Button>
          <Button onClick={() => handleDeletePrinter()} variant="contained">
            DELETE
          </Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
};

export default DashboardNavbar;
