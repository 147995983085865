import {
  CATEGORY_LOADING,
  MAIN_MENU_LOADING,
  MAIN_MENU_LOADING_COMPLETED,
  MAIN_MENU_LOADING_NOT_COMPLETED,
  STATUS_LOADING,
  STOP_CATEGORY_LOADING,
  UPDATE_MAIN_MENU,
} from "../types";

const initialState = {
  mainMenu: [],
  menuLoading: false,
  categoryLoading: false,
  loadingCompleted: false,
  statusLoading: false,
  selectedMainMenu: "",
};

export default function mainMenuReducer(state = initialState, action) {
  switch (action.type) {
    //   case LOADING_MENU:
    //     return {
    //       ...state,
    //       loadingMenu: true
    //     }
    //   case ADD_MENU:
    //     return {
    //       ...state,
    //       menu: [...state.menu, action.payload],
    //       loadingMenu: false
    //     }
    case "SELECTED_MAIN_MENU":
      return {
        ...state,
        selectedMainMenu: action.payload,
        menuLoading: false,
      };
    case "DESELECT_MAIN_MENU":
      return {
        ...state,
        selectedMainMenu: null,
        menuLoading: false,
      };
    case "SET_ALL_MAIN_MENU":
      return {
        ...state,
        mainMenu: action.payload,
        menuLoading: false,
        statusLoading: false,
      };
    case UPDATE_MAIN_MENU:
      return {
        ...state,
        mainMenu: [
          ...state.mainMenu.map((menu) =>
            menu._id == action.payload._id ? {...action.payload} : menu
          ),
        ],
      };
    case MAIN_MENU_LOADING:
      return {
        ...state,
        menuLoading: true,
      };
    case MAIN_MENU_LOADING_COMPLETED:
      return {
        ...state,
        loadingCompleted: true,
        //   loadingMenu: false
      };
    case MAIN_MENU_LOADING_NOT_COMPLETED:
      return {
        ...state,
        loadingCompleted: false,
        //   loadingMenu: false
      };
    case CATEGORY_LOADING:
      return {
        ...state,
        categoryLoading: true,
        //   loadingMenu: false
      };
    case STOP_CATEGORY_LOADING:
      return {
        ...state,
        categoryLoading: false,
        //   loadingMenu: false
      };
    case STATUS_LOADING:
      return {
        ...state,
        statusLoading: true,
        //   loadingMenu: false
      };
    //   case UPDATE_MENU:
    //     return {
    //       ...state,
    //       menu: [...state.menu.map((menuItem) =>
    //         menuItem._id === action.payload._id ? { ...action.payload } : menuItem)],
    //       loadingMenu: false
    //     }
    //   case DELETE_MENU:
    //     return {
    //       ...state,
    //       menu: [state.menu.filter((menuItem) => menuItem._id !== action.payload)],
    //       loadingMenu: false
    //     }
    default:
      return state;
  }
}
