import {Box, Card, CardContent, CardHeader, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import React from "react";

const useStyles = makeStyles()(() => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      minWidth: 265,
      maxWidth: 300,
    },
    nameWrapper: {
      padding: "10px",
      backgroundColor: "#389B3A",
      color: "white",
    },
    qtyWrapper: {
      padding: "10px",
    },
  };
});

function ItemsContainer({item}) {
  const {classes} = useStyles();
  return (
    <Box>
      <Card className={classes.root}>
        <CardHeader
          title={
            <Typography variant="h6" style={{fontSize: "18px"}}>
              {item.name}
            </Typography>
          }
          className={classes.nameWrapper}
        />

        <CardContent className={classes.qtyWrapper}>
          <Typography>Quantity - {item.qty}</Typography>
        </CardContent>
      </Card>
    </Box>
  );
}

export default ItemsContainer;
