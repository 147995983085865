import React, {useEffect, useRef, useState} from "react";
import ItemsCard from "src/components/kitchen-display-system/ItemsCard";
import {useDispatch, useSelector} from "react-redux";

import {
  Box,
  Container,
  Typography,
  Switch,
  Breadcrumbs,
  FormControlLabel,
  Link,
} from "@mui/material";
import {getMenuItem} from "src/components/order/helpers";
import {getKdsItems} from "src/redux/actions/kdsItemsActions";
import {getWaitingOrders} from "src/redux/actions/orderActions";
import {io} from "socket.io-client";
import {makeDecrypt} from "src/utils/securels";
import {Helmet} from "react-helmet";
import {makeStyles} from "tss-react/mui";
import Spinner from "src/utils/spinner/spinner";
import ItemsContainer from "src/components/kitchen-display-system/ItemsContainer";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import HomeIcon from "@mui/icons-material/Home";
import {useNavigate} from "react-router";

const useStyles = makeStyles()({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  container: {
    margin: "0 auto",
    overflowX: "scroll",
    padding: "10px",
  },
  innerRoot: {
    padding: "10px 10px",
  },
  itemContainer: {
    margin: "0 auto",
    // display: "flex",
    overflowX: "scroll",
  },
  breadCrumbs: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2px 8px ",
    borderRadius: "20px",
    backgroundColor: "#ffffff",
    border: "1px solid #FF5757",
    fontSize: "15px",
    fontWeight: "500",
    "& .MuiBreadcrumbs-separator svg": {
      fontSize: "20px !important",
    },
  },
});

function KitchenDisplaySystem() {
  const {classes} = useStyles();
  const [itemsTables, setItemsTables] = useState([]);
  const [progress, setProgress] = useState(0);
  const [waitingOrderTables, setWaitingOrderTables] = useState([]);
  const dispatch = useDispatch();
  let {kdsItems} = useSelector((state) => state.kdsItems);
  let {waitingOrders} = useSelector((state) => state.order);
  let {_id} = useSelector((state) => state.restaurant);
  const socket = useRef();
  const [reload, setReload] = useState(false);
  const [diningItems, setDiningItems] = useState([{name: "", qty: ""}]);
  const [takeoutItems, setTakeoutItems] = useState([{name: "", qty: ""}]);
  const [viewByItem, setViewByItem] = useState(false);
  const [tempSocket, setTempSocket] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const makeConnection = () => {
      // socket.current = io("wss://api.dinify.io", {transports: ["websocket"]});
      socket.current = io(process.env.REACT_APP_WEB_SOCKET_URL, {
        transports: ["websocket"],
      });
      setTempSocket(socket);
      socket.current.on("orderStatus", (data) => {
        if (
          (data.restaurantId == makeDecrypt("restaurantId") &&
            data.status === "Accepted") ||
          data.status === "Completed"
        ) {
          setItemsTables([]);
          setProgress(0);
          dispatch(getKdsItems(makeDecrypt("restaurantId"), setProgress));
        }
      });
    };
    return makeConnection();
  }, []);

  useEffect(() => {
    setProgress(0);
    if (_id) dispatch(getKdsItems(_id, setProgress));
    dispatch(getWaitingOrders());
  }, [_id, reload]);
  useEffect(() => {
    let tempKdsItems = [];
    if (kdsItems.length) {
      tempKdsItems = kdsItems;
      setItemsTables(tempKdsItems.reverse());
    }
  }, [kdsItems]);

  useEffect(() => {
    let tempWaitingItems = [];
    if (waitingOrders.length) {
      tempWaitingItems = waitingOrders;
      setWaitingOrderTables(tempWaitingItems.reverse());
    }
  }, [waitingOrders]);

  useEffect(() => {
    let tempDiningItems = [];
    let tempTakeoutItems = [];
    if (itemsTables.length) {
      itemsTables.map((table) => {
        // return table.status === "notServed" ? <ItemsCard table={table} /> : "";
        if (table.tableNo !== "takeout" && table.status === "notServed") {
          table.items.map((tableItem) => {
            let tempItem = tempDiningItems.find(
              (item) => item.name === tableItem.itemName
            );
            if (tempItem) {
              tempItem.qty += tableItem.quantity;
              let itemIndex = tempDiningItems.findIndex(
                (obj) => obj.name == tableItem.itemName
              );
              tempDiningItems[itemIndex] = tempItem;
            } else {
              tempDiningItems.push({name: tableItem.itemName, qty: tableItem.quantity});
            }
          });
        } else if (table.tableNo === "takeout" && table.status === "notServed") {
          table.items.map((tableItem) => {
            let tempItem = tempTakeoutItems.find(
              (item) => item.name === tableItem.itemName
            );
            if (tempItem) {
              tempItem.qty += tableItem.quantity;
              let itemIndex = tempTakeoutItems.findIndex(
                (obj) => obj.name == tableItem.itemName
              );
              tempTakeoutItems[itemIndex] = tempItem;
            } else {
              tempTakeoutItems.push({name: tableItem.itemName, qty: tableItem.quantity});
            }
          });
        }
      });
    }
    setDiningItems(tempDiningItems);
    setTakeoutItems(tempTakeoutItems);
  }, [itemsTables]);

  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        style={{margin: "20px 20px 0"}}
      >
        <Link
          color="primary"
          onClick={() => navigate("/app/dashboard")}
          style={{cursor: "pointer"}}
        >
          <Box className={classes.breadCrumbs}>
            <HomeIcon color="primary" style={{fontSize: "15px", marginRight: "2px"}} />
            Home
          </Box>
        </Link>
        <Link
          color="primary"
          // href="/app/orders"
          style={{cursor: "pointer"}}
        >
          <Box className={classes.breadCrumbs}>
            <RestaurantMenuIcon
              color="primary"
              style={{fontSize: "15px", marginRight: "2px"}}
            />
            KDS
          </Box>
        </Link>
      </Breadcrumbs>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "fit-content",
          margin: "10px 10px 0 auto",
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={viewByItem}
              onChange={(e) => setViewByItem(e.target.checked)}
              color="primary"
              name="checkedB"
              inputProps={{"aria-label": "primary checkbox"}}
            />
          }
          label="View by items"
        />
      </Box>
      {itemsTables.length ? (
        <Box className={classes.root}>
          <Helmet>
            <title>KDS | Dinify</title>
          </Helmet>
          <Box className={classes.innerRoot}>
            <Typography variant="h3" style={{marginBottom: "10px"}}>
              {" "}
              Dining order items
            </Typography>
            <Box display="flex" gap={3} className={classes.container}>
              {itemsTables.length ? (
                !viewByItem ? (
                  <>
                    {itemsTables.map((table) => {
                      // return table.status === "notServed" ? <ItemsCard table={table} /> : "";
                      return (
                        table.tableNo !== "takeout" &&
                        table.status === "notServed" && (
                          <ItemsCard
                            table={table}
                            setItemsTables={setItemsTables}
                            reload={reload}
                            setReload={setReload}
                            tempSocket={tempSocket}
                            key={table._id}
                          />
                        )
                      );
                    })}
                    {!itemsTables.some(
                      (table) =>
                        table.tableNo !== "takeout" && table.status === "notServed"
                    ) ? (
                      <Typography>This box is empty</Typography>
                    ) : (
                      ""
                    )}
                  </>
                ) : diningItems.length ? (
                  diningItems.map((item) => {
                    return <ItemsContainer item={item} key={item.name} />;
                  })
                ) : (
                  <Typography>This box is empty</Typography>
                )
              ) : (
                <Typography>This box is empty</Typography>
              )}
            </Box>
          </Box>
          <Box className={classes.innerRoot}>
            <Typography variant="h3" style={{marginBottom: "10px"}}>
              Takeout order items
            </Typography>
            <Box display="flex" gap={3} className={classes.container}>
              {itemsTables.length ? (
                !viewByItem ? (
                  <>
                    {itemsTables.map((table) => {
                      // return table.status === "notServed" ? <ItemsCard table={table} /> : "";
                      return table.tableNo === "takeout" &&
                        table.status === "notServed" ? (
                        <ItemsCard
                          table={table}
                          setItemsTables={setItemsTables}
                          reload={reload}
                          setReload={setReload}
                          tempSocket={tempSocket}
                          key={table._id}
                        />
                      ) : (
                        ""
                      );
                    })}
                    {!itemsTables.some(
                      (table) =>
                        table.tableNo === "takeout" && table.status === "notServed"
                    ) ? (
                      <Typography>This box is empty</Typography>
                    ) : (
                      ""
                    )}
                  </>
                ) : takeoutItems.length ? (
                  takeoutItems.map((item) => {
                    return <ItemsContainer item={item} key={item.name} />;
                  })
                ) : (
                  <Typography>This box is empty</Typography>
                )
              ) : (
                <Typography>No takeout items</Typography>
              )}
            </Box>
          </Box>
          <Box className={classes.innerRoot} sx={{mt: 2}}>
            <Typography variant="h3" style={{marginBottom: "10px"}}>
              {" "}
              Waiting order items
            </Typography>
            <Box display="flex" gap={3} className={classes.container}>
              {waitingOrderTables.length ? (
                waitingOrderTables.map((table) => {
                  // return table.status === "notServed" ? <ItemsCard table={table} /> : "";
                  return (
                    <ItemsCard
                      table={table}
                      setWaitingOrderTables={setWaitingOrderTables}
                      reload={reload}
                      setReload={setReload}
                      isWaiting={true}
                      tempSocket={tempSocket}
                      key={table._id}
                    />
                  );
                })
              ) : (
                <Typography>This box is empty</Typography>
              )}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
          {progress === 100 ? (
            <Typography variant="h3">No items to show</Typography>
          ) : (
            <Spinner />
          )}
        </Box>
      )}
    </>
  );
}

export default KitchenDisplaySystem;
