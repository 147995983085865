import {makeDecrypt} from "src/utils/securels";
import axios from "../../axios";
import {
  CATEGORY_LOADING,
  DELETE_TABLE,
  LOADING_RESTAURANT,
  SET_RESTAURANT,
  SET_THROTTLING,
  STOP_CATEGORY_LOADING,
  UPDATE_MAIN_MENU,
  UPDATE_RESTAURANT,
  UPDATE_TABLE,
} from "../types";
import {setReload} from "./orderActions";
import {getMainMenus} from "./mainMenuActions";
import {getFilteredMenuItems, getMenuItems} from "./menuActions";

export const getRestaurant = () => async (dispatch) => {
  dispatch({type: LOADING_RESTAURANT});
  const restId = makeDecrypt("restaurantId");
  return axios
    .get(`/restaurant/restaurant-details/${restId}`, {
      headers: {Authorization: makeDecrypt("jwt")},
    })
    .then((res) => {
      dispatch({
        type: SET_RESTAURANT,
        payload: res.data.result,
      });
      dispatch({type: STOP_CATEGORY_LOADING});
      return res.data.result;
      // navigate('/app/products', { replace: true })
    })
    .catch((error) => {
      return error.message;
    });
};

export const updateRestaurant = (restaurantData) => (dispatch) => {
  return axios
    .put(
      `/restaurant/restaurant-details/${makeDecrypt("restaurantId")}`,
      restaurantData,
      {headers: {Authorization: makeDecrypt("jwt")}}
    )
    .then((res) => {
      dispatch({
        type: UPDATE_RESTAURANT,
        payload: res.data,
      });
      // navigate("/app/dashboard", {replace: true});
    })
    .catch((error) => {
      return error.message;
    });
};

export const getMenuCategories = (navigate) => (dispatch) => {
  const restId = makeDecrypt("restaurantId");
  axios
    .get(`/restaurant/menu-categories/${restId}`, {
      headers: {Authorization: makeDecrypt("jwt")},
    })
    .then((res) => {
      dispatch({
        type: UPDATE_RESTAURANT,
        payload: res.data.result,
      });
      // dispatch(setReload())
    })
    .catch((error) => {
      return error.message;
    });
  // navigate('/app/products', { replace: true })
};

export const editCategoryName = (data, navigate, selectedMainMenu) => (dispatch) => {
  const restId = makeDecrypt("restaurantId");
  axios
    .put(`/restaurant/menu-categories/edit-name/${restId}`, data, {
      headers: {Authorization: makeDecrypt("jwt")},
    })
    .then((res) => {
      // dispatch(getRestaurant())
      // window.location.reload();
      dispatch(getMainMenus(navigate, selectedMainMenu));
    })
    .catch((error) => {
      return error.message;
    });
};

export const addCategory =
  (data, menuId, navigate, setThisReload, thisReload) => (dispatch) => {
    dispatch({type: CATEGORY_LOADING});
    // dispatch({ type: LOADING_RESTAURANT })
    const restId = makeDecrypt("restaurantId");
    axios
      .post(`/restaurant/menu-categories/add-category/${restId}/${menuId}`, data, {
        headers: {Authorization: makeDecrypt("jwt")},
      })
      .then((res) => {
        // dispatch({type: UPDATE_MAIN_MENU, payload: res.data});
        // window.location.reload();
        // dispatch(setReload())
        // setThisReload(!thisReload);
        // dispatch(getRestaurant(navigate));
        let selectedMainMenu = res.data.name;
        dispatch(getMainMenus(navigate, selectedMainMenu));
        return "yeess";
      })
      .catch((error) => {
        return error.message;
      });
    // navigate('/app/products', { replace: true })
  };

export const toggleCategoryStatus =
  (data, navigate, selectedMainMenu, selectedCategory, page, limit) => (dispatch) => {
    const restId = makeDecrypt("restaurantId");
    axios
      .put(`/restaurant/menu-categories/toggle-status/${restId}`, data, {
        headers: {Authorization: makeDecrypt("jwt")},
      })
      .then((res) => {
        // window.location.reload();
        dispatch(getMainMenus(navigate, selectedMainMenu));
        // dispatch(getMenuItems(true));
        dispatch(getFilteredMenuItems(selectedCategory, page, limit));
      })
      .catch((error) => {
        return error.message;
      });
  };

export const createExpressAccount = async () => {
  return axios
    .post(`/stripe/createExpressAccount`, {})
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.message;
    });
};

export const createOnboardingLink = async (stripeAccountId) => {
  return axios
    .post(`/stripe/getOnboardingLink`, {
      stripeAccountId: stripeAccountId,
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.message;
    });
};

export const createDashboardLink = async (stripeAccountId) => {
  return axios
    .post(`/stripe/getDashboardLink`, {
      stripeAccountId: stripeAccountId,
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.message;
    });
};

export const createNewCustomer = async (email) => {
  return axios
    .post("/stripe/newCustomer", {email})
    .then(async (res) => {
      return res.data;
    })
    .catch((error) => {
      return error.message;
    });
};

export const getRestaurantBranches = () => (dispatch) => {
  axios
    .get(`/restaurant/get-restaurant-branches`, {
      headers: {Authorization: makeDecrypt("jwt")},
    })
    .then((res) => {
      // window.location.reload();
      dispatch({
        type: "SET_RESTAURANT_BRANCHES",
        payload: res.data,
      });
    })
    .catch((error) => {
      return error.message;
    });
};

export const addPrinter = (restaurant, defaultName, customName) => (dispatch) => {
  axios
    .put(
      `/restaurant/restaurant-details/add-printer/${makeDecrypt("restaurantId")}`,
      {restaurant: restaurant, name: customName, value: defaultName},
      {
        headers: {Authorization: makeDecrypt("jwt")},
      }
    )
    .then((res) => {
      dispatch({
        type: SET_RESTAURANT,
        payload: res.data,
      });
      // window.location.reload();
      // dispatch({
      //   type: "SET_RESTAURANT_BRANCHES",
      //   payload: res.data,
      // });
    })
    .catch((error) => {
      return error.message;
    });
};

export const deletePrinter = (restaurant, printerId) => (dispatch) => {
  return axios
    .post(
      `/restaurant/restaurant-details/delete-printer/${makeDecrypt("restaurantId")}`,
      {restaurant: restaurant, printerId: printerId},
      {
        headers: {Authorization: makeDecrypt("jwt")},
      }
    )
    .then((res) => {
      dispatch({
        type: SET_RESTAURANT,
        payload: res.data,
      });
      return res.data;
    })
    .catch((error) => {
      return error.message;
    });
};

export const setThrottling =
  (status, limit, setThrottlingBtnLoading, setBtnLoading) => (dispatch) => {
    axios
      .post(
        `/restaurant/set-order-throttling`,
        {restId: makeDecrypt("restaurantId"), status: status, limit: limit},
        {
          headers: {Authorization: makeDecrypt("jwt")},
        }
      )
      .then((res) => {
        dispatch({
          type: SET_THROTTLING,
          payload: res.data,
        });
        setThrottlingBtnLoading(false);
        setBtnLoading(false);
        // window.location.reload();
        // dispatch({
        //   type: "SET_RESTAURANT_BRANCHES",
        //   payload: res.data,
        // });
      })
      .catch((error) => {
        setThrottlingBtnLoading(false);
        setBtnLoading(false);
        return error.message;
      });
  };

export const deleteTable = (tableId, tables, setTables) => (dispatch) => {
  axios
    .get(`/restaurant/delete-table/${makeDecrypt("restaurantId")}/${tableId}`)
    .then((res) => {
      dispatch({
        type: DELETE_TABLE,
        payload: tableId,
      });
      return res.data;
    })
    .catch((error) => {
      return error.message;
    });
};

export const updateTable = (tableId, tables, setTables, newTableId) => (dispatch) => {
  axios
    .get(
      `/restaurant/update-table/${makeDecrypt("restaurantId")}/${tableId}/${newTableId}`
    )
    .then((res) => {
      dispatch({
        type: UPDATE_TABLE,
        payload: {id: tableId, updateNo: newTableId},
      });
      return res.data;
    })
    .catch((error) => {
      return error.message;
    });
};
