import {Box, CircularProgress, Divider, Paper, Typography} from "@mui/material";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import EachOrder from "./EachOrder";
import {makeStyles} from "tss-react/mui";
import axios from "../../axios";
import {useSelector} from "react-redux";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import _, {map} from "lodash";
var HashMap = require("hashmap");
const useStyles = makeStyles()((theme) => {
  return {
    ordersBlock: {
      // margin: `${theme.spacing(2)} 0`,
      // backgroundColor: "#f8f9fa",
      backgroundColor: "#ffffff",
      marginBottom: "20px",
      padding: "10px",
      borderRadius: "5px",
    },
  };
});

function TabPanel(props) {
  const {classes} = useStyles();
  const {
    children,
    value,
    index,
    order,
    tempSocket,
    setAlertMessage,
    setTakeout,
    ...other
  } = props;
  const [ordersNeedAction, setOrdersNeedAction] = useState(false);
  const [ordersInProgress, setOrdersInProgress] = useState(false);
  const [cloverDeviceConnected, setCloverDeviceConnected] = useState(false);
  const {restaurant} = useSelector((state) => state);
  const { reload  } = useSelector((state) => state.order);
  const [statusLoading, setStatusLoading] = useState(false);
  const [map, setMap] = useState(new HashMap());

  useEffect(() => {
    if (order) {
      map.clear();
      order.liveOrders.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      order.liveOrders.map((eachOrder) => {
        if (eachOrder.items.some((item) => item.isAccepted !== true)) {
          setOrdersNeedAction(true);
        } else if (eachOrder.items.every((item) => item.isAccepted === true)) {
          setOrdersInProgress(true);
        }
        map.set(
          eachOrder.tableNumber,
          order.liveOrders.filter(
            (liveOrder) => liveOrder.tableNumber === eachOrder.tableNumber
          )
        );
      });
    }
  }, [order, reload]);

  useEffect(() => {
    if (restaurant?.clover?.deviceId && !cloverDeviceConnected) {
      setStatusLoading(true);

      axios
        .post("/clover/check-device-connectivity", {
          deviceId: restaurant?.clover?.deviceId,
          apiToken: restaurant?.clover?.apiToken,
          restaurantName: restaurant?.name,
        })
        .then((response) => {
          setCloverDeviceConnected(true);
          setStatusLoading(false);
        })
        .catch((error) => {
          setCloverDeviceConnected(false);
          setStatusLoading(false);
        });
    }
  }, [restaurant]);
  const filterTheOrders = (ordersMap, condition, type) => {
    // console.log(ordersMap)
    return ordersMap.keys().map((eachOrderTable) => {
      let multipleOrders = ordersMap.get(eachOrderTable);
      // console.log(multipleOrders);
      if (multipleOrders.length > 1) {
        if (
          multipleOrders.every((eachFromMultipleOrder) =>
            eachFromMultipleOrder.items.some((item) => item.isAccepted === condition)
          )
        ) {
          if (eachOrderTable !== "takeout") {
            let eachOrder = multipleOrders[0];
            return (
              <EachOrder
                order={eachOrder}
                needAction={type == "needsAction" ? true : false}
                key={eachOrder._id}
                setTakeout={setTakeout}
                tempSocket={tempSocket}
                cloverDeviceConnected={cloverDeviceConnected}
                setAlertMessage={setAlertMessage}
                areMultipleOrders={true}
                multipleOrders={multipleOrders}
              />
            );
          } else {
            return multipleOrders.map((takeoutOrder) => {
              return (
                <EachOrder
                  order={takeoutOrder}
                  needAction={type == "needsAction" ? true : false}
                  key={takeoutOrder._id}
                  tempSocket={tempSocket}
                  setTakeout={setTakeout}
                  cloverDeviceConnected={cloverDeviceConnected}
                  setAlertMessage={setAlertMessage}
                  areMultipleOrders={false}
                  multipleOrders={[]}
                />
              );
            });
          }
        } else {
          return multipleOrders.map((eachFromMultipleOrder) => {
            if (
              eachFromMultipleOrder.items.some((item) => item.isAccepted === condition)
            ) {
              return (
                <EachOrder
                  order={eachFromMultipleOrder}
                  needAction={type == "needsAction" ? true : false}
                  key={eachFromMultipleOrder._id}
                  tempSocket={tempSocket}
                  setTakeout={setTakeout}
                  cloverDeviceConnected={cloverDeviceConnected}
                  setAlertMessage={setAlertMessage}
                  areMultipleOrders={false}
                  multipleOrders={[]}
                />
              );
            }
          });
        }
      } else {
        let eachOrder = multipleOrders[0];
        if (eachOrder.items.some((item) => item.isAccepted === condition)) {
          return (
            <EachOrder
              order={eachOrder}
              needAction={type == "needsAction" ? true : false}
              key={eachOrder._id}
              tempSocket={tempSocket}
              setTakeout={setTakeout}
              cloverDeviceConnected={cloverDeviceConnected}
              setAlertMessage={setAlertMessage}
              areMultipleOrders={false}
              multipleOrders={[]}
            />
          );
        }
      }
    });
  };
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index &&
        (order ? (
          <Box>
            {restaurant?.clover?.acceptPayment && (
              <Box
                display={"flex"}
                alignItems="center"
                width={"fit-content"}
                sx={{my: 2}}
              >
                {statusLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <FiberManualRecordIcon
                    fontSize="small"
                    style={{
                      color: cloverDeviceConnected ? "green" : "gray",
                      margin: "auto",
                    }}
                  />
                )}
                <Typography sx={{ml: 1}}>
                  {statusLoading
                    ? "Fetching device status"
                    : cloverDeviceConnected
                    ? "Clover device online"
                    : "Clover device offline"}
                </Typography>
              </Box>
            )}
            { ordersNeedAction ? (
              <Box className={classes.ordersBlock}>
              <Typography variant="h4">Orders needing Action</Typography>
              <Divider style={{margin: "10px 0"}} />

              <Box sx={{px: 0}}>
                { filterTheOrders(map, false, "needsAction") }
              </Box>
            </Box>
            ) : ""}
            
            <Box className={classes.ordersBlock}>
              <Typography variant="h4">Orders in Progress</Typography>
              <Divider style={{margin: "10px 0"}} />
              <Box sx={{px: 0.5}}>
                {!ordersInProgress ? (
                  <Typography variant="h5" color="textSecondary" textAlign="center">
                    Still no orders is accepted
                  </Typography>
                ) : (
                  filterTheOrders(map, true, "inProgress")
                )}
              </Box>
            </Box>
          </Box>
        ) : (
          <Box>{children}</Box>
        ))}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default TabPanel;
