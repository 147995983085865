import {
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {makeStyles, withStyles} from "tss-react/mui";
import moment from "moment";
import React, {useEffect, useState} from "react";
import Spinner from "src/utils/spinner/spinner";
import OrderModal from "./OrderModal";
import {useDispatch, useSelector} from "react-redux";
import EachOrder from "./EachOrder";
const StyledTableCell = withStyles(TableCell, (theme) => ({
  head: {
    fontSize: 18,
  },
  body: {
    fontSize: 14,
  },
}));

const StyledTableRow = withStyles(TableRow, (theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const useStyles = makeStyles()((theme) => {
  return {
    table: {
      minWidth: 700,
    },
    root: {
      margin: `${theme.spacing(2)} 0`,
    },
    eachOrder: {
      fontSize: "18px",
      padding: theme.spacing(3),
      margin: `${theme.spacing(2)} 0`,
      background: "#f4f4f4",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      width: "fit-content",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2),
    },
    orderBox: {
      margin: "10px",
      boxShadow:
        "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;",
      padding: "20px 10px ",
      borderRadius: "5px",
      cursor: "pointer",
      backgroundColor: "white",
    },
  };
});

export default function PastOrdersTable({orders, tempSocket, tab}) {
  const {classes} = useStyles();
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [acceptedItems, setAcceptedItems] = useState([]);
  const [notAcceptedItems, setNotAcceptedItems] = useState([]);
  const [innerModalOpen, setInnerModalOpen] = useState(false);
  let {application_service_fee} = useSelector(
    (state) => state.restaurant.dinify_payment_plan
  );
  const {currencySign} = useSelector((state) => state.restaurant);
  useEffect(() => {
    // setRows([]);
    let tempRows = [];

    orders.map((order) => {
      let subTotal = 0;

      order.items.map((item, index) => {
        subTotal = parseFloat(subTotal) + parseFloat(item.quantity * item.price);
      });

      tempRows.push(
        tab === "pastOrders"
          ? createData(
              order._id,
              order.createdAt,
              order.grandTotal,
              order.status,
              order.seqValue
            )
          : createDataForTips(
              order._id,
              order.tableNumber,
              order.server ? order.server : 1,
              parseFloat(order.tip ? order.tip : 0),
              order.createdAt,
              order.seqValue
            )
      );
      // setRows((prevValues) => [
      //   ...prevValues,
      //  tab ==="pastOrders" ? createData(order._id, order.createdAt, parseFloat(order.totalPrice) + parseFloat(order.tip), order.status) :
      //  createDataForTips(order._id, order.tableNumber, order.server ? order.server : 1 ,parseFloat(order.tip),order.createdAt),
      // ]);
      if (tempRows.length === orders.length) setRows(tempRows);
    });
  }, [orders]);

  function createData(id, date, amount, status, seqValue) {
    return {id, date, amount, status, seqValue};
  }
  function createDataForTips(id, tableNumber, employeeId, tipAmount, time, seqValue) {
    return {id, tableNumber, employeeId, tipAmount, time, seqValue};
  }
  const handleOpen = (index) => {
    setOpen(true);
    setCurrentOrder(orders[index]);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentOrder(null);
  };

  const showModal = (needAction = false) => {
    return (
      <OrderModal
        order={currentOrder}
        needAction={needAction}
        pastOrder={true}
        handleClose={handleClose}
        tempSocket={tempSocket}
        rows2={notAcceptedItems}
        rows={acceptedItems}
        setRows={setAcceptedItems}
        setRows2={setNotAcceptedItems}
        openInnerModal={innerModalOpen}
        setOpenInnerModal={setInnerModalOpen}
        showUpdateBtn={false}
        isMerged={false}
        multipleOrders={[]}
        setClonedOrder={() => {}}
      />
    );
  };

  return (
    <Box className={classes.root}>
      {!rows.length ? (
        <Spinner />
      ) : (
        rows.map((row, index) => {
          return (
            <Box
              key={row.id}
              className={classes.orderBox}
              onClick={() => {
                tab != "tips" && handleOpen(index);
              }}
            >
              <Grid container display={"flex"} alignItems="center" spacing={1}>
                <Grid item sm={3} xs={12}>
                  <Box scope="row">
                    <Typography variant="h3" style={{fontSize: "18px"}}>
                      Order No.{row.seqValue ? row.seqValue : row.id}
                    </Typography>
                  </Box>
                </Grid>
                {tab === "tips" && (
                  <Grid item sm={3} xs={12}>
                    <Box align="right">
                      {row.tableNumber == "takeout" ? (
                        <Typography>{row.tableNumber}</Typography>
                      ) : (
                        <Typography>Table No.{row.tableNumber}</Typography>
                      )}
                    </Box>
                  </Grid>
                )}
                {tab !== "tips" && (
                  <Grid item sm={4} xs={12}>
                    <Box align="right">
                      <Typography>{moment(row.date).fromNow()}</Typography>
                    </Box>
                  </Grid>
                )}
                {tab === "tips" && (
                  <Grid item sm={5} xs={12}>
                    <Box align="right">
                      <Typography>Employee Id - {row.employeeId}</Typography>
                    </Box>
                  </Grid>
                )}
                {tab !== "tips" && (
                  <Grid item sm={4} xs={12}>
                    <Box align="right">
                      <Typography>Total - ${row.amount.toFixed(2)}</Typography>
                    </Box>
                  </Grid>
                )}

                {tab === "tips" && (
                  <Grid item sm={1} xs={12}>
                    <Box align="right">
                      <Typography>
                        Tip - {currencySign}
                        {row.tipAmount.toFixed(2)}
                      </Typography>
                    </Box>
                  </Grid>
                )}
                {tab === "tips" ? (
                  <Grid item sm={4} xs={12}>
                    <Box align="right">
                      <Typography>
                        {moment(row.time).fromNow() + " on " + row.time.split("T")[0]}
                      </Typography>
                    </Box>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </Box>
          );
        })
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        // BackdropComponent={Backdrop}
        // BackdropProps={{
        //   timeout: 500,
        // }}
      >
        <Fade in={open}>
          <Box className={classes.paper}>{currentOrder ? showModal() : ""}</Box>
        </Fade>
      </Modal>
      <Box style={{display: "none"}}>
        {currentOrder && (
          <EachOrder
            order={currentOrder}
            needAction={false}
            tempSocket={tempSocket}
            hideAcceptBtn={true}
            setAcceptedItems={setAcceptedItems}
            setNotAcceptedItems={setNotAcceptedItems}
            setInnerModalOpen={setInnerModalOpen}
            multipleOrders={[]}
            areMultipleOrders={false}
          />
        )}
      </Box>
    </Box>
  );
}
