import OnBoarding from "./OnBoarding";
import RestDetails from "src/components/onboarding-steps/RestDetails";
import Agreement from "src/components/onboarding-steps/Agreement";
import React, {useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {Button, Stepper, Step, StepLabel} from "@mui/material/";
import OnBoardingProfile from "src/components/account/OnBoardingProfile";
import Payment from "src/components/onboarding-steps/Payment";
import {makeDecrypt} from "src/utils/securels";
import {Box, Dialog, DialogContent, DialogTitle, Typography, Grid} from "@mui/material";
import Logo from "src/components/Logo";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Plan from "src/components/onboarding-steps/Plan";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      // width: (newBranch) => (newBranch ? "100%" : "70%"),
      width: (newBranch) => "100%",
      // padding: "20px 0",
      margin: "auto",
      backgroundColor: "white",
      padding: "15px",
      height: "90vh",
      "& .MuiOutlinedInput-root": {
        maxWidth: "400px",
      },
    },
    stepper: {
      display: "flex",
      // backgroundColor: "white",
      opacity: "1",
      // padding: "10px  !important",
      // boxShadow: "0 13px 38px -25px grey",
      "& .MuiStepLabel-label": {
        color: "white",
      },
      "& .MuiSvgIcon-root": {
        color: "white",
      },
      "& .MuiStepIcon-text": {
        color: "#FF5757",
        fill: "#FF5757",
      },
      marginTop: "50px",
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    formWrapper: {
      // marginTop: "10px",
      // margin: "auto",
      // width: (newBranch) => (newBranch ? "100%" : "60%"),
      width: "100%",
    },
    buttonCont: {
      display: "flex",
      justifyContent: "space-between",
      padding: "10px 20px",
      borderTop: "1px solid gray",
      position: "sticky",
      bottom: "0",
      backgroundColor: "white",
      zIndex: "99",
      boxShadow: "1px -8px 58px -34px rgba(0,0,0,0.91)",
      height: "55px",
    },
    stepsBox: {
      height: "98vh",
      width: "100%",
      borderRadius: "10px",
      backgroundColor: "#FF5757",
      padding: "20px",
      position: "relative",
    },
    contentBox: {
      height: "90vh",
      paddingTop: "20px",
    },
    helpBox: {
      position: "absolute",
      bottom: "20px",
    },
  };
});

export default function HorizontalLabelPositionBelowStepper({newBranch, handleClose}) {
  const {classes} = useStyles(newBranch ? true : false);
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [logoImage, setLogoImage] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(true);
  const [restaurantData, setRestaurantData] = useState();
  const [representativeName, setRepresentativeName] = useState("");
  const [onBoardingSuccess, setOnboardingSuccess] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState("");
  const [yearly, setYearly] = useState(false);
  const [initialData, setInitialData] = useState();
  const [plandSelected, setPlanSelected] = useState(false);
  const [open, setOpen] = useState(false);

  const newhandleSubmit = () => {
    document.getElementById("hiddenButton").click();
  };
  const handleNext = () => {
    if (activeStep === 3 && onBoardingSuccess) {
      window.location.replace("/dashboard");
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function getSteps() {
    return ["Logo", "Details", "Choose your plan", "Terms of Service", "Finish"];
  }

  const saveInitialData = () => {
    document.getElementById("hiddenButtonInitial").click();
  };

  const handleClick = () => {
    if (activeStep === 1) newhandleSubmit();
    else if (activeStep === 0) saveInitialData();
    else handleNext();
  };

  /**
   *Onboarding steps
   */

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <OnBoarding
            logoImage={logoImage}
            setLogoImage={setLogoImage}
            newBranch={newBranch}
            initialData={initialData}
            setInitialData={setInitialData}
            setRepresentativeName={setRepresentativeName}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
          />
        );
      case 1:
        return (
          <OnBoardingProfile
            logoImage={logoImage}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            setRepresentativeName={setRepresentativeName}
            setRestaurantData={setRestaurantData}
            newBranch={newBranch}
            initialData={initialData}
          />
        );
      case 2:
        return (
          <Plan
            setTermsAccepted={setTermsAccepted}
            legalRepresentative={makeDecrypt("legalRepresentative")}
            setSelectedPrice={setSelectedPrice}
            selectedPrice={selectedPrice}
            setYearly={setYearly}
            yearly={yearly}
            restaurantData={restaurantData}
            setPlanSelected={setPlanSelected}
          />
        );
      case 3:
        return (
          <Agreement
            setTermsAccepted={setTermsAccepted}
            legalRepresentative={makeDecrypt("legalRepresentative")}
            setSelectedPrice={setSelectedPrice}
            selectedPrice={selectedPrice}
            setYearly={setYearly}
            yearly={yearly}
            restaurantData={restaurantData}
          />
        );
      case 4:
        return (
          <Payment
            setTermsAccepted={setTermsAccepted}
            legalRepresentative={makeDecrypt("legalRepresentative")}
            representativeName={representativeName}
            setOnboardingSuccess={setOnboardingSuccess}
            selectedPrice={selectedPrice}
            setYearly={setYearly}
            yearly={yearly}
            restaurantData={restaurantData}
            logoImage={logoImage}
            newBranch={newBranch}
            handleClose={handleClose}
          />
        );
      default:
        return "Unknown stepIndex";
    }
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={2}>
          <Box className={classes.stepsBox} backgroundColor="primaryMain">
            <Box display={"flex"} alignItems="center">
              <Logo />
              <Typography variant="h1" fontWeight={"bold"} color="#fff">
                Dinify
              </Typography>
            </Box>
            <Box>
              <Stepper
                activeStep={activeStep}
                // alternativeLabel
                orientation="vertical"
                className={classes.stepper}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            <Box className={classes.helpBox}>
              <Typography variant="caption" style={{color: "white"}}>
                {`Don't know what to choose?`}
              </Typography>
              <Typography
                variant="h6"
                style={{color: "white", cursor: "pointer"}}
                onClick={() => setOpen(true)}
              >
                Get help
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={10}>
          <div className={classes.formWrapper}>
            {activeStep === steps.length ? (
              <div>
                <Typography className={classes.instructions}>
                  All steps completed
                </Typography>
                <Button onClick={handleReset}>Reset</Button>
              </div>
            ) : (
              <div className={classes.contentBox}>
                {getStepContent(activeStep)}
                <div className={classes.buttonCont}>
                  <Button
                    onClick={handleBack}
                    className={classes.backButton}
                    disabled={activeStep == 0 ? true : false}
                    startIcon={<KeyboardBackspaceIcon />}
                  >
                    Back
                  </Button>
                  {!(activeStep === steps.length - 1) && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClick}
                      id="nextStepButton"
                      disabled={
                        activeStep == 2
                          ? !plandSelected
                          : activeStep == 3
                          ? termsAccepted
                            ? false
                            : true
                          : false
                      }
                      endIcon={<ArrowRightAltIcon />}
                    >
                      Continue
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
        </Grid>
      </Grid>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <Box>
            <Typography variant="h4">For support contact us at:</Typography>
            <Box sx={{mt: 1}}>
              <Box display={"flex"} alignItems="center">
                <EmailIcon fontSize="small" />
                <Typography variant="h6" sx={{m: 1}}>
                  support@dinify.io
                </Typography>
              </Box>
              <Box display={"flex"} alignItems="center">
                <CallIcon fontSize="small" />
                <Typography variant="h6" sx={{m: 1}}>
                  +12365090705
                </Typography>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
