import { makeDecrypt } from "src/utils/securels";
import { CATEGORY_LOADING, UPDATE_CATEGORY, UPDATE_N_CATEGORY, UPDATE_RESTAURANT } from "../types";
import { getMainMenus } from "./mainMenuActions";
import axios from "../../axios";
import { getFilteredMenuItems } from "./menuActions";

export const addCategoryN =
    (data, menuId, navigate, setThisReload, thisReload) => (dispatch) => {
        dispatch({ type: CATEGORY_LOADING });
        // dispatch({ type: LOADING_RESTAURANT })
        const restId = makeDecrypt("restaurantId");
        axios
            .post(`/category/menu-categories/add-category/${restId}/${menuId}`, data, {
                headers: { Authorization: makeDecrypt("jwt") },
            })
            .then((res) => {
                // dispatch({type: UPDATE_MAIN_MENU, payload: res.data});
                // window.location.reload();
                // dispatch(setReload())
                // setThisReload(!thisReload);
                // dispatch(getRestaurant(navigate));
                dispatch(getMenuCategoriesN());
                let selectedMainMenu = res.data.name;
                dispatch(getMainMenus(navigate, selectedMainMenu));
                return "yes";
            })
            .catch((error) => {
                return error.message;
            });
        // navigate('/app/products', { replace: true })
    };

export const toggleCategoryStatusN =
    (data, navigate, selectedMainMenu, selectedCategory, page, limit) => (dispatch) => {
        const restId = makeDecrypt("restaurantId");
        axios
            .put(`/category/menu-categories/toggle-status/${restId}`, data, {
                headers: { Authorization: makeDecrypt("jwt") },
            })
            .then((res) => {
                // window.location.reload();
                dispatch(getMenuCategoriesN());
                dispatch(getMainMenus(navigate, selectedMainMenu));
                // dispatch(getMenuItems(true));
                dispatch(getFilteredMenuItems(selectedCategory, page, limit));
            })
            .catch((error) => {
                return error.message;
            });
    };

export const getMenuCategoriesN = (navigate) => (dispatch) => {
    const restId = makeDecrypt("restaurantId");
    axios
        .get(`/category/menu-categories/${restId}`, {
            headers: { Authorization: makeDecrypt("jwt") },
        })
        .then((res) => {
            dispatch({
                type: UPDATE_N_CATEGORY,
                payload: res.data,
            });
            // dispatch(setReload())
        })
        .catch((error) => {
            return error.message;
        });
    // navigate('/app/products', { replace: true })
};

export const editCategoryNameN = (data, navigate, selectedMainMenu) => (dispatch) => {
    const restId = makeDecrypt("restaurantId");
    axios
      .put(`/category/menu-categories/edit-name/${restId}`, data, {
        headers: { Authorization: makeDecrypt("jwt") },
      })
      .then((res) => {
        // dispatch(getRestaurant())
        // window.location.reload();
        dispatch(getMenuCategoriesN());
        dispatch(getMainMenus(navigate, selectedMainMenu));
      })
      .catch((error) => {
        return error.message;
      });
  };
