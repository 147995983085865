import {makeDecrypt} from "src/utils/securels";
import axios from "../../axios";

export const getLiveOrders = () => async (dispatch) => {
  const employeeId = makeDecrypt("employeeId");
  const restId = makeDecrypt("restaurantId");
  await axios
    .get(`/order/${employeeId}/${restId}/live-orders`, {
      headers: {Authorization: makeDecrypt("jwt")},
    })
    .then((res) => {
      dispatch({
        type: "ADD_LIVE_ORDERS",
        payload: res.data,
      });
    })
    .catch((error) => {
      return error.message;
    });
};

export const getWaitingOrders = () => async (dispatch) => {
  // const employeeId = makeDecrypt("employeeId");
  const restId = makeDecrypt("restaurantId");
  await axios
    .get(`/order/${restId}/get-waiting-orders`, {
      headers: {Authorization: makeDecrypt("jwt")},
    })
    .then((res) => {
      dispatch({
        type: "ADD_WAITING_ORDERS",
        payload: res.data,
      });
    })
    .catch((error) => {
      return error.message;
    });
};

export const setReload = () => {
  return {
    type: "SET_RELOAD",
  };
};

export const resetInitialLoad = () =>{
  return {
    type: "RESET_INITIAL_LOAD",
  };
}
