import {
  ADD_EMPLOYEE,
  LOADING_EMPLOYEE,
  UPDATE_EMPLOYEE,
  DELETE_EMPLOYEE,
  GET_ALL_EMPLOYEES,
} from "../types";

const initialState = {
  employee: [
    {
      isVerified: true,
      email: "",
      name: "",
      role: "ROLE_EMPLOYEE",
      // address: {},
      // imageUrl: [],
      phone: "",
      restaurant: "",
      createdAt: "",
      _id: "",
      firstTimeLogin: true,
    },
  ],
  loadingEmployee: true,
};

export default function employeeReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_EMPLOYEE:
      return {
        ...state,
        loadingEmployee: true,
      };
    case ADD_EMPLOYEE:
      return {
        ...state,
        employee: [...state.employee, action.payload],
        loadingEmployee: true,
      };
    case GET_ALL_EMPLOYEES:
      return {
        ...state,
        employee: action.payload.map((employee) => employee),
        loadingEmployee: false,
      };
    case UPDATE_EMPLOYEE:
      return {
        ...state,
        employee: [
          state.employee.map((employee) =>
            employee._id === action.payload._id ? {...action.payload} : employee
          ),
        ],
        loadingEmployee: false,
      };
    case DELETE_EMPLOYEE:
      return {
        ...state,
        employee: [state.employee.filter((employee) => employee._id !== action.payload)],
        loadingEmployee: false,
      };
    default:
      return state;
  }
}
