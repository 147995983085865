import {UploadMenuImage} from "src/components/product/helpers";
import {makeDecrypt} from "src/utils/securels";
import axios from "../../axios";
import {
  GET_ALL_MENU,
  UPDATE_MENU,
  LOADING_MENU,
  DELETE_MENU,
  LOADING_COMPLETED,
  ADD_MENU,
  LOADING_NOT_COMPLETED,
  STOP_LOADING_MENU,
} from "../types";
import {getMainMenus} from "./mainMenuActions";
import {getMenuCategories} from "./restaurantActions";

export const createMenuItem =
  (
    menuData,
    menuImage,
    navigate,
    thisReload,
    setThisReload,
    logoUrl,
    setAlertMessage,
    setSnackOpen
  ) =>
  (dispatch) => {
    const restId = makeDecrypt("restaurantId");
    // dispatch({ type: LOADING_MENU })
    axios
      .post(`/menu/${restId}/create-menu-item`, menuData, {
        headers: {Authorization: makeDecrypt("jwt")},
      })
      .then(async (res) => {
        const formData = new FormData();
        formData.append("file", menuImage);

        //uploaded on aws s3
        const newMenuData = await UploadMenuImage(formData, restId, res.data._id);

        dispatch({type: ADD_MENU, payload: res.data});
        setAlertMessage("Menu item added successfully!");
        setSnackOpen(true);

        //updating menu item with image url

        await axios.put(
          `/menu/${restId}/${res.data._id}/update-menu-item`,
          {
            imageUrl: newMenuData ? newMenuData?.file?.location : logoUrl,
          },
          {headers: {Authorization: makeDecrypt("jwt")}}
        );

        // dispatch(getMenuItems())
      })
      .catch((error) => {
        return error.message;
      });
    // navigate('/app/products', { replace: true })
  };

export const getMenuItems = (flag) => async (dispatch) => {
  const restId = makeDecrypt("restaurantId");
  let progress = 0;

  dispatch({type: LOADING_MENU});
  dispatch({type: LOADING_NOT_COMPLETED});
  return axios
    .get(`/menu/${restId}/menu-items`, {
      headers: {Authorization: makeDecrypt("jwt")},
      onDownloadProgress: (p) => {
        progress = (Math.round((p.loaded / p.total) * 100), "progress");
      },
    })
    .then((res) => {
      if (flag)
        dispatch({
          type: GET_ALL_MENU,
          payload: res.data,
        });
      if (progress == 100) dispatch({type: LOADING_COMPLETED});
      dispatch({type: STOP_LOADING_MENU});
      return progress;
    })
    .catch((error) => {
      return error.message;
    });
};

export const getFilteredMenuItems =
  (selectedCategory, page, limit) => async (dispatch) => {
    axios
      .get(
        `/menu/${makeDecrypt("employeeId")}/${makeDecrypt(
          "restaurantId"
        )}/${selectedCategory}/menuItems?page=${page}&limit=${limit}`,
        {headers: {Authorization: makeDecrypt("jwt")}}
      )
      .then((res) => {
        dispatch({
          type: "GET_ALL_MENU",
          payload: res.data.result,
        });
        dispatch({type: LOADING_COMPLETED});
      })
      .catch((error) => {});
  };

export const updateMenuItemStatus = (data, navigate) => (dispatch) => {
  dispatch({type: LOADING_MENU});
  axios
    .put(`/menu/${data.restId}/${data._id}/update-menu-item-status`, data, {
      headers: {Authorization: makeDecrypt("jwt")},
    })
    .then((res) => {
      dispatch({
        type: UPDATE_MENU,
        payload: res.data,
      });
    })
    .catch((error) => {
      return error.message;
    });
};

export const updateMenu = (menuData, navigate) => (dispatch) => {
  axios
    .post(`/${menuData.restId}/${menuData.menuId}/menu-item-detail`, menuData, {
      headers: {Authorization: makeDecrypt("jwt")},
    })
    .then((res) => {
      dispatch({
        type: UPDATE_MENU,
        payload: res.data.result,
      });
    })
    .catch((error) => {
      return error.message;
    });
  navigate("/app/customers", {replace: true});
};

export const deleteMenu = (menuData, navigate) => (dispatch) => {
  //   dispatch({ type: SET_ONBOARDED })
  axios
    .delete(`/${menuData.restId}/${menuData.menuId}/delete-menu-item`, {
      headers: {Authorization: makeDecrypt("jwt")},
    })
    .then((res) => {
      dispatch({
        type: DELETE_MENU,
        payload: res.data.result,
      });
    })
    .catch((error) => {
      return error.message;
    });
  navigate("/app/customers", {replace: true});
};

export const getDailySpecials = (menuItems) => (dispatch) => {
  //   dispatch({ type: SET_ONBOARDED })
  dispatch({type: LOADING_MENU});
  dispatch({type: LOADING_NOT_COMPLETED});
  axios
    .post(`/menu/${makeDecrypt("restaurantId")}/get-daily-special-items`, {
      menuItems: menuItems,
    })
    .then((res) => {
      dispatch({type: LOADING_COMPLETED});
      dispatch({type: STOP_LOADING_MENU});
      dispatch({
        type: GET_ALL_MENU,
        payload: res.data,
      });
    })
    .catch((error) => {
      return error.message;
    });
};
