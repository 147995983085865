/**
 *Steps for the tour feature
 */

export const Toursteps = [
  {
    element: ".dashboard",
    intro: "You can see your restaurant performance here.",
    position: "right",
    tooltipClass: "myTooltipClass",
    highlightClass: "myHighlightClass",
  },
  {
    element: ".orders",
    intro: "See live orders, Track past orders.",
    position: "right",
  },
  {
    element: ".floorlayout",
    intro: "See live orders, Track past orders.",
    position: "right",
  },
  {
    element: ".products",
    intro: "Manage you menu, categories & items.",
    position: "right",
  },
  {
    element: ".customers",
    intro: "Manage employees from here",
    position: "right",
  },
  {
    element: ".accounting",
    intro: "See your accounting details here.",
    position: "right",
  },
  {
    element: ".account",
    intro: "You can edit your profile from here",
    position: "right",
  },
  {
    element: ".manageaccount",
    intro: "See your Dinify plan.",
    position: "right",
  },
  {
    element: "#stripeButton",
    intro: "See your payments details",
    position: "right",
  },
  {
    element: ".notificationButton",
    intro: "See the notifications here",
    position: "bottom",
  },
  {
    element: ".logoutButton",
    intro: "You can logout from here",
    position: "bottom",
  },
];

export default {Toursteps};
