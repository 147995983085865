import {Button, IconButton, Snackbar} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, {useEffect, useState} from "react";

function AlertMsg({alertMessage, alertChange, setAlertMessage}) {
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    if (alertMessage) {
      setAlertOpen(true);

      if (setAlertMessage) {
        setTimeout(() => {
          setAlertMessage("");
        }, 3000);
      }
    }
  }, [alertMessage]);

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        key="bottomleft"
        open={alertOpen && alertMessage}
        autoHideDuration={3000}
        onClose={() => setAlertOpen(false)}
        message={alertMessage}
        action={
          <>
            <Button color="secondary" size="small" onClick={() => setAlertOpen(false)}>
              UNDO
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setAlertOpen(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </div>
  );
}

export default AlertMsg;
