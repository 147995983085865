import {Box, Button, Tooltip, Typography} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {getLiveOrders} from "src/redux/actions/orderActions";
import {paymentRequestToClover, updateOrder, printTheReceipt} from "./helpers";
const columns = [
  {
    field: "desc",
    headerName: "Name",
    width: 150,
    editable: false,
  },
  {
    field: "qty",
    headerName: "Qty",
    width: 120,
    editable: false,
  },
  {
    field: "unit",
    headerName: "Price",
    type: "number",
    width: 110,
    align: "left",
    sortable: false,
    editable: false,
  },
  {
    field: "subTotalForBill",
    headerName: "Total",
    sortable: false,
    width: 120,
    editable: false,
  },
];
export default function OrderItemsList({
  rows,
  orderItemsWithPst,
  dividesIn,
  appliedFee,
  gstValue,
  selectedBill,
  splitValues,
  setSplitValues,
  bills,
  cloverDeviceConnected,
  order,
  splittingType,
  setSplittingType,
  setAlertMessage,
  saveOn,
}) {
  const [selections, setSelections] = useState([]);
  const [dataToRender, setDataToRender] = useState([]);
  const [appliedPst, setAppliedPst] = useState(0);
  const [billToPay, setBillToPay] = useState(0);
  const [currentBillNo, setCurrentBillNo] = useState(null);
  let {restaurant} = useSelector((state) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedBill) {
      let tempSelections = [];
      tempSelections = bills[selectedBill - 1].selectedItems.map(
        (selectedItem) => selectedItem.id
      );
      if (tempSelections.length) {
        setSelections(tempSelections);
        let temp = bills[selectedBill - 1].selectedItems;
        if (rows.length !== temp.length) {
          let thisItems = rows.filter(
            (item) => !temp.some((tempItem) => tempItem._id == item._id)
          );
          temp = [...temp, ...thisItems];
        }

        setDataToRender(temp);
      } else {
        setSelections([]);
        setDataToRender(rows);
      }
      setCurrentBillNo(selectedBill - 1);
    }
  }, [selectedBill, splittingType]);

  useEffect(() => {
    setDataToRender(rows);
  }, [rows]);

  useEffect(() => {
    if (selections.length && dataToRender.length) {
      let tempBills = [...bills];
      let temp = 0;
      let tempPst = 0;
      for (const item of dataToRender) {
        temp = temp + parseFloat(item.subTotalForBill);
        tempPst = tempPst + parseFloat(item.applicablePst);
      }
      tempPst = tempPst.toFixed(2);
      tempBills[currentBillNo].subTotal = temp;
      let appliedApplicationFee = appliedFee ? parseFloat(appliedFee / dividesIn) : 0;
      let appliedGst = gstValue ? parseFloat(gstValue / dividesIn) : 0;
      temp += appliedApplicationFee + appliedGst + parseFloat(tempPst);

      setBillToPay(temp.toFixed(2));

      tempBills[currentBillNo].appliedApplicationFee = appliedApplicationFee;
      tempBills[currentBillNo].appliedGst = appliedGst;
      tempBills[currentBillNo].appliedPst = parseFloat(tempPst);
      tempBills[currentBillNo].totalBill = temp.toFixed(2);

      setSplitValues((prevValues) => ({...prevValues, bills: tempBills}));
    } else {
      setBillToPay(0);
    }
  }, [dataToRender]);

  const executeSelectionChange = (thisSelections) => {
    if (splittingType === "equally") {
      setSplittingType("manually");
    }
    if (thisSelections.length) {
      if (selections.length < thisSelections.length) {
        //  Logic when selection is added
        let tempData = [...dataToRender];
        let tempAppliedPst = parseFloat(0);

        for (const selector of thisSelections) {
          //Updating selected value
          let index = dataToRender.findIndex((item) => item._id === selector);
          tempData[index] = {
            ...tempData[index],
            selected: true,
          };
        }

        let tempBills = [...bills];
        let temp = tempBills[currentBillNo];
        temp.selectedItems = tempData.filter((eachData) => eachData.selected);
        tempBills[currentBillNo] = temp;

        for (const selector of thisSelections) {
          let billIndexs = tempBills.map((tempBill, index) =>
            tempBill.selectedItems.some((selectedItem) => selectedItem._id === selector)
              ? index
              : -1
          );

          // Calculating divide by
          let divideBy = 0;

          divideBy = tempBills.filter((eachBill) =>
            eachBill.selectedItems.some((selectedItem) => selectedItem._id === selector)
          ).length;

          //Calculating pst
          if (orderItemsWithPst.some((item) => item._id === selector)) {
            tempAppliedPst = orderItemsWithPst.find(
              (item) => item._id === selector
            ).itemTotalPst;
          }

          tempAppliedPst = parseFloat(tempAppliedPst / divideBy).toFixed(2);
          //setting tempdata
          let index = tempData.findIndex((item) => item._id === selector);

          tempData[index] = {
            ...tempData[index],
            subTotalForBill: parseFloat((tempData[index].price / divideBy).toFixed(2)),
            applicablePst: tempAppliedPst,
          };

          //setting bills
          billIndexs.forEach((billIndex) => {
            if (billIndex > -1) {
              let indexOfItem = tempBills[billIndex].selectedItems.findIndex(
                (item) => item._id === selector
              );

              tempBills[billIndex].selectedItems[indexOfItem].subTotalForBill =
                parseFloat(
                  (
                    tempBills[billIndex].selectedItems[indexOfItem].price / divideBy
                  ).toFixed(2)
                );
              tempBills[billIndex].selectedItems[indexOfItem].applicablePst =
                tempAppliedPst;
            }
          });

          setSplitValues((prevValues) => ({...prevValues, bills: tempBills}));
        }
        setDataToRender(tempData);
      } else {
        // Logic when selections got removed
        let tempAppliedPst = parseFloat(0);
        let tempData = [...dataToRender];
        let difference = selections.filter((x) => !thisSelections.includes(x));
        let removedSelections = difference.map((item) => item);

        //setting tempData
        let removedItemIndexInTempData = tempData.findIndex((item) =>
          difference.some((diff) => diff === item._id)
        );
        if (removedItemIndexInTempData > -1) {
          tempData[removedItemIndexInTempData] = {
            ...tempData[removedItemIndexInTempData],
            selected: false,
            subTotalForBill: 0,
            applicablePst: 0,
          };
          setDataToRender(tempData);
        }

        //setting tempbills
        let tempBills = [...bills];
        let temp = tempBills[currentBillNo];
        temp.selectedItems = tempData.filter((eachData) => eachData.selected);
        tempBills[currentBillNo] = temp;

        if (difference.length) {
          for (const selector of removedSelections) {
            let billIndexs = tempBills.map((tempBill, index) =>
              tempBill.selectedItems.some((selectedItem) => selectedItem._id === selector)
                ? index
                : -1
            );
            let divideBy = 0;
            divideBy = tempBills.filter((eachBill) =>
              eachBill.selectedItems.some((selectedItem) => selectedItem._id === selector)
            ).length;
            //Calculating pst
            if (orderItemsWithPst.some((item) => item._id === selector)) {
              tempAppliedPst = orderItemsWithPst.find(
                (item) => item._id === selector
              ).itemTotalPst;
            }

            //setting bills
            billIndexs.forEach((billIndex) => {
              if (billIndex > -1) {
                let indexOfItem = tempBills[billIndex].selectedItems.findIndex(
                  (item) => item._id === selector
                );
                tempBills[billIndex].selectedItems[indexOfItem].subTotalForBill =
                  parseFloat(
                    (
                      tempBills[billIndex].selectedItems[indexOfItem].price / divideBy
                    ).toFixed(2)
                  );

                tempBills[billIndex].selectedItems[indexOfItem].applicablePst =
                  parseFloat(tempAppliedPst / divideBy).toFixed(2);
              }
            });
            setSplitValues((prevValues) => ({...prevValues, bills: tempBills}));
          }
        }
      }
    } else {
      setDataToRender(rows);
      let tempAppliedPst = parseFloat(0);
      let difference = rows.filter((x) => !dataToRender.includes(x));
      let removedSelections = difference.map((item) => item._id);
      let tempBills = [...bills];

      tempBills[currentBillNo].selectedItems = [];
      if (difference.length) {
        for (const selector of removedSelections) {
          let billIndexs = tempBills.map((tempBill, index) =>
            tempBill.selectedItems.some((selectedItem) => selectedItem._id === selector)
              ? index
              : -1
          );

          //calculating divide by
          let divideBy = 0;
          divideBy = tempBills.filter((eachBill) =>
            eachBill.selectedItems.some((selectedItem) => selectedItem._id === selector)
          ).length;

          //Calculating pst
          if (orderItemsWithPst.some((item) => item._id === selector)) {
            tempAppliedPst = orderItemsWithPst.find(
              (item) => item._id === selector
            ).itemTotalPst;
          }

          //setting bills
          billIndexs.forEach((billIndex) => {
            if (billIndex > -1) {
              let indexOfItem = tempBills[billIndex].selectedItems.findIndex(
                (item) => item._id === selector
              );
              tempBills[billIndex].selectedItems[indexOfItem].subTotalForBill =
                parseFloat(
                  (
                    tempBills[billIndex].selectedItems[indexOfItem].price / divideBy
                  ).toFixed(2)
                );

              tempBills[billIndex].selectedItems[indexOfItem].applicablePst = parseFloat(
                tempAppliedPst / divideBy
              ).toFixed(2);
            }
          });
          setSplitValues((prevValues) => ({...prevValues, bills: tempBills}));
        }
      }
    }
    setSelections(thisSelections);
  };

  const handleGenerateBill = async () => {
    if (cloverDeviceConnected) {
      let temp = bills[currentBillNo];
      temp.billGenerated = true;
      try {
        paymentRequestToClover({
          order,
          deviceId: restaurant.clover.deviceId,
          apiToken: restaurant.clover.apiToken,
          restaurantName: restaurant.name,
          splitting: true,
          bill: temp,
        });
        let orderSplitValues = {...splitValues};
        orderSplitValues.bills[currentBillNo] = temp;
        setSplitValues(orderSplitValues);
        let updatedOrder = await updateOrder(order._id, {
          orderSplitValues: orderSplitValues,
          orderSplitted: true,
        });
        dispatch(getLiveOrders());
      } catch (error) {
        setAlertMessage(error.message);
      }
    } else {
      setAlertMessage("Clover device not connected. Printing bill");
      let orderSplitValues = {...splitValues};
      let temp = rows.map((item, index) => ({
        ...item,
        price: orderSplitValues.bills[index].totalBill,
      }));
      for (var i = 0; i < orderSplitValues.splitedIn; i++) {
        printTheReceipt(order._id, {
          items: temp,
          order,
        });
      }
    }
  };
  return (
    <>
      {selectedBill ? (
        <Box>
          <DataGrid
            autoHeight
            rows={dataToRender}
            columns={columns}
            pageSize={dataToRender.length}
            checkboxSelection
            disableSelectionOnClick
            hideFooterPagination
            onSelectionModelChange={(d) => {
              executeSelectionChange(d);
            }}
            selectionModel={selections}
          />
          {bills[currentBillNo]?.billGenerated && (
            <Typography variant="h5" mt={2} color="primary">
              Note: Bill is already generated
            </Typography>
          )}
          <Box
            mt={2}
            style={{display: "flex", alignItems: "center", justifyContent: "right"}}
          >
            <Typography variant="h5" mr={2}>
              Total(Including taxes and fees) : {billToPay}
            </Typography>
            {saveOn ? (
              <Tooltip title="Please click the Save button to proceed">
                <span>
                  <Button
                    variant="contained"
                    onClick={handleGenerateBill}
                    disabled={saveOn}
                  >
                    Generate Bill
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Button variant="contained" onClick={handleGenerateBill} disabled={saveOn}>
                Generate Bill
              </Button>
            )}
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
}
