import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  List,
  ListItem,
  Select,
  TextField,
  Typography,
  Divider,
  LinearProgress,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import ProductCard from "src/components/product/ProductCard";
import {addMenu} from "src/redux/actions/mainMenuActions";
import {addCategory} from "../../redux/actions/restaurantActions";
import EachMenuCard from "./EachMenuCard";
import {makeStyles} from "tss-react/mui";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {copyAllMenuItems} from "./helpers";

const useStyles = makeStyles()((theme) => {
  return {
    selectTimeMsg: {
      margin: "20px auto",
      color: "black",
    },

    timePicker: {
      margin: "10px 30px auto 0px",
    },
  };
});

const MenuListToolbar = ({
  menus,
  tempSocket,
  thisReload,
  setThisReload,
  setShowBackdrop,
  ...rest
}) => {
  const {classes} = useStyles();

  const [open, setOpen] = useState(false);
  const [newMenu, setNewMenu] = useState("");
  const [printer, setPrinter] = useState("");
  const [description, setDescription] = useState("");
  const {_id, printers} = useSelector((state) => state.restaurant);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {role, restaurant} = useSelector((state) => state.auth);
  const {mainMenu, menuLoading} = useSelector((state) => state.mainMenu);
  const flexContainer = {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    overflowX: "auto",
    scrollBehavior: "auto",
  };

  const root = {
    width: "100%",
    maxWidth: 250,
  };

  const [selectedDate, setSelectedDate] = React.useState({
    start: "00:01",
    end: "23:59",
  });

  const [progress, setProgress] = useState(0);
  useEffect(() => {
    if (printers?.length) setPrinter(printers[0]._id);
  }, [printers]);

  const handleDateChange = (e) => {
    setSelectedDate({...selectedDate, [e.target.name]: e.target.value});
  };
  const handleChange = (event) => {
    setNewMenu(event.target.value);
  };

  const handleClickOpen = (message) => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
    setDescription("");
    setPrinter("");
    setSelectedDate({
      start: "00:01",
      end: "23:59",
    });
  };

  const handleAddMenu = (event) => {
    const data = {
      name: newMenu,
      description: description,
      availability: selectedDate,
      printer: printer,
    };
    dispatch(addMenu(data, navigate, setThisReload, thisReload));
    handleDialogClose();
  };

  const handleCopyMenuItems = () => {
    copyAllMenuItems(restaurant, setThisReload, thisReload, dispatch, setProgress);
  };

  function CircularProgressWithLabel(props) {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" {...rest} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="caption"
            component="div"
            color="textSecondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"ADD MENU DESCRIPTION"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <DialogContentText>{/* {message} */}</DialogContentText>
            <>
              <TextField
                autoFocus
                margin="dense"
                name="name"
                id="name"
                label="Menu Name"
                fullWidth
                required
                onChange={handleChange}
              />
              <TextField
                autoFocus
                type="text"
                margin="dense"
                name="description"
                id="description"
                label="Menu Description"
                fullWidth
                required
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <Select
                native
                value={printer}
                onChange={(e) => setPrinter(e.target.value)}
                inputProps={{
                  name: "Select Printer",
                  id: "age-native-simple",
                }}
                style={{margin: "7px 0"}}
              >
                {printers?.map((thisPrinter, index) => (
                  <option key={thisPrinter._id} value={thisPrinter._id}>
                    {thisPrinter.customName}
                  </option>
                ))}
              </Select>
              <Typography className={classes.selectTimeMsg}>
                Select Timeframe in which the menu will be available
              </Typography>
              <TextField
                id="start"
                name="start"
                label="START TIME"
                type="time"
                defaultValue="00:01"
                size="small"
                onChange={handleDateChange}
                className={classes.timePicker}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />

              <TextField
                id="end"
                name="end"
                label="END TIME"
                type="time"
                defaultValue="23:59"
                size="small"
                onChange={handleDateChange}
                className={classes.timePicker}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />

              {/* <label for="end">Select a End time:</label>
            <input type="time" id="end" name="end" onChange={handleDateChange} /> */}
            </>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
          <Button onClick={handleAddMenu} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Box {...rest}>
        <Box sx={{mt: 2}}>
          <Card>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 2,
              }}
            >
              <Typography variant="h3" color="textPrimary">
                Main menu
              </Typography>
              {role === "ROLE_ADMIN" && (
                <>
                  <Button
                    color="primary"
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={handleClickOpen}
                    className="addNewButton"
                  >
                    Add New Menu
                  </Button>

                  {restaurant !== _id && !mainMenu?.length && !progress ? (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleCopyMenuItems}
                      className="copyMenuItems"
                      style={{marginLeft: "10px"}}
                    >
                      Copy From Main Branch
                    </Button>
                  ) : (
                    ""
                  )}
                  {progress ? <CircularProgressWithLabel value={progress} /> : ""}
                </>
              )}
            </Box>
            {menuLoading ? <LinearProgress /> : <Divider />}
            <Box sx={{mt: 3}}>
              {/* {!menuLoading && ( */}
              <List style={flexContainer}>
                {menus?.length ? (
                  menus.map((eachMenu) => {
                    return (
                      <ListItem style={root} key={eachMenu?._id}>
                        <EachMenuCard
                          name={eachMenu.name}
                          printer={eachMenu.printer ? eachMenu.printer : "123"}
                          selectedDate={eachMenu.availability}
                          menuId={eachMenu._id}
                          status={eachMenu.status}
                          /*sequence={product.sequence ? product.sequence : 0 }  */ description={
                            eachMenu.description
                          }
                          setThisReload={setThisReload}
                          thisReload={thisReload}
                          tempSocket={tempSocket}
                        />
                      </ListItem>
                    );
                  })
                ) : (
                  <Box
                    style={{
                      display: "flex",
                      width: "100%",
                      minHeight: "100px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography textAlign="center"> No Menu Yet</Typography>
                  </Box>
                )}
              </List>
              {/* )} */}
            </Box>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default MenuListToolbar;
