import {Button, Paper, Typography, Box} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import React from "react";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import {makeDecrypt} from "src/utils/securels";
import {useSelector} from "react-redux";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
const useStyles = makeStyles()(() => {
  return {
    planCard: {
      display: "flex",
      width: (manage) => {
        return manage ? "270px" : "240px";
      },
      height: (manage) => {
        return manage ? "350px" : "400px";
      },
      flexDirection: "column",
      padding: "20px ",
      margin: "auto",
      boxShadow:
        "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;",
      borderRadius: "10px",
    },
    activePlan: {
      display: "flex",
      width: (manage) => {
        return manage ? "270px" : "240px";
      },
      height: (manage) => {
        return manage ? "350px" : "400px";
      },
      flexDirection: "column",
      padding: "20px ",
      margin: "auto",
      boxShadow:
        "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;",
      borderRadius: "10px",
      backgroundColor: "#FFEEEE",
      border: "1px solid #ff5757",
    },
    planName: {},
    priceMonthly: {
      fontSize: "3vw",
      margin: "20px 0",
      "&::after": {
        content: "'/mo'",
        fontSize: "14px",
        position: "relative",
        left: "5px",
      },
    },
    priceYearly: {
      fontSize: "3vw",
      margin: "20px 0",
      "&::after": {
        content: "'/ye'",
        fontSize: "14px",
        position: "relative",
        left: "5px",
      },
    },
    period: {
      // content: '"/MO"',
      fontSize: "14px",
      position: "absolute",
      right: "-25px",
      bottom: "10px",
    },
    features: {
      marginTop: "10px",
    },
  };
});
function PlanCard({selectedPrice, item, products, handleClick, manage}) {
  const {classes} = useStyles(manage);
  let country = useSelector((state) => state.restaurant.restaurantAddress.country);

  let {currencySign} = useSelector((state) => state.restaurant);

  // if (!currencySign) {
  if (
    makeDecrypt("country") == "CANADA" ||
    (country?.toUpperCase() == "CANADA" && manage)
  ) {
    currencySign = "$";
  } else if (
    makeDecrypt("country") == "INDIA" ||
    (country?.toUpperCase() == "INDIA" && manage)
  ) {
    currencySign = "₹";
  } else if (
    makeDecrypt("country") == "NEPAL" ||
    (country?.toUpperCase() == "NEPAL" && manage)
  ) {
    currencySign = "रु";
  }
  // }

  const planName =
    makeDecrypt("country") == "CANADA" || (country?.toUpperCase() == "CANADA" && manage)
      ? products.find((thisProduct) => thisProduct.id == item.product).name
      : item.item.name;


  const interval =
    makeDecrypt("country") == "CANADA" ? item.recurring.interval : item.period;

  return (
    <Box
      className={
        selectedPrice.id === item.id && !manage ? classes.activePlan : classes.planCard
      }
    >
      <Typography variant="h5" className={classes.planName}>
        {planName}
      </Typography>
      <Typography
        variant="h1"
        className={interval === "year" ? classes.priceYearly : classes.priceMonthly}
      >
        {currencySign}
        {parseFloat(
          makeDecrypt("country") == "CANADA" ||
            (country?.toUpperCase() == "CANADA" && manage)
            ? item.unit_amount / 100
            : item.item.amount / 100
        )}
      </Typography>
      {interval === "year" || interval === "yearly" ? (
        <>
          <Typography variant="body2" className={classes.features}>
            Free trial
          </Typography>
          <Typography variant="h4" style={{fontWeight: 700}}>
            1 Week
          </Typography>
          {makeDecrypt("country") == "CANADA" && (
            <>
              <Typography variant="body2" className={classes.features}>
                {!manage ? "You are saving " : "You have saved "}
              </Typography>
              <Typography variant="h4" style={{fontWeight: 700}}>
                {currencySign}
                {parseFloat(item.unit_amount / 100).toFixed(2) / 11}
              </Typography>
            </>
          )}
        </>
      ) : (
        <>
          <Typography variant="body2" className={classes.features}>
            {!manage
              ? `Charges`
              : item.recurring && item.recurring.aggregate_usage
              ? `Charges`
              : `Charges`}
          </Typography>
          <Typography variant="h4" style={{fontWeight: 700}}>
            {currencySign}
            {parseFloat(
              makeDecrypt("country") == "CANADA" ||
                (country?.toUpperCase() == "CANADA" && manage)
                ? item.unit_amount / 100
                : item.item.amount / 100
            ).toFixed(2)}
            {item.recurring && item.recurring.aggregate_usage ? `/Order` : ""}
          </Typography>
        </>
      )}
      {!manage ? (
        <Box sx={{marginTop: "auto", display: "flex", alignItems: "center"}}>
          {selectedPrice.id === item.id && (
            <CheckCircleOutlineIcon fontSize="small" color="primary" />
          )}
          <Typography
            color="primary"
            style={{cursor: "pointer"}}
            onClick={() => handleClick(item, planName)}
            variant="h5"
          >
            {selectedPrice.id === item.id ? "Selected plan" : "Select plan"}
          </Typography>
        </Box>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={handleClick}
          style={{marginTop: "auto"}}
        >
          MANAGE SUBSCRIPTION
        </Button>
      )}
    </Box>
  );
}

export default PlanCard;
