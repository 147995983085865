import {SET_TABLE} from "src/redux/types";
import {makeDecrypt} from "src/utils/securels";
import axios from "../../../axios";

export const getFloorLayout = async () => {
  const restId = makeDecrypt("restaurantId");

  return await axios
    .get(`/restaurant/floor-layout/${restId}`)
    .then((res) => {
      return res.data.tables;
    })
    .catch((error) => {
      return error.message;
    });
};

export const updateFloorLayout = async (tables, dispatch) => {
  const restId = makeDecrypt("restaurantId");

  return await axios
    .post(`/restaurant/update-floor-layout/${restId}`, {tables: tables})
    .then((res) => {
      dispatch({
        type: SET_TABLE,
        payload: res.data.tables,
      });
      return res.data;
    })
    .catch((error) => {
      return error.message;
    });
};

export const disableTable = async (tableId, status) => {
  const restId = makeDecrypt("restaurantId");

  return await axios
    .post(`/restaurant/change-table-status/${restId}`, {
      tableId: tableId,
      status: status,
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const createUser = async () => {
  return await axios
    .post("/user/user-details", {new: true})
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getReservations = async () => {
  const restId = makeDecrypt("restaurantId");

  return await axios
    .get(`/restaurant/get-reservations/${restId}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.message;
    });
};
