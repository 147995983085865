import {
  Box,
  Breadcrumbs,
  Grid,
  Container,
  Pagination,
  FormControl,
  InputLabel,
  CircularProgress,
  Card,
  Select,
  Typography,
  MenuItem,
  CardContent,
  Button,
  Link,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getCustomerDetailsByPage} from "../components/CRM/helpers";
import {FileDownload} from "@mui/icons-material";
import {ExportToCsv} from "export-to-csv-fix-source-map";
import CustomerTable from "src/components/CRM/CustomerTable";
import Spinner from "../utils/spinner/spinner";
import DateFilter from "../components/CRM/DateFilter";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {makeStyles} from "tss-react/mui";
import {Users} from "react-feather";
import HomeIcon from "@mui/icons-material/Home";
import {CrmCsvOptions} from "src/components/CRM/helpers/data";
const useStyles = makeStyles()((theme) => {
  return {
    breadCrumbs: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "2px 8px ",
      borderRadius: "20px",
      backgroundColor: "#ffffff",
      border: "1px solid #FF5757",
      fontSize: "15px",
      fontWeight: "500",
      "& .MuiBreadcrumbs-separator svg": {
        fontSize: "20px !important",
      },
    },
    root: {
      display: "flex",
      justifyContent: "space-between",
    },
    button: {
      display: "block",
      marginTop: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    loadingBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "300px",
    },
    filterBox: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "space-between",
      },
      [theme.breakpoints.up("sm")]: {
        justifyContent: "left",
      },
    },
    paginationBox: {
      display: "flex",
      alignItems: "right",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
      [theme.breakpoints.up("sm")]: {
        justifyContent: "right",
      },
    },
  };
});

const Crm = () => {
  const {classes} = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [startDate, setstartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const csvExporter = new ExportToCsv(CrmCsvOptions);
  const [endDate, setendDate] = useState(new Date());
  const [pageNo, setpageNo] = useState(1);
  const {authenticated, loading, role} = useSelector((state) => state.auth);
  const [limit, setlimit] = useState(10);
  const [customerType, setCustomerType] = useState("All");
  const [open, setOpen] = useState(false);
  const [openType, setopenType] = useState(false);
  const [count, setCount] = useState(1);
  const [dataloading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [exportData, setExportData] = useState([]);
  useEffect(() => {
    setLoading(true);
    const getdetails = async () => {
      const response = await getCustomerDetailsByPage(
        startDate,
        endDate,
        pageNo,
        limit,
        customerType
      );
      setData(response.data);
      if (customerType == "All") {
        const temp = response.wholedata.map((t) => {
          return {...t, items: t.items.map((k) => k.name).toString()};
        });
        setExportData(temp);
      }

      setCount(response.maxPages);
      setLoading(false);
    };
    getdetails();
  }, [startDate, endDate, pageNo, limit, customerType]);

  const handleExportData = () => {
    csvExporter.generateCsv(exportData);
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>CRM Details | Dinify</title>
      </Helmet>
      {loading === true ? (
        <Spinner />
      ) : (
        <Box
          sx={{
            backgroundColor: "background.default",
            minHeight: "100%",
            py: 3,
          }}
        >
          <Box sx={{m: 1, mb: 5}}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link
                color="primary"
                onClick={() => navigate("/app/dashboard")}
                style={{cursor: "pointer"}}
              >
                <Box className={classes.breadCrumbs}>
                  <HomeIcon
                    color="primary"
                    style={{fontSize: "15px", marginRight: "2px"}}
                  />
                  Home
                </Box>
              </Link>
              <Link
                color="primary"
                // href="/app/orders"
                style={{cursor: "pointer"}}
              >
                <Box className={classes.breadCrumbs}>
                  <Users size={15} style={{marginRight: "2px"}} />
                  Customer Relationship Management
                </Box>
              </Link>
            </Breadcrumbs>
          </Box>
          <Container maxWidth={false} sx={{mt: 2}}>
            <Box sx={{display: "flex", justifyContent: "space-between"}}>
              <DateFilter
                setStartingDate={setstartDate}
                setEndingDate={setendDate}
              ></DateFilter>
              <Button
                color="primary"
                onClick={handleExportData}
                startIcon={<FileDownload />}
                variant="contained"
                size="small"
              >
                Export Data
              </Button>
            </Box>
            <Box sx={{pt: 3}}>
              <Box>
                <Typography display="inline-flex" variant="h4" align="left">
                  Your Customers
                </Typography>
              </Box>
              {dataloading ? (
                <Box className={classes.loadingBox}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {data.length ? (
                    <CustomerTable data={data} />
                  ) : (
                    <Box>
                      <Card variant="outlined" sx={{minWidth: 275}}>
                        <CardContent>
                          <Typography sx={{fontSize: 20}} align="center">
                            No orders made on those dates
                          </Typography>
                        </CardContent>
                      </Card>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Container>
          <Box className={classes.root}>
            <Grid container display={"flex"} alignItems="center">
              <Grid item sm={6} xs={12}>
                <Box className={classes.filterBox}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-controlled-open-select-label-limit">
                      Rows/Page
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label-limit"
                      id="demo-controlled-open-select-limit"
                      open={open}
                      onClose={() => setOpen(false)}
                      onOpen={() => setOpen(true)}
                      value={limit}
                      onChange={(e) => setlimit(e.target.value)}
                      size="small"
                      label="Rows/Page"
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-controlled-open-select-label-type">
                      New/Repeating Customer
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label-type"
                      id="demo-controlled-open-select-type"
                      open={openType}
                      onClose={() => setopenType(false)}
                      onOpen={() => setopenType(true)}
                      value={customerType}
                      onChange={(e) => setCustomerType(e.target.value)}
                      size="small"
                      label="Rows/Page"
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      <MenuItem value={"new"}>New</MenuItem>
                      <MenuItem value={"old"}>Repeating</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Box className={classes.paginationBox}>
                  <Pagination
                    count={count}
                    color="primary"
                    onChange={(e, val) => setpageNo(val)}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

export default Crm;
